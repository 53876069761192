
import YouTube from '../assets/images/musicLinks/yt_music.svg';
import SoundCloud from '../assets/images/musicLinks/sound_cloud.svg';
import AppleMusic from '../assets/images/musicLinks/apple_music.svg';
import ApplePodcasts from '../assets/images/musicLinks/apple_podcasts.svg';
import Anghami from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/anghamiDefault.svg';
import Spotify from '../assets/images/spotify.svg';
import Deezer from '../assets/images/deezer.jpg';
import GenericIcon from '../assets/images/music.svg';
export const MusicLinkPlatformsMap = [
    {value: 'spotify', label: 'Spotify', icon: Spotify},
    {value: 'appleMusic', label: 'Apple Music', icon: AppleMusic},
    {value: 'youtubeMusic', label: 'Youtube Music', icon: YouTube},
    {value: 'soundCloud', label: 'Sound Cloud', icon: SoundCloud},
    {value: 'deezer', label: 'Deezer', icon: Deezer},
    {value: 'applePodcast', label: 'Apple Podcasts', icon: ApplePodcasts},
    {value: 'anghami', label: 'Anghami', icon: Anghami},
    {value: 'others', label: 'Others', icon: GenericIcon},
];
export const getPlatformDetails = (type: string) => {
    return MusicLinkPlatformsMap.find((e) => e.value === type) || null;
};

