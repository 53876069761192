import React, {useState} from 'react';
import BaseModal from '../LinkModal/components/BaseModal';
import {BaseModalProps} from '../LinkModal/components/BaseModal';
import nonHookRequest from '../../features/API/nonHookRequest';
import {SelectDeal} from './steps/SelectDeal';
import {AddProductDetails} from './steps/AddProductDetails';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {raiseToast} from '../Toast/Toast';

type Props = BaseModalProps & {
  getTrackingLinks: () => Promise<void>;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>
};

const AddTrackingLinkModal = ({getTrackingLinks, setCurrPage, onClose, ...props}: Props) => {
  const [step, setStep] = useState(1);
  const [productTitle, setProductTitle] = useState<string | null>(null);
  const [productLink, setProductLink] = useState<string | null>(null);
  const [productLogo, setProductLogo] = useState<string | null>(null);
  const [userDealId, setUserDealId] = useState<string | null>(null);

  const {t} = useTranslation();

  const resetForm = () => {
    setStep(1);
    setProductTitle(null);
    setProductLink(null);
    setProductLogo(null);
    setUserDealId(null);
  };

  const handleAddingDetails = async () => {
    const response = await nonHookRequest({
      url: `/brandshub/api/user-deal/trackinglink/${userDealId}`,
      method: 'PUT',
      body: {content: productLink, description: productTitle, logoUrl: productLogo},
      isShortUrl: true,
      filterError: true,
    });

    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: t('tracking_link_added')});
      setCurrPage(1);
      getTrackingLinks();
      onClose({}, 'close');
      resetForm();
    };
  };
    
  return (
    <BaseModal {...props} onClose={() => {
      onClose({}, 'close');
      resetForm();
    }}>
      {step === 1 && 
        <SelectDeal
          userDealId={userDealId}
          setUserDealId={setUserDealId}
          setStep={setStep}
        />
      }
      {step === 2 && 
        <AddProductDetails 
          handleAddingDetails={handleAddingDetails}
          productTitle={productTitle}
          setProductTitle={setProductTitle}
          productLink={productLink}
          setProductLink={setProductLink}
          productLogo={productLogo}
          setProductLogo={setProductLogo}
        />
      }
    </BaseModal>
  );
};

export default AddTrackingLinkModal;
