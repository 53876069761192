import React from 'react';
import './landingFooter.scss';
import logo from '../../assets/images/landing/LogoWhiteFont.svg';
import pin from '../../assets/images/landing/pin.svg';
import phone from '../../assets/images/landing/phone.svg';
import {useTranslation} from 'react-i18next';
import {isArabic} from '../../features/util';


const LandingFooter = () => {
  const {t, i18n} = useTranslation();
  const arabicFormat: boolean = isArabic(i18n.language);
  return (
    <footer id='landingFooter'>
      <div className="hearFromYou">
        <div>{t('we_would_love_to_hear_from_you')}</div>
        <p>support@alfangroup.com</p>
      </div>
      <div className="company">
        <h4>{t('company')}</h4>
        <ul>
          <li> <a href="https://alfangroup.com/public/network" target='_blank' rel="noreferrer">{t('network')} </a> </li>
          <li> <a href="https://alfangroup.com/public/audio" target='_blank' rel="noreferrer">{t('audio')} </a> </li>
          <li> <a href="https://alfangroup.com/public/brands" target='_blank' rel="noreferrer">{t('brands')} </a> </li>
          <li> <a href="https://alfangroup.com/public/careers" target='_blank' rel="noreferrer">{t('careers')} </a> </li>
        </ul>

      </div>
      <div className="info">
        <h4>{t('info')}</h4>
        <ul>
          <li> <a href="https://app.al.fan/privacy-policy" target='_blank' rel="noreferrer"> {t('privacy_policy')}</a></li>
          <li> <a href="https://app.al.fan/terms-and-condition" target='_blank' rel="noreferrer"> {t('terms_conditions')} </a></li>
        </ul>

      </div>

      <div className="contact">
        <h4>{t('contact_footer')}</h4>
        <div className={`companyName row`}>
          <img src={pin} alt="location" />
          <p>Alfan Dubai</p>
        </div>
        <p className="location">
        2 King Salman Bin Abdulaziz Al Saud St - CNN Building, Office 406 & 410 - Dubai Media City - Dubai
        </p>
        <div className={`phoneNumber row`}>
          <img src={phone} className={arabicFormat?'reverseIcon':''} alt="phone"/>
          <div className='number-container' dir='ltr'>+971 56 623 4747</div>
        </div>

      </div>

      <div className="logoAndRights">
        <img id="footer-logo" src={logo} alt="logo" />
        <p>Copyright © 2023 Alfan Group. All rights reserved</p>

      </div>
    </footer>
  );
};

export default LandingFooter;
