import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface kycState {
  data: any;
  exist: boolean;
  uploads: any;
}

const initialState: kycState = {
  data: {},
  uploads: [],
  exist: true,
};

export const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setKyc: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.exist = true;
    },
    removeKyc: (state) => {
      state.data = {};
      state.exist = false;
    },
    setKycUploads: (state, action: PayloadAction<any>) => {
      state.uploads = action.payload;
    },
    removeKycUploads: (state) => {
      state.uploads = [];
    },
  },
});

export const {setKyc, removeKyc, setKycUploads, removeKycUploads} = kycSlice.actions;

export const getKycStore = (state: RootState) => state.kyc;

export default kycSlice.reducer;
