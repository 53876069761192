import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';
import {BaseModalProps} from '../components/BaseModal';

export const MonetizeTypes = ['videoMsg', 'tipJar', 'socialMediaPromote', 'sellProduct', 'sellProductGuide', 'shortCall'] as const;

export type MonetizeType = typeof MonetizeTypes[number];

export type LinkModalType = MonetizeType | 'link' | 'custom';

export type LinkModalProps = BaseModalProps & {
  defaultLink?: LinksSectionItem;
  editable?: boolean;
  mode?: 'add' | 'edit';
  onSave: (link: LinksSectionItem) => void;
  onDelete: (id: string) => void;
};

export const initialLink: LinksSectionItem = {
  id: '',
  title: '',
  url: '',
  type: '',
  hidden: false,
  isVisible: true,
  customUploadedImage: '',
  linksSectionId: '',
  orderNumber: 0,
};

export type MonetizeLink = {
  id: string;
  title: string;
  subTitle: string;
  alfanPercantage: number;
  maxPrice: number;
  minPrice: number;
};
