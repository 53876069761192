import React, {useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import currencies from '../../../constants/currencies';
import {CurrencySelectorProps} from "./componentInterface";
import {useStylesBox} from './styleCustom';

const CurrencySelector = ({handleInputChange, item}: CurrencySelectorProps) => {
    const classesInput = useStylesBox();

    return (
        <Select
        style={{width: '150px', height: '56px', padding: '6px 20px', marginRight: '0.2rem', fontSize: '16px', fontWeight: 400, textTransform: 'uppercase', background: 'white', borderRadius: '26px', borderBottom: '1px solid rgba(217, 217, 217, 0.6)'}}
        className={classesInput.textField}
        labelId="currency-selector"
        id="currency-selector"
        disableUnderline
        value={item.currency}
        onChange={(e) => handleInputChange(e.target.value, item.id, 'currency')}
        onBlur={(e) => handleInputChange(e.target.value, item.id, 'currency')}
    >
        {Object.keys(currencies).map((val) => <MenuItem style={{textTransform: 'uppercase'}} key={`currency-name-${val}`} value={val}>{val}</MenuItem>)}
    </Select>
    );
};

export default CurrencySelector;
