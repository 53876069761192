import youtubeIcon from '../assets/images/PlatformEarningIcons/01-Youtube-Red.png';
import spotify from '../assets/images/PlatformEarningIcons/18- Spotify.png';
import deezer from '../assets/images/PlatformEarningIcons/16-Deezer-White.png';
import facebook from '../assets/images/PlatformEarningIcons/05-Facebook.png';
import tiktok from '../assets/images/PlatformEarningIcons/03-Tiktok.png';
import amazonUnlimited from '../assets/images/PlatformEarningIcons/06-AmazonMusic.png';
import tim from '../assets/images/PlatformEarningIcons/07-Tim-white.png';
import ytMusic from '../assets/images/PlatformEarningIcons/02-YT Music-Red.png';
import napster from '../assets/images/PlatformEarningIcons/08-Napster.png';
import anghami from '../assets/images/PlatformEarningIcons/04-Anghami.png';
import joox from '../assets/images/PlatformEarningIcons/09-Joox.png';
import tidal from '../assets/images/PlatformEarningIcons/07-Tim.png';
import saavn from '../assets/images/PlatformEarningIcons/11-JioSavaan-White.png';
import imusica from '../assets/images/PlatformEarningIcons/12-imusica.png';
import iheart from '../assets/images/PlatformEarningIcons/13-IHeart.png';
import appleMusic from '../assets/images/PlatformEarningIcons/14-Apple Music.png';
import pandora from '../assets/images/PlatformEarningIcons/15-Pandora.png';
import itunes from '../assets/images/PlatformEarningIcons/17-ITunes.png';
import lineMusic from '../assets/images/PlatformEarningIcons/19-LineMusic-Green.png';
import yandex from '../assets/images/PlatformEarningIcons/20-Yandex.png';
import soundCloud from '../assets/images/PlatformEarningIcons/21-Soundcloud-Orange.png';
import soundtrack from '../assets/images/PlatformEarningIcons/22-SoundTrack Your Brand-White.png';
import music from '../assets/images/PlatformEarningIcons/Alfan_Music_Logo_Design.svg';
import instagram from '../assets/images/PlatformEarningIcons/Instagram_logo_2016.svg';
import ultimateMusicChina from '../assets/images/PlatformEarningIcons/ultimate-music-china.png';

import snapchat from '../assets/images/PlatformEarningIcons/Snapchat.png';
import nono from '../assets/images/PlatformEarningIcons/Nono_Live_Logo.png';
import souq from '../assets/images/PlatformEarningIcons/Alfan-Souq-Logo-Design.png';
import darkMoney from '../assets/images/PlatformEarningIcons/deal.black.svg';
import twitter from '../assets/images/versionV2/SocialIcons/SocialIconsDefault/twitterDefault.svg';
import genericMusic from '../assets/images/PlatformEarningIcons/Generic-Music.png';


export const getDenseCardIcons: any = {
    'Instagram': instagram,
    'YouTube': youtubeIcon,
    'Youtube': youtubeIcon,
    'YouTube CSV': youtubeIcon,
    'Spotify': spotify,
    'TikTok': tiktok,
    "TikTok Inc.": tiktok,
    'Deezer': deezer,
    'Facebook': facebook,
    'Amazon Unlimited': amazonUnlimited,
    'Youtube Ad Supported': youtubeIcon,
    'TIM Italia': tim,
    'Youtube Music': ytMusic,
    'Napster': napster,
    'Anghami': anghami,
    'Joox': joox,
    'Tidal': tidal,
    'JioSaavn': saavn,
    'iMusica': imusica,
    'iHeart Radio US': iheart,
    'Apple Music': appleMusic,
    'Pandora': pandora,
    'iTunes': itunes,
    'Soundcloud': soundCloud,
    'LINE Music': lineMusic,
    'Yandex': yandex,
    'Yandex Music': yandex,
    'Soundtrack Your Brand': soundtrack,
    'Snapchat': snapchat,
    'Nono Live': nono,
    'Alfan Souq': souq,
    'Alfan Music': music,
    'Deals': darkMoney,
    'Twitter': twitter,
    'Ultimate Music China': ultimateMusicChina,
    'Other music streaming platforms': genericMusic,
    "Other music streaming platforms (Sept'22 to Mar'23)": genericMusic,
    "Other music streaming platforms (Sept'22 to Dec'22)": genericMusic,
    "Other music streaming platforms (Jan'23 to Mar'23)": genericMusic,
    "Other music streaming platforms (Apr'23 to Jun'23)": genericMusic,
    "Other music streaming platforms (July'23 to Sept'23)": genericMusic,
  };
