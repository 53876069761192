import React from 'react';
import styles from '../ColorPicker/CustomColorPicker.module.scss';
import './CustomPicker.css';
import ColorPicker from 'coloreact';
import {store} from '../../app/store';
import {setUserTheme} from '../../features/stores/userThemeSlicer';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';


const CustomColorPicker = ({color, setColor, fieldName, theme}: any) => {
  const changeColor = (color: any) => {
    let b = color.rgbString;
    if (fieldName === 'borderColor' && theme.borderColor === 'transparent') {
      b= b.replace('0)', '0.99)');
    }
    setColor(b);
    return b;
  };

  const width = useWindowWidth();
  return (
    <div className={styles.container} >
      <div className={styles.color_picker}>
        <ColorPicker
          opacity={true}
          style={{
            position: 'relative',
            height: '220px',
            width: '220px',
            border: 0,
            background: 'transparent',
          }}
          color={color}
          onChange={(color)=>{
            let value = changeColor(color);
            store.dispatch(setDataChange(true));
            if (fieldName === 'boxShadow') {
              value = '2px 6px 16px ' + value;
            }
            store.dispatch(setUserTheme({
              ...theme, [fieldName]: value, themeName: 'custom',
              ...(fieldName === 'backgroundColor' && {mobileImage: '', desktopImage: ''}
              ),
            }));
          }}
        />
      </div>
      <div className={styles.hex_wrapper}>
        <span className={styles.hex}>RGB: </span>
        <span className={styles.hex_value}>{color}</span>
      </div>
    </div>
  );
};

export default CustomColorPicker;
