import {UserThemeState} from '../features/stores/userThemeSlicer';

interface Radius {
  borderRadius: string,
  mobileBorderRadius: string,
  mobilePreviewBorderRadius: string,
  desktopPreviewBorderRadius: string,
  themePreviewBorderRadius: string,
}

export interface ICardShape {
  name: string,
  border: Radius,
  icon: Radius
}

export const previewStyles = {
  MOBILE_PREVIEW: 'mobile-preview',
  DESKTOP_PREVIEW: 'desktop-preview',
  DEFAULT: 'default',
};

export const cardShape = {
  ROUNDED: {
    name: 'rounded',
    border: {
      borderRadius: '12px',
      mobileBorderRadius: '12px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '5px',
      themePreviewBorderRadius: '12px',
    },
    icon: {
      borderRadius: '12px',
      mobileBorderRadius: '12px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '5px',
      themePreviewBorderRadius: '6px',
    },
    video: {
      borderRadius: '16px',
      mobileBorderRadius: '16px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '4px',
      themePreviewBorderRadius: '12px',
    },
    header: {
      borderRadius: '16px',
      mobileBorderRadius: '16px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '4px',
      themePreviewBorderRadius: '12px',
    },
  },
  OVAL: {
    name: 'oval',
    border: {
      borderRadius: '32px',
      mobileBorderRadius: '32px',
      mobilePreviewBorderRadius: '32px',
      desktopPreviewBorderRadius: '20px',
      themePreviewBorderRadius: '32px',
    },
    icon: {
      borderRadius: '32px',
      mobileBorderRadius: '32px',
      mobilePreviewBorderRadius: '32px',
      desktopPreviewBorderRadius: '12px',
      themePreviewBorderRadius: '32px',
    },
    video: {
      borderRadius: '16px',
      mobileBorderRadius: '16px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '4px',
      themePreviewBorderRadius: '12px',
    },
    header: {
      borderRadius: '16px',
      mobileBorderRadius: '16px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '4px',
      themePreviewBorderRadius: '12px',
    },
  },
  SQUARE: {
    name: 'square',
    border: {
      borderRadius: '0px',
      mobileBorderRadius: '0px',
      mobilePreviewBorderRadius: '0px',
      desktopPreviewBorderRadius: '0px',
      themePreviewBorderRadius: '0px',
    },
    icon: {
      borderRadius: '13px',
      mobileBorderRadius: '0px',
      mobilePreviewBorderRadius: '8px',
      desktopPreviewBorderRadius: '3px',
      themePreviewBorderRadius: '5px',
    },
    video: {
      borderRadius: '0px',
      mobileBorderRadius: '0px',
      mobilePreviewBorderRadius: '0px',
      desktopPreviewBorderRadius: '0px',
      themePreviewBorderRadius: '0px',
    },
    header: {
      borderRadius: '0px',
      mobileBorderRadius: '0px',
      mobilePreviewBorderRadius: '0px',
      desktopPreviewBorderRadius: '0px',
      themePreviewBorderRadius: '0px',
    },
  },
  EDGES: {
    name: 'edges',
    border: {
      borderRadius: '8px 35px',
      mobileBorderRadius: '3.44px 30.58px',
      mobilePreviewBorderRadius: '3.44px 30.58px',
      desktopPreviewBorderRadius: '1px 9.5px',
      themePreviewBorderRadius: '3px 15px',
    },
    icon: {
      borderRadius: '13px 13px 13px 25px',
      mobileBorderRadius: '3.44px 30.58px',
      mobilePreviewBorderRadius: '8.77px 8.77px 8.77px 16.98px',
      desktopPreviewBorderRadius: '3.16px 3.16px 3.16px 7px',
      themePreviewBorderRadius: '6px 6px 6px 10px',
    },
    video: {
      borderRadius: '16px',
      mobileBorderRadius: '16px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '4px',
      themePreviewBorderRadius: '12px',
    },
    header: {
      borderRadius: '16px',
      mobileBorderRadius: '16px',
      mobilePreviewBorderRadius: '12px',
      desktopPreviewBorderRadius: '4px',
      themePreviewBorderRadius: '12px',
    },
  },
};

export const englishFonts = {
  LUFGA: 'Lufga',
  UBUNTU: 'Ubuntu',
  ANTON: 'Anton',
  QUICKSAND: 'Quicksand',
  LOBSTER: 'Lobster',
  ROBOTO: 'Roboto',
  MONTSERRAT: 'Montserrat',
  MERRYWEATHER: 'Merriweather',
  SPACEGROTESK: 'Space Grotesk',
  EBGARAMOND: 'EB Garamond',
  OPENSANS: 'Open Sans',
  EXODUSSANS: 'EudoxusSans',
};

export const arabicFonts ={
  CAIRO: 'Cairo',
  REEM_KUFI: 'Reem Kufi',
  TAJAWAL: 'Tajawal',
  LEMONADA: 'Lemonada',
  AREF_RUQAA: 'Aref Ruqaa',
  CHANGA: 'Changa',
};

export const NULL_THEME = {
  themeName: null,
  mobileImage: null,
  desktopImage: null,
  cardTextColor: null,
  nonCardTextColor: null,
  backgroundColor: null,
  cardColor: null,
  mobileSocialMediaColor: null,
  desktopSocialMediaColor: null,
  cardShape: null,
  englishFont: null,
  arabicFont: null,
  iconSet: null,
  boxShadow: null,
  cardStyle: null,
  borderColor: null,
};

export const CARD_STYLE = {
  SOLID: 'solid',
  OUTLINE: 'outline',
};

export const themes:UserThemeState[]=[
  {
    themeName: '01',
    mobileImage: require(`../assets/images/Wallpapers/37-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/37-D.png`).default,
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    backgroundColor: '#e4eaed',
    cardColor: '#ffffff',
    mobileSocialMediaColor: '#0B0B0B', // was mobileIconColor
    desktopSocialMediaColor: '#0B0B0B',
    cardShape: cardShape.ROUNDED.name,
    englishFont: englishFonts.ROBOTO,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    borderColor: 'transparent',
    nonCardTextColorDesktop: '#23262F',
  },
  {
    themeName: '02',
    mobileImage: require(`../assets/images/Wallpapers/39-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/39-D.png`).default,
    cardTextColor: '#000000',
    nonCardTextColor: '#000000',
    backgroundColor: '#FBF7F1',
    cardColor: '#FFFFFF',
    mobileSocialMediaColor: '#0B0B0B',
    desktopSocialMediaColor: '#0B0B0B',
    cardShape: cardShape.OVAL.name,
    englishFont: englishFonts.ROBOTO,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'black',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    borderColor: 'transparent',
    nonCardTextColorDesktop: '#000000',
  },
  {
    themeName: '03',
    mobileImage: require(`../assets/images/Wallpapers/38-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/38-D.png`).default,
    cardTextColor: '#FFFFFF',
    nonCardTextColor: '#FFFFFF',
    backgroundColor: '#000000',
    cardColor: '#1C1C1E',
    desktopSocialMediaColor: '#FFFFFF',
    mobileSocialMediaColor: '#FFFFFF',
    cardShape: cardShape.ROUNDED.name,
    englishFont: englishFonts.ROBOTO,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'white',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#FFFFFF',
    borderColor: 'transparent',
  },
  {
    themeName: '04',
    mobileImage: require(`../assets/images/Wallpapers/40-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/40-D.png`).default,
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    backgroundColor: '#f77cb7',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.EDGES.name,
    englishFont: englishFonts.MERRYWEATHER,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
  {
    themeName: '05',
    mobileImage: require(`../assets/images/Wallpapers/41-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/41-D.png`).default,
    backgroundColor: '#202020',
    cardColor: '#101010',
    cardTextColor: '#FAFAFA',
    nonCardTextColor: '#FAFAFA',
    mobileSocialMediaColor: '#FAFAFA',
    desktopSocialMediaColor: '#FAFAFA',
    cardShape: cardShape.ROUNDED.name,
    englishFont: englishFonts.SPACEGROTESK,
    arabicFont: arabicFonts.TAJAWAL, // needs changing later
    displayLocale: 'en',
    iconSet: 'white',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#FAFAFA',
    borderColor: 'transparent',
  },
  {
    themeName: '06',
    mobileImage: require(`../assets/images/Wallpapers/42-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/42-D.png`).default,
    backgroundColor: '#EF3124',
    cardColor: '#FAD9DC',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.SQUARE.name,
    englishFont: englishFonts.EBGARAMOND,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
  {
    themeName: '07',
    mobileImage: require(`../assets/images/Wallpapers/43-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/43-D.png`).default,
    backgroundColor: '#e04186',
    cardColor: 'transparent',
    cardTextColor: '#FFFFFF',
    nonCardTextColor: '#FFFFFF',
    mobileSocialMediaColor: '#FFFFFF',
    desktopSocialMediaColor: '#FFFFFF',
    cardShape: cardShape.SQUARE.name,
    englishFont: englishFonts.OPENSANS,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.OUTLINE,
    nonCardTextColorDesktop: '#FFFFFF',
    borderColor: '#FFFFFF',
  },
  {
    themeName: '08',
    mobileImage: require(`../assets/images/Wallpapers/44-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/44-D.png`).default,
    backgroundColor: '#f2f2ed',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.SQUARE.name,
    englishFont: englishFonts.EBGARAMOND,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'none',
  },
  {
    themeName: '09',
    mobileImage: require(`../assets/images/Wallpapers/45-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/45-D.png`).default,
    backgroundColor: 'pink',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.EDGES.name,
    englishFont: englishFonts.MERRYWEATHER,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: '2px 6px 16px rgba(0, 0, 0, 0.12)',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
  {
    themeName: '10',
    mobileImage: require(`../assets/images/Wallpapers/46-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/46-D.png`).default,
    backgroundColor: '#911620',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.OVAL.name,
    englishFont: englishFonts.SPACEGROTESK,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
  {
    themeName: '11',
    mobileImage: require(`../assets/images/Wallpapers/47-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/47-D.png`).default,
    backgroundColor: '#e831eb',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.ROUNDED.name,
    englishFont: englishFonts.OPENSANS,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
  {
    themeName: '12',
    mobileImage: require(`../assets/images/Wallpapers/48-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/48-D.png`).default,
    backgroundColor: '#ebfae8',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.SQUARE.name,
    englishFont: englishFonts.EBGARAMOND,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: '6px 8px 0px rgba(0, 0, 0, 0.08)',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
  {
    themeName: '13',
    mobileImage: require(`../assets/images/Wallpapers/49-M.png`).default,
    desktopImage: require(`../assets/images/Wallpapers/49-D.png`).default,
    backgroundColor: '#90cef5',
    cardColor: 'rgba(255, 255, 255, 0.8)',
    cardTextColor: '#23262F',
    nonCardTextColor: '#23262F',
    mobileSocialMediaColor: '#23262F',
    desktopSocialMediaColor: '#23262F',
    cardShape: cardShape.ROUNDED.name,
    englishFont: englishFonts.OPENSANS,
    arabicFont: arabicFonts.TAJAWAL,
    displayLocale: 'en',
    iconSet: 'default',
    boxShadow: 'none',
    cardStyle: CARD_STYLE.SOLID,
    nonCardTextColorDesktop: '#23262F',
    borderColor: 'transparent',
  },
];

export const getShapeData = (shapeName: string)=>{
  for (const [key, value] of Object.entries(cardShape)) {
    if (value.name === shapeName) return value;
  }
};

export const themeColor = {
  background: '#7066FF',
  color: 'white',
  btnBackground: '#F0F0FF',
  btnColor: '#7066FF',
};
