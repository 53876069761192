import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';

export interface UserState {
  me: any;
  loginStatus: 'LOGGED_OUT' | 'LOGGED_IN' | 'ERROR' | 'FETCHING' | 'REFRESHING',
  hasNickname: boolean,
}

const initialState: UserState = {
  me: null,
  loginStatus: 'LOGGED_OUT',
  hasNickname: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeMe: (state) => {
      state.me = null;
      state.loginStatus = 'LOGGED_OUT';
      state.hasNickname = false;
    },
    setMe: (state, action: PayloadAction<any>) => {
      state.me = action.payload.me;
      state.loginStatus = action.payload.loginStatus;
      state.hasNickname = action.payload.hasNickname;
    },
  },
});

export const {removeMe, setMe} = userSlice.actions;

export const selectMe = (state: RootState) => state.user;

export default userSlice.reducer;
