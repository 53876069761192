import React, {
    useEffect,
    useState,
  } from 'react';
  import styles from './DateTabs.module.scss';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {dateRangeGraphOption} from "../../../../constants/helper";
  import {getDateFromRange} from '../../../../Helper/generalHelper';

  type DateTabsProps = {
      handleChange: Function;
  }
  
  const DateTabs = ({handleChange}: DateTabsProps) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState('Last 30d');

    useEffect(() => {
        handleChange(getDateFromRange(selected));
    }, [selected]);
  
    return (
      <div className={styles.date_container}>
        {dateRangeGraphOption.map((date: string) => (
            <div onClick={() => setSelected(date)} className={`${styles.route} ${selected == date ? styles.active : ''}`} key={date}>
                {t(date)}
            </div>
        ))}
      </div>
    );
  };
export default DateTabs;
