import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import styles from './ProofModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface IProps{
    handleClose: () => void;
    modalObj: any;
};

const ProofModal = ({modalObj, handleClose}: IProps)=>{
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <>
       <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
              <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
              <h4>{t('attachment')}</h4>
              <div className={styles.content_body}>
              {loading ? <div className={styles.loader}>
                <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
              </div> : <>
              {<div>
                <div>{modalObj?.attachmentUrl ? <img src={modalObj.attachmentUrl} /> : '--'}</div>
              </div>}
               </>}
              </div>
              
          </div>
        </div>
      </div>
    </>);
};

export default ProofModal;
