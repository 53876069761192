import {store} from '../../app/store';
import {removeMe, setMe} from '../stores/userSlicer';
import nonHookRequest from '../API/nonHookRequest';

export interface IDashboardSectionItem {
  id: string;
  linkString: string;
  orderNumber: number;
  type: string;
  createdAt: string;
  updatedAt: string;
}

const addNew = async (data: IDashboardSectionItem, sectionType: string) => {
  if (data) {
    const params = {fromAlfan: 'LINKS'};
    const request = await nonHookRequest({method: 'GET', url: '/auth/me', params});
    if (request) {
      let open = true;
      for (const [, value] of Object.entries(request.password)) {
        if (value) {
          open = false;
          break;
        }
      }
      store.dispatch(
          setMe({
            me: request,
            loginStatus: 'LOGGED_IN',
            hasNickname: request.hasNickname,
          }),
      );
    } else {
      store.dispatch(
          setMe({me: null, loginStatus: 'ERROR', hasNickname: false}),
      );
    }
  }
};

export {addNew};
