import React, {useRef, useEffect, useState} from 'react';
import styles from './TransactionTable.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import {useTranslation} from 'react-i18next';
import Sort from '../../assets/images/sort.svg';
import moment from 'moment';
import arrowDown from '../../assets/images/al_arrow_down_accordion.svg';
import SortArrowUp from '../../assets/images/sortUpArrow.svg';
import SortArrowDown from '../../assets/images/sortDownArrow.svg';
import {useSelector} from 'react-redux';
import {getConfig} from '../../features/stores/configSlicer';
import {haveSegmentPermission} from '../../features/util';
import {getCurrencyLabel} from '../../constants/currencies';

const TransactionTable = ({transactionList, sort, setSort}: any)=>{
  const {t} = useTranslation();
  const mobile = useWindowWidth();
  const [openAccordianId, setOpenAccordianId] = useState('');
  const config = useSelector(getConfig).data || [];
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];

  const handleSort = (value: any) => {
    let sortString = `${value}-ASC`;
    if (sort.includes(value)) {
      sortString = sort.includes('-DESC') ? `${value}-ASC` : `${value}-DESC`;
    };
    setSort(sortString);
  };

  const handleToggle = (id: any) => {
    if (id == openAccordianId) {
      setOpenAccordianId('');
    } else {
      setOpenAccordianId(id);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tablemain}>
          <div className={styles.table_header}>
            <div onClick={() => handleSort('id')}>{t('transactionID')} <img src={sort.includes('id') ? (sort.includes('-ASC') ? SortArrowUp : SortArrowDown) : Sort} /></div>
            <div onClick={() => handleSort('amount')}>{t('amount')} <img src={sort.includes('amount') ? (sort.includes('-ASC') ? SortArrowUp : SortArrowDown) : Sort} /></div>
            <div onClick={() => handleSort('orderId')}>{t('referenceID')} <img src={sort.includes('orderId') ? (sort.includes('-ASC') ? SortArrowUp : SortArrowDown) : Sort} /></div>
            <div onClick={() => handleSort('createdAt')}>{t('date')} <img src={sort.includes('createdAt') ? (sort.includes('-ASC') ? SortArrowUp : SortArrowDown) : Sort} /></div>
            <div onClick={() => handleSort('status')}>{t('status')} <img src={sort.includes('status') ? (sort.includes('-ASC') ? SortArrowUp : SortArrowDown) : Sort} /></div>
          </div>
          <div className={styles.table_body}>
            {transactionList.map((transaction: any) => {
                // Possible transaction status [payout(+), withdraw(-), returned(-), charge(will not show this to creator)]
                return (mobile > 768 ? 
                  <div className={`${styles.table_row} ${transaction.id == openAccordianId ? styles.hightlight : ''}`} key={transaction.id}>
                    <div className={styles.main_row}>
                      <div>{transaction.id}</div>
                      <div className={['payout'].includes(transaction.status.toLowerCase()) ? styles.green : styles.red}>{['payout'].includes(transaction.status.toLowerCase()) ? '+' : '-'}{getCurrencyLabel(transaction.currency)} {transaction.amount/(currencyMap.filter((e: any) => e.currency == transaction.currency)[0]?.conversionFactor || 100)}</div>
                      <div>{transaction.orderId || '--'}</div>
                      <div>{moment(transaction.createdAt).format('MMM DD, YYYY')}</div>
                      <div className={styles.accordian}><span className={['payout'].includes(transaction.status.toLowerCase()) ? styles.success : styles.debit}>{transaction.status}</span><img className={transaction.id == openAccordianId ? styles.open : ''} onClick={() => handleToggle(transaction.id)} src={arrowDown} /></div>
                    </div>
                    {transaction.id == openAccordianId && <div className={styles.nested_row}>
                      <div><span>{t('description')}</span><br /><div className={styles.light}>{transaction.description || '--'}</div></div>
                      <div><span>{t('transactedOn')}</span><br /><div className={styles.light}>{moment(transaction.createdAt).format('MMM DD, YYYY, ddd hh:mm A')}</div></div>
                    </div>}
                  </div> :
                    <div className={styles.table_row_mobile} key={transaction.id}>
                      <div className={styles.table_row}> 
                      <div>{transaction.orderId || '--'}</div>
                      <div><b>{['payout'].includes(transaction.status.toLowerCase()) ? '+' : '-'}{getCurrencyLabel(transaction.currency)} {transaction.amount/(currencyMap.filter((e: any) => e.currency == transaction.currency)[0]?.conversionFactor || 100)}</b></div>
                      </div>
                      <div className={styles.table_row}> 
                      <div>{moment(transaction.createdAt).format('MMM DD, YYYY')}</div>
                      <div><span className={['payout'].includes(transaction.status.toLowerCase()) ? styles.success : styles.debit}>{transaction.status}</span></div>
                      </div>
                    </div>
                );
            })}
            
          </div>
        </div>
      </div>
    </>);
};

export default TransactionTable;
