import React, {useState, useEffect, useRef} from 'react';
import {MusicAlbumItem} from '../../features/stores/musicAlbumsSlicer';
import styles from './MusicLinks.module.scss';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import {Switch} from 'antd';
import {useTranslation} from 'react-i18next';
import trash from '../../assets/images/al_delete_red.svg';
import {cardShape, getShapeData, previewStyles} from '../../constants/newThemes';
import useWindowWidth from '../../hooks/useWindowWidth';
import moveIcon from '../../assets/images/al_move_icon.svg';
import useRequest from '../../features/API/request';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import copy from 'copy-to-clipboard';
import {toast} from 'react-toastify';
import {ReactComponent as PencilIcon} from '../../assets/images/pencil.svg';

interface IProps {
    handleVisibilityToggle: (id: string, currentVisbility: boolean) => void;
    handleMusicLinkDelete: (id: string) => void;
    handleMusicLinkEdit: (link: MusicAlbumItem) => void;
    musicLinkItem: MusicAlbumItem;
}
interface MusicLinksProps {
    theme?: any;
    parentStyles?: any;
    selectedStyle?: any;
    album: MusicAlbumItem;
    onClick: () => void;
    baseUrl?: string;
    linkCopySuccessText?: string;
}
export const MusicLinkPreview = ({
    theme,
    parentStyles,
    selectedStyle,
    album,
    onClick,
    baseUrl,
    linkCopySuccessText,
}: MusicLinksProps) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const shapeData = theme ? getShapeData(theme.cardShape!) : undefined;
    const fontFamily = theme?.englishFont!;
    let cardShapeRadius = '' as any;
    let edgeCardPadding = '0px';
    let iconRadius = '' as any;
    const width = useWindowWidth();
    if (selectedStyle === previewStyles.DESKTOP_PREVIEW) {
        cardShapeRadius = shapeData?.border.desktopPreviewBorderRadius;
        iconRadius = shapeData?.icon.desktopPreviewBorderRadius;
        edgeCardPadding = '4px';
    } else if (selectedStyle === previewStyles.MOBILE_PREVIEW) {
        cardShapeRadius = shapeData?.border.mobilePreviewBorderRadius;
        iconRadius = shapeData?.icon.mobilePreviewBorderRadius;
        edgeCardPadding='10px';
    } else if (selectedStyle === previewStyles.DEFAULT) {
        cardShapeRadius = shapeData?.border.borderRadius;
        iconRadius = shapeData?.icon.borderRadius;
        edgeCardPadding = '12px';
        if (width < 768) {
            edgeCardPadding = '16px';
        }
    }

    const recordAlbumEvent = useRequest({
        method: 'POST',
        url: '/albumAnalytics',
      });

    const toggleAudio = () => {
        if (selectedStyle === previewStyles.DEFAULT && audioRef.current && !isPlaying) {
            recordAlbumEvent.doRequest({'albumId': album.id, 'previewPlay': true});
        }
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    useEffect(() => {
        if (audioRef.current) {
          // Add an event listener to detect when the audio playback ends
          audioRef.current.addEventListener('ended', () => {
            setIsPlaying(false); // Update state to allow the play button to be displayed again
          });
        }
    
        // Clean up the event listener when the component unmounts
        return () => {
          if (audioRef.current) {
            audioRef.current.removeEventListener('ended', () => {});
          }
        };
      }, []);

    return (<div style={theme && {
            backgroundColor: theme.cardColor!,
            boxShadow: theme.boxShadow!,
            fontFamily: theme.fontFamily,
            color: theme.cardTextColor!,
            border: '1px solid ' + theme.borderColor,
            borderRadius: cardShapeRadius,
            ...(theme.cardShape === cardShape.EDGES.name && {padding: edgeCardPadding}),
        }}
        className={`${parentStyles ? parentStyles.music_card: styles.music_card}` + ` ${isPlaying ? styles.playing : ''}`}>
                <div className={styles.cover_play} onClick={toggleAudio}>
                    <img
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: iconRadius ? iconRadius : cardShapeRadius,
                          }}
                        src={album.coverImageUrl}
                        alt={`${album.title} cover`}
                        className={styles.music_card_image}
                    />
                    {album.previewUrl && album.previewUrl.length > 0 &&<div className={styles.play_button}>
                        {isPlaying ? (
                            <PauseIcon fontSize="large" />
                        ) : (
                            <PlayArrowIcon fontSize="large" />
                        )}
                    </div>}
                </div>
                <div className={styles.music_card_info} onClick={onClick}>
                    <h2 style={{
                            color: theme?.cardTextColor!,
                            fontFamily: fontFamily,
                        }}
                        className={`${styles.music_card_title} ${styles.music_card_text}`}>{album.title}</h2>
                    {album.description?.length > 0 && <p style={{
                            color: theme?.cardTextColor!,
                            fontFamily: fontFamily,
                            }}
                        className={`${styles.music_card_artist} ${styles.music_card_text}` }>{album.description}</p>}
                </div>
                {selectedStyle === previewStyles.DEFAULT && <div className={styles.copy_music_links}
                    onClick={() => {
                        copy(`${baseUrl}?music=${album.urlSlug}`);
                        toast(<span style={{color: 'black'}}>{linkCopySuccessText}</span>, {
                            autoClose: 3000,
                          });
                    }}>
                    <CopyIcon stroke={theme?.cardTextColor!} />
                    </div>}
                {album.previewUrl && album.previewUrl.length > 0 && <audio ref={audioRef} src={album.previewUrl} />}
            </div>);
};

const MusicLinkItem = ({
    handleVisibilityToggle,
    handleMusicLinkEdit,
    handleMusicLinkDelete,
    musicLinkItem,
}: IProps) => {
    const {t} = useTranslation();
    return (
    <div className={styles.music_line_item}>
        <div className={styles.left}>
            <img src={moveIcon} />
        </div>
        <div className={styles.right}>
        <MusicLinkPreview 
            album={musicLinkItem} onClick={() => {}}/>
            <div className={styles.item_actions}>
                <div className={styles.title_pencil_wrapper}>
                    <Switch className={styles.switch} checked={musicLinkItem.visible} onChange={() => {
                            handleVisibilityToggle(musicLinkItem.id, musicLinkItem.visible);
                        }} />
                    &nbsp;&nbsp;{t('visibility')}
                </div>
          <div className={styles.buttons_container}>
            <div className={`${styles.title_pencil_wrapper}`} onClick={() => handleMusicLinkEdit(musicLinkItem)}>
              <PencilIcon className={styles.pencil} />
                    &nbsp;&nbsp;{t('Edit')}
            </div>
                <div className={`${styles.title_pencil_wrapper}`} onClick={() => {
                    handleMusicLinkDelete(musicLinkItem.id);
                }} >
                    <img src={trash} className={styles.trash} />
                    &nbsp;&nbsp;{t('delete')}
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicLinkItem;
