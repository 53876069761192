import React, {useState, useEffect} from 'react';
import styles from './formikForm.module.scss';
import {blobToBase64} from '../../Helper/imageCropper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {PlusOutlined} from '@ant-design/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import {useTranslation, Trans} from 'react-i18next';
import {toast} from 'react-toastify';

const handleOpen = (url: string) => {
  window.open(url, '_blank');
};

const FormikUpload = ({formik, name, labelName, id, multiple, accept, deleteName, maxSize, ...props}:any) => {
  const {values, setFieldValue} :any= formik;
  const {t} = useTranslation();
  const singleUpload = !multiple && Array.isArray(values[name]) ? values[name][0] : values[name];
  const fileSelectedHandler = async (e: any) => {
    if (e.target.files) {
      if (Array.isArray(values[name])) {
        const tempDocArray = [];
        for (let i = 0; i < e.target?.files?.length; i++) {
          if (maxSize && ((e.target.files[i].size / 1024 / 1024) < maxSize)) {
            tempDocArray.push(e.target.files[i]);
          } else {
            const name = e.target.files[i].name;
            toast(<span style={{color: 'black'}}>
              <Trans i18nKey="size_has_exceeded_the_allowed_size" name={name} maxSize={maxSize}>{{name}} size has exceeded the allowed size {{maxSize}} Mb </Trans>
              </span>, {autoClose: 3000});
          }
        }
        setFieldValue(name, tempDocArray);
      } else {
        setFieldValue(name, e.target.files[0]);
      }
      e.target.value=null;
    }
  };

  const fileSelectedHandlerMultiple = async (e: any) => {
    if (e.target.files) {
      const tempDocArray = [];
      for (let i = 0; i < e.target?.files?.length; i++) {
        if (maxSize && ((e.target.files[i].size / 1024 / 1024) < maxSize)) {
          tempDocArray.push(e.target.files[i]);
        } else {
          toast(<span style={{color: 'black'}}>
              <Trans i18nKey="size_has_exceeded_the_allowed_size" name={name} maxSize={maxSize}>{{name}} size has exceeded the allowed size {{maxSize}} Mb </Trans>
            </span>, {autoClose: 3000});
        }
      }
      setFieldValue(name, values[name].concat(tempDocArray));
      e.target.value=null;
    }
  };

  const handleRemove = (index: any) => {
    const temp = JSON.parse(JSON.stringify(values[name]));
    const tempDocArray = [];
    for (let i = 0; i < temp?.length; i++) {
      if (index != i) {
        tempDocArray.push(values[name][i]);
      } else {
        if (values[name][i]?.id && deleteName) {
          setFieldValue(deleteName, values[deleteName].concat([values[name][i]?.id]));
        }
      }
    }
    setFieldValue(name, tempDocArray);
  };

  const handleUploadThumbnail = () => {
    document.getElementById(id)?.click();
  };

  const getPreview = (file: any) => {
    const isImage = file?.type?.includes('image') || ['jpg', 'png', 'jpeg', 'svg'].includes(file.name.split('.')[file.name.split('.').length - 1].toLowerCase());
    return (isImage ? <ImagePreview file={file} /> : <div className={styles.file_name} onClick={(event: any) => {
      event.stopPropagation();
      file?.url ? handleOpen(file.url) : '';
    }}>{file.name}</div>);
  };

  if (multiple) {
    return (
      <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
      {values[name] && !!values[name].length && values[name].map((e: any, i: any) => {
        return (
          <div key={`e.name-${i}`} className={styles.upload_btn}>
            {getPreview(e)}
            <div className={styles.cross} onClick={() => handleRemove(i)}><CancelIcon /></div>
          </div>
        );
      })}
      <div className={styles.upload_btn} onClick={handleUploadThumbnail}>
        <PlusOutlined />
        <div style={{marginTop: 8, textAlign: 'center'}}>{t(labelName || 'upload')}</div>
        <input accept={accept || "image/*"} multiple id={id} style={{display: 'none'}} type='file' onChange={(e) => fileSelectedHandlerMultiple(e)} />
      </div>
    </div>
    );
  }

  return <div className={`${styles.upload_container} ${formik.values[name] ? styles.img_present : ''}`}>
  {singleUpload ?
    <div className={styles.image_upload} onClick={handleUploadThumbnail}>
      {getPreview(singleUpload)}
      <div className={styles.cross} onClick={(e) => {
        e.stopPropagation(); setFieldValue(name, Array.isArray(values[name]) ? [] : '');
        if (deleteName && singleUpload?.id) {
          setFieldValue(deleteName, [singleUpload?.id]);
        }
      }}><CancelIcon /></div>
    </div> : <div className={styles.image_upload_holder} onClick={handleUploadThumbnail}><CloudUploadIcon fontSize="large" /> {labelName}</div>
    }
  <input id={id} accept={accept || "image/*"} style={{display: 'none'}} type='file' onChange={(e) => fileSelectedHandler(e)} />
</div>;
};
export default FormikUpload;

const ImagePreview = ({file}:any) => {
  const [imageSrc, setImageSrc] = useState("");

  const getConvertedImage = async () => {
    const url: any = await blobToBase64(file);
    setImageSrc(url);
  };

  useEffect(() => {
    if (file.id) {
      setImageSrc(file.url);
    } else {
      getConvertedImage();
    }
  }, [file]);

  return <img style={{cursor: 'pointer', maxHeight: '100px'}} onClick={(e: any) => {
    e.stopPropagation();
    file?.url ? handleOpen(imageSrc) : '';
  }} src={imageSrc} />;
};
