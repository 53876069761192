import React, {useEffect, useState} from 'react';
import styles from '../ModalMonetize.module.scss';
import {useTranslation} from 'react-i18next';
import {getCurrencyLabel} from '../../../constants/currencies';
import {PriceBreakdownProps} from "./componentInterface";
import {getPGFeePercentage} from '../../../features/util';
import {useSelector} from 'react-redux';
import {getConfig} from '../../../features/stores/configSlicer';

const PriceBreakDown = ({getAmount, item}: PriceBreakdownProps) => {
    const {t} = useTranslation();
    const config = useSelector(getConfig).data || [];
    const pgFee = getPGFeePercentage(config);

    return (
        <>
            <div className={styles.content_row}>
                <div className={styles.content_row_left}>
                    <div className={styles.breakdown_title}>{`${item.alfanPercantage}% ${t('alfan_service_fee')}`}</div>
                </div>
                <div className={styles.content_row_right}>
                    {getCurrencyLabel(item.currency)} {(getAmount('alfanCut', item.amount, item.alfanPercantage)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            </div>
            {Number(pgFee) > 0 && <div className={styles.content_row}>
                <div className={styles.content_row_left}>
                    <div className={styles.breakdown_title}>{`${pgFee}% ${t('payment_processing_fee')}`}</div>
                </div>
                <div className={styles.content_row_right}>
                    {getCurrencyLabel(item.currency)} {(getAmount('processingFee', item.amount, item.alfanPercantage)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            </div>}
            <div className={styles.content_row}>
                <div className={styles.content_row_left}>
                    <div className={styles.breakdown_title}>{t('you_will_receive')}</div>
                    <div className={styles.sub_title_breakdown}>{t('the_estimated_amount_you_will_get')}</div>
                </div>
                <div className={styles.content_row_right}>
                    {getCurrencyLabel(item.currency)} {(getAmount('earn', item.amount, item.alfanPercantage)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            </div>
        </>
    );
};

export default PriceBreakDown;
