/* eslint-disable react/prop-types */
import React from 'react';
import Slider from 'react-slick';
import './landingCarousel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from 'react-i18next';
import {isArabic} from '../../features/util';
import arrowForward from '../../assets/images/landing/arrow_forward_ios.svg';
import arrowBack from '../../assets/images/landing/arrow_back_ios.svg';

export interface MediaItem {
  id: number;
  imageURL: string;
  creatorProfileURL: string;
  creatorType: string;
  creatorName: string;
}

interface LandingCarouselProps {
  mediaItems: MediaItem[];
}

const LandingCarousel: React.FC<LandingCarouselProps> = ({mediaItems}) => {
  const sliderRef = React.useRef(null);
  const {t, i18n} = useTranslation();
  const isArabicLang = isArabic(i18n.language);
  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          infinite: true,
          slidesToShow: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 2500,
        },
      },
    ],
  };

  const goToNext = () => {
    (sliderRef.current as any).slickNext();
  };

  const goToPrev = () => {
    (sliderRef.current as any).slickPrev();
  };

  const renderSlides = () => {
    return mediaItems.map((item) => (
      <div key={item.id} className="card">
        <div className="img-container">
          <img src={item.imageURL} alt={item.creatorName} />
        </div>
        <div className="buttons-container">
          <a
            href={item.creatorProfileURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="urlButton">
              {item.creatorProfileURL.substring(8)}
            </button>
          </a>
          <a
            href={item.creatorProfileURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="creatorType">{t(item.creatorType)}</button>
          </a>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className={`arrows-container ${isArabicLang ? 'reverse' : ''}`}>
        <div className="prevArrow arrow" onClick={goToPrev}>
          <img src={arrowBack} alt="prevArrow" />
        </div>
        <div className="nextArrow arrow" onClick={goToNext}>
          <img src={arrowForward} alt="nextArrow" />
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {renderSlides()}
      </Slider>
    </div>
  );
};

export default LandingCarousel;
