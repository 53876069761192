import React, {useEffect, useState} from 'react';
import {withStyles, createStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from '../ModalMonetize.module.scss';
import {useTranslation, Trans} from 'react-i18next';
import {themeColor} from '../../../constants/newThemes';
import {isValidHttpUrl} from '../../../features/util';
import {IPropsFooter} from "./componentInterface";

const ButonCustom = withStyles({
    root: {
      background: (props: any) => props.background,
      color: (props: any) => props.color,
      borderRadius: '40px',
      padding: '16px 24px',
      textTransform: 'capitalize',
    },
  })(Button);

const ItemFooter = ({handleInputChange, handleAddAction, item, selectValue, errors, currencyMap}: IPropsFooter) => {
  const {t} = useTranslation();
  const minAmount = (item.minPrice/Number(currencyMap?.filter((e: any) => e.currency == item?.currency)[0]?.conversionFactor || 100) || 1);
  const maxAmount = (item.maxPrice/Number(currencyMap?.filter((e: any) => e.currency == item?.currency)[0]?.conversionFactor || 100) || 5000);

  const isDisabled = (item: any) => {
    let disable = false;
    if (item.id == 'sellProduct' && ((['Picture', 'Video', 'Guide'].includes(selectValue['sellProduct']) && !!!item.attachment) || (['Video'].includes(selectValue['sellProduct']) && !!!item.attachmentURL))) {
      disable = true;
    } else if (item.id != 'tipJar' && ((item.amount <= minAmount) || (item.amount > maxAmount))) {
      disable = true;
    } else if (item.id == 'shortCall' && (errors?.calendlyUrl || !isValidHttpUrl(item.url))) {
      disable = true;
    } else if (!item.title || item.loading) {
      disable = true;
    }
    return disable;
  };

  return (
    <>
        <div className={styles.footer}>
            {/* <ButonCustom onClick={() => handleInputChange(null, item.id)!} variant="contained" size="large" color='black' background="white">
                {t('Cancel')}
           </ButonCustom> */}
            <ButonCustom disabled={isDisabled(item)} onClick={() => handleAddAction(item)} variant="contained" size="large" color={themeColor.color} background={'#7066FF'}>
                {t('publish')}
            </ButonCustom>
        </div>
    </>
  );
};

export default ItemFooter;
