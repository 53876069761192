import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface AlbumLink {
  id?: string;
  type: string;
  url: string;
}

export enum SaveActionOperations {
  UPDATE = 'put',
  ADD = 'post',
}

export interface MusicAlbumItem {
  id: string;
  title: string;
  description: string;
  previewUrl: string;
  coverImageUrl: string;
  albumLinks: AlbumLink[];
  urlSlug?: string;
  visible: boolean;
  order?: number;
  saveAction?: SaveActionOperations;
}

export interface UpdateMusicAlbumItem {
  id: string;
  data: Partial<MusicAlbumItem>;
}

export interface MusicStream {
  streamingService: string;
  url: string;
  key: string;
  editable?: boolean;
}

export interface MusicLinks {
  musicLinks: MusicAlbumItem[];
  ogAlbums: MusicAlbumItem[];
  toBeDeletedMusicLinks: string[];
}

const initialState: MusicLinks = {
  musicLinks: [],
  toBeDeletedMusicLinks: [],
  ogAlbums: [],
};


export const musicAlbumsSlice = createSlice({
  name: 'userTheme',
  initialState,
  reducers: {
    setMusicLinks: (state, action: PayloadAction<MusicAlbumItem[]>) => {
      state.musicLinks = action.payload.map(({
        id, order, albumLinks, previewUrl, coverImageUrl, title, description, visible,
      }) => ({id, order, 
        albumLinks: albumLinks.map(({id, type, url}) => ({id, type, url})),
        previewUrl, coverImageUrl, title, description, visible}));
      state.toBeDeletedMusicLinks = [];
      state.musicLinks = state.musicLinks.slice().sort((a: any, b: any) => {
        if (a.order > b.order) return 1;
        else return -1;
      });
      state.ogAlbums = [...state.musicLinks];
      state.toBeDeletedMusicLinks = [];
    },
    addMusicLink: (state, action: PayloadAction<MusicAlbumItem>) => {
      state.musicLinks.push({...action.payload, saveAction: SaveActionOperations.ADD});
    },
    updateMusicLink: (state, action: PayloadAction<UpdateMusicAlbumItem>) => {
      const ind = state.musicLinks.findIndex((link) => link.id === action.payload.id);
      if (ind > -1) {
        state.musicLinks[ind] = {...state.musicLinks[ind], ...action.payload.data, saveAction: state.musicLinks[ind].saveAction || SaveActionOperations.UPDATE};
      }
    },
    deleteMusicLink: (state, action: PayloadAction<string>) => {
      const ind = state.musicLinks.findIndex((link) => link.id === action.payload);
      if (ind > -1) {
        if (state.musicLinks[ind].saveAction != SaveActionOperations.ADD) {
          state.toBeDeletedMusicLinks.push(state.musicLinks[ind].id);
        }
        state.musicLinks.splice(ind, 1);
      }
    },
    sortMusicLinkItems: (state) => {
       state.musicLinks = state.musicLinks.slice().sort((a: any, b: any) => {
          if (a.order > b.order) return 1;
          else return -1;
        });
    },
    resetMusicLinks: (state) => {
      state.musicLinks = [...state.ogAlbums];
      state.toBeDeletedMusicLinks = [];
    },
  },
});

export const resetMusicLinksThunk = (): any => {
  return (dispatch: any) => {
    dispatch(resetMusicLinks());
  };
};

export const {setMusicLinks, addMusicLink, updateMusicLink, sortMusicLinkItems, deleteMusicLink, resetMusicLinks} = musicAlbumsSlice.actions;

export const getMusicAlbums = (state: RootState) => state.musicAlbums;

export default musicAlbumsSlice.reducer;
