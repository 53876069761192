import React from 'react';
import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import {LinksSectionItem, deleteLinksSectionItem, updateSectionItem} from '../../features/stores/linksUserSlicer';
import styles from './DraggableLinksSection.module.scss';
import LinkItem from '../LinkItem/LinkItem';
import {LinkIcon} from '../../Helper/getLinkIcon';
import {store} from '../../app/store';
import {setDataChange} from '../../features/stores/changeDataSlicer';

export interface IDraggableLinksSectionProps {
  onDragEnd: (result: DropResult, list: LinksSectionItem[]) => void;
  onEditLink: (link: LinksSectionItem) => void;
  list: LinksSectionItem[];
}

const DraggableLinksSection = ({
  onDragEnd,
  onEditLink,
  list,
}: IDraggableLinksSectionProps) => {
  const handleDelete = ({id}: LinksSectionItem) => {
    store.dispatch(deleteLinksSectionItem({sectionItemId: id}));
    store.dispatch(setDataChange(true));
  };

  const toggleVisibility = ({isVisible, id}: LinksSectionItem) => {
    store.dispatch(updateSectionItem({id, data: {isVisible: !isVisible}}));
    store.dispatch(setDataChange(true));
  };
  return (
    <>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, list)}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div
              className={styles.itemsContainer}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {list?.map((link: LinksSectionItem, index: number) => {
                return (
                  <Draggable
                    key={link.id}
                    draggableId={link.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <LinkItem
                        visibility={link.isVisible}
                        title={link.title!}
                        icon={<LinkIcon type={link.type} customImage={link.customUploadedImage} />}
                        onClick={() => onEditLink(link)}
                        handleDelete={() => handleDelete(link)}
                        toggleVisibility={() => toggleVisibility(link)}
                        draggableProvided={provided}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DraggableLinksSection;
