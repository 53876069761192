import React, {ComponentProps, ReactNode} from 'react';
import styles from './TextInput.module.scss';
import {TextField} from '@material-ui/core';

export type TextInputProps = Omit<ComponentProps<typeof TextField>, 'label' | 'error'> & {
  label: string;
  error?: string;
  icon?: ReactNode;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  maxLength?: number; // Optional prop to specify max length
};

const TextInput = ({label, error, containerClassName, containerStyle, maxLength = 250, ...fieldProps}: TextInputProps) => {
  return (
    <div className={containerClassName} style={containerStyle}>
      <div className={styles.textInput}>
        <div className={styles.icon}>{fieldProps.icon}</div>
        <span className={styles.fieldContainer}>
          <TextField
            className={styles.input}
            InputProps={{disableUnderline: true}}
            inputProps={{
              className: styles.inputInner,
              maxLength: maxLength,
            }}
            {...fieldProps}
            variant={undefined}
            style={{width: '100%'}}
          />
          <label htmlFor='' className={fieldProps.value ? styles.active : ''}>
            {label}
          </label>
        </span>
      </div>
      {/* {error && <div className={styles.error}>{error}</div>} */}
    </div>
  );
};

export default TextInput;
