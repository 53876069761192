/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';
import InputField from './InputField';

interface TipJarProps {
  monetizeObj: any;
  visitorData: any;
  setVisitorData: (data: any) => void;
  visitorDataError: any;
  currencyLabel: string;
  handleChangeInput: (value: any, param: string) => void;
}

const TipJar: React.FC<TipJarProps> = ({
  monetizeObj,
  visitorData,
  setVisitorData,
  visitorDataError,
  currencyLabel,
  handleChangeInput,
}) => {
  const amountLabel = 'Amount'; // Replace with translation if needed
  const minAmountLabel = 'Min amount'; // Replace with translation if needed
  const minPrice = `${monetizeObj.minPrice} ${currencyLabel}`;

  const getAmountOptions = (minPrice: number) => {
    const options: number[] = [];
    const start = Math.ceil(minPrice / 5) * 5;
    for (let i = start; i <= monetizeObj.maxPrice && options.length < 3; i += 5) {
      options.push(i);
    }
    return options;
  };

  return (
    <>
      <div className={styles.label} style={{marginBottom: '6px'}}>
        {amountLabel}
        <span className={styles.description} style={{paddingLeft: '3px'}}>
          ({minAmountLabel} {minPrice})
        </span>
      </div>
      <div className={`${styles.row} ${styles.tipjarBox}`}>
        {getAmountOptions(monetizeObj.minPrice).map((amount, index) => (
          <div
            key={index}
            onClick={() => setVisitorData({...visitorData, tipJarAmount: amount, isCustom: false})}
            className={visitorData.tipJarAmount === amount && !visitorData.isCustom ? styles.activeBox : ''}
          >
            {currencyLabel} {amount}
          </div>
        ))}
        <div
          onClick={() => setVisitorData({...visitorData, tipJarAmount: 20, isCustom: true})}
          className={visitorData.isCustom ? styles.activeBox : ''}
        >
          Custom ({currencyLabel})
        </div>
      </div>
      {visitorData.isCustom && (
        <InputField
          label=""
          type="number"
          value={visitorData.tipJarAmount}
          error={visitorDataError.tipJarAmount}
          onChange={(e) => handleChangeInput(e.target.value, 'tipJarAmount')}
        />
      )}
    </>
  );
};

export default TipJar;
