import React from "react";
import BaseModal from "../components/BaseModal";
import {BaseModalProps} from "../components/BaseModal";
import style from './ConfirmationModal.module.scss';

type Props = BaseModalProps & {
  title: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  handlePrimaryButtonClick: () => void;
};

const ConfirmationModal = ({
  title,
  primaryButtonText,
  handlePrimaryButtonClick,
  secondaryButtonText,
  onClose, 
  ...props
}: Props) => {
  return (
    <BaseModal {...props} onClose={onClose}>
      <div className={style.container}>
        <h5 className={style.heading}>{title}</h5>
        <button 
          className={style.primaryButton}
          onClick={handlePrimaryButtonClick}
        >
          {primaryButtonText}
        </button>
        <span className={style.spacing}></span>
        <button 
          className={style.secondaryButton}
          onClick={() => onClose({}, 'close')}
        >
          {secondaryButtonText}
        </button>
      </div>
    </BaseModal>
  );
};

export default ConfirmationModal;
