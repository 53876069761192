import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './PersonalInformation.module.scss';
import Loader from 'react-loader-spinner';
import CreatorProfile from '../../../components/CreatorProfile/CreatorProfile';
import RouteLeavingGuard from '../../../components/UnsavedChanges/RouteLeavingGuard';
import {useSelector} from 'react-redux';
import {selectLinksUser} from '../../../features/stores/linksUserSlicer';
import useRequest from '../../../features/API/request';
import {refreshMe} from '../../../features/Login/login';
import {toast} from 'react-toastify';
import {raiseToast} from '../../../components/Toast/Toast';
import {store} from '../../../app/store';
import {setDataChange} from '../../../features/stores/changeDataSlicer';

const selectChangeData = (state: any) => state.changeData.changeData;

const PersonalInformation = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const dataChange = useSelector(selectChangeData);
  const linksUser = useSelector(selectLinksUser) as any;
  const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/profile-settings'});

  const savePersonalInfo = async () => {
    try {
      setLoading(true);
      const payload = {contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || [], phone: linksUser?.phone, gender: linksUser?.gender};
      await updateProfileRequest.doRequest(payload);
      raiseToast({message: t('changes_saved')});
      store.dispatch(setDataChange(false));
      await refreshMe();
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (updateProfileRequest.hasError) {
      toast.error(<span style={{color: 'white'}}>{updateProfileRequest?.error?.message || t('something_went_wrong')}</span>);
    }
  }, [updateProfileRequest.hasError]);

  return (
    <>
      <RouteLeavingGuard when={dataChange} navigate={() => '/profile/setting/personal-information'}
        shouldBlockNavigation={() => true} saveFunction={async ()=>{
          return savePersonalInfo();
        }}/>
      <div className={styles.personal_info_container}>
        <div className={styles.personal_info_body}>
          <div className={styles.heading_main}>
            {t('Personal Data')}
          </div>
          <br />
          <div className={loading ? styles.loader : ''}>
            {loading ? <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /> : <div>
              <CreatorProfile />
              <div className={styles.buttons_wrapper}>
                <div className={styles.save_button} onClick={savePersonalInfo}>
                  {updateProfileRequest.isFetching ? <Loader type="TailSpin" color="#FFF" height={20} width={20} /> : t('save')}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
