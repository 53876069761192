import React from 'react';
import styles from './ViewDealDetailsModal.module.scss';
import {useTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/TimerOutlined';
import copy from 'copy-to-clipboard';
import {dealItem} from '../../features/stores/dealsSlicer';
import {toast} from 'react-toastify';
import {UserThemeState} from '../../features/stores/userThemeSlicer';
import {calculateTimeLeft, isArabic} from '../../features/util';
import {Tooltip} from 'antd';
import {dealType, dealCategory} from '../../constants/helper';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import {ReactComponent as ExternalLinkIcon} from '../../assets/images/external_link.svg';
import {themeColor} from '../../constants/newThemes';

interface IViewDealDetailsModalProps {
  open: boolean;
  setIsOpen: (arg: boolean) => void;
  dealItem: dealItem;
  translate: (key: string) => string;
  theme: UserThemeState;
  isCreator?: boolean
}

const ViewDealDetailsModal = ({
  open, 
  setIsOpen, 
  dealItem, 
  translate, 
  theme,
  isCreator,
}: IViewDealDetailsModalProps) =>{
  const {t} = useTranslation();

  const handleCopy = (value: string) => {
    copy(value);
    toast(<span style={{color: 'black'}}>{translate('code_copied')}</span>, {autoClose: 3000});
  };

  const codeItems = dealItem?.userDealContents?.filter((item) => item.type == dealType.COUPON) || undefined;
  const collections = dealItem?.userDealContents?.filter((item) => item.type == dealType.COLLECTIONS) || undefined;

  const getLocalizedText = (theme: UserThemeState, value: number, unit: string) => {
    const language = theme.displayLocale || 'en';
    return isArabic(language) ? `${value} ${translate(unit)} متبقي` : `${value} ${translate(unit)}${value > 1 ? 's' : ''}`;
  };

  const expiry = (endDate: string) => {
    return endDate ? calculateTimeLeft(theme, endDate, getLocalizedText) : translate('limited_time_deal');
  };

  const truncateName = (name: string) => {
    return name.length > 10 ? name.substring(0, 10) + '...' : name;
  };

  return (
    open ? 
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <div className={styles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
          <div className={styles.content_body}>
            <div className={styles.row_top}>
              <a
                href={dealItem?.dealTrackingLink} 
                rel="noreferrer" 
                target="_blank"
                className={styles.content_image}>
                <img src={dealItem?.logoUrl || dealItem?.deal?.logoUrl} />
              </a>
              <div className={styles.content}>
                <a
                  href={dealItem?.dealTrackingLink} 
                  rel="noreferrer" 
                  target="_blank"
                  className={styles.content_headline}
                >
                  {dealItem?.deal?.name}
                  <ExternalLinkIcon className={styles.external_icon} />
                </a>
              </div>
            </div>
            <div className={styles.row_middle}>
              {dealItem?.dealTrackingLink && isCreator && dealItem?.deal?.dealType !== dealCategory.COUPON ? <div className={styles.sectionContainer}>
                <div className={styles.coupon}>{translate('sharing_link')}</div>
                <div className={styles.coupon_container}>
                  <div className={styles.custom_input}>
                    <p>{dealItem?.dealTrackingLink}</p>
                    <div className={styles.coupon_right}>
                    <CopyIcon 
                      stroke={themeColor.background} 
                      className={styles.actionIcon}
                      onClick={() => handleCopy(dealItem?.dealTrackingLink)}
                    />
                    </div>
                  </div>
                </div>
              </div> : null}

              {dealItem?.deal?.dealType !== dealCategory.TRACKING_LINK && codeItems && codeItems.length > 0 && <div className={styles.sectionContainer}>
                <div className={styles.coupon}>{translate('coupon_code')}</div>
                  {codeItems?.map((codeItem) => 
                    <div key={codeItem.id} className={styles.coupon_container}>
                      <div className={styles.custom_input}>
                        <p>{codeItem.content}</p>
                        <div className={styles.coupon_right}>
                        {codeItem.endDate && <Tooltip title={expiry(codeItem.endDate)}><TimerIcon htmlColor='orange'></TimerIcon></Tooltip>}
                        <CopyIcon 
                          stroke={themeColor.background} 
                          className={styles.actionIcon}
                          onClick={() => handleCopy(codeItem.content)}
                        />
                        </div>
                      </div>
                    </div>,
                  )}
                </div>
              }

              {dealItem?.deal?.dealType !== dealCategory.COUPON && collections && collections.length > 0 && 
                <div className={styles.sectionContainer}>
                  <div className={styles.coupon}>{translate('products')}</div>
                  <div className={`${styles.collections} ${styles.customScrollbar}`}>
                    {collections?.map((collection) => 
                      <div key={collection.id} className={styles.collection}>
                        <a href={collection.productTrackingLink || collection.content} rel="noreferrer" target="_blank">
                          <img
                            src={collection.logoUrl || dealItem?.logoUrl} 
                            className={styles.collectionImg}
                          ></img>
                        </a>
                        <p>
                          <Tooltip title={collection?.description}>
                            <div className={styles.colection_description}>
                              {truncateName(collection?.description)}
                            </div>
                          </Tooltip>
                        </p>
                      </div>,
                    )}
                  </div>
                </div>
              }
            </div>
            {!isCreator ? <div className={styles.content_text}>
              <label className={styles.how_to}>{translate('how_it_works')}</label>
              <ul>
                <li>{translate('coupons_may_come')}</li>
                <li>{translate('to_avail_discount')}</li>
              </ul>             
            </div> : null}
          </div>
        </div>
      </div>
    </div> : <></>
  );
};

export default ViewDealDetailsModal;
