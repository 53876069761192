import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./CreatorProfile.module.scss";
import {contentOptions, creatorTypeOptions, contentOptionsProd, creatorTypeOptionsProd, contentOptionsNewOnBoarding, contentOptionsNewOnBoardingProd, MAX_CONTENT_CATEGORY_SELECTION} from "../../constants/helper";
import {useSelector} from "react-redux";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {store} from "../../app/store";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import {isProdSite} from "../../Helper/generalHelper";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {toast} from "react-toastify";

const CreatorProfile: React.FC = ()=> {
  const {t, i18n} = useTranslation();
  const isProd = isProdSite();
  const linksUser = useSelector(selectLinksUser);
  const showBioAndName = window.location.pathname !== '/profile/setting/personal-information';
  const [currentSlide, setCurrentSlide] = useState(1); // State to track current slide number
  const creatorTypeOptionsLocal = isProd ? creatorTypeOptionsProd : creatorTypeOptions;
  const contentOptionsLocal = isProd ? contentOptionsNewOnBoardingProd.filter((e) => !e?.hideInOnboard) : contentOptionsNewOnBoarding.filter((e) => !e?.hideInOnboard);
  const handleSelect = (value: string, param: string) => {
    const dataCopy = JSON.parse(JSON.stringify(param == 'categoryIds' ? linksUser?.categoryIds : linksUser?.contentCategoryIds));
    if (dataCopy?.length >= MAX_CONTENT_CATEGORY_SELECTION && !dataCopy.includes(value)) {
      toast(<span style={{color: 'black'}}>{t('you_can_select_max_three_superpower')}</span>, {
        autoClose: 5000,
      });
      return;
    }
    store.dispatch(setLinksUser({...linksUser, [param]: dataCopy.includes(value) ? dataCopy.filter((e: string) => e != value) : [...dataCopy, value]}));
    store.dispatch(setDataChange(true));
  };
  const inactiveColor = '#AA9C9F';
  const activeColor = '#E887F9';

  const getRow = (startIndex: number, endIndex: number) => {
      const slicedContent = contentOptionsLocal.slice(startIndex, endIndex);
      return (
        <div className={styles.row}>
              {slicedContent?.map((category) => (
                <div key={category.id} onClick={() => handleSelect(category?.id, 'contentCategoryIds')} className={`${styles.category} ${linksUser?.contentCategoryIds?.includes(category?.id) ? styles.active : ''}`}>
                <div className={styles.white_circle}>
                  <category.icon fill={linksUser?.contentCategoryIds?.includes(category?.id) ? activeColor : inactiveColor} />
                </div>
                <div className={styles.category_text}>
                  {category.name}
                </div>
              </div>
              ))}
        </div>
      );
  };

  return (
    <div className={showBioAndName ? '' : styles.profile_body}>
      <div className={styles.carousel_wrapper}>
          <button disabled={currentSlide === 1} className={`${styles.back_btn} ${styles.left}`} onClick={() => setCurrentSlide(1)}><ArrowBackIosIcon /></button>
          <div>
            {currentSlide === 1 && <div className={styles.carousel_section}>
              {getRow(0, 3)}
              {getRow(3, 6)}
              {getRow(6, 9)}
            </div>}
            {currentSlide === 2 && <div className={styles.carousel_section}>
              {getRow(9, 12)}
              {getRow(12, 15)}
              {getRow(15, 18)}
            </div>}
          </div>
          <button disabled={currentSlide === 2} className={`${styles.back_btn} ${styles.right}`} onClick={() => setCurrentSlide(2)}><ArrowForwardIosIcon /></button>
      </div>
      <div className={styles.carousel_wrapper_dots}>
        <div className={`${styles.dots} ${currentSlide === 1 ? styles.active_dot : ''}`} onClick={() => setCurrentSlide(1)}></div>
        <div className={`${styles.dots} ${currentSlide === 2 ? styles.active_dot : ''}`} onClick={() => setCurrentSlide(2)}></div>
      </div>
    </div>
  );
};

export default CreatorProfile;
