import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import styles from './LinkModal.module.scss';
import {LinksSectionItem} from '../../features/stores/linksUserSlicer';
import {socialMediaLinks} from '../../constants/socialMedia';
import {useTranslation} from 'react-i18next';
import {isValidHttpUrl} from '../../features/util';
import EditableImageInput from './components/EditableImageInput';
import EditableTextInput from './components/EditableTextInput';
import TextInput from './components/TextInput';
import BaseModal, {BaseModalProps} from './components/BaseModal';
import ToggleButton from './components/ToggleButton';
import DeleteButton from './components/DeleteButton';
import SaveButton from './components/SaveButton';

export type LinkModalProps = BaseModalProps & {
  defaultLink?: LinksSectionItem;
  editable?: boolean;
  mode?: 'add' | 'edit';
  onSave: (link: LinksSectionItem) => void;
  onDelete: (id: string) => void;
};

const initialLink: LinksSectionItem = {
  id: '',
  title: '',
  url: '',
  type: 'other',
  hidden: false,
  isVisible: true,
  customUploadedImage: '',
  linksSectionId: '',
  orderNumber: 0,
};

const LinkModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();

  useEffect(() => {
    setLink(defaultLink);
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length > 50) return;
    setLink({...link, title});
  };

  const urlChange = (url: string) => {
    const skipSections = new Set(['seasonal']);
    const type =
      socialMediaLinks.find(
          ({key, section}) =>
            url.toLowerCase().includes(key) && !skipSections.has(section!),
      )?.key || 'other';
    setLink({...link, url, type});
  };

  const isValid = useMemo(() => {
    return link.title?.length && link.url.length && isValidHttpUrl(link.url);
  }, [link]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t('add_title')}
        disabled={!editable}
      />
      <EditableImageInput
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(customUploadedImage) =>
          setLink({...link, customUploadedImage})
        }
        editable={editable}
      />
      <TextInput
        value={link.url}
        onChange={(e)=>urlChange(e.target.value)}
        disabled={!editable}
        label='Your Link'
        error={link.url && !isValidHttpUrl(link.url) ? 'Invalid URL' : ''}
      />

      <EditControlsContainer>
        <ToggleButton
          checked={link.isVisible}
          onChange={(checked) => setLink({...link, isVisible: checked})}
          label={t('visibility')}
        />
        {mode === 'edit' && (
          <DeleteButton onClick={() => onDelete(link.id)}>
            {t('delete')}
          </DeleteButton>
        )}
      </EditControlsContainer>

      <SaveButton disabled={!isValid} onClick={() => onSave(link)}>
        {t('save')}
      </SaveButton>
    </BaseModal>
  );
};

const EditControlsContainer = ({children}: PropsWithChildren<{}>) => {
  return <div className={styles.editControls}>{children}</div>;
};

LinkModal.EditControlsContainer = EditControlsContainer;

export default LinkModal;
