import React from 'react';
import {Chart} from "react-google-charts";
import './charts.modules.scss';

const DemographicChart = ({values}: {values: [string, string|number][]}) => {
  const options = {
    colorAxis: {colors: Array.from({length: values?.length}, (v, i) => "#7066FF")},
    legend: "none",
  };
  return (
    <div className="chart" id="lineChart">
      <Chart
      chartType="GeoChart"
      width="100%"
      data={values}
      options={options}
    />
    </div>
  );
};

export default DemographicChart;
