import React, {
  useEffect,
  useState,
} from 'react';
import moment, {Moment} from 'moment';
import styles from './analytics.module.scss';
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import LinkVisits from "./Components/LinkVisits/LinkVisits";
import ProfileVisits from './Components/ProfileVisits/ProfileVisits';
import DemographicVisits from './Components/DemographicVisits/DemographicVisits';
import Followers from './Components/Followers/Followers';
import LinkClicks from './Components/LinkClicks/LinkClicks';
import MusicLinkClicks from './Components/LinkClicks/MusicLinkClicks';
import MusicLinkStatsBreakdown from './Components/LinkClicks/MusicLinkClicksBreakdown';
import DealsClickStats from './Components/Deals/DealsClick';
import DealsTotal from './Components/Deals/DealsTotal';

type RangeValue = Moment | null;

const Analytics = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.header}>{t('Analytics')}</span>
      <div className={styles.main_box}>
        <div className={styles.graph_row_one}>
          <div className={styles.box}>
            <ProfileVisits />
          </div>
          <div className={styles.box}>
            <LinkClicks />
          </div>
          <div className={styles.box}>
            <LinkVisits />
          </div>
        </div>
        <div className={styles.graph_row_two}>
          <div className={styles.box_left}>
            <div className={styles.world_map}>
              <DemographicVisits />
            </div>
          </div>
          <div className={styles.box_right}>
              <Followers />
          </div>
        </div>
        <p className={styles.musicLinks}>{t('music')}</p>
        <div className={styles.graph_row_one}>
          <div className={styles.box}>
            <MusicLinkClicks previewPlay={false} title={t('clicks_and_plays')}/>
          </div>
          <div className={styles.box}>
            <MusicLinkStatsBreakdown previewPlay={false} title={t('clicks_per_album')}/>
          </div>
          <div className={styles.box}>
            <MusicLinkStatsBreakdown previewPlay={true} title={t('plays_per_album')}/>
          </div>
        </div>
        <p className={styles.musicLinks}>{t('deals')}</p>
        <div className={styles.graph_row_one}>
          <div className={styles.border_box}>
            <DealsTotal />
          </div>
          <div className={styles.border_box}>
            <DealsClickStats />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
