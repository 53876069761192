import clevertapEventTracking from './clevertap-event-tracking';
import mixpanelEventTracking from './mixpanel-event-tracking';

export type EventTracking = {
  initialize: () => void;
  track: (eventName: string, data?: Record<string, any>) => void;
  identify: (id: string, data?: Record<string, any>) => void;
  updateProfile: (data: Record<string, any>) => void;
  reset: () => void;
};

export const creatorActor: EventTrackingOptions = {actor: 'creator'};

type EventTrackingService = 'clevertap' | 'mixpanel';
type EventActor = 'creator' | 'fan';

type EventTrackingOptions = { service: EventTrackingService; actor?: never } | { actor: EventActor; service?: never };

const getEventTrackingByActor = (actor: EventActor) => {
  switch (actor) {
    case 'creator':
      return mixpanelEventTracking;
    case 'fan':
      return clevertapEventTracking;
    default:
      return clevertapEventTracking;
  }
};

const getEventTrackingByService = (service: EventTrackingService) => {
  switch (service) {
    case 'clevertap':
      return clevertapEventTracking;
    case 'mixpanel':
      return mixpanelEventTracking;
    default:
      return clevertapEventTracking;
  }
};

export const eventTracking = (options?: EventTrackingOptions): EventTracking => {
  if (!options) return clevertapEventTracking;

  if (options.actor) return getEventTrackingByActor(options.actor);
  if (options.service) return getEventTrackingByService(options.service);

  return clevertapEventTracking;
};

type EventName =
  | 'signup_initiated'
  | 'signup_email_verified'
  | 'signup_account_created'
  | 'signup_email_changed'
  | 'signup_otp_resend'
  | 'onboarding_upload_pp'
  | 'onboarding_add_social_links'
  | 'onboarding_add_basic_info'
  | 'onboarding_add_super_power'
  | 'onboarding_add_interests';
