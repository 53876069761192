import React from 'react';
import styles from './formikForm.module.scss';
import DropDownIcon from '../../assets/images/earnings/expand_dropdown_icon.svg';
import {useTranslation} from 'react-i18next';

const FormikInputSelect = ({formik, name, labelName, options, ...props}:any) => {
  const {t} = useTranslation();
  const {values, handleChange} :any= formik;
  return <div className={styles.form_group}>
    <div className={styles.form_group_label}>
      {labelName.toUpperCase()}
    </div>
    <div className={`${styles.form_group_input} ${styles.form_group_select_container}`}>
      <img src={DropDownIcon} />
      <select required onChange={handleChange} name={name} value={values[name]} className={styles.form_group_select} {...props}>
        <option value="" disabled>{`${t('select')} ${labelName}`}</option>
        {options.map((option:any, index:number) => {
          return <option key={index} value={option}>{option}</option>;
        })}
      </select>
    </div>
  </div>;
};
export default FormikInputSelect;
