import React from 'react';
import mobilePreviewStyles from './PreviewTemplate.module.scss';
import defaultIconBlack from '../../assets/images/al_defaultLinkIconBlack.svg';
import defaultIconWhite from '../../assets/images/al_defaultLinkIconWhite.svg';
import defaultAvatar from '../../assets/images/al_default_avatar.png';
import {ReactComponent as LocationDynamic} from '../../assets/images/al_location_new.svg';
import {dynamicSocials} from '../../constants/socialMedia';
import {getShapeData, cardShape} from '../../constants/newThemes';
import {determineLinkItemIcon} from '../../features/util';
import {LinksSectionItem} from '../../features/stores/linksUserSlicer';
import {numberFollowerFormat, getUniqueGlobalFollowerCount} from '../../Helper/generalHelper';

interface IPublicUserProfile {
  linksUser: any;
  themeProp?: any;
  selectedStyle?: string;
}

const PreviewTemplate = ({
  linksUser,
  themeProp,
}: IPublicUserProfile) => {
  const styles = mobilePreviewStyles;

  if (!linksUser && !linksUser.linksSections) {
    return <div>Loading...</div>;
  } else {
    const linksSections = linksUser.linksSections;

    const socialSectionData = linksSections.find(
        (section: any) => section.type === 'social',
    );
    const userImportantLinks = linksSections.find(
        (section: any) => section.type === 'important_links',
    );
    const socialMediaItems = socialSectionData.linksSectionItems;

    const theme = themeProp;
    const uniqueFollowerCount = linksUser?.displayFollower ? (numberFollowerFormat(linksUser?.followersCount)) : 0;

    const cardShapeData = getShapeData(theme.cardShape);
    const profileAvatar = linksUser ? (linksUser.avatar?.url || linksUser.avatar || defaultAvatar) : (theme.avatar || defaultAvatar);
    const borderRadius = cardShapeData?.border.themePreviewBorderRadius;
    const iconRadius = cardShapeData?.icon.themePreviewBorderRadius;

    let defaultLinkImage = '';

    const socials = dynamicSocials(theme.mobileSocialMediaColor);
    const socialMediaData = socialMediaItems.map((item:any, index:number)=>{
      const dynamicData = socials.filter((soc:any)=>soc.key === item.type);
      if (dynamicData.length) return <div key={'socialIcon'+index} style={{transform: 'scale(0.55)', width: '17px', height: '15px'}}>{dynamicData[0].dynamic}</div>;
    });


    switch (theme.iconSet) {
      case 'default':
        defaultLinkImage = defaultIconBlack; // TEMPORARY
        break;
      case 'white':
        defaultLinkImage = defaultIconWhite; // TEMPORARY
        break;
      case 'black':
        defaultLinkImage = defaultIconBlack; // TEMPORARY
        break;
      default:
        defaultLinkImage = defaultIconBlack; // TEMPORARY
        break;
    }

    return (
      <div
        className={styles.userWrapper}
        style={{
          backgroundColor: theme.backgroundColor!,
          backgroundImage: `url(${theme.mobileImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat',
          fontFamily: theme.englishFont!,
          color: theme.nonCardTextColor!,
        }}
      >

        <div className={styles.userContentContainer}>
          <div className={styles.userContentFloating}>
            <div style={{
              backgroundColor: theme.cardColor!,
            }} className={styles.head_wrapper}>
              <div className={styles.image_container}>
                <div className={styles.image}>
                  <img className={styles.avatar} src={profileAvatar} />
                </div>
                <div style={{maxWidth: '110px'}}>
                  <span className={styles.title}>
                    {linksUser.fullname}
                  </span>
                  {uniqueFollowerCount != 0 ? <div className={styles.followers}>{uniqueFollowerCount} Followers</div> : null}
                  {linksUser.location && (
                    <div className={styles.location}>
                      {/* <img src={location} /> */}
                      <LocationDynamic
                      fill={theme.cardTextColor} />
                      <p className={styles.subtitle}>
                        {linksUser.location}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <p className={styles.description}>{linksUser.bio}</p>
              {socialSectionData.isVisible && <div className={styles.socialWrapper}>{socialMediaData}</div>}
            </div>
            <div className={styles.userContentCards}>
              {userImportantLinks.linksSectionItems
                  .filter((e: LinksSectionItem) => !e.hidden)
                  .slice(0, 3)
                  .map((el: any, index: number) => {
                    return (
                      <div
                        key={index+'themepreviewLinks'+el.id}
                        className={styles.userContentCards__item}
                        style={{
                          borderRadius,
                          height: '30px',
                          border: theme.borderColor !== 'transparent' ? '1px solid ' + theme.borderColor : '',
                          backgroundColor: theme.cardColor,
                          ...(theme.cardShape === cardShape.EDGES.name && {padding: '5px'}),
                          color: theme.cardTextColor,
                        }}
                      >
                        <img
                          style={{
                            borderRadius: iconRadius,
                            ...(theme.cardShape === cardShape.EDGES.name && {height: '20px'}),
                            backgroundColor: theme.backgroundColor,
                          }}
                          src={
                            determineLinkItemIcon(el, theme.iconSet)
                          }
                        />
                        <p>
                          {el.title}
                        </p>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PreviewTemplate;
