import React, {useState, useRef} from 'react';
import styles from './SocialMediaIconModal.module.scss';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {ExploreModalData, socialMediaLinks} from '../../constants/socialMedia';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {useTranslation} from 'react-i18next';
import {themeColor} from '../../constants/newThemes';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextFieldInput from '../TextFieldInput/TextFieldInput';

interface IProps{
    handleClose: () => void;
    orderNumber: number;
    handleChosePlatform: Function;
};

const ButonCustom = withStyles({
    root: {
      background: (props: any) => props.background,
      color: (props: any) => props.colorText,
    },
  })(Button);

const SocialMediaIconModal = ({handleClose, orderNumber, handleChosePlatform}: IProps) => {
  const {t} = useTranslation();
  const [currentView, setCurrentView] = useState('CHOOSE-LINK');
  const linksUser = useSelector(selectLinksUser);
  const socialSectionData = linksUser.linksSections.find(
    (section: any) => section.type === 'social',
    );
  const socialSectionItems = socialSectionData?.linksSectionItems?.filter((e: any) => {
    return !e?.url && !['instagram', 'tiktok', 'youtube', 'facebook', 'snapchat', 'twitter'].includes(e?.type);
  });
  const [addObj, setAddObj] = useState<any>({});
  const node = useRef(null);
  const handleBackwardClick = () => {
    if (currentView == 'ADD-LINK') {
        setCurrentViewHandle('CHOOSE-LINK');  
    }
  };
  const determineIcon = () => {
    return socialMediaLinks.find((e) => e.key === addObj?.type)?.defaultIconTransparent;
  };

  const handleChangeUrl = (url: any) => {
    setAddObj({...addObj, url: url});
  };

  const setCurrentViewHandle = (value: string, item?: any) => {
    if (value == 'ADD-LINK') {
        setAddObj(item);  
    }
    setCurrentView(value);
  };

  const handleAddLink = () => {
     handleChosePlatform(addObj?.id, addObj?.url, addObj?.type);
     handleClose();
  };

  const getContentBody = () => {
    switch (currentView) {
        case 'CHOOSE-LINK': 
            return <>
            <div className={styles.head_container} style={{justifyContent: 'center'}}>
                <div className={styles.head_left}>
                    {/* <div className={styles.head_title}>{t('connect_your_content')}</div> */}
                    <h5 className={styles.text_center}>{t('icons')}</h5>
                    <div className={styles.quick_link}>{t('quick_link_to_your_social_media_accounts')}</div>
                </div>
            </div>
            {/* <div className={styles.divider}></div> */}
            <div className={styles.box_body}>
                {socialSectionItems?.map((item: any, index: number) => (
                    <div className={styles.card_item_box} key={`${item.key}-${index}`}>
                        <div className={styles.card_item_box_left}>
                            <div className={styles.icon_container}><img src={socialMediaLinks.find((e) => e.key === item?.type)?.defaultIcon} /></div>
                            <div className={styles.card_item_box_middle}>
                                <div className={styles.name}>{socialMediaLinks.find((e) => e.key === item?.type)?.name}</div>
                            </div>
                        </div>
                        <div className={styles.card_item_box_right} onClick={() => setCurrentViewHandle('ADD-LINK', item)}>
                          {/* {t('+add')} */}
                          <AddCircleIcon htmlColor="black" />
                        </div>
                    </div>
                ))}    
            </div>
            </>; 
        case 'ADD-LINK': 
            const linkType = socialMediaLinks.find((e) => e.key === addObj.type)?.name;
            return <>
            <div>
                <h5 className={styles.text_center}>{linkType}</h5>
            </div>
            <div className={`${styles.box_body} ${styles.box_add_single} ${styles.link}`}>
                <TextFieldInput
                  placeholder={linkType}
                  value={addObj.url}
                  customStyle={{height: '56px', padding: '0px 12px'}}
                  onChange={(e: any) => handleChangeUrl(e.target.value)}
                  icon={determineIcon()!} />
            </div>
            <div className={styles.add_footer}>
                 <ButonCustom onClick={handleBackwardClick} variant="contained" size="large" colorText='black' background="white">{t('Cancel')} </ButonCustom>
                 <ButonCustom disabled={!!!addObj?.url?.length} onClick={handleAddLink} variant="contained" size="large" colorText={themeColor.color} background={themeColor.background}>{t('add')}</ButonCustom>
            </div>
            </>;             
        default:
            break;       
    }  
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={`${styles.modal_content} ${currentView == 'ADD-LINK' ? styles.add_link : ''}`}>
              <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
              <div className={styles.content_body}>
                {getContentBody()}
              </div>
              
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaIconModal;
