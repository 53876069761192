import {makeStyles} from '@material-ui/core';

export const useStylesBox = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        background: 'rgba(119, 126, 144, 0.1)',
        border: '1px solid rgba(217, 217, 217, 0.6)',
        // borderBottom: 'none',
        borderRadius: '5px',
        width: '100px',
    },
    textFieldLink: {
        borderBottom: 'none',
        borderRadius: '5px',
        width: '100%',
        paddingRight: '50px',
    },
    placeholderTextField: {
        width: '160px',
    },
}),
);

export const useStyles = makeStyles(() => ({
    expanded: {},
    content: {
        '&$expanded': {
            margin: '12px 0px',
        },
    },
}));
