import React, {useEffect, useState, useRef} from 'react';
import styles from './MyProfileItem.module.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import arrowDown from '../../assets/images/al_arrow_down_accordion.svg';
import RouteLeavingGuard from '../UnsavedChanges/RouteLeavingGuard';
import {store} from '../../app/store';
import {makeStyles, Slider, TextField} from '@material-ui/core';
import pencil from '../../assets/images/pencil.svg';
import useWindowWidth from '../../hooks/useWindowWidth';
import ContentEditable from 'react-contenteditable';
import {updateSectionTitle} from '../../features/stores/linksUserSlicer';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import AutoSizeInput from '../AutosizeInput/AutosizeInput';

interface IProps {
  name: string;
  id?: string;
  subtitle: String;
  content: JSX.Element;
  isAccordion: boolean;
  isVisible?: boolean;
  headerComponent?: JSX.Element;
  editableTitle?: boolean;
  setIsOpenModal?: (arg: boolean)=>void;
  keyId?: string;
  mobileHideTitle?: boolean;
}

const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    '&$expanded': {
      margin: '12px 0px',
    },
  },
}));

const MyProfileItem = ({name, id, subtitle, content, isAccordion, headerComponent, isVisible, editableTitle, setIsOpenModal, keyId, mobileHideTitle}: IProps) => {
  const imgSource = name === 'My links' ? arrowDown : arrowDown;
  const image = <img src={imgSource} />;
  const [contentExpanded, setContentExpanded] = useState(false);
  const width = useWindowWidth();
  const classes = useStyles();

  useEffect(()=>{
    if (setIsOpenModal) {
      if (contentExpanded) {
        setIsOpenModal(true);
      } else {
        setIsOpenModal(false);
      }
    }
  }, [contentExpanded]);

  const inputRef = useRef(null) as any;


  return (
    <>
      <div className={styles.container} id='scrollChoseIcon'>
        {isAccordion === true ? (
        <Accordion style={{boxShadow: 'none'}}
          onChange={(e, expanded)=>{
            setContentExpanded(!contentExpanded);
          }}
          expanded={contentExpanded}
        >
          <AccordionSummary
            expandIcon={image}
            aria-controls="panel1a-content"
            id="panel1a-header"
            focusVisibleClassName={styles.removeFocus}
            classes={{expanded: classes.expanded, content: classes.content}}
          >
            <div className={styles.accordion_header}>
              {editableTitle ?
              <div className={styles.textfield_container}>
                <AutoSizeInput
                  value={name}
                  disabled={false}
                  className={styles.textfield}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e)=>{
                    setContentExpanded(contentExpanded);
                    store.dispatch(setDataChange(true));
                    if (id) {
                      store.dispatch(updateSectionTitle({id: id, title: e.length <= 50? e : name}));
                    }
                  }}
                ></AutoSizeInput>
                <img src={pencil} className={styles.pencil} />

              </div> :
              <p className={`${styles.accordion_name} ${mobileHideTitle ? styles.hide_section : ''}`}>{name}</p>}
              {subtitle && <p className={styles.accordion_subtitle}>{subtitle}</p>}
            </div>

          </AccordionSummary>
          <AccordionDetails
          >
            <div className={styles.content_container}>
              {content}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className={styles.header}>
          <div className={styles.header__container__noaccordian}>
            <p className={`${styles.accordion_name} ${mobileHideTitle ? styles.hide_section : ''}`}>{name}{subtitle && <div className={styles.accordion_subtitle}>{subtitle}</div>}</p>
            {headerComponent}
          </div>
          {content}
        </div>
      )}
      </div>
    </>
  );
};

export default MyProfileItem;
