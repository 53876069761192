import React, {useRef, useEffect, useState} from 'react';
import {Button} from 'antd';
import styles from '../CommonScss/Table.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const ContractTable = ({contractList, handleViewClick}: any)=>{
  const {t} = useTranslation();
  const mobile = useWindowWidth();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.tablemain}>
          <div className={styles.table_header}>
            <div>{t('date_of_signing')}</div>
            <div>{t('date_of_linking')}</div>
            <div>{t('date_of_expiry')}</div>
            <div>{t('category')}</div>
            <div className={styles.action_center}>{t('action')}</div>
          </div>
          <div className={styles.table_body}>
            {contractList.map((contract: any) => {
                return (mobile > 768 ? 
                  <div className={`${styles.table_row}`} key={contract.id}>
                    <div className={styles.main_row}>
                      <div style={{fontWeight: 500}}>{moment(contract.date_of_signing).format('MMM DD, YYYY')}</div>
                      <div>{moment(contract.date_of_linking).format('MMM DD, YYYY')}</div>
                      <div>{moment(contract.date_of_expiry).format('MMM DD, YYYY')}</div>
                      <div className={styles.tag_container}>
                        {contract?.contractCategory?.map((tag: any, i: number) => (
                            <div className={styles.tag} key={`${tag}-${i}`}>
                              {tag?.category?.name}
                            </div>
                        ))}
                      </div>
                      <div className={styles.action_center}>
                        <Button size='small' onClick={() => handleViewClick(contract || [])} className={styles.withdraw_button}>
                          {t('view')}
                        </Button>
                      </div>
                    </div>
                  </div> :
                    <div className={styles.table_row_mobile} key={contract.id}>
                      <div className={styles.table_row}> 
                      <div>{t('date_of_signing')}</div>
                      <div><span style={{fontWeight: 500}}>{moment(contract.date_of_signing).format('MMM DD, YYYY')}</span></div>
                      </div>
                      <div className={styles.table_row}> 
                      <div>{t('date_of_linking')}</div>
                      <div>{moment(contract.date_of_linking).format('MMM DD, YYYY')}</div>
                      </div>
                      <div className={styles.table_row}> 
                      <div>{t('date_of_expiry')}</div>
                      <div>{moment(contract.date_of_expiry).format('MMM DD, YYYY')}</div>
                      </div>
                      <div className={styles.table_row}> 
                      <div>{t('category')}</div>
                      <div className={styles.tag_container}>
                        {contract?.contractCategory?.map((tag: any, i: number) => (
                            <div className={styles.tag} key={`${tag}-${i}`}>
                              {tag?.category?.name}
                            </div>
                        ))}
                      </div>
                      </div>
                      <div className={styles.table_row}> 
                      <div>{t('')}</div>
                      <div> <Button size='small' onClick={() => handleViewClick(contract || [])} className={styles.withdraw_button}>
                          {t('view')}
                        </Button></div>
                      </div>
                    </div>
                );
            })}
            
          </div>
        </div>
      </div>
    </>);
};

export default ContractTable;
