import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {getPreferredLocale, setPreferredLocale} from './features/util';
const searchParams = new URLSearchParams(window.location.search);
const queryLangValue = searchParams?.get('lang')?.toLowerCase();
const lang = (queryLangValue == 'en' || queryLangValue == 'ar') ? queryLangValue : getPreferredLocale();
i18n.use(initReactI18next).init({
  fallbackLng: lang || 'en',
    debug: true,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      "en": {
        translation: require('./locales/en/translation.json'),
      },
      "ar": {
        translation: require('./locales/ar/translation.json'),
      },
    },
});
setPreferredLocale(lang);
i18n.changeLanguage(lang!);

export default i18n;
