import React from 'react';
import styles from './WarningModal.module.scss';
interface IModalProps{
    contentHeadline?: string;
    contentText?: string;
    buttonText?: string;
    setIsOpen: (arg: boolean) => void;
    open: boolean;
}

const WarningModal = ({contentText, buttonText, setIsOpen, open, contentHeadline}: IModalProps) =>{
  return (
    open? <div className={styles.modal}>
      <div className={styles.modal_content}>
        <div className={styles.content_wrapper}>
          <div className={styles.content_headline}>
            {contentHeadline}
          </div>
          <div className={styles.content_text}>{contentText}</div>
        </div>
        <div className={styles.button_wrapper} onClick={() => setIsOpen(false)}>
          {buttonText}
        </div>
      </div>
    </div> : <></>
  );
};

export default WarningModal;
