import React, {useEffect, useState} from 'react';
import styles from './MusicLinks.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {Trans, useTranslation} from 'react-i18next';
import {Divider} from 'antd';
import {ButtonCustom} from '../ImportantLinks/ImportantLinks';
import {useUploadRequest} from '../../features/API/request';
import {PlusCircleFilled} from '@ant-design/icons';
import {themeColor} from '../../constants/newThemes';
import AudioPreviewUpload from './AudioPreviewUpload';
import StreamLineItem from './StreamLineItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {TextField, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import trashIcon from '../../assets/images/al_delete_purple.svg';
import {MusicAlbumItem, MusicStream} from '../../features/stores/musicAlbumsSlicer';
import {toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  root: {
    'width': '100%',
    'textarea:focus-visible': {
        'border': 'none',
    },
  },
});

const initialAlbum: MusicAlbumItem = {
  id: '',
  title: '',
  description: '',
  previewUrl: '',
  coverImageUrl: '',
  albumLinks: [],
  visible: true,
};

interface IProps{
    handleSubmit: (payload: MusicAlbumItem) => void;
    handleClose?: () => void;
    album?: MusicAlbumItem;
}

const MusicModal = ({handleSubmit, handleClose, album = initialAlbum}: IProps) => {
  const {t} = useTranslation();
  const [songTitle, setSongTitle] = useState<string>(album.title);
  const [audioPreviewURL, setAudioPreviewURL] = useState<string>(album.previewUrl);
  const [streamingLinks, setStreamingLinks] = useState<MusicStream[]>(album.albumLinks.map((link) => ({streamingService: link.type, url: link.url, key: link.id!, editable: false})));
  const [coverPicture, setCoverPicture] = useState(album.coverImageUrl);
  const [coverPictureLoading, setCoverPictureLoading] = useState<boolean>(false);
  const [albumDescription, setAlbumDescription] = useState<string>(album.description);
  const [isHovered, setIsHovered] = useState(false);
  const [publishDisable, setPublishDisable] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const uploadFileRequest = useUploadRequest({method: 'POST', url: '/linksFile/upload'});
  const classes = useStyles();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDeleteClick = () => {
    setCoverPicture('');
  };

  const uploadAudioCover = async (e: any) => {
    try {
      const maxSize = 1;
      if (e.target.files && e.target.files.length > 0 && ((e.target.files[0].size / 1024 / 1024) > maxSize)) {
          const name = 'Song poster';
          toast(<span style={{color: 'black'}}><Trans i18nKey="size_has_exceeded_the_allowed_size" name={name} maxSize={maxSize}>{{name}} size has exceeded the allowed size {{maxSize}} Mb </Trans></span>, {autoClose: 3000});
          setCoverPictureLoading(false);
          return;
      }
      const params = {
          file: {
              key: 'file',
              value: e.target.files[0],
              fileName: e.target.files[0].name,
          },
      };
      const response = await uploadFileRequest.doRequest(params);
        if (response.length) {
          setCoverPicture(response[0].url);
          setCoverPictureLoading(false);
        }
      } catch (error) {
        // ToDo - show error
        setCoverPicture('');
        setCoverPictureLoading(false);
      }
  };

  const addNewStreamingLink = () => {
    const newStream: MusicStream = {
      streamingService: 'others',
      url: '',
      key: (Math.random() + 1).toString(36).substring(7),
      editable: true,
    };
    const updatedStreamingLinks = [...streamingLinks];
    updatedStreamingLinks.push(newStream);
    setStreamingLinks(updatedStreamingLinks);
  };

  const deleteStreamingLink = (index: number) => {
    const updatedStreamingLinks = [...streamingLinks];
    updatedStreamingLinks.splice(index, 1);
    setStreamingLinks(updatedStreamingLinks);
  };

  const updateStreamingLink = (index: number, streamingSevice: string, url: string) => {
    const updatedStreamingLinks = [...streamingLinks];
    updatedStreamingLinks[index].url = url;
    updatedStreamingLinks[index].streamingService = streamingSevice;
    setStreamingLinks(updatedStreamingLinks);
  };

  const handleAddMusicLink = () => {
    const addMusicLinkParams: MusicAlbumItem = {
        id: album.id || Date.now().toString(),
        title: songTitle,
        description: albumDescription,
        previewUrl: audioPreviewURL,
        coverImageUrl: coverPicture,
        visible: true,
        albumLinks: streamingLinks.filter(({url}) => !!url).map((link: MusicStream) => {
          return {type: link.streamingService, url: link.url};
        }),
    };
    handleSubmit(addMusicLinkParams);
  };

  useEffect(()=>{
    if (!streamingLinks.length) {
      addNewStreamingLink();
    }
  }, []);

  useEffect(()=>{
    if (songTitle.length > 0 && coverPicture.length > 0) {
      setPublishDisable(false);
    } else {
      setPublishDisable(true);
    }
  }, [coverPicture, songTitle]);

  const fetchMetaFromLink = () => {
    return !songTitle.length || !coverPicture.length || !albumDescription.length || !audioPreviewURL.length;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
              <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
              <div className={styles.title}>{t('listen_to_song')}</div>
              <div className={styles.subTitle}>{t('listen_to_song_subtitle')}</div>

              {/* Streaming platforms */}
              <div className={styles.modalSectionTitle}>{t('select_streaming_service')} <Tooltip placement='top' title={t('added_streaming_links_cannot_be_edited_after_publishing')}><InfoIcon /></Tooltip></div>
                {streamingLinks.length > 0 ?
                streamingLinks.map((streamingLink: MusicStream, index: number) => <div className={styles.input_wrapper} key={streamingLink.key}>
                  <StreamLineItem disabled= {!streamingLink.editable} fetchHandler={setLoader} streamingLink={streamingLink} updateStreamingLink={(streamingService, url) => {
                    updateStreamingLink(index, streamingService, url);
                  }} deleteStreamingLink={() => {
                    deleteStreamingLink(index);
                  }} updateAlbumMetadata={fetchMetaFromLink() ? (title, description, coverURL, audioURL) => {
                    if (!songTitle.length) {
                      setSongTitle(title);
                    }
                    if (!coverPicture.length) {
                      setCoverPicture(coverURL);
                    }
                    if (!audioPreviewURL.length) {
                      setAudioPreviewURL(audioURL);
                    }
                    if (!albumDescription.length) {
                      setAlbumDescription(description);
                    }
                  } : () => {}}
                  />
                </div>) : <></> }
              <ButtonCustom onClick={addNewStreamingLink} className={styles.custom_btn} variant="contained" size={"medium"} colortext={themeColor.btnColor} background={themeColor.btnBackground} startIcon={<PlusCircleFilled />}>
                <div>{t('add_streaming_link')}</div>
              </ButtonCustom>
              
              {/* Song name */}
              <div className={`${styles.modalSectionTitle} ${styles.required}`}>{t('song_name')}</div>
              <div className={styles.input_wrapper}>
                <input
                  className={styles.input_field}
                  placeholder={t('song_name')}
                  value={songTitle}
                  onChange={(e) => {
                    setSongTitle(e.target.value);
                  }}
                />
              </div>

              {/* Song preview */}
              <div className={styles.modalSectionTitle}>{t('audio_preview')}</div>
              <AudioPreviewUpload audioPreviewURL={audioPreviewURL} setAudioPreviewURL={setAudioPreviewURL}/>

              <div className={styles.additionalAlbumMeta}>
                {/* Song cover */}
                <div className={styles.songCover}>
                  <div className={`${styles.modalSectionTitle} ${styles.required}`}>{t('album_cover')}</div>
                  <div className={styles.uploadParentDiv}>
                    <input
                        id={`linkIcon-audio-cover-upload`}
                        style={{display: 'none'}}
                        type={'file'}
                        multiple={false}
                        accept="image/*"
                        onChange={(e) => {
                            setCoverPictureLoading(true);
                            uploadAudioCover(e);
                        }}
                    />
                    <div className={styles.icon_sibling}>
                      {coverPictureLoading && <CircularProgress disableShrink className={styles.coverLoader}/>}
                      {coverPicture.length > 0 && !coverPictureLoading && 
                        <div onMouseEnter={handleMouseEnter} className={styles.uploadedCover}
                        onMouseLeave={handleMouseLeave} >
                          <img src={coverPicture} className={styles.coverPicture} />
                          {isHovered && (
                            <div className={styles.deleteButton} >
                              <img src={trashIcon} className={styles.audioTrash} onClick={handleDeleteClick}/>
                            </div>
                          )}
                        </div>
                      }
                      {coverPicture.length == 0 && !coverPictureLoading && 
                      <div className={styles.uploadDiv} onClick={() => {
                        document.getElementById('linkIcon-audio-cover-upload')?.click();
                      }}>
                        <AddAPhotoIcon />
                        <div style={{marginTop: 8}}>{t('upload')}</div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.albumDescription}>
                  <div className={styles.modalSectionTitle}>{t('description')}</div>
                  <TextField className={classes.root} variant="outlined" multiline rows={4} value={albumDescription} onChange={(e) => setAlbumDescription(e.target.value)} />
                </div>
            </div>
              
            {/* Save */}
            <Divider />
            <div className={styles.submit}>
              {isLoading ? <Loader type={'TailSpin'} color="#EB3B5A" height={40} width={40}></Loader> :
              <ButtonCustom disabled={publishDisable} onClick={publishDisable ? () => {} : handleAddMusicLink} size="large" variant="contained" color={themeColor.color} background={themeColor.background}>
                {t('publish')}
              </ButtonCustom>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MusicModal;
