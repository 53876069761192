/* eslint-disable react/prop-types */
import React from 'react';
import {Checkbox} from '@material-ui/core';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';

interface CheckboxFieldProps {
  checked: boolean;
  onChange: () => void;
  label: React.ReactNode;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({checked, onChange, label}) => (
  <div className={styles.terms}>
    <Checkbox checked={checked} onChange={onChange} name="checkedAccept" />
    <div className={styles.title}>{label}</div>
  </div>
);

export default CheckboxField;
