import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import styles from '../ImageCrop/ImageCrop.module.scss';
import '../ImageCrop/ImageCrop.css';
import {useSelector} from 'react-redux';
import useWindowWidth from '../../hooks/useWindowWidth';
import defaultAvatar from '../../assets/images/al_default_avatar.png';
import {useTranslation} from "react-i18next";

interface IProps {
  imgSrc: any;
  setIsOpen: (arg: boolean) => void;
  type: string;
  setImageUrl: (arg: any) => void;
  setIsConfirmed?: Function;
  handleCancelUpload?: Function;
  originalImage?: string;
}
const selectLinksUser = (state: any) => state.user.me.linksUser;


const ImageCrop = ({imgSrc, setIsOpen, type, setImageUrl, setIsConfirmed, originalImage, handleCancelUpload}: IProps) => {
  // const [cropData, setCropData] = useState('#');
  const {t} = useTranslation();
  const [cropper, setCropper] = useState<any>();
  const [zoom, setZoom] = useState(0);
  const [className, setClassName] = useState('');
  const linksUser = useSelector(selectLinksUser);
  const mobile = useWindowWidth() < 500;

  useEffect(() => {
    if (type === 'profile') {
      setClassName('profile');
    } else if (type === 'theme') {
      setClassName('theme');
    } else if (type === 'video') {
      setClassName('video');
    } else if (type === 'important_link') {
    }
  }, [type]);

  const minZoom = 0.44;
  const maxZoom = 5;

  const increaseZoom = () => {
    zoom < maxZoom ? setZoom(zoom + 1) : ' ';
  };

  const descreaseZoom = () => {
    zoom > minZoom ? setZoom(zoom - minZoom) : ' ';
  };
  const i = new Image();

  i.onload = function() {
    console.log('aaa', i.naturalWidth+', '+i.naturalHeight );
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setIsConfirmed && setIsConfirmed(true);
      setImageUrl(cropper.getCroppedCanvas().toDataURL());
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    setIsConfirmed && setIsConfirmed(false);
    setIsOpen(false);
    if (originalImage) setImageUrl(originalImage);
    else setImageUrl(linksUser.avatar?.url || defaultAvatar);
    handleCancelUpload && handleCancelUpload();
  };

  return (
    <div>
      <div className={className} style={{width: '100%', boxSizing: 'border-box'}}>
        <Cropper
          style={{height: 460,
            width: mobile ? 350 : 460}}
          aspectRatio={type === 'theme'? 0 : 1/1}
          preview=".img-preview"
          src={imgSrc}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={false}
          checkOrientation={false}
          autoCrop={true}
          autoCropArea={1}
          onInitialized={(instance:any) => {
            setCropper(instance);
          }}
        />
        {/* <div className={styles.controls}>
          <div className={styles.plus} onClick={increaseZoom}>
            <p>+</p>
          </div>
          <div className={styles.minus} onClick={descreaseZoom}>
            <p>-</p>
          </div>
        </div> */}
      </div>
      <div className={styles.buttons_wrapper}>
        <div className={styles.cancel_button} onClick={handleCancel}>
          {t('Cancel')}
        </div>
        <div className={styles.save_button} onClick={getCropData}>
          {t('Accept')}
        </div>
      </div>
      <br style={{clear: 'both'}} />
    </div>
  );
};

export default ImageCrop;
