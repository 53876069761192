import React from "react";
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import {isArabic} from "../../features/util";
import {useTranslation} from "react-i18next";

const TextFieldInput = (props: (TextFieldProps & {icon?: string | React.SVGProps<any>, customStyle?: any, staticHeader?: boolean} & any)) => {
  const {t, i18n} = useTranslation();
  return (
    <TextField id="filled-basic" fullWidth margin="normal"
      variant="filled"
      InputProps={{
        disableUnderline: true, startAdornment: props?.icon ? (
          <InputAdornment position="start" style={{marginTop: '0px'}}>
            <img src={props?.icon as string} />
          </InputAdornment>
        ) : null, style: {...{border: '1px solid #E7E8EA', background: 'white', borderRadius: '30px', fontSize: '16px'}, ...props?.InputPropsStyle},
      }}
      inputProps={{style: props?.customStyle}}
      style={{marginTop: '8px', marginBottom: '0px'}}
      InputLabelProps={{
        //   shrink: false,
        style: {color: '#7A808A', right: isArabic(i18n.language) ? ((props?.icon) ? '40px' : '20px') : '20px', left: isArabic(i18n.language) ? 'unset' : ((props?.icon) ? '30px' : (props?.staticHeader ? '10px' : '0px')), textAlign: 'start'},
      }}
      {...props} />
  );
};

export default TextFieldInput;

