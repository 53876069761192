import React, {useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import styles from './Deals.module.scss';
import DealItem from './DealItem';
import {useTranslation} from 'react-i18next';
import {dealsConst} from '../../constants/helper';
import {getDealsStore} from '../../features/stores/dealsSlicer';
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';

const AppliedDeals = () => {
  const {t} = useTranslation();
  const appliedDeals = useSelector(getDealsStore)?.appliedDeals || [];
  const scrollableDivRef = useRef() as any;

  return (
    <>
      <div className={styles.container}>
        {appliedDeals.length > 0 ? (
          <div className={styles.scrollable} ref={scrollableDivRef}>
            {appliedDeals.map((item, index) => (
              <div key={item.id} className={styles.scrollableItem}>
                <DealItem
                  deal={item}
                  handleDealApply={() => {}}
                  activeId={''}
                  status={dealsConst.APPLIED}
                />
              </div>
            ))}
          </div>
        ) : (
            <div className={styles.no_links}>
              <img src={noVideo} alt="No deals" />
              <div className={styles.no_links_text}>
                <div className={styles.no_links_title}>{t('no_deals_to_display')}</div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default AppliedDeals;
