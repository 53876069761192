import React, {
    useEffect,
    useState,
  } from 'react';
  import moment, {Moment} from 'moment';
  import useRequest from '../../../../features/API/request';
  import LinkClicksChart from '../../../Charts/LinkClicksChart';
  import styles from './LinkClicks.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";

  interface IMusicLinkStatsProps{
    previewPlay: boolean;
    title: string;
  }
  
  const MusicLinkClicks = ({previewPlay, title}: IMusicLinkStatsProps) => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [total, setTotal] = useState(0);
    const [stackedData, setStackedData]= useState<{stackValues: {name: string, data: number[]}[], stackLabels: string[]}>({stackValues: [], stackLabels: []});
    const stackRequest = useRequest({method: 'GET', url: '/albumAnalytics'});
  
    const getLinkAnalyticsData = async () => {
      try {
        const params = {
          from: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          to: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
        };
        const stackResult = await stackRequest.doRequest(params);
        if (stackResult) {
          const keys: string[] = Object.keys(stackResult); // Seperate keys for x-axis 
          const totalArray: number[] = [];
          let total: number = 0;
          Object.values(stackResult).map((ele: any) => {
              totalArray.push(ele[0].count);
              total = total + ele[0].count;
          });
          const stackValues: {name: string, data: number[]}[] = [{name: title, data: totalArray}] || [];
          const stackLabels: string[] = keys || [];
          setStackedData({stackValues, stackLabels});
          setTotal(total);
          setData(true);
        } else {
          setData(true);
        }
      } catch (error) {
        setData(true);
        console.log('Error occured', error);
      }
    };
  
    useEffect(() => {
        if (value.length) {
          getLinkAnalyticsData();    
        }
    }, [value]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
              <div className={styles.heading}><p>{t('clicks')}</p><p className={styles.total}>{total?.toLocaleString()}</p></div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
  
            <div className={styles.chart_wrapper}>
              {data ? <LinkClicksChart values={stackedData.stackValues} labels={stackedData.stackLabels} /> :
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                  <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                </div>
                <LinkClicksChart values={[]} labels={[]} />
              </div>
              }
            </div>
          </div>
        </div>
    );
  };
export default MusicLinkClicks;
