import Music from '../assets/images/MusicPodcastVideo_items/Music.svg';
import MusicActive from '../assets/images/MusicPodcastVideo_items/Music_active.svg';
import Podcast from '../assets/images/MusicPodcastVideo_items/Podcast.svg';
import PodcastActive from '../assets/images/MusicPodcastVideo_items/Podcast_active.svg';
import Video from '../assets/images/MusicPodcastVideo_items/Video.svg';
import VideoActive from '../assets/images/MusicPodcastVideo_items/Video_active.svg';

export const sections = [
  {
    key: 'music',
    name: 'Music',
    icon: Music,
    activeIcon: MusicActive,
    addMessage: 'Add song',
    defaultTitle: 'Featured song',
  },
  {
    key: 'podcast',
    name: 'Podcast',
    icon: Podcast,
    activeIcon: PodcastActive,
    addMessage: 'Add podcast',
    defaultTitle: 'Featured episode',
  },
  {
    key: 'video',
    name: 'Featured videos',
    icon: Video,
    activeIcon: VideoActive,
    addMessage: 'Add video',
    defaultTitle: 'Featured videos',
  },
];

