import React, {useRef, useEffect, useState} from 'react';
import styles from '../CommonScss/Table.module.scss';
import stylesBox from './RequestTable.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import {useTranslation} from 'react-i18next';
import Sort from '../../assets/images/sort.svg';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {getConfig} from '../../features/stores/configSlicer';
import {getDateBasedOnLocalTimezone, haveSegmentPermission} from '../../features/util';
import {getCurrencyLabel} from '../../constants/currencies';
import {monetizeImageMap} from '../../constants/socialMedia';
import EventIcon from '@material-ui/icons/Event';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const RequestTable = ({requestList, sort, setSort, setOpenTaskCompleteModal, setTaskIDObj, setOpenProofModal, setModalObj}: any)=>{
  const {t} = useTranslation();
  const mobile = useWindowWidth();
  const [openAccordianId, setOpenAccordianId] = useState('');
  // const [openProofModal, setOpenProofModal] = useState(false);
  // const [modalObj, setModalObj] = useState({attachmentUrl: '', comments: ''});
  const config = useSelector(getConfig).data || [];
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];

  const handleSort = (value: any) => {
    let sortString = `${value}-ASC`;
    if (sort.includes(value)) {
      sortString = sort.includes('-DESC') ? `${value}-ASC` : `${value}-DESC`;
    };
    setSort(sortString);
  };

  const handleComplete = (id: any, type: string) => {
    if (setTaskIDObj && setOpenTaskCompleteModal) {
      setTaskIDObj({taskID: id, type});
      setOpenTaskCompleteModal(true);
    }
  };

  const handleToggle = (id: any) => {
    if (id == openAccordianId) {
      setOpenAccordianId('');
    } else {
      setOpenAccordianId(id);
    }
  };

  const handleDownload = (url: any) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = "_blank";
    // a.download = "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleShowMoreLess = (idMore: any, idLess: any, param: any) => {
    const more: any = document.getElementById(idMore);
    const less: any = document.getElementById(idLess);
    if (more && less) {
      if (param == 'more') {
      more.style.display = 'none';
      less.style.display = 'block';
      } else {
        more.style.display = 'block';
        less.style.display = 'none';
      }
    }
  };

  const handleOpenModal = (request: any) => {
    setOpenProofModal(true);
    setModalObj({attachmentUrl: request.attachmentUrl, comments: request.comments});
  };

  const getCollapsedNotes = (notes: string, id: string, size: number, key: string) => {
    return (
      notes?.length > size ? (<>
        <div className={`${stylesBox.notes} ${styles.notes}`} id={`show-more-${key}-${id}`} style={{width: '100%'}}>{notes.substring(0, 30)}...<button className={styles.show_less_btn} onClick={() => handleShowMoreLess(`show-more-${key}-${id}`, `show-less-${key}-${id}`, 'more')}>{t('show_more')}</button></div>
        <div className={`${stylesBox.notes} ${styles.notes}`} id={`show-less-${key}-${id}`} style={{display: 'none', width: '100%'}}>{notes}<button className={styles.show_less_btn} onClick={() => handleShowMoreLess(`show-more-${key}-${id}`, `show-less-${key}-${id}`, 'less')}>{t('show_less')}</button></div>
        </>
      ) : (notes || '--')
    );
  };

  return (
    <>
      {/* Possible status [InReview, Accepted, Rejected, Returned, Reverted, Failed, Completed] */}
        <div className={styles.tablemain}>
  
          <div className={stylesBox.container}>
          {requestList.map((request: any) => {
                const currencyLabel = getCurrencyLabel(request.currency);
                const startDate = request?.buyerMeta?.details?.startDate;
                const endDate = request?.buyerMeta?.details?.endDate;
                const timeZone = request?.buyerMeta?.details?.timezone;
                return (
                  <div className={`${stylesBox.single_box} ${request.id == openAccordianId ? styles.hightlight : ''}`} key={request.id}>
                    <div className={stylesBox.single_inner_box}>
                      <div className={stylesBox.header_row}>
                          <div className={stylesBox.column_img}>
                            <img onClick={() => handleToggle(request.id)} src={monetizeImageMap[request?.linkType]} />
                          </div>
                          <div className={stylesBox.column_right}>
                              <div className={stylesBox.title}>
                                    <div className={stylesBox.heading_one}>{request.linkTitle}</div>
                              </div>
                              <div className={stylesBox.amount}>
                                <div className={stylesBox.heading_two}>{request?.buyerMeta?.name || '--'}</div>
                                <div>
                                  {currencyLabel} {(Number(request.amount)/(currencyMap.filter((e: any) => e.currency == (request?.currency || 'usd'))[0]?.conversionFactor || 100)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </div>
                              </div>
                              <div className={stylesBox.date}>
                                <AlternateEmailIcon /> {request?.buyerMeta?.email || '--'}
                              </div>
                              {startDate && endDate &&
                              <div className={stylesBox.date}>
                                <EventIcon /> {`${getDateBasedOnLocalTimezone(startDate, timeZone, 'DD MMM YYYY')}, ${getDateBasedOnLocalTimezone(startDate, timeZone, 'hh:mm A')} - ${getDateBasedOnLocalTimezone(endDate, timeZone, 'hh:mm A')}`}
                              </div>}
                              <div className={stylesBox.date}>
                                  {moment(request.createdAt).format('MMM DD, YYYY')}
                              </div>
                          </div>
                      </div>
                      <div className={stylesBox.box_body}>
                        {getCollapsedNotes(request?.buyerMeta?.notes, request.id, 60, 'notes')}
                      </div>
                      <div className={stylesBox.action_btn}>
                          {["Completed", "Rejected", "Reverted", "Returned", "Failed"].includes(request.status) ? null: 
                          ((["Accepted"].includes(request.status) || (["In Review"].includes(request.status) && request.type == 'tipJar')) ? <button className={stylesBox.accept} onClick={() => handleComplete(request.id, 'Completed')}>{t('complete')}</button> : (
                            (["In Review"].includes(request.status) && request.type != 'tipJar') ? [<button key={`accept-${request.id}`} className={stylesBox.accept} onClick={() => handleComplete(request.id, 'Accepted')}>Accept</button>, <button key={`reject-${request.id}`} className={stylesBox.reject} onClick={() => handleComplete(request.id, 'Rejected')}>Reject</button>] : null
                          ))}
                      </div>
                    </div>
                    {request.id == openAccordianId && <div className={styles.nested_row}>
                      <div><span>{t('request_notes')}</span><br /><div className={styles.light}>
                          {getCollapsedNotes(request?.buyerMeta?.notes, request.id, 60, 'notes')}
                        </div>
                      </div>
                      {request.comments && ["Rejected", "Completed"].includes(request.status) && <div><span>{t(request.status == "Rejected" ? 'rejection_reason' : 'completion_notes')}</span><br />
                        <div className={styles.light}>
                          {getCollapsedNotes(request?.comments, request.id, 50, 'comments')}
                          <br />
                          {request.attachmentUrl && <div>
                            <div><button className={styles.show_less_btn} onClick={() => handleOpenModal(request)}>{t('attachment')}</button></div>
                          </div>}
                        </div>
                      </div>}
                      <div><span>{t('net_earning')}</span><br /><div className={styles.light}>{currencyLabel} {(Number(request.creatorShare)/(currencyMap.filter((e: any) => e.currency == (request?.currency || 'usd'))[0]?.conversionFactor || 100)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div></div>
                      <div><span>{t('gross_earning')}</span><br /><div className={styles.light}>{currencyLabel} {(Number(request.amount)/(currencyMap.filter((e: any) => e.currency == (request?.currency || 'usd'))[0]?.conversionFactor || 100)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div></div>
                    </div>}
                  </div>
                );
            })}
          </div>
        </div>
    </>);
};

export default RequestTable;
