import {Dialog, makeStyles} from '@material-ui/core';
import React, {ComponentProps, PropsWithChildren} from 'react';
import styles from './BaseModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export type BaseModalProps = Omit<ComponentProps<typeof Dialog>, 'onClose'> & {
  classes?: {
    main?: string;
  };
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeIcon' | 'close') => void;
  withBackButton?: boolean;
  onBack?: () => void;
};

const useModalStyles = makeStyles({
  paper: {
    'borderRadius': 16,
    'width': 500,
    '@media (max-width: 480px)': {
      maxWidth: 'calc(100% - 32px)',
      margin: 16,
    },
  },
});

const BaseModal = ({children, onClose, withBackButton = false, onBack, classes, ...dialogProps}: PropsWithChildren<BaseModalProps>) => {
  const modalStyles = useModalStyles();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      onClose({}, 'close');
    }
  };

  return (
    <Dialog maxWidth={false} classes={{paper: modalStyles.paper}} scroll='body' onClose={onClose} {...dialogProps}>
      <div className={styles.container}>
        <div className={styles.header}>
          {withBackButton && <ArrowBackIosIcon className={styles.headerIcon} fontSize='medium' onClick={() => handleBack()} />}
          <CloseIcon className={[styles.headerIcon, styles.closeIcon].join(' ')} fontSize='inherit' onClick={(e) => onClose(e, 'closeIcon')} />
        </div>
        <div className={classes?.main ?? styles.main}>{children}</div>
      </div>
    </Dialog>
  );
};

export default BaseModal;
