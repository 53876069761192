import React from 'react';
import styles from './SocialMediaBox.module.scss';
import {useSelector} from 'react-redux';
import {LinksSectionItem, selectLinksUser} from '../../features/stores/linksUserSlicer';
import {useTranslation} from "react-i18next";
import {socialMediaLinks} from '../../constants/socialMedia';
import noLink from "./../../assets/images/versionV2/Profile/noLinks.svg";


const SocialMediaBox = () => {
    const {t} = useTranslation();
    const linksUser = useSelector(selectLinksUser);
    const linksUserSections = linksUser.linksSections;
    const socialSectionData = linksUserSections.find(
        (section: any) => section.type === 'social',
    );
    const socialSectionItems = socialSectionData?.linksSectionItems?.filter((e: any) => e?.url);

    return (
        <>
            <div className={styles.drop_container}>
                {socialSectionItems && socialSectionItems?.length ? 
                    <>{socialSectionItems?.map((item: LinksSectionItem, index: number) => (
                        <div className={styles.social_block} key={item?.id}>
                            <div className={styles.icon}>
                                <img src={(socialMediaLinks.find((e) => e.key === item?.type)?.defaultIconTransparent)} />
                            </div>
                            <div className={styles.username}>
                                {item?.url?.split('/')[item?.url?.split('/').length-1]}
                            </div>
                        </div>
                    ))}</> : <div className={styles.empty}>
                        <img src={noLink} />
                        <div>{t('no_data_found')}</div>
                        </div>}
            </div></>);
};

export default SocialMediaBox;
