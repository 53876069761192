import React, {useEffect, useState} from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from './MusicLinks.module.scss';
import {useTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import trashIcon from '../../assets/images/al_delete_purple.svg';
import GenericIcon from '../../assets/images/music.svg';
import nonHookRequest from '../../features/API/nonHookRequest';
import {MusicStream} from '../../features/stores/musicAlbumsSlicer';
import {MusicLinkPlatformsMap} from '../../Helper/MusicLinkPlatformsMap';

interface IProps{
    fetchHandler: (flag: boolean) => void;
    streamingLink: MusicStream;
    updateStreamingLink: (streamingService: string, url: string) => void;
    deleteStreamingLink: () => void;
    updateAlbumMetadata: ((title: string, description: string, coverURL: string, audioPreviewURL: string) => void);
    disabled?: boolean;
}

const StreamLineItem = ({fetchHandler, streamingLink, updateStreamingLink, deleteStreamingLink, updateAlbumMetadata, disabled = false}: IProps) => {
    const {t} = useTranslation();
    const [streamingURL, setStreamingURL] = useState<string>(streamingLink.url || '');
    const [streamingService, setStreamingService] = useState<string>(streamingLink.streamingService || '');

    // Define an async function to make the HTTP call
    const fetchAlbumData = async (url: string) => {
        try {
            fetchHandler(true);
            const response = await nonHookRequest({method: 'GET', url: '/albumLinks/meta', params: {
                url,
            }});
            if (response) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(response, 'text/html');

                // Extract meta tags
                const ogTitleTag = doc.querySelector('meta[property="og:title"]');
                const ogAudioTag = doc.querySelector('meta[property="og:audio"]') || doc.querySelector('meta[property="og:audio:url"]');
                const ogImageTag = doc.querySelector('meta[property="og:image"]');
                const ogDescTag = doc.querySelector('meta[property="og:description"]');
                const title = ogTitleTag?.getAttribute('content') || '';
                const imageUrl = ogImageTag?.getAttribute('content') || '';
                const previewUrl = ogAudioTag?.getAttribute('content') || '';
                const description = ogDescTag?.getAttribute('content') || '';
                updateAlbumMetadata(title, description, imageUrl, previewUrl);
            }
            fetchHandler(false);
            // updateAlbumMetadata based on above reponse
        } catch (error) {
            fetchHandler(false);
            console.error('Error fetching data:', error);
        }
    };

    
    const getStreamingServiceIcon = (streamingService: string) => {
        const item = MusicLinkPlatformsMap.filter((platform) => platform.value == streamingService);
        return item.length > 0 ? item[0].icon : GenericIcon;
    };
    const [streamingServiceIcon, setStreamingServiceIcon] = useState<string>(getStreamingServiceIcon(streamingService));

    
    const handleUrlChange = (url: string) => {
        const service = recognizeMusicStreamingPlatform(url);
        setStreamingURL(url);
        setStreamingService(service);
        setStreamingServiceIcon(getStreamingServiceIcon(service));
        if (service != 'others') {
            fetchAlbumData(url);
        }
    };

    useEffect(() => {
        updateStreamingLink(streamingService, streamingURL);
    }, [streamingService, streamingURL]);
    
    // Handle input changes with the debounced function
    const handleInputChange = (event: any) => {
        const url = event.target.value;
        // ToDo - handle debounce?
        handleUrlChange(url);
    };

    const recognizeMusicStreamingPlatform = (url: string) => {
        // Define regular expressions for known platforms
        const platforms = {
            "spotify": /^(https?:\/\/)?(www\.)?open\.spotify\.com/i,
            "appleMusic": /^(https?:\/\/)?(music\.)?apple\.com/i,
            "youtubeMusic": /^(https?:\/\/)?(music\.)?youtube\.com/i,
            "soundCloud": /^(https?:\/\/)?(www\.)?soundcloud\.com/i,
            "deezer": /^(https?:\/\/)?(www\.)?deezer\.com/i,
            "applePodcast": /^(https?:\/\/)?(podcasts\.)?apple\.com/i,
            "anghami": /^https:\/\/play\.anghami\.com/i,
            // Add more platforms and their regular expressions as needed
        };
    
        // Check if the URL matches any known platform
        for (const [platform, pattern] of Object.entries(platforms)) {
            if (pattern.test(url)) {
                return platform;
            }
        }
        return 'others';
    };

    return (<>
            <Grid container spacing={1}>
                <Grid item xs={3} sm={2}>
                    <Select disabled={disabled} variant="outlined" fullWidth value={streamingService} native={false}
                        onChange={(e) => {
                            setStreamingService(e.target.value as string);
                            setStreamingServiceIcon(getStreamingServiceIcon(e.target.value as string));
                        }}
                        renderValue={() => <img src={streamingServiceIcon} className={styles.selectedStreamingChoiceIcon}/>} 
                        className={styles.streamlineSelect}>
                        {MusicLinkPlatformsMap.map((platform) => <MenuItem key={platform.value} value={platform.value}>
                            <img src={platform.icon} className={styles.streamingChoiceIcon}/>
                            {platform.label}
                        </MenuItem>)}
                    </Select>
                </Grid>
                <Grid item xs={8} sm={9}>
                    <TextField disabled={disabled} autoFocus variant="outlined" fullWidth value={streamingURL} onChange={handleInputChange} className={styles.streamURL} />
                </Grid>
                <Grid item xs={1} sm={1} className={styles.trashContainer}>
                    <img src={trashIcon} className={styles.trash} onClick={deleteStreamingLink}/>
                </Grid>
            </Grid>
        </>);
};

export default StreamLineItem;
