import {store} from '../app/store';

export const checkIfAllSectionsAreValid = () => {
  const sections = store.getState().changeData.sectionStatus;
  let status = true;
  let invalidSection = '';
  sections.forEach((element) => {
    if (!element.valid) {
      status = false;
      invalidSection = element.section;
      return;
    }
  });
  return {status, invalidSection};
};

export const checkIfAllSectionsAreSaved = () => {
  const sections = store.getState().changeData.sectionStatus;
  let status = true;
  let unsavedSection = '';
  sections.forEach((element) => {
    if (!element.saveStatus) {
      status = false;
      unsavedSection = element.section;
      return;
    }
  });
  return {status, unsavedSection};
};
