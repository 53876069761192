import axios from 'axios';
import {getSession, logOutUnauthenticated} from '../Login/login';

const api = axios.create();

api.interceptors.response.use( (response) => response, async (error) => {
  // handle invalid or expired tokens
  if (error.response.status === 401 && localStorage.getItem('token')) {
    const isRefreshed = await getSession(false);
    if (isRefreshed) {
      error.config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
      // send the request again with the new token
      const result = await axios(error.config);
      return result;
    } else {
      logOutUnauthenticated();
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
},
);
export default api;
