import React from 'react';
import Loader from 'react-loader-spinner';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';

const LoaderComponent: React.FC = () => (
  <div className={styles.loader}>
    <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
  </div>
);

export default LoaderComponent;
