import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {store} from '../../app/store';
import CustomTheme from '../../components/CustomTheme/CustomTheme';
import MobilePreview from '../../components/MobilePreview/MobilePreview';
import MyProfileItem from '../../components/MyProfileItems/MyProfileItem';
import NaviButtons from '../../components/NaviButtons/NaviButtons';
import ThemeSelect from '../../components/ThemeSelect/ThemeSelect';
import {raiseToast} from '../../components/Toast/Toast';
import {themes} from '../../constants/newThemes';
import {selectChangeData, setShouldSave} from '../../features/stores/changeDataSlicer';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import useWindowWidth from '../../hooks/useWindowWidth';
import styles from './Design.module.scss';
import {useTranslation} from "react-i18next";

// const selectChangeData = (state: any) => state.changeData.changeData;
// const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;

const Design = () =>{
  const {t} = useTranslation();
  const dataChange = useSelector(selectChangeData);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedBackgroundIndex, setSelectedBackgroundIndex] = useState<number>(-1);
  const [selectedFontIndex, setSelectedFontIndex] = useState<number>(-1);
  const width = useWindowWidth();
  // const shouldSave = useSelector(selectShouldSave);

  const handleLeft = () => {
    if (selectedIndex !== -1) {
      setSelectedIndex(selectedIndex - 1);
    }
    const el = document.getElementById('selectedTheme');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };
  const handleRight = () => {
    if (selectedIndex < themes.length - 1) {
      setSelectedIndex(selectedIndex - 1);
    }
    setSelectedIndex(selectedIndex + 1);
    const el = document.getElementById('selectedTheme');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  const handleBackgroundLeft = () => {
    if (selectedBackgroundIndex !== -1) {
      setSelectedBackgroundIndex(selectedBackgroundIndex - 1);
    }
    const el = document.getElementById('selectedBackground');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };
  const handleBackgroundRight = () => {
    if (selectedBackgroundIndex < themes.length - 1) {
      setSelectedBackgroundIndex(selectedBackgroundIndex - 1);
    }
    setSelectedBackgroundIndex(selectedBackgroundIndex + 1);
    const el = document.getElementById('selectedBackground');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  const handleFontLeft = () => {
    if (selectedFontIndex !== -1) {
      setSelectedFontIndex(selectedFontIndex - 1);
    }
    const el = document.getElementById('selectedFont');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };
  const handleFontRight = () => {
    if (selectedFontIndex < themes.length - 1) {
      setSelectedFontIndex(selectedFontIndex - 1);
    }
    setSelectedFontIndex(selectedFontIndex + 1);
    const el = document.getElementById('selectedFont');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  const customTheme = (<>
    <ThemeSelect
      handleLeft={handleLeft}
      handleRight={handleRight}
      setIndex={(e: number) => setSelectedIndex(e)}
      index={selectedIndex}
    />
    <CustomTheme
      handleBackgroundLeft={handleBackgroundLeft}
      handleBackgroundRight={handleBackgroundRight}
      setBackgroundIndex={(e: number) => setSelectedBackgroundIndex(e)}
      backgroundIndex={selectedBackgroundIndex}
      handleFontLeft={handleFontLeft}
      handleFontRight={handleFontRight}
      setFontIndex={(e: number) => setSelectedFontIndex(e)}
      fontIndex={selectedFontIndex}
    />
  </>);

  const SectionList = [
    {
      key: 'custom_design',
      name: t('customize_design'),
      subtitle: '',
      isAccordion: false,
      content: customTheme,
      headerComponent: <div className={styles.header_container}>
        <div className={styles.title}>
          <h5>{t('select_from_templates')}</h5>
          <span>{t('preview_your_public_profile')}</span>
        </div>
        {width > 768 && <div><NaviButtons onLeftClick={() => handleLeft()} onRightClick={() => handleRight()}/></div>}
        </div>,
    },
  ];

  useEffect(()=>{
    store.dispatch(setShouldSave(false)); // clear state
  }, []);

  // useDidUpdateEffect(()=>{
  //   if (shouldSave) {
  //     store.dispatch(setShouldSave(false));
  //     raiseToast({message: 'Changes successfully saved'});
  //   }
  // }, [shouldSave, dataChange]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          {SectionList.map((section, index)=>
            <div key={index} className={styles.section_container}>
              <MyProfileItem
                name={section.name}
                subtitle={section.subtitle}
                content={section.content}
                isAccordion={section.isAccordion}
                headerComponent={section.headerComponent}
                keyId={section.key}
                mobileHideTitle={true}
              />
            </div>)}
        </div>
      </div>

      <div className={styles.right}>
        <MobilePreview showLink={width > 768} />
      </div>
    </div>
  );
};

export default Design;
