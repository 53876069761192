import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import styles from './TaskCompleteModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useRequest from '../../features/API/request';

interface IProps{
    handleClose: (status?: any, attachmentUrl?: any, comments?: any) => void;
    taskIDObj: any;
};
const TaskCompleteModal = ({handleClose, taskIDObj}: IProps) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({imageProof: '', notes: '', url: ''});
  const taskRequest = useRequest({url: `/finance/api/orders/${taskIDObj?.taskID}/status`, method: 'PUT', isShortUrl: true});

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && ((e.target.files[0].size / 1024 / 1024) < 30)) {
      const imageDataUrl: any = e.target.files[0];
      const url: any = await readFile(e.target.files[0]);
      setData({...data, imageProof: imageDataUrl, url});
      e.target.value=null;
    } else {
      toast(<span style={{color: 'black'}}>{t('image_size_cannot_exceed_30Mb')}</span>, {autoClose: 3000});
    }
  };

  const handleUploadThumbnail = () => {
    document.getElementById(`proofUpload`)?.click();
  };

  const completeTask = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      if (data?.imageProof) {
        body.append('file', data.imageProof);
      }
      if (data?.notes) {
        body.append('comment', data.notes);
      }
      body.append('orderStatus', taskIDObj?.type || 'Completed');
      const result = await taskRequest.doRequest(body);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Something went wrong!');
    }
  };

  useEffect(() => {
    if (taskRequest?.data?.orderId) {
      toast(<span style={{color: 'black'}}>{t(taskIDObj?.type == 'Completed' ? 'request_completed' : taskIDObj?.type == 'Rejected' ? 'request_rejected' : 'request_accepted')}</span>, {autoClose: 3000});
      handleClose(taskIDObj?.type, taskRequest?.data?.attachmentUrl, taskRequest?.data?.comments);
    }
  }, [taskRequest.data]);

  useEffect(() => {
    if (taskRequest.hasError) {
      toast(<span style={{color: 'black'}}>{'Something went wrong!'}</span>, {autoClose: 3000});
    }
  }, [taskRequest.hasError]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
              <div className={styles.cross} onClick={handleClose}><CloseIcon /></div>
              <h5>{taskIDObj?.type == 'Completed' ? t('complete_task') : taskIDObj?.type == 'Rejected' ? t('reject_task') : taskIDObj?.type == 'Accepted' ? t('accept_task') : ''}</h5>
              <div className={styles.content_body}>
              {loading ? <div className={styles.loader}>
                <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
              </div> : <>
              {['Completed', 'Rejected'].includes(taskIDObj?.type) && <div style={{display: 'flex', columnGap: '20px'}}>
                {data?.imageProof ? 
                    <div className={styles.image_upload} onClick={handleUploadThumbnail}>
                        <img src={data.url} />
                    </div> : <div className={styles.image_upload} onClick={handleUploadThumbnail}>
                        <div><CloudUploadIcon fontSize="large" /></div>
                    </div>} 
                <input
                    id="proofUpload"
                    accept="image/*"
                    style={{display: 'none'}}
                    type='file'
                    onChange={(e) => {
                        fileSelectedHandler(e);
                    }}
                /> 
              <div className={styles.input}>
                <textarea placeholder={t('enter_your_reason')} value={data.notes} onChange={(e: any) => setData({...data, notes: e.target.value})} style={{height: '100px'}} />
              </div>
              </div>}
              {['Accepted'].includes(taskIDObj?.type) && <div className={styles.accept_request}>
                {t('are_you_sure_want_to_accept')}
              </div>}
              <div className={styles.buttons_wrapper} style={{justifyContent: taskIDObj?.type == 'Accepted' ? 'center' : 'flex-end'}}>
                <div className={styles.cancel_button} onClick={handleClose}>
                  {t('Cancel')}
                </div>
                 <button disabled={taskIDObj?.type == 'Rejected' && !data.notes} className={styles.save_button} onClick={completeTask}>
                  {t('save')}
                 </button>
               </div>  
               </>}
              </div>
              
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskCompleteModal;
