import React from 'react';
import styles from './customInput.module.scss';

interface IProps{
  title?: string;
  placeholder?: string;
  value: any;
  onChange: (args: any) => void;
  className?: string;
  wrapperClassName?: string;
  children?: React.ReactNode
}

const CustomInput = (props: IProps) => {
  return <div className={`${styles.input_wrapper} ${props.wrapperClassName}`}>
    <input
      className={`${styles.input_field} ${props.className}`}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      disabled
    />
    <div className={styles.input_wrapper__icons}>
      {props.children}
    </div>
  </div>;
};

export default CustomInput;
