import React, {PropsWithChildren, useState} from "react";
import moment from 'moment';
import styles from './TrackingLinksTable.module.scss';
import nonHookRequest from "../../features/API/nonHookRequest";
import tableStyles from '../CommonScss/Table.module.scss';
import {Pagination} from "antd";
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import ConfirmationModal from "../LinkModal/modals/ConfirmationModal";
import {TrackingLinks} from "../../pages/TrackingLinks/TrackingLinksPage";
import {themeColor} from '../../constants/newThemes';
import {ReactComponent as TrashIcon} from '../../assets/images/al_trash_red2.svg';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {Tooltip} from '@material-ui/core';
import {raiseToast} from "../Toast/Toast";

interface TrackingLinksTableProps {
  trackingLinks: TrackingLinks[];
  trackingLinksTotalCount: number;
  getTrackingLinks: () => Promise<void>;
  currPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  isSearching: boolean;
  AddTrackingLinkButton: () => JSX.Element
};

const TrackingLinksTable = ({
  trackingLinks, 
  trackingLinksTotalCount, 
  getTrackingLinks, 
  currPage,
  setCurrPage,
  perPage,
  isSearching,
  AddTrackingLinkButton,
}: TrackingLinksTableProps) => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openVisibilityConfirmationModal, setOpenVisibilityConfirmationModal] = useState(false);
  const [trackingLinkId, setTrackingLinkId] = useState<string | null>(null);
  const [userDealId, setUserDealId] = useState<string | null>(null);
  const [isCurrentVisible, setIsCurrentVisible] = useState(false);
  
  const {t} = useTranslation();

  const deleteTrackingLink = async () => {
    const response = await nonHookRequest({
      method: 'DELETE',
      url: `/brandshub/api/user-deal/trackinglink/${trackingLinkId}`,
      isShortUrl: true,
      body: {userDealId},
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: t('tracking_link_deleted')});
      getTrackingLinks();
      setOpenDeleteConfirmationModal(false);
    };
  };

  const handleCopyingLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    raiseToast({message: t('copied_to_clipboard')});
  };

  const updateVisibility = async () => {
    const response = await nonHookRequest({
      method: 'PUT',
      url: `/brandshub/api/user-deal/trackinglink/visibility/${trackingLinkId}`,
      isShortUrl: true,
      body: {visible: !isCurrentVisible},
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      getTrackingLinks();
      setOpenVisibilityConfirmationModal(false);
    };
  };

  return !trackingLinks?.length ? (
    isSearching ? 
    <div className={styles.emptyContainer}>
      <h3 className={styles.emptyHeading}>{t('no_search_result')}</h3>
      <p className={styles.emptysubHeading}>{t('click_to_add')}</p>
    </div> :
    <div className={styles.emptyContainer}>
      <h3 className={styles.emptyHeading}>{t('no_tracking_link')}</h3>
      <AddTrackingLinkButton />
    </div> 
  ): 
  (
    <div className={styles.container}>
      <div className={tableStyles.tablemain}>
        <div className={`${tableStyles.table_header} ${styles.pl0} ${styles.dFlex}`}>
          <div className={styles.colBig}><span className={styles.tableHeading}>Products </span></div>
          <div className={`${styles.colMid} ${styles.hideMobile}`}><span className={styles.tableHeading}>Deal </span></div>
          <div className={`${styles.colMid} ${styles.hideMobile}`}><span className={styles.tableHeading}>Created At </span></div>
          <div className={styles.colMid}><span className={styles.tableHeading}>{t('actions')} </span></div>
        </div>
        <div className={tableStyles.table_body}>
          {trackingLinks.map((trackingLink) => (
            <div className={`${tableStyles.table_row} ${styles.pl0}`} key={trackingLink.id}>
              <div className={tableStyles.main_row}>
                <div className={`${styles.colBig} ${styles.tableColumn}`}>
                  <img src={trackingLink.logoUrl} className={styles.trackingLogo} alt=""/>
                  <span className={styles.tableSubHeading}>{trackingLink.description}</span>
                </div>
                <div className={`${styles.colMid} ${styles.hideMobile}`}><span className={styles.tableSubHeading}>{trackingLink.userDeal.deal.name}</span></div>
                <div className={`${styles.colMid} ${styles.hideMobile}`}><span className={styles.tableSubHeading}>{moment(trackingLink.createdAt).format('MMM DD, YYYY')}</span></div>
                <div className={`${styles.colMid} ${styles.tableColumn}`}>
                  <Tooltip arrow placement='top' title={trackingLink?.visible ? 'Hide' : 'Show'} onClick={(e: any) => {
                    e.stopPropagation();
                    setTrackingLinkId(trackingLink.id);
                    setIsCurrentVisible(trackingLink?.visible);
                    setOpenVisibilityConfirmationModal(true);
                  }}>
                    {trackingLink?.visible ? (
                      <VisibilityIcon className={styles.actionIcon} htmlColor='#7066ff' />
                    ) : (
                      <VisibilityOffIcon className={styles.actionIcon} htmlColor='#888' />
                    )}
                  </Tooltip>
                
                  <span className={styles.mx10}></span>

                  <CopyIcon 
                    stroke={themeColor.background} 
                    className={styles.actionIcon}
                    onClick={() => handleCopyingLink(trackingLink.productTrackingLink)}
                  />
                  
                  <span className={styles.mx10}></span>

                  <div
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setTrackingLinkId(trackingLink.id);
                      setUserDealId(trackingLink.userDealId);
                      setOpenDeleteConfirmationModal(true);
                    }}
                  >
                    <TrashIcon className={styles.actionIcon} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.customPagination}>
        <Pagination
          onChange={(e) => setCurrPage(e)}
          size="small"
          pageSize={perPage}
          total={trackingLinksTotalCount}
          current={currPage}
          showSizeChanger={false}
          showLessItems={true}
          itemRender={(page, type, originalElement) => {
            return type == "page" ? (
              <div className={currPage == page ? styles.pagiActive : ''}>{page}</div>
            ) : (
              originalElement
            );
          }}
        />
      </div>

      <ConfirmationModal
        title={t('sure_delete_tracking_link')}
        primaryButtonText={t('yes_delete_link')}
        handlePrimaryButtonClick={() => deleteTrackingLink()}
        secondaryButtonText={t('no_keep_link')}
        open={openDeleteConfirmationModal}
        onClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
      />

      <ConfirmationModal
        title={isCurrentVisible ? t('hide_product_confirmation') : t('publish_product_confirmation')}
        primaryButtonText={isCurrentVisible ? t('yes_hide') : t('yes_publish')}
        handlePrimaryButtonClick={() => updateVisibility()}
        secondaryButtonText={isCurrentVisible ? t('no_publish') : t('no_hide')}
        open={openVisibilityConfirmationModal}
        onClose={() => {
          setOpenVisibilityConfirmationModal(false);
        }}
      />
    </div>
  );
};

export default TrackingLinksTable;
