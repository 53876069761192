import React from 'react';
import styles from './formikForm.module.scss';
import {useHistory} from 'react-router-dom';
import BackArrowIcon from '../../assets/images/earnings/backArrow.svg';
const FormikForm = ({children, formik, title, submitTitle, ...props}:any) => {
  const history = useHistory();
  return <form className={styles.form} onSubmit={formik.handleSubmit} {...props}>
    <div className={styles.heading}>
      <a className={styles.back_icon} onClick={()=>{
        history.goBack();
        props.onBack();
      }}><img style={{width: '13px'}} src = {BackArrowIcon}/></a>
      <div className=''>{title}</div>
    </div>
    {children}
    <div className={styles.form_group}>
      <div className={styles.form_group_input}>
        <button disabled={formik.isSubmitting} type='submit' className={styles.form_submit}>{submitTitle}</button>
      </div>
    </div>
  </form>;
};

export default FormikForm;
