import React, {useRef, useState, useEffect} from 'react';
import {Button, Dialog, DialogContent, List, ListItem, ListItemText} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import {AlbumLink} from '../../features/stores/musicAlbumsSlicer';
import styles from './MusicLinks.module.scss';
import {getPlatformDetails} from '../../Helper/MusicLinkPlatformsMap';
import useRequest from '../../features/API/request';

interface PopupProps {
  albumId: string;
  open: boolean;
  onClose: () => void;
  coverImageUrl?: string;
  audioUrl?: string;
  items?: AlbumLink[];
  playText: string;
  albumTitle: string;
  albumDescription: string;
  dir?: any;
}

const AlbumDetails=({albumId, open, onClose, coverImageUrl, audioUrl, items, playText, albumTitle, albumDescription, dir}: PopupProps) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const recordAlbumEvent = useRequest({
      method: 'POST',
      url: '/albumAnalytics',
    });
    const recordAlbumLinkEvent = useRequest({
      method: 'POST',
      url: '/albumLinkAnalytics',
    });

    const toggleAudio = () => {
        if (audioRef.current) {
            setIsPlaying(!isPlaying);
            if (isPlaying) {
                audioRef.current.pause();
            } else {
              audioRef.current.play();
              recordAlbumEvent.doRequest({'albumId': albumId, 'previewPlay': true});
            }
        }
    };

    useEffect(() => {
        if (audioRef.current) {
          // Add an event listener to detect when the audio playback ends
          audioRef.current.addEventListener('ended', () => {
            setIsPlaying(false); // Update state to allow the play button to be displayed again
          });
        }
        recordAlbumEvent.doRequest({'albumId': albumId, 'previewPlay': false});
    
        // Clean up the event listener when the component unmounts
        return () => {
          if (audioRef.current) {
            audioRef.current.removeEventListener('ended', () => {});
          }
        };
      }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={styles.popupContainer}>
        <div className={styles.cover_play}>
        <img src={coverImageUrl} alt="Cover" className={styles.coverImage} />
        <div className={styles.transition_div}></div>
        {audioUrl && <div className={`${styles.play_button} ${styles.play_button_public}`} onClick={toggleAudio}>
                        {isPlaying ? (
                            <PauseIcon fontSize="large" />
                        ) : (
                            <PlayArrowIcon fontSize="large" />
                        )}
                    </div>}
        </div> 
        {audioUrl && <audio src={audioUrl} ref={audioRef}>
          Your browser does not support the audio element.
        </audio>}

        <div className={styles.album_title}>{albumTitle}</div>
        <div className={styles.album_desc}>{albumDescription}</div>
        <List dir={dir} style={{width: '100%'}}>
          {items?.map((item, index) => {
              const platform = getPlatformDetails(item.type);
             return platform ? (<ListItem key={index} className={styles.listItem}>
              <img src={platform?.icon} className={styles.streamingChoiceIcon}/>
              <ListItemText primary={platform?.label} />
              <Button
                className={styles.stream_play}
                variant="contained"
                onClick={() => {
                  recordAlbumLinkEvent.doRequest({'albumLinkId': item.id});
                  window.open(item.url, '_blank');
                }}
              >
                {playText}
              </Button>
            </ListItem>) : <></>;
            },
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default AlbumDetails;
