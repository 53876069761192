import React, {useMemo} from 'react';
import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';
import {getPGFeePercentage, haveSegmentPermission} from '../../../features/util';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getConfig} from '../../../features/stores/configSlicer';
import {getCurrencyLabel} from '../../../constants/currencies';
import {FeeType, calculateFeeAmount, getCurrencyConversion} from '../../../Helper/utils';
import styles from './PriceBreakdown.module.scss';
import {MonetizeType, MonetizeLink} from '../modals/types';

type Props = {
  link: LinksSectionItem;
  googleCalander?: boolean;
};

const PriceBreakdown = ({link, googleCalander=false}: Props) => {
  const {t} = useTranslation();
  const monetizeType: MonetizeType = link.type as MonetizeType;
  const config = useSelector(getConfig).data || [];
  const monetizationConfig: MonetizeLink[] = haveSegmentPermission(config, 'monetize2');
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];

  const defaultMonetize = monetizationConfig.find((item) => item.id === monetizeType);
  const alfanPercentage = link.alfan_share || defaultMonetize?.alfanPercantage;
  const pgFeePercentage = getPGFeePercentage(config) as number;

  const getAmountTotal = () => {
    if (link?.price?.length) {
      let total = 0;
      link?.price.map((e: any) => total += (e?.amount ? e?.amount : 0));
      return total;
    } else {
      return link.price;
    }
  };

  const calculateFee = (feeType: FeeType) => {
    return calculateFeeAmount(feeType, getAmountTotal() / getCurrencyConversion(currencyMap, link?.currency) || 0, {alfanPercentage, pgFeePercentage}).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
    );
  };

  const alfanFee = calculateFee('alfanCut');

  const processingFee = calculateFee('processingFee');

  const earn = calculateFee('earn');

  if (monetizeType === 'tipJar') {
    return (
      <div className={styles.container}>
        <div className={styles.content_row}>
          <div className={styles.content_row_left}>
            <div className={styles.breakdown_title}>{`${alfanPercentage}% ${t('alfan_service_fee')}`}</div>
          </div>
        </div>
        {pgFeePercentage > 0 && (
          <div className={styles.content_row}>
            <div className={styles.content_row_left}>
              <div className={styles.breakdown_title}>{`${pgFeePercentage}% ${t('payment_processing_fee')}`}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content_row}>
        <div className={styles.content_row_left}>
          <div className={styles.breakdown_title}>{`${alfanPercentage}% ${t('alfan_service_fee')}`}</div>
        </div>
        <div className={styles.content_row_right}>
          {getCurrencyLabel(link.currency)} {alfanFee}
        </div>
      </div>
      {pgFeePercentage > 0 && (
        <div className={styles.content_row}>
          <div className={styles.content_row_left}>
            <div className={styles.breakdown_title}>{`${pgFeePercentage}% ${t('payment_processing_fee')}`}</div>
          </div>
          <div className={styles.content_row_right}>
            {getCurrencyLabel(link.currency)} {processingFee}
          </div>
        </div>
      )}
      <div className={styles.content_row}>
        <div className={styles.content_row_left}>
          <div className={styles.breakdown_title}>{t('you_will_receive')}</div>
          <div className={styles.sub_title_breakdown}>{t('the_estimated_amount_you_will_get')}</div>
        </div>
        <div className={styles.content_row_right}>
          {getCurrencyLabel(link.currency)} {earn}
        </div>
      </div>
    </div>
  );
};

export default PriceBreakdown;
