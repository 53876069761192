import React from 'react';
import {addLinkIcons} from '../constants/socialMedia';

type LinkIconProps = {
  type: string;
  customImage?: string;
};
export const LinkIcon = ({type, customImage}: LinkIconProps) => {
  if (customImage) {
    return <img src={customImage} alt='link image' />;
  }

  if (type !== 'other') {
    const LinkIcon = addLinkIcons[type] || addLinkIcons['regularLink'];
    return <LinkIcon />;
  }

  const RegularLinkIcon = addLinkIcons['regularLink'];
  return <RegularLinkIcon />;
};
