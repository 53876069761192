import {Switch} from 'antd';
import React, {ComponentProps} from 'react';
import styles from './ToggleButton.module.scss';


type Props = ComponentProps<typeof Switch> & {
    label?: string;
};

const ToggleButton = ({label, ...props}: Props) => {
  return (
    <div className={styles.toggle}>
      <Switch className={styles.switch} {...props} />
      <div>{label}</div>
    </div>
  );
};

export default ToggleButton;
