import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

interface PreviewModeState {
  value: boolean;
}

const initialState: PreviewModeState = {
  value: false,
};

export const previewModeSlice = createSlice({
  name: 'previewMode',
  initialState,
  reducers: {
    setPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const {setPreviewMode} = previewModeSlice.actions;

export const selectPreviewMode = (state: RootState): boolean => {
  return state.previewMode.value;
};

export default previewModeSlice.reducer;

