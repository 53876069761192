import React, {useEffect, useState} from 'react';
import {dealItem} from '../../features/stores/dealsSlicer';
import CloseIcon from '@material-ui/icons/Close';
import ModalStyles from './EditCollectionsModal.module.scss';
import copyIcon from '../../assets/images/icons/copy.svg';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import styles from './ShowCouponsModal.module.scss';

interface IModalProps{
    dealItem?: dealItem;
    setIsOpen: (arg: boolean) => void;
    open: boolean;
    onSubmit: () => void;
}

const ShowCouponsModal = ({dealItem, setIsOpen, open, onSubmit}: IModalProps) => {
  const [coupons, setCoupons] = useState<any>([]);
  const [trackingLinks, setTrackingLinks] = useState<any>([]);

  const {t} = useTranslation();

  useEffect(() => {
    dealItem?.userDealContents?.map((elem) => {
      if (elem.type === 'Coupon') setCoupons((prev: any) => [...prev, elem]);
      if (elem.productTrackingLink) setTrackingLinks((prev: any) => [...prev, elem]);
    });
  }, [dealItem]);

  const handleCopyingLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    toast.info(t('copied_to_clipboard'));
  };

  return (
  <div className={ModalStyles.modal}>
    <div className={ModalStyles.modal_content}>
      <div className={ModalStyles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
      <div className={ModalStyles.content_wrapper}>
        <div className={styles.headerContainer}>
          <img src={dealItem?.deal.logoUrl} className={styles.logo} alt=""/>
          <div className={styles.titleWrapper}>
            <h4>
              {dealItem?.deal.name}
            </h4>
            <p>{moment(dealItem?.deal.createdAt).format('MMM DD, YYYY')}</p>
          </div>
        </div>
        <div>
          <h4>{t('coupons')}: </h4>
          {coupons?.map((coupon: any, i: number) => {
            return (
              <div key={i} className={styles.container}>
                <p className={styles.boldWeight}>{coupon?.content}</p>
                <span className={styles.autoMargin}></span>
                <img 
                  src={copyIcon} 
                  className={styles.copyIcon}
                  alt=""
                  onClick={() => handleCopyingLink(coupon?.content)}
                />   
              </div>
            );
          })}
        </div>

        <div>
          <h4>{t('deal_tracking_links')}: </h4>
          {trackingLinks?.map((trackingLink: any, i: number) => {
            return (
              <div key={i} className={styles.container}>
                <p className={styles.boldWeight}>{trackingLink?.productTrackingLink}</p>
                <span className={styles.autoMargin}></span>
                <img 
                  src={copyIcon} 
                  className={styles.copyIcon}
                  alt=""
                  onClick={() => handleCopyingLink(trackingLink?.productTrackingLink)}
                />   
              </div>
            );
          })}
        </div>          
      </div>
    </div>
  </div>
  );
};

export default ShowCouponsModal;
