import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import styles from './UserSettings.module.scss';
import {useState} from 'react';
import {store} from '../../app/store';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {selectLinksUser, setLinksUser} from '../../features/stores/linksUserSlicer';
import {useTranslation} from 'react-i18next';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import AvatarDisplay from '../AvatarDisplay/AvatarDisplay';
import CountrySelect from '../CountrySelect/CountrySelect';
import Loader from 'react-loader-spinner';
import CreatorProfile from '../../components/CreatorProfile/CreatorProfile';
import {refreshMe} from '../../features/Login/login';
import useRequest from '../../features/API/request';
import {raiseToast} from '../../components/Toast/Toast';
import {MAX_BIO_CHARACTERS} from '../../constants/helper';

export interface ICountry {
  code: string;
  label: string;
  phone: string;
}

const UserSettings = (): JSX.Element => {
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const [showFollower, setShowFollower] = useState(linksUser.displayFollower);
  const [loading, setLoading] = useState(false);
  const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/profile-settings'});

  const savePersonalInfo = async () => {
    try {
      setLoading(true);
      const payload = {bio: linksUser.bio, fullname: linksUser.fullname, location: linksUser.location, displayFollower: linksUser.displayFollower, city: linksUser?.city, contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || [], phone: linksUser?.phone, gender: linksUser?.gender};
      await updateProfileRequest.doRequest(payload);
      raiseToast({message: t('changes_saved')});
      store.dispatch(setDataChange(false));
      await refreshMe();
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };
  const setFollower = (value: any) => {
    setShowFollower(value);
    store.dispatch(setLinksUser({...linksUser, displayFollower: value}));
    store.dispatch(setDataChange(true));
  };

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newBio = e.target.value;
    const truncatedBio = newBio.slice(0, MAX_BIO_CHARACTERS); // Truncate the text if it exceeds 200 characters
    store.dispatch(setLinksUser({...linksUser, bio: truncatedBio}));
    store.dispatch(setDataChange(true));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <AvatarDisplay />
        </div>
        <div className={styles.right}>
          <div className={styles.inputs_wrapper}>
            <label className={styles.label}>{t('display_name_label')}</label>
            <div className={styles.input_wrapper}>
              <TextFieldInput
                placeholder={t('display_name_placeholder')}
                value={linksUser.fullname as string}
                customStyle={{height: '56px', padding: '0px 12px'}}
                onChange={(e: any) => {
                  store.dispatch(setLinksUser({...linksUser, fullname: e.target.value}));
                  store.dispatch(setDataChange(true));
                }}
              />
            </div>
          </div>
          <div className={styles.inputs_wrapper}>
            <label className={styles.label}>{t('username')}</label>
            <div className={styles.input_wrapper}>
              <TextFieldInput
                className={styles.input_field}
                placeholder={t('display_name_placeholder')}
                value={linksUser?.nickname?.toLowerCase()}
                customStyle={{height: '56px', padding: '0px 12px'}}
                disabled
                onChange={(e: any) => {
                  store.dispatch(setLinksUser({...linksUser, nickname: e.target.value}));
                  store.dispatch(setDataChange(true));
                }}
              />
            </div>
          </div>
          <div className={`${styles.inputs_wrapper} ${styles.username}`} >
            <label className={styles.label} style={{marginBottom: '8px'}}>{t('country_name_label')}</label>
            <div className={styles.margin_eight}>
              <CountrySelect />
            </div>
          </div>
          <div className={styles.inputs_wrapper} >
            <label className={styles.label}>{t('city')}</label>
            <TextFieldInput
              placeholder={t('city')}
              value={linksUser?.city as string}
              customStyle={{height: '56px', padding: '0px 12px'}}
              onChange={(e: any) => {
                store.dispatch(setLinksUser({...linksUser, city: e.target.value}));
                store.dispatch(setDataChange(true));
              }}
            />
          </div>
          <div className={styles.inputs_wrapper}>
            <label className={styles.label}>{t('bio')}</label>
            <div className={styles.input_wrapper}>
              <textarea
                className={styles.biography}
                rows={6}
                onChange={handleBioChange}
                value={linksUser.bio as string}
                maxLength={MAX_BIO_CHARACTERS} // Set the maxLength attribute for the textarea
                placeholder={t('bio_placeholder')}
              ></textarea>
              <p className={styles.characterCounter}>{`${(linksUser.bio || '').length}/${MAX_BIO_CHARACTERS}`}</p>
            </div>
          </div>

          <div className={styles.inputs_wrapper}>
            <label className={styles.label}>{t('display_follower')}</label><br />
            <div className={styles.input_wrapper}>
              <div className={styles.tabs}>
                <button className={showFollower ? styles.tabs__selected : ''} onClick={() => setFollower(true)}>{t('yes')}</button>
                <button className={!showFollower ? styles.tabs__selected : ''} onClick={() => setFollower(false)}>{t('no')}</button>
              </div>
            </div>
          </div>

          <div className={loading ? styles.loader : ''}>
            {loading ? <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /> : <div>
              <CreatorProfile />
              <div className={styles.buttons_wrapper}>
                <div className={styles.save_button} onClick={savePersonalInfo}>
                  {updateProfileRequest.isFetching ? <Loader type="TailSpin" color="#FFF" height={20} width={20} /> : t('save')}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;
