import React, {useState} from 'react';
import './landingSection.scss';
import landingPhoto from '../../../assets/images/landing/landingPhoto.svg';
import landingPhotoLowQ from '../../../assets/images/landing/landingPhoto.png';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {isArabic} from '../../../features/util';

const LandingSection = () => {
  const {t, i18n} = useTranslation();
  const [landingVideoIsLoaded, setLandingVideoIsLoaded] = useState(false);
  const arabicFormat:boolean = isArabic(i18n.language);
  return (
    <section id="landing-section" className="page-section">
      <div className="paragraph">
        <h2>{t('all_in_one')}</h2>
        <p className="description">
          {t('best_way_for_creator')}
        </p>
        <Link to="/public/signup" className="button-container">
          <button>{t('get_start')}</button>
        </Link>
      </div>

      <div className="animation-container">
        <img
          src={landingPhotoLowQ}
          alt="Alfan Link Animation"
          style={{display: landingVideoIsLoaded ? 'none' : 'block'}}
        />
        <video
          className="brands-video"
          autoPlay
          muted
          loop
          onCanPlay={() => setLandingVideoIsLoaded(true)}
          style={{display: landingVideoIsLoaded ? 'block' : 'none'}}
        >
          <source
            src={
              'https://alfan-files-production.s3.eu-west-1.amazonaws.com/Landing-public-page/Alfan_Hero.mp4'
            }
          />
        </video>
      </div>

    </section>
  );
};

export default LandingSection;
