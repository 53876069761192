import React, {ComponentProps, useRef, useState, useEffect} from 'react';
import {Tooltip} from '@material-ui/core';

type OverflowTooltipProps = ComponentProps<typeof Tooltip>;
const OverflowTooltip = (props: OverflowTooltipProps) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare = textElementRef.current!.offsetWidth < textElementRef.current!.scrollWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  useEffect(() => {
    compareSize();
  }, [props.children]);

  return (
    <Tooltip interactive disableHoverListener={!hoverStatus} {...props}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
