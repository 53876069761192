import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';

export interface WalletState {
  data: any;
}

const initialState: WalletState = {
  data: [],
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    removeWallet: (state) => {
      state.data = [];
    },
    setWallet: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const {removeWallet, setWallet} = walletSlice.actions;

export const getWalletStore = (state: RootState) => state.wallet;

export default walletSlice.reducer;
