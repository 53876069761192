import React, {useEffect} from 'react';
import style from './themeSelect.module.scss';
import plusIcon from '../../assets/images/plusIcon.svg';
import PreviewTemplate from '../PreviewTemplate/PreviewTemplate';
import {themes} from '../../constants/newThemes';
import {selectUserTheme, setUserTheme, UserThemeState} from '../../features/stores/userThemeSlicer';
import {store} from '../../app/store';
import {useSelector} from 'react-redux';
import useRequest from '../../features/API/request';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {refreshMe} from '../../features/Login/login';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {useTranslation} from 'react-i18next';

interface IThemeCard{
  img?: string;
  blank?: boolean;
  selected?: boolean;
  onClick: () => void;
  linksTemplateUser?: any;
  themeProp?: any;
}


export const ThemeCard = (props: IThemeCard) => {
  const {t} = useTranslation();
  const className = props.selected? `${style.ThemeCard} ${style.ThemeCard__selected}` : style.ThemeCard;
  return (
    <div className={className} onClick={props.onClick}>
      {(props.blank) && <div className={style.ThemeCard__blank}>
        <img src={plusIcon}></img>
        <p>{t('create_own_theme')}</p>
      </div>}

      {props.linksTemplateUser &&
         <PreviewTemplate
           themeProp={props.themeProp}
           linksUser={props.linksTemplateUser}
           selectedStyle={'mobile'}/>
      }
      <div className={`${(props.selected? style.ThemeCard__selected__frame : '')}`} id={`${(props.selected? 'selectedTheme' : '')}`}></div>
    </div>
  );
};

interface IThemeSelect{
  handleLeft?: () => void;
  handleRight?: () => void;
  index: number;
  setIndex:(arg1: number) => void;
}

const ThemeSelect = (props: IThemeSelect) => {
  const selectedTheme = useSelector(selectUserTheme);
  const linksUser = useSelector(selectLinksUser);
  const isElementInViewport = (el: any) => {
    const rect = el? el.getBoundingClientRect() : '';

    return (
      rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  };

  if (props.index === themes.length) {
    props.setIndex(-1);
  }
  useEffect(() => {
    const object = document.getElementById(`item-${props.index}`)!;
    const isInView = isElementInViewport(object);
    if (isInView) {
      object?.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
      if (!object) {
        props.setIndex(-1);
      }
    }
  }, [props.index, props.setIndex]);

  useEffect(() => {
    if (selectedTheme.themeName === 'custom') {
      props.setIndex(-1);
    } else {
      const index = themes.findIndex((e) => e.themeName === selectedTheme.themeName);
      props.setIndex(index);
    }
  }, [selectedTheme]);

  return (
    <div className={style.ThemeSelect__container} id={'item-container'}>
      {themes.map((theme: UserThemeState, index: number) => {
        return <div key={index} id={`item-${index}`}>
          <ThemeCard
            linksTemplateUser={{...linksUser, themeName: theme.themeName}}
            selected={props.index === index}
            themeProp={theme}
            onClick={async () => {
              store.dispatch(setUserTheme({...theme}));
              store.dispatch(setDataChange(true));
              props.setIndex(index);
            }}></ThemeCard>
        </div>;
      })}
    </div>
  );
};

export default ThemeSelect;
