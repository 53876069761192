import React from 'react';
import './featuresSection.scss';
import fanSupport from '../../../assets/images/landing/fan-support.svg';
import askMe from '../../../assets/images/landing/ask-me.svg';
import consulting from '../../../assets/images/landing/consulting-call.svg';
import sellGuides from '../../../assets/images/landing/sell-guides.svg';
import bonusContent from '../../../assets/images/landing/bonus-content.svg';
import secretLink from '../../../assets/images/landing/secret-link.svg';
import analytics from '../../../assets/images/landing/analytics-img.svg';
import analyticsMobile from '../../../assets/images/landing/analytics-img-mobile.svg';
import followers from '../../../assets/images/landing/followers-img.svg';
import followersMobile from '../../../assets/images/landing/followers-img-mobile.svg';
import wallet from '../../../assets/images/landing/wallet-img.svg';
import walletMobile from '../../../assets/images/landing/wallet-img-mobile.svg';
import brandDeals from '../../../assets/images/landing/brand-deals-img.svg';
import brandDealsMobile from '../../../assets/images/landing/brand-deals-img-mobile.svg';
import step1 from '../../../assets/images/landing/step1.svg';
import step2 from '../../../assets/images/landing/step2.svg';
import step3 from '../../../assets/images/landing/step3.svg';
import privateContent from '../../../assets/images/landing/privateContent.svg';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import useWindowWidth from '../../../hooks/useWindowWidth';

const FeaturesSection = () => {
  const {t} = useTranslation();
  const viewWidth = useWindowWidth();
  const isMobileView = (): boolean => viewWidth < 960;

  return (
    <section id="features-section" className="page-section">
      <div className="make-money-section">
        <h1 className="make-money-subheader">{t('turn_your_passion')}</h1>
        <div className="icons-container">
          <div className="icon">
            <img src={fanSupport} alt="fan support" />
            <p>{t('fan_support')}</p>
          </div>

          <div className="icon">
            <img src={consulting} alt="1-1 consulting call" />
            <p>{t('consulting_call')}</p>
          </div>

          <div className="icon">
            <img src={askMe} alt="ask me anything" />
            <p>{t('ask_me_anything')}</p>
          </div>
          <div className="icon">
            <img src={sellGuides} alt="sell guides" />
            <p>{t('sell_guides')}</p>
          </div>

          <div className="icon">
            <img src={bonusContent} alt="bonus content" />
            <p>{t('bonus_content')}</p>
          </div>

          <div className="icon">
            <img src={secretLink} alt="secret link" />
            <p>{t('secret_link')}</p>
          </div>
        </div>
      </div>

      <div className="why-alfan">
        <h1 className="why-subheader">{t('why_alfan_link')}</h1>

        <div className="features-container">
          <div className="feature">
            <div className="feature-info">
              <Trans i18nKey="in_depth_analytics">
                {' '}
                <strong>In-Depth Analytics:</strong>{' '}
                <h2>
                  Gain insights into your link performance, audience engagement,
                  and click-through rates with our advanced analytics tools.
                </h2>
              </Trans>
            </div>
            <div id="analytics" className="feature-image-container">
              <img
                src={isMobileView() ? analyticsMobile : analytics}
                alt="Analytics"
                className="feature-image"
              />
            </div>
          </div>
          <div className="feature">
            <div className="feature-info">
              <Trans i18nKey="unlock_influence">
                {' '}
                <strong>Unlock Your Influence:</strong>{' '}
                <h2>
                  Showcase your total followers number directly on your profile.
                </h2>
              </Trans>
            </div>
            <div id="followers" className="feature-image-container">
              <img
                src={isMobileView() ? followersMobile : followers}
                alt="Influence"
                className="feature-image"
              />
            </div>
          </div>
          <div className="feature">
            <div className="feature-info">
              <Trans i18nKey="monetization_opportunities">
                {' '}
                <strong>Monetization Opportunities:</strong>{' '}
                <h2>
                  Turn your influence into income! Monetize your links
                  effortlessly through 100+ opportunities to make money.
                </h2>
              </Trans>
            </div>
            <div id="wallet" className="feature-image-container">
              <img
                src={isMobileView() ? walletMobile : wallet}
                alt="Financials"
                className="feature-image"
              />
            </div>
          </div>
          <div className="feature">
            <div className="feature-info">
              <Trans i18nKey="brand_deals">
                {' '}
                <strong>Direct Access To Brand Deals:</strong>{' '}
                <h2>
                  Connect with top brands and amplify your earning potential.
                </h2>
              </Trans>
            </div>
            <div id="brandDeals" className="feature-image-container">
              <img
                src={isMobileView() ? brandDealsMobile : brandDeals}
                alt="Brand Deals"
                className="feature-image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="music-links">
        <h1>
          <Trans i18nKey="music_links"></Trans>
        </h1>
        <h3 className="music-subheader">{t('keep_audience')}</h3>

        <div className="steps-container">
          <img src={step1} alt="step 1" />

          <img src={step2} alt="step 2" />

          <img src={step3} alt="step 3" />
        </div>
      </div>

      <div className="its-free">
        <img src={privateContent} alt="private content" />
        <h1>{t('its_free')}</h1>
        <Link to="/public/signup" className="button-container">
          <button>{t('get_start')}</button>
        </Link>
      </div>
    </section>
  );
};

export default FeaturesSection;
