import React, {ComponentProps, PropsWithChildren} from 'react';
import styles from './DeleteButton.module.scss';
import {ReactComponent as TrashIcon} from '../../../assets/images/al_delete_red.svg';

type Props = ComponentProps<'div'> & {};

const DeleteButton = ({children, ...props}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.deleteBtn} {...props}>
      <TrashIcon fill='#7066ff' />
      <div>{children}</div>
    </div>
  );
};

export default DeleteButton;
