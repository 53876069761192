import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';
import {commissionType, dealCategory} from '../../constants/helper';

export interface deal {
    id: string,
    name: string,
    client: string,
    description: string,
    logoUrl?: string,
    commission: string,
    commissionType: commissionType.ABSOLUTE | commissionType.PERCENTAGE,
    currency: "usd" | "aed" | "sar",
    paymentTerm: number,
    type: string,
    active: boolean,
    createdAt: string,
    updatedAt: string,
    offerId: string,
    affiliateMarketingPlatformType: "Everflow" | null,
    dealType: dealCategory.COUPON | dealCategory.TRACKING_LINK | null
}

export interface UserDealContent {
  content: string,
  description: string,
  id: string,
  type: string,
  logoUrl: string,
  startDate: string,
  endDate: string,
  userDealId: string
  productTrackingLink: string
}

export interface dealItem {
    createdAt: string,
    deal: deal,
    dealId: string,
    id: string,
    status: string,
    updatedAt: string,
    userId: string,
    visible: boolean,
    active: boolean,
    description?: string,
    logoUrl?: string,
    dealTrackingLink: string,
    userDealContents? : UserDealContent[]
  }

export interface DealsState {
  data: deal[];
  approvedDeals: dealItem[];
  appliedDeals: deal[];
  approvedChangeIds: string[];
}

const initialState: DealsState = {
  data: [],
  approvedDeals: [],
  appliedDeals: [],
  approvedChangeIds: [],
};

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setDeals: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setAppliedDeals: (state, action: PayloadAction<any>) => {
      state.appliedDeals = action.payload;
    },
    setApprovedDeals: (state, action: PayloadAction<any>) => {
      state.approvedDeals = action.payload;
    },
    updateApprovedDeal: (state, action: PayloadAction<any>) => {
      const ind = state.approvedDeals.findIndex((deal) => deal.id === action.payload.id);
      if (ind > -1) {
        state.approvedDeals[ind] = {...state.approvedDeals[ind], ...action.payload.data};
        state.approvedChangeIds = [...new Set([...state.approvedChangeIds, action.payload.id])];
      }
    },
    resetApprovedDeals: (state, action) => {
      const approvedDeals = action?.payload?.me?.userDeals;
      state.approvedDeals = approvedDeals || [];
      state.approvedChangeIds = [];
    },
  },
  extraReducers: {
    'user/setMe': (state, {payload}) => {
      if (payload.me?.userDeals) {
        const approvedDeals = payload.me?.userDeals;
        state.approvedDeals = approvedDeals || [];
        state.approvedChangeIds = [];
      }
    },
  },
});

export const resetApprovedDealsThunk = (): any => {
  return (dispatch: any, getState: any) => {
    const {user} = getState();
    dispatch(resetApprovedDeals(user));
  };
};

export const {setDeals, setAppliedDeals, setApprovedDeals, updateApprovedDeal, resetApprovedDeals} = dealsSlice.actions;

export const getDealsStore = (state: RootState) => state.deals;

export default dealsSlice.reducer;
