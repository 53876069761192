import React, {useCallback, useEffect, useState} from 'react';
  import {useTranslation} from "react-i18next";
  import styles from './Contracts.module.scss';
  import ContractTable from '../../../components/ContractTable/ContractTable';
  import useRequest from '../../../features/API/request';
  import Loader from 'react-loader-spinner';
  import NoTransaction from '../../../assets/images/noTransaction.svg';
  import InputLabel from "@material-ui/core/InputLabel";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
  import {Select as MUSelect} from "@material-ui/core";
  import ContractRevenueModal from '../../../components/ContractRevenueModal/ContractRevenueModal';
  
  const Contracts = () => {
    const {t} = useTranslation();
    const [contractList, setContractList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState<any>([]);
    const [categoryList, setCategoryList] = useState<any>([]);
    const [openRevenueModal, setOpenRevenueModal] = useState(false);
    const [revenueData, setRevenueData] = useState(undefined);
    const getDashboardRequest = useRequest({method: 'GET', url: '/dashboard/api/contract/backend/user', isShortUrl: true});
    const getPlatformsRequest = useRequest({method: 'GET', url: '/finance/api/earnings/externalPlaforms', isShortUrl: true});

    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const params = {status: ['Active']};
        const result = await getDashboardRequest.doRequest(params);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Something went wrong!');
      }
    };

    const fetchPlatforms = async () => {
      await getPlatformsRequest.doRequest();
    };

    const getContractList = useCallback(() => {
        let contractTempList: any = [];
        if (filter.length) {
            for (let i = 0; i < contractList.length; i++) {
                for (let y = 0; y < contractList[i]?.contractCategory.length; y++) {
                    if (filter.includes(contractList[i]?.contractCategory[y]?.category?.id)) {
                        contractTempList.push(contractList[i]);
                        break;
                    }
                }
            }
        } else {
            contractTempList = [...contractList];
        }
        return contractTempList;
    }, [contractList, filter]);

    const handleModalOpen = (data: any) => {
      setRevenueData(data);
      setOpenRevenueModal(true);
    };

    useEffect(() => {
        if (contractList.length) {
            let categoryArray: any = [];
            contractList.map((contracts: any) => {
                contracts?.contractCategory?.map((ele: any) => {
                    categoryArray = [...categoryArray, {value: ele?.category?.id, key: ele?.category?.name}];
                  });
            });
            setCategoryList([...new Map(categoryArray.map((item: any) => [item['value'], item])).values()]);
        } else {
            setCategoryList([]);
        }
    }, [contractList]);

    useEffect(() => {
      if (getDashboardRequest?.data?.contracts?.length) {
        setContractList(getDashboardRequest?.data?.contracts || []);
      } else {
        setContractList([]);
      }
    }, [getDashboardRequest.data]);

    useEffect(() => {
        fetchTransactions();
        fetchPlatforms();
    }, []);
  
    return (
      <div className={styles.contract_container}>
        {openRevenueModal && <ContractRevenueModal platforms={getPlatformsRequest?.data || []} revenueData={revenueData || []} handleClose={() => setOpenRevenueModal(false)} />}
        <div className={styles.contract_body}>
        <div className={styles.heading_main}>
          {t('contracts')}
          <>
          <div className={styles.filter_container}>
            <div className={styles.search_container}>
              <div>
                <FormControl size="small" variant="outlined" className={styles.filter}>
                  <InputLabel htmlFor="grouped-select-dashboard">{filter.length ? '' : t('Filter')}</InputLabel>
                  <MUSelect onChange={(e) => {
                      setFilter(e.target.value || []);
                      }} multiple value={filter} id="grouped-select-dashboard"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}>
                    {categoryList.map((ele: any, i: number) => {
                      return (
                          <MenuItem key={`${ele.value}-${i}`} value={`${ele.value}`}>{ele.key}</MenuItem>
                      );
                    })}
                  </MUSelect>
                </FormControl>
              </div>
            </div>
          </div>
          </>
        </div>
        <div className={loading ? styles.loader : ''}>
          {loading ? <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /> : contractList.length ? <ContractTable handleViewClick={handleModalOpen} contractList={getContractList()} /> :
          <div className={styles.noTransaction}>
            <img src={NoTransaction} />
            <div>{t('no_data_found')}</div>
          </div>}
        </div>
        </div>
      </div>
    );
  };
  
  export default Contracts;
