import React, {useEffect, useState} from 'react';
import Input from '@material-ui/core/Input';
import {CurrencySelectorProps} from "./componentInterface";
import styles from '../ModalMonetize.module.scss';
import {useTranslation} from 'react-i18next';
import {useStylesBox} from './styleCustom';
import TextFieldInput from '../../TextFieldInput/TextFieldInput';

const AmountInput = ({handleInputChange, item, currencyMap}: CurrencySelectorProps) => {
    const classesInput = useStylesBox();
    const [focus, setFocus] = useState(false);
    const {t} = useTranslation();
    const minAmount = item.minPrice/Number(currencyMap?.filter((e: any) => e.currency == item?.currency)[0]?.conversionFactor || 100) || 1;
    const maxAmount = item.maxPrice/Number(currencyMap?.filter((e: any) => e.currency == item?.currency)[0]?.conversionFactor || 100) || 5000;
    return (
        <div style={{position: 'relative'}}>
        <TextFieldInput
              type="number"
              placeholder={t("amount")}
              value={item.amount}
              customStyle={{height: '56px', padding: '0px 20px'}}
              onChange={(e: any) => {
                handleInputChange(e.target.value, item.id, undefined, undefined, item);
              }}
              onFocus={() => setFocus(true)}
            />
        {/* <Input onFocus={() => setFocus(true)} type='number' className={classesInput.textField} id="standard-adornment-amount" value={item.amount} onChange={(e: any) => handleInputChange(e.target.value, item.id, undefined, undefined, item)} /> */}
        {focus && item.amount < minAmount && <div className={`${styles.red} ${styles.amountError}`}>{`${t('amount_cant_be_less_than')} ${minAmount || 1}`}</div>}
        {focus && item.amount > maxAmount && <div className={`${styles.red} ${styles.amountError}`}>{`${t('amount_cant_be_greater_than')} ${maxAmount || 5000}`}</div>}
        </div>
    );
};

export default AmountInput;
