import React from 'react';
import styles from '../UnsavedChanges/WarningDialog.module.scss';

const WarningDialog = ({open, titleText, contentText, cancelButtonText, confirmButtonText, onCancel, onConfirm, onModalClose} :any) => {
  return (
    <div>
      {open && <div className={styles.modal}>
        <div className={styles.modal_content}>
          <div className={styles.close_title_wrapper}>
            {/* <div className={styles.close_wrapper}>
              <span className={styles.close} onClick={onModalClose}>&times;</span>
            </div> */}
            <div className={styles.title_wrapper}>
              <span className={styles.modal_title}>{titleText}</span>
            </div>
          </div>
          {contentText}
          <div className={styles.buttons_wrapper}>
            <div className={styles.cancel_button} onClick={onConfirm}>
              {confirmButtonText}
            </div>
            <div className={styles.save_button} onClick={onCancel}>
              {cancelButtonText}
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default WarningDialog;
