import React, {useState, useEffect} from 'react';
import styles from './ContractRevenueModal.module.scss';
import {useTranslation} from 'react-i18next';
import {Input, Space} from 'antd';
import {toast} from 'react-toastify';
import useRequest from '../../features/API/request';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import {getDenseCardIcons} from '../../Helper/IconsMap';
import lightMoney from '../../assets/images/PlatformEarningIcons/deal.black.svg';

interface IProps{
    handleClose: () => void;
    revenueData: any;
    platforms: any[];
}

const ContractRevenueModal = ({handleClose, revenueData, platforms}: IProps) => {
  const {t, i18n} = useTranslation();
  const getContractDocRequest = useRequest({method: 'GET', url: `/dashboard/api/contractDocs/backend?contractId=${revenueData?.id!}`, isShortUrl: true});

  const fetchContractDoc = async () => {
    await getContractDocRequest.doRequest();
  };

  const getPlatformName = (value: string) => {
    const platform = platforms.filter((e) => e.id == value);
    return platform[0]?.name || '--';
  };

  useEffect(() => {
    fetchContractDoc();
  }, []);

  useEffect(() => {
    if (getContractDocRequest.hasError) {
      toast.error(<span style={{color: 'white'}}>{getContractDocRequest?.error?.message || t('something_went_wrong')}</span>);
    }
  }, [getContractDocRequest.hasError]);

  return (
    <>
      <div className={styles.modal}>
        <div className={`${styles.modal_content}`}>
          <div className={styles.close_title_wrapper}>
            <div className={styles.close_wrapper}>
              <span className={styles.close} onClick={handleClose}>&times;</span>
            </div>
          </div>
          <div className={styles.heading}>{t('contract')}</div>
          <div className={styles.container_attachment}>
            {getContractDocRequest?.isFetching ? <Loader type="TailSpin" height={20} width={20} /> :
            getContractDocRequest?.data && getContractDocRequest?.data[0]?.url ? <div className={styles.attachment}>
               <a href={getContractDocRequest?.data && getContractDocRequest?.data[0]?.url} target="_blank" rel="noopener noreferrer">
                 {getContractDocRequest?.data && getContractDocRequest?.data[0]?.name}
               </a>
            </div> : <div>{t('no_data_found')}</div>}
          </div>
          <div className={styles.heading}>{t('revenue_share')}</div>
          <div className={styles.container}>
                    <div className={`${styles.row} ${styles.table_head}`}>
                        <div className={styles.box_icon}>
                            <div className={styles.bold}></div>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.bold}>{t('platform')}</div>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.bold}>{t('alfan_cut')} (%)</div>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.bold}>{t('description')}</div>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.bold}>{t('cms')}</div>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.bold}>{t('unlinking_date')}</div>
                        </div>
                    </div>
              {
                 revenueData?.revenue?.length ? revenueData?.revenue?.map((revenue: any, i: number) => {
                    const iconDisplayName = getPlatformName(revenue?.external_provider_id);
                    return (
                    <div className={styles.row} key={revenue?.id}>
                       <div className={styles.box_icon}>
                            <div className={styles.platform_name}>
                              <img className={styles.table_img} src={getDenseCardIcons[iconDisplayName] || lightMoney} />
                            </div>
                        </div>
                        <div className={styles.box}>
                            <div className={`${styles.bold} ${styles.mobile_head}`}>{t('platform')}</div>
                            <div className={styles.platform_name}>
                              <img className={styles.mobile_img} src={getDenseCardIcons[iconDisplayName] || lightMoney} />
                              {iconDisplayName}
                            </div>
                        </div>
                        <div className={styles.box}>
                            <div className={`${styles.bold} ${styles.mobile_head}`}>{t('alfan_cut')} (%)</div>
                            <div>{revenue?.alfan_cut}</div>
                        </div>
                        <div className={styles.box}>
                            <div className={`${styles.bold} ${styles.mobile_head}`}>{t('description')}</div>
                            <div>{revenue?.description || '--'}</div>
                        </div>
                        <div className={styles.box}>
                            <div className={`${styles.bold} ${styles.mobile_head}`}>{t('cms')}</div>
                            <div>{revenue?.cms || 'N/A'}</div>
                        </div>
                        <div className={styles.box}>
                            <div className={`${styles.bold} ${styles.mobile_head}`}>{t('unlinking_date')}</div>
                            <div>{revenue?.unlinkingDate && moment(revenue?.unlinkingDate).format('MMM DD, YYYY') || 'N/A'}</div>
                        </div>
                    </div>
                  );
                }) : <div className={styles.no_data_table}>{t('no_data_found')}</div>
              }
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractRevenueModal;
