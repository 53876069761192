import React from 'react';
import styles from './CustomProgressBar.module.scss';
import logo from '../../assets/images/AlfanBrandNew.svg';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import Step from './Step';

interface ICustomProgressBarProps {
  currentStep: number,
  stepsName: string[],
  hideProgress?: boolean
}

const CustomProgressBar = ({currentStep, stepsName, hideProgress=false}: ICustomProgressBarProps) => {
  const totalSteps = stepsName.length;

  return (<>
    <div className={styles.percentage_text}>
        <div className={styles.logo_mob}>
          <img src={logo} />
        </div>
        <div className={styles.picker_mob}>
          <LanguagePicker onChange={() => {}} onBoardUi={true} />
        </div>
    </div>
    {!hideProgress && <div className={styles.progress_container}>
      <div className={styles.progress_bar} >
      </div>
      <div className={styles.progress_bar_white} style={{width: `${100}%`}}>
      </div>
      <div className={styles.steps}>
        {Array.from({length: totalSteps}, (_, index) => (
          <Step index={index} currentStep={currentStep} totalSteps={totalSteps} stepsName={stepsName} />
        ))}
      </div>
    </div>}
  </>
  );
};

export default CustomProgressBar;
