import React, {createRef, useEffect, useState} from 'react';
import styles from '../ModalMonetize.module.scss';
import {useTranslation, Trans} from 'react-i18next';
import pencil from '../../../assets/images/pencil.svg';
import {TextEditorProps} from "./componentInterface";

const TitleTextEditor = ({
    handleInputChange,
    item,
    index, setActiveSection, activeSection}: TextEditorProps) => {
    const {t} = useTranslation();
    const inputField = createRef();

    return (
        <div className={`${styles.rowBox} ${styles.marginTop20} ${item?.clicked ? styles.fullwidth : ''}`}>
            {item?.clicked ?
            <div className={styles.rowTop} style={{display: 'flex'}}><input autoFocus={true} id={`linkIcon-pencil-edit-${item?.id}`} className={`${styles.input} ${item?.clicked ? styles.clicked : ''}`} type="text" value={item?.title} onChange={(e) => {
                e.target.value.length < 50 ? handleInputChange(e.target.value, item?.id, 'title') : '';
            }} onBlur={() => {
                const arrayTemp = JSON.parse(JSON.stringify(item));
                arrayTemp.clicked = false;
                setActiveSection && setActiveSection({...activeSection, item: arrayTemp});
            }} />
                {/* {!item?.clicked && <img src={pencil} className={styles.pencil_icon_title} onClick={() => {
                    const arrayTemp = JSON.parse(JSON.stringify(activeSection));
                    arrayTemp.clicked = true;
                    setActiveSection && setActiveSection({...activeSection, item: arrayTemp});
                    document.getElementById(`linkIcon-pencil-edit-${item?.id}`)?.focus();
                }} />} */}
            </div> : <div className={styles.rowTop} style={{display: 'flex'}}>
                <div className={styles.title_preview}>{item?.title}</div> &nbsp;&nbsp;
                {!item?.clicked && !['sellProduct', 'socialMediaPromote'].includes(item.id) && <img src={pencil} className={styles.pencil_icon_title} onClick={() => {
                    const arrayTemp = JSON.parse(JSON.stringify(item));
                    arrayTemp.clicked = true;
                    setActiveSection && setActiveSection({...activeSection, item: arrayTemp});
                    // document.getElementById(`linkIcon-pencil-edit-${item?.id}`)?.focus();
                }} />}
            </div> }
            <div className={`${styles.rowBottom} ${styles.sub_title_inside}`}>{t(item?.subTitle)}</div>
        </div>
    );
};

export default TitleTextEditor;
