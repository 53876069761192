import React from 'react';
import ReactApexChart from 'react-apexcharts';
import '../Charts/charts.modules.scss';
import useWindowWidth from '../../hooks/useWindowWidth';

const PieChart = ({values, labels}: {values:number[], labels:string[]}) => {
  const mobile = useWindowWidth();
  const navigationMessage = mobile < 668 ? 'Tap':'Hover';
  const series = values;

  const options : ApexCharts.ApexOptions | undefined = {
    chart: {
      type: 'polarArea',
      height: '700px',
      toolbar: {
        show: false,
      },
    },
    labels: labels,

    colors: ['#FF5F5E', '#69BBF8', '#B8B6FE', '#091D60', '#B2F4FF'],
    fill: {
      opacity: 1,
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    tooltip: {
      custom: function({seriesIndex, w}:any) {
        return '<div className="test">' +
        '<p className="serie">' + series[seriesIndex] +'</p>' +
          '</div>';
      },
    },
    legend: {
      position: 'right',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 45,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 12,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    responsive: [{
      breakpoint: 768,
      options: {
        chart: {
          height: '260px',
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'right',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 50,
          offsetY: 25,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 12,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        tooltip: {
          custom: function({seriesIndex, w}:any) {
            return '<div className="test">' +
            '<p className="serie">' + series[seriesIndex] +'</p>' +
              '</div>';
          },
        },
      },
    }],
  };
  return (
    <div className="pieChart_wrapper">
      <div id="pieChart">
        <ReactApexChart
          options={options}
          series={series}
          type="polarArea"
          width="485"
        />
      </div>
      <span className="hover_text_wrapper">*{navigationMessage} to get the info.</span>
    </div>
  );
};

export default PieChart;
