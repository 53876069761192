export type Currencies = {
  [key: string]:{
    value: string;
    label: string;
    fullLabel: string;
  }
};
const currencies: Currencies = {
  'usd': {
    value: 'usd',
    label: '$',
    fullLabel: 'USD',
  },
  'aed': {
    value: 'aed',
    label: 'AED',
    fullLabel: 'AED',
  },
};

export const getCurrencyLabel = (currency?: string) => {
  if (currency == 'sar') {
    return 'SAR';
  }
  return currencies[currency ?? 'usd'].label;
};

export const getCurrencyFullLabel = (currency?: string) => {
  if (currency == 'sar') {
    return 'SAR';
  }
  return currencies[currency ?? 'usd'].fullLabel;
};

export default currencies;
