import React, {useState, useEffect, useMemo} from 'react';
import styles from './Availability.module.scss';
import {useTranslation} from 'react-i18next';
import {Checkbox, MenuItem} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {timeArray} from '../../../constants/helper';
import MuiSelect from '@material-ui/core/Select';
import Select, {useSelectStyles} from './Select';
import moment from 'moment';
import {timeZones} from '../../../constants/timezone';
import {useSelector} from 'react-redux';
import {getSchedule} from '../../../features/stores/scheduleSlicer';
import Loader from 'react-loader-spinner';
import nonHookRequest from '../../../features/API/nonHookRequest';
import {fetchUserSchedule} from '../../../features/Login/login';
import {toast} from 'react-toastify';
import {ReactComponent as Arrow} from '../../../assets/images/keyboard_arrow_down.svg';
import {getGoogleCalender} from '../../../features/stores/googleCalendarSlicer';
import GoogleCalendarAuth from './GoogleCalendarAuth';
import SelectWithSearch from '../../SelectWithSearch/SelectWithSearch';

const initialValue = {
  Mon: [],
  Tue: [],
  Wed: [],
  Thu: [],
  Fri: [],
  Sat: [],
  Sun: [],
};

const Availability = ({
  handleChange,
  settingView = false,
}: {
  handleChange?: (data: any) => void;
  settingView?: boolean;
}) => {
  const {t} = useTranslation();
  const selectStyles = useSelectStyles();
  const schedule = useSelector(getSchedule).data || {};
  const weekDay = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const [timezone, setTimezone] = useState<any>('');
  const [activeDay, setActiveDay] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [scheduleData, setScheduleData] = useState<any>(initialValue);

  const getCurrentTimeInterval = () => {
    const start = moment().format('hh:00 A');
    const end = moment(start, 'hh:00 A').add(15, 'm').format('hh:mm A');
    return {startTime: start, endTime: end};
  };

  const handleToggle = (day: string) => {
    const scheduleCopy = {...scheduleData};
    let sendDay = [];
    if (activeDay.includes(day)) {
      const days = activeDay.filter((e: string) => e != day);
      scheduleCopy[day] = [];
      setActiveDay(days);
      sendDay = days;
    } else {
      setActiveDay((prevState: any) => [...prevState, day]);
      scheduleCopy[day] = [...scheduleCopy[day], getCurrentTimeInterval()];
      sendDay = [...activeDay, day];
    }
    setScheduleData(scheduleCopy);
    if (handleChange) {
      handleChange({change: true, slots: scheduleCopy, activeDay: sendDay});
    }
  };

  const handleSelectChange = (
      day: string,
      event: any,
      index: number,
      type: string,
  ) => {
    if (event?.target?.value) {
      const scheduleCopy = {...scheduleData};
      scheduleCopy[day][index][type] = event?.target?.value;
      setScheduleData(scheduleCopy);
      if (handleChange) {
        handleChange({change: true, slots: scheduleCopy, activeDay});
      }
    }
  };

  const addDeleteMoreSlots = (day: string, index: number) => {
    const scheduleCopy = {...scheduleData};
    if (index == 0) {
      scheduleCopy[day] = [...scheduleCopy[day], getCurrentTimeInterval()];
    } else {
      scheduleCopy[day] = scheduleCopy[day].filter(
          (e: any, i: number) => index != i,
      );
    }
    setScheduleData(scheduleCopy);
    if (handleChange) {
      handleChange({change: true, slots: scheduleCopy, activeDay});
    }
  };

  const handleTimezone = (value: any) => {
    const timezoneName = value.name || value;
    setTimezone(timezoneName);

    if (handleChange) {
      handleChange({
        change: true,
        slots: scheduleData,
        activeDay: activeDay,
        timezone: timezoneName, // Only passing the timezone name
      });
    }
  };

  const handleUpdate = async () => {
    try {
      if (!timezone) {
        toast.error(
            <span style={{color: 'white'}}>{t('select_timezone')}</span>,
        );
        return;
      }
      setLoading(true);
      const payload: any = {slots: [], timezone: timezone};
      Object.keys(scheduleData).map((e) => {
        scheduleData[e].map((el: any) => {
          payload.slots.push({
            dayOfWeek: e,
            startTime: el.startTime,
            endTime: el.endTime,
          });
        });
      });
      const calenderResponse = await nonHookRequest({
        method: schedule?.id ? 'PUT' : 'POST',
        url: '/calendar-availability',
        body: payload,
      });
      fetchUserSchedule();
      if (calenderResponse) {
        toast(<span style={{color: 'black'}}>{t('slots_updated')}</span>, {
          autoClose: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log('something went wrong!');
      setLoading(false);
    }
  };

  const dataFilledNotValid = useMemo(() => {
    let notValid = false;
    weekDay.map((day) => {
      scheduleData[day]?.length ?
        scheduleData[day].map((e: any, index: number) => {
          notValid =
              scheduleData[day]
                  .filter((e: any, ind: number) => index != ind)
                  .some(
                      (el: any) =>
                        moment(el.startTime, 'h:m A').isBetween(
                            moment(e.startTime, 'h:m A'),
                            moment(e.endTime, 'h:m A'),
                        ) ||
                    moment(el.endTime, 'h:m A').isBetween(
                        moment(e.startTime, 'h:m A'),
                        moment(e.endTime, 'h:m A'),
                    ) ||
                    (moment(el.startTime, 'h:m A').isSame(
                        moment(e.startTime, 'h:m A'),
                    ) &&
                      moment(el.endTime, 'h:m A').isSame(
                          moment(e.endTime, 'h:m A'),
                      )),
                  ) ||
              moment(e.endTime, 'h:m A').isBefore(moment(e.startTime, 'h:m A')) ?
                true :
                notValid;
        }) :
        false;
    });
    if (handleChange) {
      handleChange({notValid});
    }
    return notValid;
  }, [scheduleData]);

  useEffect(() => {
    if (schedule?.id) {
      const scheduleCopy = settingView ?
        JSON.parse(JSON.stringify(initialValue)) :
        JSON.parse(JSON.stringify(scheduleData));
      const dayCopy: string[] = [];
      schedule?.slots?.map((el: any) => {
        scheduleCopy[el?.dayOfWeek].push({
          startTime: el?.startTime,
          endTime: el?.endTime,
        });
        if (!dayCopy.includes(el?.dayOfWeek)) {
          dayCopy.push(el?.dayOfWeek);
        }
      });
      setScheduleData(scheduleCopy);
      setActiveDay(dayCopy);
      setTimezone(schedule?.timezone);
      if (handleChange) {
        handleChange({
          change: false,
          slots: scheduleCopy,
          activeDay: dayCopy,
          timezone: schedule?.timezone,
        });
      }
    }
  }, [schedule]);

  return (
    <div className={styles.availibility_main}>
      <div className={styles.schedule_container}>
        {weekDay.map((day) => {
          return (
            <div key={day} className={styles.schedule_weekday}>
              <div className={styles.dayname_box}>
                <Checkbox
                  color="primary"
                  checked={activeDay.includes(day)}
                  onChange={() => handleToggle(day)}
                  name={day}
                />
                <span className={styles.day}>{day}</span>
              </div>
              <div className={styles.slots_container}>
                {scheduleData[day]?.length ? (
                  scheduleData[day].map((e: any, index: number) => {
                    return (
                      <div
                        className={styles.slots_single}
                        key={`slot-${day}-${index}`}
                      >
                        <div className={styles.slots_single_box}>
                          <MuiSelect
                            disabled={!activeDay.includes(day)}
                            name={`key-1-${day}-${index}`}
                            className={`${styles.select_custom} ${
                              !activeDay.includes(day) ?
                                styles.disabled_select :
                                ''
                            }`}
                            disableUnderline
                            classes={{select: selectStyles.select}}
                            IconComponent={({className: classes}) => (
                              <Arrow className={`${classes} ${styles.arrow}`} />
                            )}
                            value={e.startTime}
                            onChange={(event) =>
                              handleSelectChange(day, event, index, 'startTime')
                            }
                          >
                            {timeArray.map((time) => (
                              <MenuItem key={time} value={time}>
                                {time}
                              </MenuItem>
                            ))}
                          </MuiSelect>
                          <div>-</div>
                          <MuiSelect
                            disabled={!activeDay.includes(day)}
                            name={`key-2-${day}-${index}`}
                            className={`${styles.select_custom} ${
                              !activeDay.includes(day) ?
                                styles.disabled_select :
                                ''
                            }`}
                            disableUnderline
                            classes={{select: selectStyles.select}}
                            IconComponent={({className: classes}) => (
                              <Arrow className={`${classes} ${styles.arrow}`} />
                            )}
                            value={e.endTime}
                            onChange={(event) =>
                              handleSelectChange(day, event, index, 'endTime')
                            }
                          >
                            {timeArray.map((time) => (
                              <MenuItem key={time} value={time}>
                                {time}
                              </MenuItem>
                            ))}
                          </MuiSelect>
                          <div
                            className={`${styles.action} ${
                              !activeDay.includes(day) ?
                                styles.disable_click :
                                ''
                            }`}
                            onClick={() => addDeleteMoreSlots(day, index)}
                          >
                            {index > 0 ? (
                              <HighlightOffIcon />
                            ) : (
                              <AddCircleIcon />
                            )}
                          </div>
                        </div>
                        {scheduleData[day]
                            .filter((e: any, ind: number) => index != ind)
                            .some(
                                (el: any) =>
                                  moment(el.startTime, 'h:m A').isBetween(
                                      moment(e.startTime, 'h:m A'),
                                      moment(e.endTime, 'h:m A'),
                                  ) ||
                              moment(el.endTime, 'h:m A').isBetween(
                                  moment(e.startTime, 'h:m A'),
                                  moment(e.endTime, 'h:m A'),
                              ) ||
                              (moment(el.startTime, 'h:m A').isSame(
                                  moment(e.startTime, 'h:m A'),
                              ) &&
                                moment(el.endTime, 'h:m A').isSame(
                                    moment(e.endTime, 'h:m A'),
                                )),
                            ) ? (
                          <div className={styles.slot_error}>
                            {t('time_overlapped')}
                          </div>
                        ) : null}
                        {moment(e.endTime, 'h:m A').isBefore(
                            moment(e.startTime, 'h:m A'),
                        ) ? (
                          <div className={styles.slot_error}>
                            {t('end_time_is_lesser')}
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <div>{t('unavailable')}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.timezone_container}>
        <SelectWithSearch
          value={timezone}
          onChange={(e) => handleTimezone(e.target.value)}
          label={t('timezone')}
          options={timeZones.map((zone) => ({
            display: `${zone.name} (GMT${zone.offset})`,
            value: zone.name,
          }))}
        />
      </div>
      {settingView && <GoogleCalendarAuth />}
      {settingView && (
        <div
          className={`${styles.buttons_wrapper} ${
            dataFilledNotValid ? styles.disable : ''
          }`}
        >
          <div className={styles.save_button} onClick={handleUpdate}>
            {loading ? (
              <Loader type="TailSpin" color="#FFF" height={20} width={20} />
            ) : (
              t('save')
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Availability;
