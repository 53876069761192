import React, {useEffect, useState} from 'react';
import styles from '../ModalMonetize.module.scss';
import {useTranslation} from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {BrandPromotionMedia, SellMyProduct} from '../../../constants/socialMedia';
import {TitleSelectProps} from "./componentInterface";
import {useStylesBox} from './styleCustom';

const selectMapping: any = {
    socialMediaPromote: BrandPromotionMedia,
    sellProduct: SellMyProduct.filter((e) => !!e.enable),
};

const PlatformSelector = ({handleBulkInputChange, selectValue, setVideoURL, item, setSelectValue}: TitleSelectProps) => {
    const {t} = useTranslation();
    const classesInput = useStylesBox();

    return (
        <div className={`${styles.rowBox} ${item.clicked ? styles.fullwidth : ''}`}>
            <div className={styles.rowTop} style={{display: 'flex'}}>
                <div dir="ltr" className={`${styles.dropdown} ${styles.input} ${styles.clicked}`}>
                    {/* <div style={{marginRight: '10px'}}>{item.title}</div> */}
                    <Select
                        labelId="social-platform-selector"
                        id="social-platform-selector"
                        style={{width: '100%', height: '56px', padding: '6px 20px', marginRight: '0.2rem', fontSize: '16px', fontWeight: 400, background: 'white', borderRadius: '26px', borderBottom: '1px solid rgba(217, 217, 217, 0.6)'}}
                        value={selectValue[item.id]}
                        className={classesInput.textField}
                        disableUnderline
                        onChange={(e) => {
                            setSelectValue({...selectValue, [item.id]: e.target.value});
                            if (item.id == 'sellProduct') {
                                handleBulkInputChange({attachmentURL: '', attachment: '', attachmentId: ''}, item.id);
                                setVideoURL('');
                            } else {
                                handleBulkInputChange({contentType: ''}, item.id);
                            }
                        }
                        }
                    >
                        {selectMapping[item.id].map((ele: any) => <MenuItem key={`social-name-${ele.name}`} value={ele.name}>{ele.name}</MenuItem>)}
                    </Select>
                </div>
            </div>
            {/* <div className={styles.rowBottom}>{t(item.subTitle)}</div> */}
        </div>
    );
};

export default PlatformSelector;
