import React from 'react';
import ReactApexChart from 'react-apexcharts';
import '../Charts/charts.modules.scss';
import useWindowWidth from '../../hooks/useWindowWidth';

const ClickOnLinkChart = ({values, labels, getLabel}: {values:number[], labels:string[], getLabel: any}) => {
  const mobile = useWindowWidth();
  const series = values;

  const options : ApexCharts.ApexOptions | undefined = {
    chart: {
      type: 'donut',
      height: 'auto',
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
    },
    legend: {
        position: 'bottom',
        show: false,
    },
    labels: labels,
    fill: {
      opacity: 1,
    },
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              color: 'black',
              formatter: function(w: any) {
                return getLabel(w);
              },
            },
            total: {
              showAlways: false,
              show: true,
            },
          },
        },
      },
    },
    tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}:any) {
          return '<div className="test">' +
          '<p className="serie">' + getLabel(w.config.labels[seriesIndex]) + ' </p>' +
          '<span className="category">' + w.config.series[seriesIndex].toLocaleString() + '</span>' +
            '</div>';
        },
    },
  };
  return (
    <div className="chart" id="lineChart" style={{height: '300px'}}>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width="100%"
          height="100%"
        />
    </div>
  );
};

export default ClickOnLinkChart;
