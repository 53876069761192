/* eslint-disable react/prop-types */
import React, {useState, useRef} from 'react';
import './CreatorsSlideshow.scss';
import {MediaItem} from '../LandingCarousel/LandingCarousel';
import SlideshowSlide from './SlideshowSlide';
import {useTranslation} from 'react-i18next';
import {isArabic} from '../../features/util';
interface creatorsSectionProps {
  mediaItems: MediaItem[];
  expandedWidth?: number;
  minimizedWidth?: number;
}

const interpolateScreenSize = (
    desktopWidth: number,
    minValue: number,
    maxValue: number,
) => {
  const minBound = 800;
  const maxBound = 1920;
  return (
    maxValue -
    ((desktopWidth - minBound) / (maxBound - minBound)) *
      (maxValue - minValue)
  );
};
const CreatorsSlideshow: React.FC<creatorsSectionProps> = ({mediaItems, expandedWidth, minimizedWidth}) => {
  const {i18n} = useTranslation();
  const arabicFormat = isArabic(i18n.language);
  const direction = arabicFormat ? 1 : -1;
  const [translationAmount, setTranslationAmount] = useState(0);
  const creatorsContainerRef = useRef<HTMLDivElement>(null);

  const moveContainerAccordingToMouseMovement = (
      event: React.MouseEvent<HTMLDivElement>,
  ) => {
    const creatorsContainerWidth = creatorsContainerRef.current?.clientWidth ?? 0; // Get the width of the creatorsContainer
    // if the screen is larger than the creatorsContainer by enough margin then return (80px was added as a margin)
    if (window.innerWidth > creatorsContainerWidth + 80) return;
    const {clientX} = event;
    const {left, right} = event.currentTarget.getBoundingClientRect();
    const clientWidth = event.currentTarget.clientWidth;
    const x = arabicFormat ? right - clientX : clientX - left;
    const xInPercentage = x / clientWidth;

    // get screen width
    const screenWidth = window.innerWidth;
    const marginFactor = interpolateScreenSize(screenWidth, 9, 20);

    // Determine the direction based on mouse position
    const newDirection = xInPercentage < 0.5 ? -1 * direction : direction;

    // Calculate the absolute percentage distance from the center
    const percentageDistanceFromCenter = Math.abs(xInPercentage - 0.5) * 2;

    // Adjust the translation amount based on direction and percentage distance from center
    const newTranslationAmount = newDirection * marginFactor * percentageDistanceFromCenter;

    setTranslationAmount(newTranslationAmount);
  };


  const resetContainer = (event: React.MouseEvent<HTMLDivElement>) => {
    const creatorsContainerWidth = creatorsContainerRef.current?.clientWidth ?? 0; // Get the width of the creatorsContainer
    if (window.innerWidth > creatorsContainerWidth + 80) return;
    event.currentTarget.style.transform = 'translateX(0)';
    setTranslationAmount(0);
  };

  return (
    <div
      className={`slideshow-component`}
      onMouseOver={moveContainerAccordingToMouseMovement}
      onMouseOut={resetContainer}
      style={{
        transform: `translateX(${translationAmount}%)`,
      }}
      ref={creatorsContainerRef}
    >
      <div className="creatorsContainer">
        {mediaItems.map((creator) => (
          <SlideshowSlide creator={creator} key={creator.id} expandedWidth={expandedWidth} minimizedWidth={minimizedWidth} />
        ))}
      </div>
    </div>
  );
};

export default CreatorsSlideshow;
