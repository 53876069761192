import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {DEFAULT_OPTIONS} from '../../constants/sectionValidations';

export interface DataChangeState {
  changeData: boolean;
  isNavigating: boolean;
  shouldSaveEverything: boolean;
  newSectionTitle: string;
  shouldValidateEverything: boolean;
  sectionStatus: typeof DEFAULT_OPTIONS
}

const initialState: DataChangeState = {
  changeData: false,
  isNavigating: false,
  shouldSaveEverything: false,
  newSectionTitle: '',
  shouldValidateEverything: false,
  sectionStatus: DEFAULT_OPTIONS,
};

export const changeData = createSlice({
  name: 'dataChange',
  initialState,
  reducers: {
    setClearDataChange: (state) => {
      state.changeData = false;
    },
    setDataChange: (state, action: PayloadAction<boolean>) => {
      state.changeData = action.payload;
    },
    setNavigating: (state, action: PayloadAction<boolean>)=>{
      state.isNavigating = action.payload;
    },
    setShouldSave: (state, action: PayloadAction<boolean>)=>{
      state.shouldSaveEverything = action.payload;
    },
  },
});

export const {setClearDataChange, setDataChange, setNavigating, setShouldSave} = changeData.actions;

export const selectChangeData = (state: RootState): boolean =>{
  return state.changeData.changeData;
};

export default changeData.reducer;
