import React, {
    useEffect,
    useState,
  } from 'react';
  import moment, {Moment} from 'moment';
  import useRequest from '../../../../features/API/request';
  import styles from './OrderTotal.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";
  import ProfileVisitChart from '../../../Charts/ProfileVisitChart';
import {Input, MenuItem, Select} from '@material-ui/core';
import {statusOption} from '../../../../constants/helper';
  
  const OrderTotal = ({updateStats}: {updateStats?: (value: any) => void}) => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedFilter, setSelectedFilter] = React.useState<string[]>(statusOption.map((e: any) => e.value));
    const [stackedData, setStackedData]= useState<{stackValues: {name: string, data: number[]}[], stackLabels: string[]}>({stackValues: [], stackLabels: []});
    const stackRequest = useRequest({method: 'GET', url: '/finance/api/orders/analytics', isShortUrl: true});
    const MenuProps = {
      // dropdown menu styling
        PaperProps: {
          style: {
            maxHeight: 224,
            width: 250,
          },
        },
      };
    const getOrderTotalAnalyticsData = async () => {
      try {
        const params = {
          startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
          status: selectedFilter,
        };
        const stackResult: {ordersTotal: {[key: string]: {total: number}[]}, total: number} = await stackRequest.doRequest(params);
        if (stackResult?.ordersTotal) {
          const keys: string[] = Object.keys(stackResult?.ordersTotal); // Seperate keys for x-axis 
          const profileVisitsArray: number[] = [];
          Object.values(stackResult?.ordersTotal).map((ele: {total: number}[]) => {
            profileVisitsArray.push(ele[0]?.total);
          });
          const stackValues: {name: string, data: number[]}[] = [{name: 'Order', data: profileVisitsArray}] || [];
          const stackLabels: string[] = keys || [];
          setStackedData({stackValues, stackLabels});
          setTotal(stackResult?.total);
          if (moment(params?.endDate)?.diff(moment(params?.startDate), 'days') == 30 && updateStats) {
            updateStats({orders: stackResult?.total});
          }
          setData(true);
        } else {
          setData(true);
        }
      } catch (error) {
        setData(true);
        console.log('Error occured', error);
      }
    };

    const handleChangeFilter = (event: any) => {
        if (event?.target?.value?.every((e: any) => typeof e == 'string')) {
            setSelectedFilter(event.target.value as string[]);
        } else {
        setSelectedFilter(statusOption.map((e: any) => e.value));
        }
    };
  
    useEffect(() => {
        if (value.length) {
          getOrderTotalAnalyticsData();    
        }
    }, [value, selectedFilter]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
            <div className={styles.heading}>
                <p>{t('order')}</p>
                <div className={styles.order_filter}>
                <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={selectedFilter}
                    onChange={handleChangeFilter}
                    input={<Input />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        if ((selected as string[]).length === statusOption?.length) {
                          return <em>All</em>;
                        }
            
                        return (selected as string[]).join(', ');
                      }}
                    >
                        <MenuItem value={statusOption.map((e: any) => e.value)}>
                            <em>All</em>
                        </MenuItem>
                    {statusOption.map((ele: any) => (
                        <MenuItem key={ele.value} value={ele.value}>
                        {ele.value}
                        </MenuItem>
                    ))}
                </Select>
                <p className={styles.total}>{total?.toLocaleString()}</p>
                </div>
            </div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
  
            <div className={styles.chart_wrapper}>
              {data ? <ProfileVisitChart chartColor='#40E5FF' values={stackedData.stackValues} labels={stackedData.stackLabels} /> :
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                  <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                </div>
                <ProfileVisitChart chartColor='#40E5FF' values={[]} labels={[]} />
              </div>
              }
            </div>
          </div>
        </div>
    );
  };
export default OrderTotal;
