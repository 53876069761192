import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Suspense,
  lazy,
} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../app/store';
import {
  setDataChange,
  setShouldSave,
} from '../../features/stores/changeDataSlicer';
import RouteLeavingGuard from '../../components/UnsavedChanges/RouteLeavingGuard';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {refreshMe, fetchUsersDeals} from '../../features/Login/login';
import Loader from 'react-loader-spinner';
import WarningModal from '../../components/WarningModal/WarningModal';
import useWindowWidth from '../../hooks/useWindowWidth';
import {raiseToast} from '../../components/Toast/Toast';
import {useTranslation} from 'react-i18next';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import nonHookRequest from '../../features/API/nonHookRequest';
import {removeStepCookie} from '../../Helper/generalHelper';
import {getDealsStore, setAppliedDeals, setDeals} from '../../features/stores/dealsSlicer';
import {
  selectPreviewMode,
  setPreviewMode,
} from '../../features/stores/previewModeSlicer';
import {store} from '../../app/store';
import styles from './DealsContainer.module.scss';
import PreviewIcon from '../../assets/images/preview_Icon.svg';

const MobilePreview = lazy(
    () => import('../../components/MobilePreview/MobilePreview'),
);
const AvailableDeals = lazy(
    () => import('../../components/Deals/AvailableDeals'),
);
const AppliedDeals = lazy(
  () => import('../../components/Deals/AppliedDeals'),
);
const Deals = lazy(() => import('../../components/Deals/Deals'));

interface StyleProps {
  width: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      border: 0,
      boxShadow: 'none',
      paddingTop: ({width}) => (width < 1024 ? '0px' : '80px'),
    },
    tabs: {
      'minHeight': '0px',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTabs-flexContainer': {
        'padding': ({width}) => (width < 768 ? '0px' : '0 80px'),
        'columnGap': ({width}) => (width < 768 ? '8px' : '10px'),
        '& button': {
          'padding': ({width}) => (width < 768 ? '6px 14px' : '8px 16px'),
          'margin': '0 !important',
          '& .MuiTab-wrapper': {
            textTransform: 'none',
            fontWeight: 400,
          },
        },
      },
    },
  }),
);

const tabStyle: React.CSSProperties = {
  borderRadius: '32px',
  height: '35px',
  minHeight: '0',
  minWidth: 'auto',
  fontSize: '16px',
  margin: '0 10px',
  background: '#8080801c',
  color: 'rgb(64 64 64)',
};

const activeStyle: React.CSSProperties = {
  ...tabStyle,
  background: 'black',
  color: 'white',
};

const getStyle = (active: boolean, width: number): React.CSSProperties => ({
  ...(active ? activeStyle : tabStyle),
  margin: width < 768 ? '0 5px' : tabStyle.margin,
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  width: number;
}

const a11yProps = (index: number) => ({
  'id': `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

interface DealsContainerProps {
  screen: "deals" | "trackingLinks"
  children?: React.ReactNode;
}

const DealsContainer = (props: DealsContainerProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const previewMode = useSelector(selectPreviewMode);
  const dataChange = useSelector(
      (state: RootState) => state.changeData.changeData,
  );
  const shouldSave = useSelector(
      (state: RootState) => state.changeData.shouldSaveEverything,
  );
  const linksUser = useSelector(selectLinksUser);
  const {approvedChangeIds, approvedDeals} = useSelector(getDealsStore);
  const [validationFinish, setValidationFinish] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [value, setValue] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });
  const width = useWindowWidth();
  const classes = useStyles({width});

  useEffect(() => {
    dispatch(setPreviewMode(false));
    dispatch(setShouldSave(false));
    removeStepCookie();
  }, [dispatch]);

  useDidUpdateEffect(() => {
    if (shouldSave) {
      validateAllBeforeSave().then((valid) => {
        if (valid && validationFinish && dataChange) {
          saveEverything();
        }
      });
    }
  }, [shouldSave, validationFinish, dataChange]);

  const validateAllBeforeSave = useCallback(async () => {
    setValidationFinish(false);
    const error = false; // Your validation logic here
    if (error) {
      setValidationFinish(false);
      dispatch(setShouldSave(false));
      return false;
    }
    setValidationFinish(true);
    return true;
  }, [dispatch]);

  const saveEverything = useCallback(async () => {
    const currentTab = value;
    try {
      if (approvedChangeIds?.length) {
        const dealArray = approvedDeals.filter((e) =>
          approvedChangeIds?.includes(e?.id),
        );
        await Promise.all(
            dealArray.map((deal) =>
              nonHookRequest({
                method: 'PUT',
                url: `/user-deals/${deal?.id}`,
                body: {visible: deal?.visible, userDealContents: deal?.userDealContents},
              }),
            ),
        );
      }
      raiseToast({message: t('changes_saved')});
    } finally {
      dispatch(setShouldSave(false));
      dispatch(setDataChange(false));
      setValidationFinish(false);
      refreshMe();
      setValue(currentTab); // Restore active tab after save
    }
  }, [approvedChangeIds, approvedDeals, dispatch, t, value]);

  useEffect(() => {
    fetchUsersDeals(setDeals, {status: 'Available'});
    fetchUsersDeals(setAppliedDeals, {status: 'Applied'});
  }, []);

  const handlePreviewClick = async () => {
    await store.dispatch(setPreviewMode(true));
    history.push('/profile/preview');
  };

  return (
    <div className={styles.container}>
      {!previewMode && (
        <RouteLeavingGuard
          when={dataChange}
          navigate={() => '/profile/deals'}
          shouldBlockNavigation={() => true}
          saveFunction={async () => {
            const validationResult = await validateAllBeforeSave();
            if (validationResult) {
              await saveEverything();
              return true;
            }
            return false;
          }}
        />
      )}
      <WarningModal
        setIsOpen={setShowModal}
        open={showModal}
        contentHeadline={modalText}
        buttonText={modalButtonText}
      />
      <div className={styles.loadingOnSave}>
        {shouldSave && (
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        )}
      </div>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          <div className={classes.root}>
            <Tabs
              value={value}
              onChange={() => history.push(props?.screen === 'deals' ? '/profile/tracking-links' : '/profile/deals')}
              className={classes.tabs}
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab
                key={`deals-header`} 
                style={getStyle(props?.screen === 'deals', width)} 
                label="Deals" {...a11yProps(0)} 
              />;
              <Tab
                key={`links-header`} 
                style={getStyle(props?.screen === 'trackingLinks', width)} 
                label="Tracking Links" {...a11yProps(1)} 
              />;
            </Tabs>
            {props.children}
          </div>
          <div className={styles.divider}></div>
        </div>
        <button className={styles.preview_button} onClick={handlePreviewClick}>
          <img
            src={PreviewIcon}
            alt="Preview"
            className={styles.preview_icon}
          />
          Preview
        </button>
      </div>
      <div className={styles.right}>
        <Suspense fallback={<div>Loading...</div>}>
          <MobilePreview showLink={width > 768} />
        </Suspense>
      </div>
    </div>
  );
};

export default DealsContainer;
