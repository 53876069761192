import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface NotificationState {
    isNotificationBarOpen: boolean;
}

const initialState: NotificationState = {
  isNotificationBarOpen: false,
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleOpen: (state, action: PayloadAction<any>) => {
      state.isNotificationBarOpen = action.payload.isNotificationBarOpen;
    },
  },
});

export const {toggleOpen} = notificationSlice.actions;

export const selectIsNotificationBarOpen = (state: RootState) => state.notifications;

export default notificationSlice.reducer;
