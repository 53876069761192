import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

const CalendarBooking = ({url}: any) => {
  /* add listener 'message' in case need to listen event from calendly */

  const isCalendlyEvent = (e: any) => {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
  };

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
      </Helmet>
      <div className="calendly-inline-widget" data-url={url} style={{maxWidth: '540px', height: '630px'}}></div>
    </>
  );
};

export default CalendarBooking;
