import React, {useState, useEffect} from "react";
import nonHookRequest from "../../../features/API/nonHookRequest";
import buttonStyles from '../../../pages/TrackingLinks/TrackingLinksPage.module.scss';
import styles from '../AddTrackingLinkModal.module.scss';
import {Grid} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import Loader from 'react-loader-spinner';

interface SelectDealProps {
  userDealId: string | null;
  setUserDealId: React.Dispatch<React.SetStateAction<string | null>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const SelectDeal = ({userDealId, setUserDealId, setStep}: SelectDealProps) => {
  const [deals, setDeals] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const {t} = useTranslation();

  useEffect(() => {
    getDeals();
  }, []);

  const getDeals = async () => {
    setLoading(true);
    const deals = await nonHookRequest({
      method: 'GET',
      url: `/api/v1/deals?status=Approved&marketingPlatform=Everflow`,
      isShortUrl: true,
    });
    setDeals(deals);
    setLoading(false);
  };

  return (
    <div style={{marginRight: '-20px'}}>
      <h5>{t('select_deal')}</h5>
      <p>{t('select_deal_note')}</p>
      {loading ? 
        <div className={styles.loader}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div> :
        <div className={styles.mt20}>
          <Grid container spacing={2} className={`${styles.dealsContainer} ${styles.customScrollbar}`}>
            {deals?.length ? deals.map((deal, i) => {
              return (
                <Grid 
                  item 
                  xs={12} 
                  sm={4}
                  key={i} 
                  className={userDealId === deal?.userDeal[0].id ? 
                    `${styles.dealContainer} ${styles.border}` : 
                    `${styles.dealContainer}`}
                >
                  <img
                    src={deal?.logoUrl}
                    className={styles.dealImage}
                    alt=""
                    onClick={() => setUserDealId(deal?.userDeal[0].id)}
                  />
                  <p className={styles.dealName}>{deal?.name}</p>
                </Grid>
              );
            }) : <h6>{t('no_deals')}</h6>}
          </Grid>
        </div>
      }
      <div className={styles.addProductBtn}>
        <button className={buttonStyles.cta} disabled={!userDealId} onClick={() => setStep(2)}>
          {t('add_product_link')}
        </button>
      </div>
    </div>
  );
};
