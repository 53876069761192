import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';
import BaseModal from '../components/BaseModal';
import DeleteButton from '../components/DeleteButton';
import EditableTextInput from '../components/EditableTextInput';
import SaveButton from '../components/SaveButton';
import TextInput from '../components/TextInput';
import ToggleButton from '../components/ToggleButton';
import LinkModal, {LinkModalProps} from '../LinkModal';
import currencies from '../../../constants/currencies';
import Select from '../components/Select';
import {MonetizeType, initialLink} from './types';
import PriceBreakdown from '../components/PriceBreakdown';
import UploadButton from '../components/UploadButton';
import PriceInput, {useMonetizePrice} from '../components/PriceInput';
import {ReactComponent as TrashIcon} from '../../../assets/images/al_delete_red.svg';
import styles from './SellProductLinkModal.module.scss';
import Alert from '@material-ui/lab/Alert';
import EditableImageInputGuide from '../components/EditableImageInputGuide';
import RichTextEditor from '../components/RichTextEditor';
import SellProductGuideDefault from '../../../assets/images/addLink/monetize/access_exclusive_content_guide.svg';


const DEFAULT_CURRENCY = 'usd';

const SellProductGuideModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();
  const {validate: validatePrice} = useMonetizePrice(link.currency, link.type as MonetizeType);

  useEffect(() => {
    setLink({
      ...defaultLink,
      currency: defaultLink.currency || DEFAULT_CURRENCY,
      isMonitize: true,
      attachment: {...{
        id: '',
        url: SellProductGuideDefault,
        name: 'default-image',
      }, ...defaultLink?.attachment},
    });
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length <= 50) {
      setLink((prevState) => ({...prevState, title}));
    }
  };

  const handleLinkBreakdown = (index: number, data: any) => {
    const linkCopy = JSON.parse(JSON.stringify(link));
    linkCopy.price[index] = {...linkCopy.price[index], ...data};
    setLink(linkCopy);
  };


  const validNote = link.note && link.note.length > 500;
  const isValid = useMemo(() => {
    return (
      link.title?.length &&
      link.price &&
      !validatePrice(link.price) &&
      link.currency &&
      link.attachment &&
      link.attachmentURL &&
      !validNote
    );
  }, [link]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t('add_title')}
        disabled={!editable}
      />

      <EditableImageInputGuide
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(image) => {
          // Update link icon image
          setLink((prevLink) => ({
            ...prevLink,
            customUploadedImage: image,
          }));
        }}
        onUploadComplete={(file) => {
          // Update cover image
          setLink((prevLink) => ({
            ...prevLink,
            attachment: file,
          }));
        }}
        editable={editable}
      />


      {!editable && (
        <Alert style={{fontWeight: 500, fontSize: '1rem'}} severity="warning">
          {t('monetization_is_disabled_for_your_account')}
        </Alert>
      )}

      <UploadButton
        url={link.attachmentURL}
        contentType="guide"
        onUpload={({url}) =>
          setLink((prevState) => ({...prevState, attachmentURL: url}))
        }
        label="Guide"
        onDelete={() =>
          setLink((prevState) => ({...prevState, attachmentURL: ''}))
        }
        editable={editable && mode === 'add'}
      />


      <div>
        {/* {!!link.attachment && (
          <div className={styles.imagePreview}>
            <a href={link.attachment?.url} target='_blank' rel='noopener noreferrer'>
              <img src={link.attachment?.url} alt='' />
            </a>
            {editable && (
              <TrashIcon
                className={styles.deletePicture}
                onClick={() => setLink({...link, attachment: undefined})}
              />
            )}
          </div>
        )} */}
        {/* {!link.attachment && (
          <UploadButton
            url=""
            contentType="picture"
            onUpload={(file) => setLink({...link, attachment: file})} // Handles guide PDF or other uploads
            label="Cover Picture"
            onDelete={() => setLink({...link, attachment: undefined, customUploadedImage: ''})}
            editable={editable}
          />

        )} */}
      </div>

      <Select
        value={link.currency}
        onChange={(e) => setLink((prevState) => ({...prevState, currency: e.target.value as string}))}
        disabled={!editable}
        label={t('currency')}
      >
        {Object.keys(currencies).map((key) => (
          <Select.Item key={key} value={key}>
            {key.toUpperCase()}
          </Select.Item>
        ))}
      </Select>

      <PriceInput link={link} onChange={(amount) => handleLinkBreakdown(0, {amount})} editable={editable} />

      <RichTextEditor
        value={link.note || ''}
        onChange={(value) => {
          setLink((prevLink) => ({...prevLink, note: value}));
        }}
        disabled={!editable}
        label={t('give_your_buyer_a_small_teaser')}
        error={validNote ? t('max_250_characters') : ''}
        maxCharacters={500}
      />

      <LinkModal.EditControlsContainer>
        <ToggleButton
          checked={link.isVisible}
          onChange={(checked) => setLink((prevState) => ({...prevState, isVisible: checked}))}
          label={t('visibility')}
        />

        {mode === 'edit' && <DeleteButton onClick={() => onDelete(link.id)}>{t('delete')}</DeleteButton>}
      </LinkModal.EditControlsContainer>

      <PriceBreakdown link={link} />

      <SaveButton disabled={!isValid} onClick={() => onSave(link)}>
        {t('save')}
      </SaveButton>
    </BaseModal>
  );
};

export default SellProductGuideModal;
