import {AnyAction, AsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';
import {populateSocialData} from '../../Helper/generalHelper';

interface Avatar{
  name: string,
  url: string,
}

interface ItemToDelete {
  sectionItemId: string;
  // sectionId: string;
}

interface UpdateLinksSectionItem {
  id: string,
  data: Partial<LinksSectionItem>
}

export interface LinksSectionItem{
  id: string,
  linksSectionId: string | null, // this id is used when creating the item first time (required for backend)
  orderNumber: number,
  channelProfileIcon?: string | null,
  videoThumbnail?: string | null,
  customUploadedImage: any | null,
  duration?: string | null,
  title: string | null,
  type: string,
  url: string,
  urlSlug?: string,
  placeholder?: string,
  hidden?: boolean,
  isVisible: boolean,
  layout?: string,
  isMonitize?: boolean,
  price?: any,
  attachment?: {url: string, name: string, id: string, thumbUrl?: string},
  note?: string
  currency?: string,
  maxPrice?: number,
  minPrice?: number,
  previewURL?: string;
  startDate?: Date;
  endDate?: Date;
  // eslint-disable-next-line camelcase
  alfan_share?: number;
  contentType?: string;
  attachmentURL?: string;
}

export interface LinksSection{
  id: string,
  isVisible: boolean,
  linksSectionItems: LinksSectionItem[],
  title: string,
  subtitle: string,
  type: string,
  updateTitle?: boolean
}

interface UpdateLinksSectionTitle{
  id: string;
  title: string;
}

export interface LinksUserState {
    id: string | null,
    fullname: string | null,
    nickname: string | null,
    location: string | null,
    bio: string | null,
    avatar: string | null,
    linksSections: LinksSection[],
    displayFollower: boolean | null,
    followersCount: number | string | null,
    gender: string | null,
    categoryIds: string[] | null,
    contentCategoryIds: string[] | null,
    phone: string | null,
    city: string | null,
    whatMakesYouTick: string | null,
}

const initialState: LinksUserState = {
  id: null,
  fullname: null,
  nickname: null,
  location: null,
  bio: null,
  avatar: null,
  linksSections: [],
  displayFollower: true,
  followersCount: 0,
  gender: null,
  categoryIds: [],
  contentCategoryIds: [],
  phone: '',
  city: '',
  whatMakesYouTick: null,
};


export const linksUserSlice = createSlice({
  name: 'linksUser',
  initialState,
  reducers: {
    setLinksUser: (state, action: PayloadAction<LinksUserState>) => {
      const linksUser = action.payload;
      state.avatar = linksUser.avatar,
      state.fullname = linksUser.fullname,
      state.bio = linksUser.bio;
      state.location = linksUser.location;
      state.linksSections = linksUser.linksSections;
      state.displayFollower = linksUser.displayFollower;
      state.nickname = linksUser.nickname;
      state.followersCount = linksUser.followersCount;
      state.gender = linksUser.gender;
      state.categoryIds = linksUser?.categoryIds || [];
      state.contentCategoryIds = linksUser?.contentCategoryIds || [];
      state.phone = linksUser?.phone;
      state.city = linksUser?.city;
      state.whatMakesYouTick = linksUser?.whatMakesYouTick;
    },
    addSectionItem: (state, action: PayloadAction<LinksSectionItem>) => {
      const targetSectionIndex = state.linksSections.findIndex((e) => e.id === action.payload.linksSectionId);
      state.linksSections[targetSectionIndex].linksSectionItems = [...state.linksSections[targetSectionIndex].linksSectionItems, action.payload];
    },
    updateSectionItems: (state, action: PayloadAction<any>) => {
      const targetSectionIndex = state.linksSections.findIndex((e) => e.id === action.payload.obj.linksSectionId);
      // state.linksSections[targetSectionIndex].linksSectionItems = [...state.linksSections[targetSectionIndex].linksSectionItems, action.payload];
      state.linksSections[targetSectionIndex].linksSectionItems = action.payload.data;
    },
    updateSectionItem: (state, action: PayloadAction<UpdateLinksSectionItem>) => {
      state.linksSections = state.linksSections.map((section) => {
        const items = section.linksSectionItems;
        const itemIndex = items.findIndex((e) => e.id === action.payload.id);
        if (itemIndex !== -1) {
          section.linksSectionItems[itemIndex] = {...section.linksSectionItems[itemIndex], ...action.payload.data};
        }
        return section;
      });
    },
    deleteLinksSectionItem: (state, action: PayloadAction<ItemToDelete>) => {
      state.linksSections = state.linksSections.map((section: LinksSection) => {
        return {...section, linksSectionItems: section.linksSectionItems.filter((item: LinksSectionItem) => item.id !== action.payload.sectionItemId)};
      });
    },
    sortLinksSectionItems: (state) => {
      state.linksSections = state.linksSections.map((section: LinksSection) => {
        return {...section, linksSectionItems: section.linksSectionItems.slice().sort((a: any, b: any) => {
          if (a.orderNumber > b.orderNumber) return 1;
          else return -1;
        })};
      });
    },
    resetLinksUser: (state, action) => {
      const user = action.payload.me.linksUser;
      const me = action.payload.me;
      state.id = user.id;
      state.avatar = user.avatar?.url,
      state.fullname = user.fullname,
      state.nickname = user.nickname,
      state.bio = user.bio,
      state.location = user.location;
      state.displayFollower = user.displayFollower;
      state.followersCount = user.followersCount;
      state.gender = me.gender;
      state.categoryIds = me?.userCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [],
      state.contentCategoryIds = me?.userContentCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [],
      state.phone = me?.phone;
      state.city = user?.city;
      state.whatMakesYouTick = user?.whatMakesYouTick;
      state.linksSections = populateSocialData({linksUser: user})?.linksUser?.linksSections.map((section: LinksSection) => {
        return {...section, linksSectionItems: section.linksSectionItems.map((e: LinksSectionItem) => {
          return {...e, customUploadedImage: e?.customUploadedImage?.url? e.customUploadedImage.url : e.customUploadedImage};
        }).sort((a: any, b: any) => {
          if (a.orderNumber > b.orderNumber) return 1;
          else return -1;
        })};
      });
    },
    updateSectionTitle: (state, action: PayloadAction<UpdateLinksSectionTitle>) => {
      const targetSectionIndex = state.linksSections.findIndex((e) => e.id === action.payload.id);
      state.linksSections[targetSectionIndex] = {...state.linksSections[targetSectionIndex], title: action.payload.title, updateTitle: true};
    },
  },
  extraReducers: {
    'user/setMe': (state, {payload}) => {
      if (payload.me?.linksUser) {
        const linksUser = payload.me.linksUser;
        const user = payload.me;
        state.id = payload.me.linksUser.id;
        state.avatar = linksUser.avatar?.url,
        state.fullname = linksUser.fullname,
        state.nickname = linksUser.nickname,
        state.bio = linksUser.bio,
        state.location = linksUser.location;
        state.displayFollower = linksUser.displayFollower;
        state.followersCount = linksUser.followersCount;
        state.gender = user?.gender;
        state.categoryIds = user?.userCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [];
        state.contentCategoryIds = user?.userContentCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [];
        state.phone = user?.phone;
        state.city = linksUser?.city;
        state.whatMakesYouTick = linksUser?.whatMakesYouTick;
        state.linksSections = linksUser.linksSections.map((section: LinksSection) => {
          return {...section, linksSectionItems: section.linksSectionItems.map((e: LinksSectionItem) => {
            return {...e, customUploadedImage: e?.customUploadedImage?.url? e.customUploadedImage.url : e.customUploadedImage};
          }).sort((a: any, b: any) => {
            if (a.orderNumber > b.orderNumber) return 1;
            else return -1;
          })};
        });
      }
    },
  },
});

// this thunk is used because it has access to the global store and thus the logged in user
export const resetLinksUserThunk = (): any => {
  return (dispatch: any, getState: any) => {
    const {user} = getState();
    dispatch(resetLinksUser(user));
  };
};

export const {
  setLinksUser,
  deleteLinksSectionItem,
  addSectionItem,
  updateSectionItem,
  sortLinksSectionItems,
  resetLinksUser,
  updateSectionTitle,
  updateSectionItems,
} = linksUserSlice.actions;

export const selectLinksUser = (state: RootState) => state.linksUser as LinksUserState;

export default linksUserSlice.reducer;
