import React from 'react';
import classNames from 'classnames';
import styles from './CustomButton.module.scss';

import copy from '../../assets/images/al-eye-open-red.svg';
import share from '../../assets/images/al-share-red.svg';
import addRed from '../../assets/images/al-plus-red.svg';
import addWhite from '../../assets/images/al-plus-white.svg';
import uploadWhite from '../../assets/images/al-upload-white.svg';

const cn = classNames.bind(styles);

export enum ButtonType {
  COPY= 'copy',
  SHARE= 'share',
  ADD_RED= 'addRed',
  ADD_WHITE= 'addWhite',
  UPLOAD_WHITE= 'uploadWhite',
  SOLID_RED= 'solid_red',
  RED = 'red',
  BLACK = 'black',
}

const ButtonStyleType = {
  RED: 'red',
  WHITE: 'white',
  DARK_GRAY: 'dark_gray',
  SOLID_RED: 'solid_red',
};

interface ButtonProps {
  onClick: () => void;
  type: ButtonType;
  text: string;
}

const CustomButton = ({onClick, type, text}: ButtonProps) => {
  let imgSrc = '';
  let styleType = '';

  switch (type) {
    case ButtonType.RED:
      imgSrc = '';
      styleType = ButtonStyleType.RED;
      break;
    case ButtonType.BLACK:
      imgSrc = '';
      styleType = ButtonStyleType.DARK_GRAY;
      break;
    case ButtonType.COPY:
      imgSrc = copy;
      styleType = ButtonStyleType.WHITE;
      break;
    case ButtonType.SHARE:
      imgSrc = share;
      styleType = ButtonStyleType.WHITE;
      break;
    case ButtonType.ADD_RED:
      imgSrc = addWhite;
      styleType = ButtonStyleType.RED;
      break;
    case ButtonType.ADD_WHITE:
      imgSrc = addRed;
      styleType = ButtonStyleType.WHITE;
      break;
    case ButtonType.UPLOAD_WHITE:
      imgSrc = uploadWhite;
      styleType = ButtonStyleType.WHITE;
      break;
    case ButtonType.SOLID_RED:
      styleType = ButtonStyleType.SOLID_RED;
      break;

    default:
      break;
  }

  return (
    <button
      type="button"
      className={cn(styles.button, styles[styleType])}
      onClick={onClick}
    >
      <img src={imgSrc} alt="" /> <i>{text}</i>
    </button>
  );
};

export default CustomButton;
