import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';

export interface ConfigState {
  data: any;
}

const initialState: ConfigState = {
  data: null,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    removeConfig: (state) => {
      state.data = null;
    },
    setConfig: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const {removeConfig, setConfig} = configSlice.actions;

export const getConfig = (state: RootState) => state.config;

export default configSlice.reducer;
