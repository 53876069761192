import React from 'react';
import {toast} from 'react-toastify';

interface ToastProps {
    message: string;
    variant?: string;
}
export const raiseToast = ({message, variant}:ToastProps) => {
  const ToastComponent = ()=> <span style={{color: variant === 'error' ? 'white' : 'black'}}>{message}</span>;
  const toastOptions = {
    autoClose: 9000,
  };

  switch (variant) {
    case 'warn':
      return toast.warn(<ToastComponent />, toastOptions);
    case 'error':
      return toast.error(<ToastComponent />, toastOptions);
    default:
      return toast(<ToastComponent />, toastOptions);
  }
};
