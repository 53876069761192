import React from 'react';
import './creatorsSection.scss';
import 'react-multi-carousel/lib/styles.css';
import LandingCarousel from '../../../components/LandingCarousel/LandingCarousel';
import CreatorsSlideshow from '../../../components/CreatorsSlideshow/CreatorsSlideshow';
import OmarFarooq from '../../../assets/images/landing/OmarFarooq.png';
import Qlaby from '../../../assets/images/landing/Qalbypng.png';
import hamdyAndWafaa from '../../../assets/images/landing/hamdyAndWafaa.png';
import Humood from '../../../assets/images/landing/humood.png';
import Katakit from '../../../assets/images/landing/Katakit.png';
import raghda from '../../../assets/images/landing/raghda.png';
import imeeto from '../../../assets/images/landing/imeeto.png';
import mmoshaya from '../../../assets/images/landing/top-creator-1.png';
import arabGT from '../../../assets/images/landing/arabGT.png';
import Alafasy from '../../../assets/images/landing/Alafasy.png';
import {useTranslation} from 'react-i18next';
import useWindowWidth from '../../../hooks/useWindowWidth';

const mediaItems = [
  {
    id: 9,
    imageURL: Alafasy,
    creatorProfileURL: 'https://alfan.link/alafasy',
    creatorType: 'public_personality',
    creatorName: 'Alafasy',
  },
  {
    id: 11,
    imageURL: OmarFarooq,
    creatorProfileURL: 'https://alfan.link/Omr94',
    creatorType: 'creator',
    creatorName: 'Omar Farooq',
  },
  {
    id: 12,
    imageURL: arabGT,
    creatorProfileURL: 'https://alfan.link/arabgt',
    creatorType: 'Publisher',
    creatorName: 'Arab GT',
  },
  {
    id: 10,
    imageURL: Qlaby,
    creatorProfileURL: 'https://alfan.link/qalbyetmaan',
    creatorType: 'Publisher',
    creatorName: 'Qalby Etmaan',
  },
  {
    id: 13,
    imageURL: imeeto,
    creatorProfileURL: 'https://alfan.link/imeeto',
    creatorType: 'creator',
    creatorName: 'Imeeto',
  },
  {
    id: 14,
    imageURL: raghda,
    creatorProfileURL: 'https://alfan.link/raghda',
    creatorType: 'Influencer',
    creatorName: 'Raghda K',
  },
  {
    id: 15,
    imageURL: Katakit,
    creatorProfileURL: 'https://alfan.link/katakit_babytv',
    creatorType: 'Publisher',
    creatorName: 'Katakit TV',
  },
  {
    id: 16,
    imageURL: Humood,
    creatorProfileURL: 'https://alfan.link/humood',
    creatorType: 'creator',
    creatorName: 'Humood',
  },
  {
    id: 17,
    imageURL: hamdyAndWafaa,
    creatorProfileURL: 'https://alfan.link/hamdyandwafaa',
    creatorType: 'Influencer',
    creatorName: 'Hamdy and Wafaa',
  },
  {
    id: 18,
    imageURL: mmoshaya,
    creatorProfileURL: 'https://alfan.link/mmoshaya',
    creatorType: 'creator',
    creatorName: 'Moshaya Family',
  },
];

const CreatorsSection = () => {
  const {t} = useTranslation();
  const viewWidth = useWindowWidth();

  return (
    // render the carousel only if screen size larger than 768px
    <section id="creators-section" className="page-section">
      <h1 id="creators-header">{t('outstanding_creators')}</h1>
      <div className={`carouselContainer`}>
        {viewWidth > 1440 && <CreatorsSlideshow mediaItems={mediaItems} />}
        {viewWidth < 1440 && <LandingCarousel mediaItems={mediaItems} />}
      </div>
    </section>
  );
};

export default CreatorsSection;
