/* eslint-disable new-cap */
import Cookies from "js-cookie";
import moment from "moment";
import {socialMediaLinks} from "../constants/socialMedia";
import {useTranslation} from "react-i18next";
import {LinksSection} from "../features/stores/linksUserSlicer";
import {dataURLtoFile} from "./imageCropper";

export const numberFollowerFormat = (followers: number, toFixed?: number) => {
  return Intl.NumberFormat( 'en-US', {maximumFractionDigits: toFixed || 2, notation: 'compact', compactDisplay: 'short'}).format(followers);
};

export const getUniqueGlobalFollowerCount = (linksSection: Array<any>) => {
  if (!Array.isArray(linksSection)) {
    return 0;
  } else {
    const linkHash: any = {};
    let count = 0;
    linksSection.map((ele: any, index: number) => {
      ele.linksSectionItems.map((item: any, itemIndex: number) => {
        const url = item.url.split('?')[0];
        if (!linkHash[url]) {
          linkHash[url] = !!item.followersCount;
          count += item.followersCount != null ? item.followersCount : 0;
        }
      });
    });
    return count;
  }
};

export const getDateFromRange = (range: any) => {
  switch (range) {
    case 'Last 7 days':
      return [moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    case 'This Month':
    case 'Month':
       return [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    case 'Last Month':
        return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
    case 'Last Quarter':
        return [moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'), moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD')];
    case 'This Year':
        return [moment().startOf('years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    case 'Last Year':
          return [moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')]; 
    case 'Week':
          return [moment().startOf('isoWeek'), moment().endOf('isoWeek')];  
    case 'Last 30d':
          return [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    case 'Today':
          return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    case 'All time':
          return [moment('2020-01-01').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    default:
      return [moment(), moment()];  
  }
};

export const isProdSite = () => {
  return window.location.host == 'alfan.link';
};

export const removeStepCookie = () => {
  Cookies.remove('step');
};

export const completeSignupTitkokEvent = () => {
  window.ttq.track('CompleteRegistration', {
    "value": "NA", // number. Value of the order or items sold. Example: 100.
    "currency": "NA", // string. The 4217 currency code. Example: "USD".
    "contents": [
      {
        "content_id": "NA", // string. ID of the product. Example: "1077218".
        "content_type": "NA", // string. Either product or product_group.
        "content_name": "NA", // string. The name of the page or product. Example: "shirt".
      },
    ],
  });
  Cookies.remove('signup_submit');
};

export const populateSocialData = (request: any) => {
    const requestCopy: any = JSON.parse(JSON.stringify(request));
    const linkSectionItems = requestCopy?.linksUser?.linksSections;
    const socialIndex = linkSectionItems?.findIndex((e: any) => e?.type == 'social');
    if (socialIndex != -1 && requestCopy?.linksUser && linkSectionItems[socialIndex]?.linksSectionItems) {
      const socialItems = linkSectionItems[socialIndex]?.linksSectionItems || [];
      const explorItemsCopy = JSON.parse(JSON.stringify(socialMediaLinks.filter((e) => !e.hideFromPrePollulated && !['seasonal'].includes(e.section!))));
      const socialSectionItems: any = socialItems;
      const availableType: string[] = [];
      socialSectionItems?.map((e: any) => availableType.push(e.type));
    
      const selfCreatedList = explorItemsCopy.filter((e: any) => !availableType.includes(e.key));
      const finalReamaingList: any = [];
      selfCreatedList.map((e: any, i: number) => {
        finalReamaingList.push(
        {
          id: (Date.now()) + i, // id generated via UTC date (unique), so that we can later delete this item (this id is not sent do backend)
          url: '',
          orderNumber: (socialSectionItems?.length + i) || 0,
          type: e.key,
          placeholder: e.placeholder,
          linksSectionId: linkSectionItems[socialIndex]?.id,
        });
      });
      requestCopy.linksUser.linksSections[socialIndex].linksSectionItems = socialItems.concat(finalReamaingList);
      return requestCopy;
    }
  // const {t} = useTranslation();
  return requestCopy;
};

export const getSocialFilteredData = (itemArray: any, getSocials: boolean = false) => {
  const socialIndex = itemArray?.findIndex((e: any) => e?.type == 'social');
  let validItems = socialIndex != -1 ? itemArray[socialIndex]?.linksSectionItems : [];
  if (getSocials) {
    return validItems;
  }
  if (socialIndex != -1 && itemArray[socialIndex]?.linksSectionItems?.length) {
    validItems = itemArray[socialIndex]?.linksSectionItems?.filter((e: any) => e?.url)?.sort((a: any, b: any) => {
      if (a.orderNumber > b.orderNumber) return 1;
      else return -1;
    });
    validItems.map((e: any, index: number) => e.orderNumber = index);
    itemArray[socialIndex].linksSectionItems = validItems;
    return itemArray;
  }
  return itemArray || [];
};

export const populateApiDataSocial = (linksUser: any) => {
  const data = new FormData();
  const linkCopy = getSocialFilteredData(JSON.parse(JSON.stringify(linksUser.linksSections)));
  let count = 0;
  linkCopy.map((section: LinksSection) => section.linksSectionItems).forEach((item: any, index: any) => {
    item.map((e: any) => {
      const keys: any = Object.keys(e).filter((ele: any) => ele != 'attachmentId');
      for (let i = 0; i < keys.length; i++) {
        if (e[keys[i]] != null && !Array.isArray(e[keys[i]])) {
          let value: any = '';
          if (['customUploadedImage'].includes(keys[i]) && typeof e[keys[i]] == 'string' && e[keys[i]] && !e[keys[i]].includes('http')) {
            value = dataURLtoFile(e[keys[i]], `buy-product`);
          } else if (keys[i] == 'attachment') {
            value = (e?.attachmentId || e?.attachment?.id || '');
          } else {
            value = e[keys[i]];
          }
          data.append(`items[${count}][${keys[i]}]`, value);
        }
        if (e[keys[i]] != null && Array.isArray(e[keys[i]])) {
          for (let j = 0; j < e[keys[i]].length; j++) {
            const arrayObjectKeys: any = Object.keys(e[keys[i]][j]);
            for (let k = 0; k < arrayObjectKeys.length; k++) {
              data.append(`items[${count}][${keys[i]}][${j}][${arrayObjectKeys[k]}]`, e[keys[i]][j][arrayObjectKeys[k]]);
            }
          }
        }
      }
      count++;
    });
  });
  return {data, count};
};

export const onBoardingDataAvailable = (me: any) => {
  return me?.gender && me?.phone && me?.linksUser?.location && me?.userContentCategory?.length && me?.linksUser?.whatMakesYouTick;
};

export const changeBackground = (color: string) => {
  document.body.style.backgroundColor = `${color}`;
};

export const getUserOnboardingStepTitles = (t: any) => {
  return [t('registration'), t('social_sync'), t('personal_info'), t('skills'), t('purpose')];
};
