import {Location} from 'history';
import React, {useEffect, useState} from 'react';
import {Prompt, useHistory} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {fetchMusicLinks, refreshMe} from '../../features/Login/login';
import {setDataChange, setNavigating} from '../../features/stores/changeDataSlicer';
import {resetLinksUser, resetLinksUserThunk} from '../../features/stores/linksUserSlicer';
import WarningDialog from './WarningDialog';
import {useTranslation} from 'react-i18next';
import {resetMusicLinksThunk} from '../../features/stores/musicAlbumsSlicer';
import {resetApprovedDealsThunk} from '../../features/stores/dealsSlicer';
// import WarningDialog from ‘./WarningDialog’;

interface Props {
    when?: boolean | undefined;
    navigate?: (path: string) => void;
    saveFunction: (() => void) | (() => boolean);
    shouldBlockNavigation: (location: Location) => boolean;
    shouldReFetch?: boolean;
    beforeNavigate?: Function;
  }

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  saveFunction,
  shouldReFetch,
  beforeNavigate,
}: Props) => {
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const history = useHistory();

  const closeModal = async () => {
    const result = await saveFunction();
    setModalVisible(false);
    if (result === false) {
      setConfirmedNavigation(false);
    } else {
      setConfirmedNavigation(true);
      history.push(lastLocation?.pathname? lastLocation?.pathname : '');
    }
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    store.dispatch(setDataChange(false));
    setModalVisible(false);
    return true;
  };
  const handleConfirmNavigationClick = (reFetch=false) => {
    store.dispatch(resetLinksUserThunk());
    store.dispatch(resetMusicLinksThunk());
    store.dispatch(resetApprovedDealsThunk());
    setModalVisible(false);
    setConfirmedNavigation(true);
    beforeNavigate && beforeNavigate();
    navigate && navigate(lastLocation?.pathname? lastLocation?.pathname : '');
    if (reFetch) {
      setTimeout(()=>{
        refreshMe();
        fetchMusicLinks();
      }, 200);
    } else store.dispatch(setDataChange(false));
  };

  const handleCloseModal = () =>{
    setModalVisible(false);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation?.pathname? lastLocation?.pathname : '');
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(()=>{
    setNavigating(false);
  }, []);

  return (
    <>
      <Prompt when={when} message={(location, action) => {
        if (action === 'PUSH' || action === 'POP') {
          if (!window.location.pathname.includes(location.pathname)) {
            return handleBlockedNavigation(location);
          } else return false;
        } else return false;
      }}/>
      <WarningDialog
        key={Date.now()}
        open={modalVisible}
        titleText={t('leave_tab_no_save')}
        contentText={t('unsaved_changes_warning')}
        cancelButtonText={t('save_changes')}
        confirmButtonText={t('discard_changes')}
        onCancel={closeModal}
        onConfirm={()=>handleConfirmNavigationClick(shouldReFetch)}
        onModalClose={handleCloseModal}
      />
    </>
  );
};

export default RouteLeavingGuard;
