/* eslint-disable new-cap */
import React, {
    useEffect,
    useState,
  } from 'react';
  import moment from 'moment';
  import useRequest from '../../../../features/API/request';
  import styles from './ClickOnLinks.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";
import ClickOnLinkChart from '../../../Charts/ClickOnLinkChart';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {monetizeKeyToText} from '../../../../constants/socialMedia';

  interface valueProps {total: number|string};
  interface linkprops {linkId: string, title: string|null, type: string};
  
  const ClickOnLinks = () => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [total, setTotal] = useState(0);
    const [stackedData, setStackedData]= useState({});
    const [showMore, setShowMore]= useState<boolean>(false);
    const stackRequest = useRequest({method: 'GET', url: '/linkAnalytics/count'});
  
    const getLinkAnalyticsData = async () => {
      try {
        const params = {
          startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
        };
        const stackResult: {linkClicks: {[key: string]: valueProps[]}, total: number} = await stackRequest.doRequest(params);
        const keys: string[] = Object.keys(stackResult?.linkClicks);
        const clickMappings: any = {};
          keys?.map((e: any) => {
            clickMappings[e] = stackResult?.linkClicks[e][0]['total'] || 0;
          });
          setStackedData(clickMappings);
          setTotal(stackResult?.total);
          setData(true);
      } catch (error) {
        setData(true);
        console.log('Error occured', error);
      }
    };

    const getLabel = (val: string) => {
      const title = monetizeKeyToText[val];
      return title || val;
    };

    const getClickList = () => {
      if (Object.keys(stackedData)?.length) {
        const keys = JSON.parse(JSON.stringify(Object.keys(stackedData)));
        const values = JSON.parse(JSON.stringify(Object.values(stackedData)));
        const loopData = showMore ? keys : keys.splice(0, 4);
        return loopData.map((ele: string, index: number) => (
            <div className={styles.country_row} key={ele + index}>
                <div className={styles.title}>{getLabel(ele)}</div>
                <div>
                  {Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format((values[index] / (values.reduce((accumulator: any, currentValue: any) => {
                    return accumulator + currentValue;
                  }, 0))) * 100)}%
                </div>
            </div>
        ));
      } else {
        return (<div className={styles.country_row}>
          {t('no_data_found')}
        </div>);
      };
    };
  
    useEffect(() => {
        if (value.length) {
          getLinkAnalyticsData();    
        }
    }, [value]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
              <div className={styles.heading}><p>{t('total_clicks')}</p><p className={styles.total}>{total?.toLocaleString()}</p></div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
            {(Object.keys(stackedData)?.length) ?
            <div className={styles.chart_wrapper}>
              <div className={styles.boxes}>
                {data ? <ClickOnLinkChart getLabel={getLabel} values={Object.values(stackedData)} labels={Object.keys(stackedData)} /> :
                <div style={{position: 'relative'}}>
                  <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                    <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                  </div>
                  <ClickOnLinkChart values={[]} labels={[]} getLabel={getLabel} />
                </div>
                }
              </div>
              <div className={styles.country_list_wrapper}>
                    <div className={styles.list}>
                      <div className={styles.list_div}>
                      {getClickList()}
                      </div>
                     {Object.keys(stackedData)?.length > 4 && <div className={styles.sell_all} onClick={() => setShowMore(!showMore)}>
                        <div>{showMore ? t('See less') : t('See all')}</div>
                        <div>{showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
                      </div>}
                    </div>
                </div>
            </div> : <div className={styles.chart_wrapper}>
                <div className={styles.no_data}>
                  {t('no_data_found')}
                </div>
              </div>}
          </div>
        </div>
    );
  };
export default ClickOnLinks;
