import React, {
  useEffect,
  useState,
} from 'react';
import moment, {Moment} from 'moment';
import useRequest from '../../../../features/API/request';
import CountChart from '../../../Charts/CountChart';
import styles from '../LinkVisits/LinkVisits.module.scss';
import Loader from 'react-loader-spinner';
import {useTranslation} from "react-i18next";
import "antd/dist/antd.css";
import {Space} from "antd";
import DateTabs from "../DateTabs/DateTabs";

interface valueProps {count: number|string, title: string};
interface IMusicLinkStatsBreakdownProps{
  previewPlay: boolean;
  title: string;
}

const MusicLinkStatsBreakdown = ({previewPlay, title}: IMusicLinkStatsBreakdownProps) => {
  const {t} = useTranslation();
  const [data, setData] = useState(false);
  const [value, setValue] = useState([]);
  const [total, setTotal] = useState(0);
  const [stackedData, setStackedData]= useState<{stackValues: {name: string, data: number[]}[], stackLabels: string[]}>({stackValues: [], stackLabels: []});
  const stackRequest = useRequest({method: 'GET', url: '/albumAnalytics'});

  const getLinkAnalyticsData = async () => {
    try {
      const params = {
        from: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
        to: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
        offset: (new Date()).getTimezoneOffset(),
        group: 'title',
        previewPlay: previewPlay,
      };
      const stackResult = await stackRequest.doRequest(params);
      if (stackResult) {
        const keys: string[] = Object.keys(stackResult); // Seperate keys for x-axis 
        const albumTitles: string[] = [];
        Object.values(stackResult).map((ele: any) => {
            ele.map((e: {title: string, count: number|string}) => {
                if (albumTitles.findIndex((ex) => ex == e.title) == -1) {
                  albumTitles.push(e?.title); // Creating unique links avaiable is given set of data
                }
            });
        });
        const albumData: {name: string, data: number[]}[] = [];
        let total = 0;
        albumTitles.map((e) => albumData.push({name: e, data: Array.from({length: keys.length}, (v, i) => 0)})); // Creating initial data for graph points
        Object.values(stackResult).map((ele: any, i) => {
          albumTitles.map((e, ind: number) => {
                const index = ele.findIndex((ee: valueProps) => ee.title == e);
                if (index != -1) {
                    albumData[ind].data[i] = parseInt(ele[index]?.count as string); // populate data if linkId matches
                    total = total + parseInt(ele[index]?.count as string);
                }
            });
        });
        const stackValues: {name: string, data: number[]}[] = albumData || [];
        const stackLabels: string[] = keys || [];
        setStackedData({stackValues, stackLabels});
        setTotal(total);
        setData(true);
      } else {
        setData(true);
      }
    } catch (error) {
      setData(true);
      console.log('Error occured', error);
    }
  };

  useEffect(() => {
      if (value.length) {
        getLinkAnalyticsData();    
      }
  }, [value]);

  return (
    <div className={styles.total_clicks_social_media_wrapper}>
        <div className={styles.total_clicks_wrapper}>
          <div className={styles.description_select_wrapper}>
            <div className={styles.heading}><p>{title}</p><p className={styles.total}>{total?.toLocaleString()}</p></div>
            <Space direction="vertical" size={12}>
              <DateTabs handleChange={setValue} />
            </Space>
          </div>

          <div className={styles.chart_wrapper}>
            {data ? <CountChart values={stackedData.stackValues} labels={stackedData.stackLabels} /> :
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
              </div>
              <CountChart values={[]} labels={[]} />
            </div>
            }
          </div>
        </div>
      </div>
  );
};
export default MusicLinkStatsBreakdown;
