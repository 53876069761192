import {Tooltip} from 'antd';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import styles from './formikForm.module.scss';
const FormikInput = ({formik, name, labelName, tooltip, customInput, ...props}:any) => {
  const {values, handleChange} :any= formik;
  return <div className={styles.form_group}>
    <div className={styles.form_group_label}>
      <span>{labelName.toUpperCase()}</span>
      {tooltip && <Tooltip title={tooltip}><HelpIcon /></Tooltip>}
    </div>
    <div className={styles.form_group_input}>
      { customInput || <input type='text' onChange={handleChange} name={name} id={name} value={values[name]} placeholder={labelName} {...props} />}
    </div>
  </div>;
};
export default FormikInput;
