import {Method} from 'axios';
import {useCallback, useState} from 'react';
import {envConfig} from '../EnvironmentVariables/enviromentVariables';
import api from './api';
import {apiVersion} from '../../constants/helper';
interface IUseRequest {
  url: string;
  method: Method;
  session?: boolean;
  isShortUrl?: boolean;
}
export interface ErrorResponse {
  message?: string;
  fields?: string[];
  statusCode?: number;
}
export interface Response {
  message?: string;
  data?: any|unknown;
}
interface IRequestState {
  data?: Response | any;
  error?: ErrorResponse;
  isFetching: boolean;
  isFetched: boolean;
  doRequest: (body?: unknown, options?: unknown) => Promise<any>;
  hasError: boolean;
}
const INITAL_STATE = {
  data: undefined,
  error: undefined,
  isFetching: false,
  isFetched: false,
  hasError: false,
};
const FETCHING_STATE = {
  isFetching: true,
  isFetched: false,
  hasError: false,
  error: undefined,
};
const FETCHED_STATE = {
  isFetching: false,
  isFetched: true,
  error: undefined,
  hasError: false,
};
const ERROR_STATE = {
  isFetching: false,
  isFetched: true,
  hasError: true,
  data: undefined,
};
const makeUrl = ({url, isShortUrl}: any) => {
  const envConfigBackend = envConfig(isShortUrl).baseUrl;
  return envConfigBackend + url;
};
const useRequest = ({url, method = 'GET', session, isShortUrl=false}: IUseRequest): IRequestState => {
  const [requestState, setRequestState] = useState(INITAL_STATE);
  const doRequest = useCallback(async (body, options) => {
    try {
      setRequestState((state) => ({...state, ...FETCHING_STATE}));
      const token = session ? sessionStorage.getItem('token') : localStorage.getItem('token');
      const config = {
        headers: {...options?.headers, "Authorization": `Bearer ${token}`, "x-api-version": apiVersion},
      };
      const requestOptions = method === 'GET' ? {method, params: body}: {method, data: body};

      const {data} = await api(makeUrl({url, isShortUrl}), {
        ...requestOptions,
        ...(token ? config : {}),
      });
      const newState = {...requestState, ...FETCHED_STATE, data};
      setRequestState(newState);
      return data;
    } catch (error:any) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching - ', error);
      // eslint-disable-next-line max-len
      const newState = {
        ...requestState,
        ...ERROR_STATE,
        error: error?.response?.data,
      };
      setRequestState(newState);
      return;
    }
  }, []);
  return {...requestState, doRequest};
};
export default useRequest;

export const useUploadRequest = ({url, method='POST', session}: IUseRequest): IRequestState => {
  const [requestState, setRequestState] = useState(INITAL_STATE);
  const doRequest = useCallback(async (body) => {
    try {
      setRequestState((state) => ({...state, ...FETCHING_STATE}));
      const token = session ? sessionStorage.getItem('token') : localStorage.getItem('token');
      const config = {
        headers: {"Authorization": `Bearer ${token}`, "x-api-version": apiVersion},
      };
      const fd = new FormData();
      fd.append(body.file.key, body.file.value, body.file.fileName);
      const requestOptions = {method, data: fd};

      const {data} = await api(makeUrl({url}), {
        ...requestOptions,
        ...(token ? config : {}),
      });
      const newState = {...requestState, ...FETCHED_STATE, data};
      setRequestState(newState);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error fetching - ', error);
      // eslint-disable-next-line max-len
      const newState = {
        ...requestState,
        ...ERROR_STATE,
        error: error?.response?.data,
      };
      setRequestState(newState);
      return;
    }
  }, []);
  return {...requestState, doRequest};
};
