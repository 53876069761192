import React, {useState} from 'react';
import {store} from '../../app/store';
import {useTranslation} from "react-i18next";
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';
import styles from './MusicLinks.module.scss';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {themeColor} from '../../constants/newThemes';
import useWindowWidth from '../../hooks/useWindowWidth';
import {PlusCircleFilled} from '@ant-design/icons';
import MusicModal from './MusicModal';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {MusicAlbumItem, getMusicAlbums, addMusicLink, updateMusicLink, sortMusicLinkItems, deleteMusicLink} from '../../features/stores/musicAlbumsSlicer';
import {useSelector} from 'react-redux';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {reorder} from '../../features/util';
import LinkItem from '../LinkItem/LinkItem';
import {MusicLinkIcon} from './MusicLinkIcon';

const ButtonCustom = withStyles({
    root: {
      background: (props: any) => props.background,
      color: (props: any) => props.colortext,
      border: (props: any) => props.border ? `1px solid ${props.border}` : 'none',
      boxShadow: 'none',
      borderRadius: '25px',
      marginBottom: ({width}) => width < 768 ? '10px' : '28px',
      textTransform: 'none',
      fontSize: '14px',
      lineHeight: '24px',
      padding: '8px 20px',
    },
    contained: {
      '&:hover': {
        background: (props: any) => props.background,
      },
    },
  })(Button);

const MusicLinks = () => {
  const MAX_MUSIC_LINKS = 15;
  const {t} = useTranslation();
  const mobile = useWindowWidth();
  const {musicLinks: musicLinks} = useSelector(getMusicAlbums);
  const [openMusicModal, setOpenMusicModal] = useState(false);
  const [editMusicLink, setEditMusicLink] = useState<MusicAlbumItem | undefined>();

  const handleShuffle = (sourceIndex: number, destIndex: number) => {
    const itemsTemp = reorder(
        musicLinks,
        sourceIndex,
        destIndex,
    ) as MusicAlbumItem[];

    itemsTemp.forEach((element: MusicAlbumItem, index: number) => {
      store.dispatch(updateMusicLink({id: element.id, data: {...element, order: index}}));
    });
    store.dispatch(sortMusicLinkItems());
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    handleShuffle(result.source.index, result.destination.index);
    store.dispatch(setDataChange(true));
  };

  const handleVisbilityToggle = (id: string, visible: boolean ) => {
        store.dispatch(updateMusicLink({id: id, data: {visible: !visible}}));
        store.dispatch(setDataChange(true));
    };

  const handleMusicLinkDelete = (id: string) => {
    const source = musicLinks.findIndex((link) => link.id == id);
    handleShuffle(source, musicLinks.length-1);
    store.dispatch(deleteMusicLink(id));
    store.dispatch(setDataChange(true));
  };

  const handleMusicLinkEdit = (album: MusicAlbumItem) => {
    setEditMusicLink(album);
    setOpenMusicModal(true);
  };

  const musicLinkSubmit = (album: MusicAlbumItem) => {
    if (editMusicLink) {
      store.dispatch(updateMusicLink({id: album.id, data: album}));
    } else {
      store.dispatch(addMusicLink({...album, order: musicLinks.length}));
    }
    store.dispatch(setDataChange(true));
    setEditMusicLink(undefined);
    setOpenMusicModal(false);
  };

  const handleCloseMusicModal = () => {
    setEditMusicLink(undefined);
    setOpenMusicModal(false);
  };

  return (
      <>
        {openMusicModal &&
         <MusicModal
           album={editMusicLink}
           handleSubmit={musicLinkSubmit}
           handleClose={handleCloseMusicModal}
         />
        }
        {musicLinks.length < MAX_MUSIC_LINKS && <ButtonCustom onClick={() => {
            setOpenMusicModal(true);
        }} width={mobile} className={styles.add_music_btn} variant="contained" size={mobile > 1024 ? "large" : "default"} colortext={themeColor.btnColor} background={themeColor.btnBackground} startIcon={<PlusCircleFilled />}>
            <div>{t('add_music')}</div>
        </ButtonCustom>
        }
        {musicLinks.length > 0 ? <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-rates">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={styles.itemsContainer}>
                        {musicLinks?.map((item: MusicAlbumItem, index: number) => (
                            <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                            >
                    {(provided) => (
                      <LinkItem
                        visibility={item.visible}
                        title={item.title!}
                        subtitle={item.description}
                        icon={<MusicLinkIcon image={item.coverImageUrl} audio={item.previewUrl} />}
                        draggableProvided={provided}
                        onClick={() => handleMusicLinkEdit(item)}
                        handleDelete={() => handleMusicLinkDelete(item.id)}
                        toggleVisibility={() => handleVisbilityToggle(item.id, item.visible)}
                      />
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            </div> : <div className={styles.no_links}>
                <img src={noVideo} />
                <div className={styles.no_links_text}>
                <div className={styles.no_links_title}>{t('no_albums_to_display')}</div>
                <div className={styles.subtitle_no_link}>{t('click_the_button_above_to_add_one')}</div>
            </div>
        </div>}
  </>);
};

export default MusicLinks;
