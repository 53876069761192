import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';

export interface googleCalendarState {
  connected: boolean;
}

const initialState: googleCalendarState = {
  connected: false,
};

export const googleCalendarSlice = createSlice({
  name: 'googleCalendar',
  initialState,
  reducers: {
    removeGoogleCalendar: (state) => {
      state.connected = false;
    },
    setGoogleCalendar: (state, action: PayloadAction<any>) => {
      state.connected = action.payload;
    },
  },
});

export const {removeGoogleCalendar, setGoogleCalendar} = googleCalendarSlice.actions;

export const getGoogleCalender = (state: RootState) => state.googleCalendar;

export default googleCalendarSlice.reducer;
