import React, {useState, useEffect} from 'react';
import styles from '../../style/global.module.scss';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/alfan-logo.svg';
import passwordIconSvg from '../../assets/images/password-icon.svg';
import chooseImage from '../../assets/images/choose-banner.png';
import classNames from 'classnames';
import {toast, ToastContainer} from 'react-toastify';
import useRequest from '../../features/API/request';
import {raiseToast} from '../../components/Toast/Toast';
import arrowLeft from '../../assets/images/arrow-left.svg';
import {submitForgetDeatilsCognito} from '../../features/Login/login';
import {determineSafariCss, isRtl} from '../../features/util';
import {useTranslation} from 'react-i18next';

const cn = classNames.bind(styles);

const NewPassword = () => {
  // visibility
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const [passwordValue, setPasswordValue] = useState('');
  const [confirmValue, setConfirmValue] = useState('');

  const [error, setError] = useState('');
  const {t, i18n} = useTranslation();
  const isRTL: boolean = isRtl(i18n.language);

  const changePasswordRequest = useRequest({url: '/auth/reset', method: 'POST'});

  const validatePasswords = () => {
    const reg = /^.{6,99}$/;
    if (!passwordValue && !confirmValue) setError('Please enter both passwords.');
    else if (!passwordValue || !confirmValue || passwordValue !== confirmValue) setError('Passwords must match!');
    // else if (passwordValue.length < 8 || confirmValue.length < 8) setError('Passwords must be at least 8 characters long.');
    else if (!reg.test(passwordValue) || !reg.test(confirmValue)) setError('Passwords must be at least 6 characters long.');
    else setError('');
  };

  const handleClickButton = async () => {
    // validate
    validatePasswords();
    if (error) return;
    // send request
    const urlSplits = window.location.pathname.split('/');
    const token = urlSplits[urlSplits.length-1];
    const result: any = await submitForgetDeatilsCognito(token, confirmValue);
    if (result.error) {
      const splitData = result.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (result.error) {
        case 'CodeMismatchException':
          raiseToast({message: msg || result.message, ...(!result.valid && {variant: 'error'} )});
          break;
        case 'ExpiredCodeException': 
          raiseToast({message: msg || result.message, ...(!result.valid && {variant: 'error'} )});
          window.location.assign('/public/forget');
          break;
        case 'UserLambdaValidationException':
          raiseToast({message: msg || 'Something went wrong!', ...(!result.valid && {variant: 'error'} )});
          break;
        case 'UserNotFoundException':
          raiseToast({message: msg || result.message, ...(!result.valid && {variant: 'error'} )});
          break;
        default:
          console.log('no error matched');  
      }
    } else {
        raiseToast({message: 'Password updated!'});
        window.location.assign('/public/login');
    }
  };

  const passwordClasses = error ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];

  const passwordVisibleFieldType = passwordVisible ? 'text' : 'password';
  const confirmVisibleFieldType = confirmVisible ? 'text' : 'password';

  return (
    <section className={styles['signin-section']}>
      <div className={styles['form-section']}>
        <a href="/">
          <span>
            <object data={logo} type="image/svg+xml"></object>
          </span>
        </a>
        <div className={styles['signin-form']}>
          <div className={styles['signin-form-inner']}>
            <h4>{t('choose_new_password')}</h4>
            <form>
              <div className={passwordClasses} >
                <input
                  style={determineSafariCss()}
                  type={passwordVisibleFieldType}
                  value={passwordValue}
                  onKeyUp={(e)=>{
                    if (e.keyCode === 13) {
                      handleClickButton();
                    }
                  }}
                  onChange={(e) => {
                    setPasswordValue(e.target.value);
                  }}
                  placeholder={t("enter_your_new_password")}
                />
                <img
                  src={passwordIconSvg}
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                  alt=""
                  style={{[isRTL ? 'left' : 'right']: '25px'}}
                />
              </div>
              <div className={passwordClasses} style={{marginTop: '15px'}}>
                <input
                  style={determineSafariCss()}
                  type={confirmVisibleFieldType}
                  value={confirmValue}
                  onKeyUp={(e)=>{
                    if (e.keyCode === 13) {
                      handleClickButton();
                    }
                  }}
                  onChange={(e) => {
                    setConfirmValue(e.target.value);
                  }}
                  placeholder={t("confirm_new_password")}
                />
                <img
                  src={passwordIconSvg}
                  onClick={() => {
                    setConfirmVisible(!confirmVisible);
                  }}
                  alt=""
                  style={{[isRTL ? 'left' : 'right']: '25px'}}
                />
              </div>
              <span style={{textAlign: 'left'}}>{error}</span>
              <div className={styles['form-group']}>
                <button
                  type="button"
                  onClick={(e) => {
                    handleClickButton();
                  }}
                  className={styles['main-btn']}
                >
                  Submit
                </button>
              </div>

              <span>
                <a href="/public/login" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <img src={arrowLeft} style={{width: '20px', height: '20px'}} />
                  <div>{t('back_to_login')}</div>
                </a>
              </span>

            </form>
          </div>
          <p className={styles['footer']}>© 2022 • Alfan Link • All Rights Reserved</p>
        </div>
      </div>
      <div className={styles['form-img']}>
        <img src={chooseImage} alt="" />
      </div>
    </section>
  );
};


export default NewPassword;
