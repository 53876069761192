import React from 'react';
import ImageCrop from '../ImageCrop/ImageCrop';
import styles from '../ModalCrop/ModalCrop.module.scss';

interface IProps{
    setIsOpen: (arg:boolean) => void,
    imgSrc: any,
    setImageUrl: (arg:any) => void,
    type: string
    originalImageurl?: string;
    setIsConfirmed?: Function;
    handleCancelUpload?: Function;
}

const ModalCrop = ({setIsOpen, imgSrc, type, setImageUrl, originalImageurl, setIsConfirmed, handleCancelUpload}: IProps) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <ImageCrop setIsConfirmed={setIsConfirmed} handleCancelUpload={handleCancelUpload}
              imgSrc={imgSrc} originalImage={originalImageurl} setIsOpen={setIsOpen} type={type} setImageUrl={setImageUrl}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCrop;
