import React from 'react';
import style from './CopyLinkCard.module.scss';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {useSelector} from 'react-redux';
import defaultAvatar from '../../assets/images/al_avatar_default.png';
import {toast} from 'react-toastify';
import CustomInput from '../CustomInput/CustomInput';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import {selectChangeData, setShouldSave} from '../../features/stores/changeDataSlicer';
import copy from 'copy-to-clipboard';
import {useTranslation} from "react-i18next";
import {themeColor} from '../../constants/newThemes';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';

// const selectLinksUser = (state: any) => state.user.me.linksUser;
const url = envConfig().copyLinkUrl;
const CopyLinkCard = (): JSX.Element => {
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const dataChange = useSelector(selectChangeData);
  const handleSave = () => {
    if (dataChange) {
      store.dispatch(setShouldSave(true));
    }
  };

  return (
    <div className={style.header}>
      <CustomInput placeholder={'testvalue'}
        value={`${url}/${linksUser?.nickname?.toLowerCase()}`} onChange={(e) => {
        }} className={style.input}>

        <div className={style.icon_wrapper} onClick={() => {
          copy(`${url}/${linksUser?.nickname?.toLowerCase()}`);
          toast(<span style={{color: 'black'}}>{t('copy_link_success')}</span>, {
            autoClose: 9000,
          });
        }}>
          <div><CopyIcon stroke={themeColor.background} /></div>
          <div className={style.icon_wrapper}>
            {/* <img src={copyIcon}></img> */}
            {/* <p>{t('copy_link')}</p> */}
          </div>
        </div>
      </CustomInput>
      <button className={style.button + ' ' + (!dataChange? style.button_disabled : '')} onClick={handleSave}>{dataChange? t('save') : t('saved')}</button>
    </div>

  );
};

export default CopyLinkCard;
