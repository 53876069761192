
import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import {useStylesBox} from '../ModalMonetize/components/styleCustom';
import {isValidHttpUrl} from '../../features/util';
import {useUploadRequest} from '../../features/API/request';
import CircularProgress from '@material-ui/core/CircularProgress';
import AudioPlayer from 'material-ui-audio-player';
import styles from './MusicLinks.module.scss';
import {useTranslation, Trans} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import trashIcon from '../../assets/images/al_delete_purple.svg';
import {makeStyles} from '@material-ui/core';
import {toast} from 'react-toastify';

interface IProps{
    audioPreviewURL: string;
    setAudioPreviewURL: (s: string) => void;
}

const useStyles = makeStyles((theme) => {
    return {
      root: {
        'margin': '0px',
        'borderRadius': '40px',
        'alignItems': 'center !important',
      },
      mainSlider: {
        'color': '#7066FF',
        '& .MuiSlider-rail': {
          color: '#7986cb',
        },
        '& .MuiSlider-track': {
          color: '#7066FF',
        },
        '& .MuiSlider-thumb': {
          color: '#7066FF',
        },
      },
    };
  });

const AudioPreviewUpload = ({audioPreviewURL, setAudioPreviewURL}: IProps) => {
    const [previewURLInput, setPreviewURLInput] = useState<string>('');
    const [isPreviewURLLoading, setPreviewURLLoading] = useState<boolean>(false);

    const {t} = useTranslation();
    const classesInput = useStylesBox();
    const uploadFileRequest = useUploadRequest({method: 'POST', url: '/linksFile/upload'});

    const uploadAudio = async (e: any) => {
        setPreviewURLLoading(true);
        try {
            const maxSize = 25;
            if (e.target.files && e.target.files.length > 0 && ((e.target.files[0].size / 1024 / 1024) > maxSize)) {
                const name = 'Audio preview';
                toast(<span style={{color: 'black'}}><Trans i18nKey="size_has_exceeded_the_allowed_size" name={name} maxSize={maxSize}>{{name}} size has exceeded the allowed size {{maxSize}} Mb </Trans></span>, {autoClose: 3000});
                setPreviewURLLoading(false);
                return;
            }
            const params = {
                file: {
                    key: 'file',
                    value: e.target.files[0],
                    fileName: e.target.files[0].name,
                },
            };
            const response = await uploadFileRequest.doRequest(params);
            if (response.length) {
                setAudioPreviewURL(response[0].url);
                setPreviewURLLoading(false);
            }
        } catch (error) {
            // ToDo - show error
            setAudioPreviewURL('');
            setPreviewURLLoading(false);
        } 
    };
    return (<>
        {isPreviewURLLoading && <div className={styles.content_row} style={{justifyContent: 'center'}}>
            <CircularProgress disableShrink />
        </div>}

        {audioPreviewURL.length ? <Grid container spacing={1} className={styles.audioPlayerDiv}>
            <Grid item xs={11} sm={11}>
                <AudioPlayer useStyles={useStyles} elevation={1} download={false} loop={false} debug={false} 
                    src={audioPreviewURL}
                    volume={false}/>
            </Grid>
            <Grid item xs={1} sm={1} className={styles.trashContainer}>
                <img src={trashIcon} className={styles.trash} onClick={() => setAudioPreviewURL('')}/>
            </Grid>
        </Grid> : !isPreviewURLLoading && <div className={styles.content_row}>
            <div className={styles.previewLinkDiv}>
                <Input
                    type='text'
                    className={classesInput.textFieldLink}
                    value={previewURLInput}
                    onChange={(e: any) => setPreviewURLInput(e.target.value)}
                    placeholder={t('enter_audio_preview_url')}
                />
                <Button disabled={!isValidHttpUrl(previewURLInput)} className={styles.savePreviewLink} color="primary" onClick={(e: any) => {
                setAudioPreviewURL(previewURLInput);
                setPreviewURLInput('');
                }}>{t('save')}</Button>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>{t('OR')}</div>
            <div>
                <input
                    id={`linkIcon-audio-preview-upload`}
                    style={{display: 'none'}}
                    type={'file'}
                    multiple={false}
                    accept="audio/*"
                    onChange={(e) => {
                        uploadAudio(e);
                    }}
                />
                <div className={styles.icon_sibling}>
                    <MusicNoteIcon fontSize="large" onClick={(e: any) => {
                    document.getElementById('linkIcon-audio-preview-upload')?.click();
                    }} />
                </div>
            </div>
        </div>}
    </>);
};

export default AudioPreviewUpload;
