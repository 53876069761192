import React, {useEffect, useState} from 'react';
import styles from '../ModalMonetize.module.scss';
import {monetizeImageMap} from '../../../constants/socialMedia';
import pencil from '../../../assets/images/pencil.svg';
import {LogoProps} from "./componentInterface";

const LogoContainer = ({determineIcon, fileSelectedHandler, handleUploadThumbnail, id}: LogoProps) => {
    return (
        <>
            <input
                id={`linkIcon-create-new-${id}`}
                style={{display: 'none'}}
                type={'file'}
                onChange={(e) => fileSelectedHandler && fileSelectedHandler(e, id, 'customUploadedImage')}
            />
            <div className={styles.icon_sibling}>
                <img src={determineIcon(id) || monetizeImageMap[id]} className={styles.main_logo} onClick={(e) => handleUploadThumbnail && handleUploadThumbnail(e, `linkIcon-create-new-${id}`)} />
                {handleUploadThumbnail && <div className={styles.pencil_icon_container} onClick={(e) => handleUploadThumbnail && handleUploadThumbnail(e, `linkIcon-create-new-${id}`)}>
                    <img src={pencil} className={styles.pencil_icon} />
                </div>}
            </div>
        </>
    );
};

export default LogoContainer;
