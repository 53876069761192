import React, {useState, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import styles from './signupv2.module.scss';
import {
  signUpCognito,
  createUserSession,
  removeUserSession,
  socialAuth,
} from '../../features/Login/login';
import logo from '../../assets/images/AlfanBrandNew.svg';
import loginImage from '../../assets/images/login-bg-new.jpg';
import passwordIconSvg from '../../assets/images/password-icon.svg';
import passwordVisibleIconSvg from '../../assets/images/al-eye-open-red.svg';
import useInput from '../../hooks/useInput';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {isRtl} from '../../features/util';
import googleCircle from '../../assets/images/googleCircleLogo.svg';
import {useTranslation} from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import {Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import {changeBackground, getUserOnboardingStepTitles, removeStepCookie} from '../../Helper/generalHelper';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {sendEvent} from '../../features/util';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';
import {eventTracking} from '../../services/event-tracking/event-tracking';

const cn = classNames.bind(styles);

const isNotEmpty = (value: String) => value.trim() !== '';
const isEmail = (email: String) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const isPassword = (value: String) => {
  const rex = /^.{6,99}$/;
  return rex.test(String(value));
};
const isFullName = (fullName: String) => {
    return fullName?.length > 3;
  };

const SignupV2 = () => {
  const {t, i18n} = useTranslation();
  const isRTL: boolean = isRtl(i18n.language);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [userHasAccount, setUserHasAccount] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [referrer, setReferrer] = useState('');
  const [loading, setLoading] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);

  useEffect(() => {
    removeUserSession();
    eventTracking().track('open_signup_form');
    const Trafficads = new URLSearchParams(window.location.search).get(
        'Trafficads',
    );
    if (Trafficads) {
      // cookie expire in 1 hour
      Cookies.set('Trafficads', Trafficads, {
        expires: new Date(new Date().getTime() + 60 * 60 * 1000),
      });
    }
    const ref = new URLSearchParams(window.location.search).get('ref');
    if (ref) {
      setReferrer(ref);
    }
  }, []);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    validate: validateEmail,
  } = useInput(isEmail);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
    validate: validatePassword,
  } = useInput(isPassword);

  const {
    value: fullName,
    isValid: fullNameIsValid,
    hasError: fullNameHasError,
    valueChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHandler,
    reset: resetFullName,
    validate: validateFullName,
  } = useInput(isFullName);

  let formIsValid = false;
  if (emailIsValid && passwordIsValid && fullNameIsValid) {
    formIsValid = true;
  }

  if (emailHasError || passwordHasError || fullNameHasError) {
    formIsValid = false;
  }

  const doRegister = async () => {
    validateEmail();
    validatePassword();
    validateFullName();

    if (!formIsValid) {
      return;
    }

    if (!termsOfService) {
      toast.error(t('accept_term_and_condition'));
      return;
    }

    setLoading(true);

    const registerCredentials = {
      email: emailValue.toLowerCase(),
      password: passwordValue,
      rememberMe: false,
      termsChecked: true,
      username: emailValue.toLowerCase(),
      referredBy: referrer,
      fullName: fullName,
    };

    const data: any = await signUpCognito(registerCredentials);
    if (data.error) {
      setLoading(false);
      const splitData = data.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (data.error) {
        case 'InvalidPasswordException':
          setPasswordError(msg || data.message);
          break;
        case 'UsernameExistsException':
          toast.error(msg || t('email_taken_error'));
          setUserHasAccount(true);
          break;
        case 'UserLambdaValidationException':
          toast.error(msg || t('email_taken_error'));
          setUserHasAccount(true);
          break;
        default:
          console.log('no match found');
      }
      return;
    }
    Cookies.set('signup_submit', '1', {
      expires: new Date(new Date().getTime() + 60 * 60 * 1000),
    });
    if (!data.userConfirmed) {
      setLoading(false);
      localStorage.setItem('submit_signup_form', '1');
      createUserSession({
        username: data.user.username,
        creds: passwordValue,
        uuid: data.userSub,
        fullName: fullName,
      });
      window.location.href = '/public/confirm';
    }
  };
  const emailClasses = emailHasError ?
    cn(styles['fields'], styles['invalid']) : styles['fields'];
      const passwordClasses = passwordHasError ?
    cn(styles['fields'], styles['invalid']) : styles['fields'];
      const isPasswordVisibleFieldType = isPasswordVisible ? 'text' : 'password';
  const fullNameClasses = fullNameHasError ? 
    cn(styles['fields'], styles['invalid']) : styles['fields'];

  const handleEvents = () => {
    if (
      window.ttq &&
      Cookies.get('signup_clicked') &&
      Cookies.get('utm_source') == 'tiktok'
    ) {
      window.ttq.track('ClickButton', {
        value: 'NA', // number. Value of the order or items sold. Example: 100.
        currency: 'NA', // string. The 4217 currency code. Example: "USD".
        contents: [
          {
            content_id: 'NA', // string. ID of the product. Example: "1077218".
            content_type: 'NA', // string. Either product or product_group.
            content_name: 'NA', // string. The name of the page or product. Example: "shirt".
          },
        ],
      });
      Cookies.remove('signup_clicked');
    } else {
      Cookies.remove('signup_clicked');
    }
  };

  useEffect(() => {
    handleEvents();
    sendEvent('signup_opened');
    changeBackground('#F4F0ED');
  }, []);

  const handleLoginEvent = () => {
    sendEvent('signup_page_login_click');
    eventTracking().track('signup_page_login_click');
  };

  return (
    <>
      {loading ? (
        <div className={styles.globalLoader}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div>
      ) : null}
      <div className={styles.bg}>
        <Link className={styles.logo} to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
        </Link>

        <div className={styles.main_body_container}>
            <div className={styles.main_body_progress_box}>
                <CustomProgressBar currentStep={1} stepsName={getUserOnboardingStepTitles(t)} />
            </div>
            <div className={styles.main_body_form_box}>
                <div className={styles.language_container}>
                  <LanguagePicker onChange={() => {}} onBoardUi={true} />
                </div>
                <div className={styles.main_body_form_wrapper}>
                    <div>
                        <div>
                        <div className={styles.head_title}>{t('get_started_in_minute')}</div>
                        <div className={styles.head_subtitle}>{t('its_free_no_credit_card_needed')}</div>
                        <div className={styles['form-img-new-mb']}>
                            <img src={'https://alfan-files-production.s3.eu-west-1.amazonaws.com/Alfan-Link-Public-Assets/signup_mob_animation.gif'} alt="" />
                        </div>
                        <form>
                            <div className={styles['socialLogoContainer']}>
                            <div
                                className={styles.google}
                                onClick={() => {
                                socialAuth(
                                    CognitoHostedUIIdentityProvider.Google,
                                    referrer,
                                );
                                removeStepCookie();
                                }}
                            >
                                <img
                                className={styles['circleLogo']}
                                src={googleCircle}
                                />
                                {t('continue_with_google')}
                            </div>
                            </div>

                            <div className={styles['socialLabelContainer']}>
                            <div>{t('or_signup_with')}</div>
                            <div className={styles['line']}></div>
                            </div>

                            <div className={fullNameClasses}>
                            <div className={styles.flex_relative}>
                                <TextFieldInput
                                type={'text'}
                                value={fullName}
                                onChange={(e: any) => {
                                    fullNameChangeHandler(e.target.value);
                                }}
                                onBlur={fullNameBlurHandler}
                                placeholder={t('full_name')}
                                label={t('full_name')}
                                customStyle={{padding: '27px 22px 10px'}}
                                staticHeader={true}
                                onKeyUp={(e: any) => {
                                    if (e.keyCode === 13) {
                                    doRegister();
                                    }
                                }}
                                />
                            </div>
                            {fullNameHasError && (
                                <p className={styles['error-text']}>{t('invalid_fullname')}</p>
                            )}
                            </div>

                            <div className={emailClasses}>
                            <TextFieldInput
                                onBlur={emailBlurHandler}
                                placeholder={t('email')}
                                onKeyUp={(e: any) => {
                                if (e.keyCode === 13) {
                                    doRegister();
                                }
                                }}
                                label={t('your_email')}
                                staticHeader={true}
                                value={emailValue}
                                customStyle={{padding: '27px 22px 10px'}}
                                onChange={(e: any) => {
                                emailChangeHandler(e.target.value);
                                setUserHasAccount(false);
                                }}
                            />
                            {emailHasError && (
                                <p className={styles['error-text']}>
                                {t('invalid_email_error')}
                                </p>
                            )}
                            </div>

                            <div className={passwordClasses}>
                            <div className={styles.flex_relative}>
                                <TextFieldInput
                                type={isPasswordVisibleFieldType}
                                value={passwordValue}
                                onChange={(e: any) => {
                                    passwordChangeHandler(e.target.value);
                                }}
                                customStyle={{padding: '27px 22px 10px'}}
                                onBlur={passwordBlurHandler}
                                placeholder={t('pwd')}
                                label={t('your_password')}
                                staticHeader={true}
                                onKeyUp={(e: any) => {
                                    if (e.keyCode === 13) {
                                    doRegister();
                                    }
                                }}
                                />
                                <img
                                style={
                                    passwordHasError && isRTL ?
                                    {top: '30px', left: '20px'} :
                                    isRTL ?
                                    {left: '20px', top: '30px'} :
                                    {right: '20px', top: '30px'}
                                }
                                className={styles.eye}
                                src={
                                    isPasswordVisible ?
                                    passwordVisibleIconSvg :
                                    passwordIconSvg
                                }
                                onClick={() => {
                                    setIsPasswordVisible(!isPasswordVisible);
                                }}
                                alt=""
                                />
                            </div>
                            {passwordHasError && (
                                <p className={styles['error-text']}>
                                {t('password_create_error')}
                                </p>
                            )}
                            </div>

                            {userHasAccount && (
                            <p className={styles['error-text']}>
                                {t('account_exists')}{' '}
                                <a href="login" style={{color: '#0099ff'}}>
                                {t('want_to_login')}
                                </a>
                            </p>
                            )}

                            <div className={styles['terms']}>
                            <div>
                                <Checkbox
                                checked={termsOfService}
                                onChange={() => setTermsOfService(!termsOfService)}
                                name="checkedAccept"
                                />
                            </div>
                            <div className={styles['title']}>
                                {t('accept')} <a
                                href="https://app.al.fan/terms-and-condition"
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                {t('terms_and_conditions')}
                                </a> {t('and')} <a
                                href="https://app.al.fan/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                {t('privacy_policy')}
                                </a>
                            </div>
                            </div>

                            <div className={styles.btn_wrapper}>
                                <button
                                    type="button"
                                    onClick={doRegister}
                                    className={styles['main-btn']}
                                >
                                    {t('signup')}
                                </button>
                            </div>
                        </form>

                        <div className={styles.login_msg}>
                            {t('already_using_alfan')}
                            <Link to="login" onClick={handleLoginEvent}>
                            {t('login')}
                            </Link>
                        </div>
                        </div>
                    </div>
                    <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
                </div>
                <div className={styles['form-img-new']}>
                <img src={'https://alfan-files-production.s3.eu-west-1.amazonaws.com/Alfan-Link-Public-Assets/signup_web_animation.gif'} alt="" />
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

SignupV2.propTypes = {};

export default SignupV2;
