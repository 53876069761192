/* eslint-disable react/prop-types */
import React from 'react';
import DOMPurify from 'dompurify';
import styles from '../../ChargeCardModal/ChargeCardModal.module.scss'; // Adjust the path to your actual styles

// Define the props interface
interface SafeHtmlDisplayProps {
  htmlContent: string;
}

const SafeHtmlDisplay: React.FC<SafeHtmlDisplayProps> = ({htmlContent}) => {
  // Sanitize the HTML content to remove any potentially harmful scripts
  const sanitizedContent = DOMPurify.sanitize(htmlContent);

  return (
    <div
      className={styles.bio} // Ensure this class contains your desired styles
      dangerouslySetInnerHTML={{__html: sanitizedContent}}
    />
  );
};

export default SafeHtmlDisplay;
