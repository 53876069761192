import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import Loader from 'react-loader-spinner';
import Cookies from 'js-cookie';
import styles from '../../style/global.module.scss';
import ConfirmStyles from './ConfirmEmail.module.scss';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/AlfanBrand.svg';
import forgotBanner from '../../assets/images/login-bg-new.jpg';
import useRequest from '../../features/API/request';
import {raiseToast} from '../../components/Toast/Toast';
import {determineSafariCss} from '../../features/util';
import {decodeToken, verifyEmailCognito, loginCognito, logIn, resendCognitoEmail, getUserSession, removeUserSession} from '../../features/Login/login';
import {completeSignupTitkokEvent} from '../../Helper/generalHelper';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import {useTranslation} from 'react-i18next';
import {sendEvent} from '../../features/util';
import {eventTracking} from '../../services/event-tracking/event-tracking';

const ConfirmEmail = () => {
  const {t} = useTranslation();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEvent = async () => {
    const Trafficads = Cookies.get('Trafficads');
    const SignupSubmit = Cookies.get('signup_submit');
    const submitSignupForm = localStorage.getItem('submit_signup_form');
    const utmSource = Cookies.get('utm_source');

    if (submitSignupForm) {
      const userDecoded: any = await getUserSession();
      setTimeout(() => {
        if (userDecoded) {
          eventTracking().updateProfile({Email: userDecoded.username});
          eventTracking().track('submit_signup_form', {UUID: userDecoded.username});
          localStorage.removeItem('submit_signup_form');
        }
      });
    }

    if (Trafficads) {
      setTimeout(() => {
        if (window?.fbq) {
          window.fbq('track', 'CompleteRegistration', {content_name: Trafficads, value: 0, currency: 'USD'});
          Cookies.remove('Trafficads');
        }
      });
    }

    if (SignupSubmit) {
      if (window.ttq && utmSource === 'tiktok') {
        completeSignupTitkokEvent();
      }
      sendEvent('signup_submit');
    }
  };


  const handleCognito = async () => {
    const tokens: any = await Auth.currentSession();
    if (tokens && tokens.idToken) {
      setLoading(true);
      localStorage.setItem('verify_email', '1');
      const me = await logIn({Bearer: tokens.idToken.jwtToken});
      if (me) {
        setCode('');
        // localStorage.setItem('verify_email', '1');
        // removeUserSession();
        if (!me.hasNickname) window.location.assign('/public/claim');
        else {
          localStorage.setItem('token', tokens.idToken.jwtToken);
          sessionStorage.clear();
          window.location.assign('/profile/home');
        }
      }
      setLoading(false);
    }
  };

  useEffect(()=>{
    handleEvent();
  }, []);

  const sendCode = async () => {
    try {
      setLoading(true);
      const storage = getUserSession();

      const result = await verifyEmailCognito(storage.username, code);
      if (!result) return;

      const loginResult = await loginCognito({email: storage.username, password: storage.creds});
      if (loginResult.error) return;

      setLoading(false);
      handleCognito();
      ['email_verified', 'signup_manual'].forEach((event) => sendEvent(event));
    } catch (error) {
      console.error('Error in sendCode:', error);
    } finally {
      setLoading(false);
    }
  };


  const resend = async ()=>{
    const storage = getUserSession();
    const result = await resendCognitoEmail(storage.username);
    raiseToast({message: 'Check your email! The new code should be there.'});
  };

  const codeClasses = styles['form-group'];

  return (
    <>
      {loading ? <div className={styles['globalLoader']}><Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /></div> : null}
      <section className={styles['signin-section']}>
        <div className={styles['form-section']}>
          <a href="/">
            <span>
              <object data={logo} type="image/svg+xml"></object>
            </span>
          </a>
          <div className={styles['signin-form']}>
            <div className={styles['signin-form-inner']}>
              <h4>{t('verify_your_email')}</h4>
              <form onSubmit={(e)=>e.preventDefault()}>
                <div className={codeClasses} >
                  <TextFieldInput
                    placeholder={t('confirmation_code')}
                    label={t('confirmation_code')}
                    staticHeader={true}
                    customStyle={{padding: '27px 22px 10px'}}
                    value={code}
                    onKeyUp={(e: any)=>{
                      if (e.keyCode === 13) {
                        sendCode();
                      }
                    }}
                    onChange={(e: any) => {
                      setCode(e.target.value);
                    }}
                  />
                </div>
                <div className={styles['form-group']}>
                  <button
                    type="button"
                    onClick={(e) => {
                      sendCode();
                    }}
                    disabled={!code?.length}
                    className={ConfirmStyles['main-btn']}
                  >
                    Confirm Email Address
                  </button>
                </div>

                <div className={ConfirmStyles.resend_container}>
                  {t('not_received_code')} &nbsp;
                  <div className={ConfirmStyles.resend}
                    onClick={resend}
                  > {t('resend')}</div>
                </div>
              </form>
            </div>
            <p className={styles['footer']}>© 2022 • Alfan Link • All Rights Reserved</p>
          </div>
        </div>

        <div className={styles['form-img']}>
          <img src={forgotBanner} alt="" />
        </div>
      </section>
    </>
  );
};


export default ConfirmEmail;
