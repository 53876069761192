import React from 'react';
import style from './naviButton.module.scss';

import arrowLeft from '../../assets/images/arrowLeft.svg';
import arrowRight from '../../assets/images/arrowRight.svg';

interface IProps{
  leftEnabled?: boolean;
  rightEnabled?: boolean;
  onLeftClick: () => void;
  onRightClick: () => void;
}

const NaviButtons = (props: IProps) => {
  return (
    <div className={style.main}>
      <div onClick={() => props.onLeftClick()} className={style.left}>
      </div>
      <div onClick={() => props.onRightClick()} className={style.right}>
      </div>
    </div>
  );
};

export default NaviButtons;
