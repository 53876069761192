export const timeZones = [
  {name: 'Africa/Abidjan', offset: '0'},
  {name: 'Africa/Addis_Ababa', offset: '+3'},
  {name: 'Africa/Algiers', offset: '+1'},
  {name: 'Africa/Blantyre', offset: '+2'},
  {name: 'Africa/Cairo', offset: '+2'},
  {name: 'Africa/Windhoek', offset: '+2'},
  {name: 'America/Adak', offset: '-10'},
  {name: 'America/Anchorage', offset: '-9'},
  {name: 'America/Argentina/Buenos_Aires', offset: '-3'},
  {name: 'America/Araguaina', offset: '-3'},
  {name: 'America/Belize', offset: '-6'},
  {name: 'America/Bogota', offset: '-5'},
  {name: 'America/Campo_Grande', offset: '-4'},
  {name: 'America/Caracas', offset: '-4'},
  {name: 'America/Chicago', offset: '-6'},
  {name: 'America/Chihuahua', offset: '-7'},
  {name: 'America/Cancun', offset: '-5'},
  {name: 'America/Dawson_Creek', offset: '-7'},
  {name: 'America/Denver', offset: '-7'},
  {name: 'America/Ensenada', offset: '-8'},
  {name: 'America/Glace_Bay', offset: '-4'},
  {name: 'America/Godthab', offset: '-3'},
  {name: 'America/Goose_Bay', offset: '-4'},
  {name: 'America/Havana', offset: '-5'},
  {name: 'America/Los_Angeles', offset: '-8'},
  {name: 'America/La_Paz', offset: '-4'},
  {name: 'America/Montevideo', offset: '-3'},
  {name: 'America/Miquelon', offset: '-3'},
  {name: 'America/New_York', offset: '-5'},
  {name: 'America/Noronha', offset: '-2'},
  {name: 'America/Santiago', offset: '-4'},
  {name: 'America/Sao_Paulo', offset: '-3'},
  {name: 'America/St_Johns', offset: '-3.5'},
  {name: 'Atlantic/Azores', offset: '0'},
  {name: 'Atlantic/Cape_Verde', offset: '-1'},
  {name: 'Atlantic/Stanley', offset: '-3'},
  {name: 'Australia/Adelaide', offset: '+9.5'},
  {name: 'Australia/Brisbane', offset: '+10'},
  {name: 'Australia/Darwin', offset: '+9.5'},
  {name: 'Australia/Eucla', offset: '+8.75'},
  {name: 'Australia/Hobart', offset: '+10'},
  {name: 'Australia/Lord_Howe', offset: '+10.5'},
  {name: 'Australia/Perth', offset: '+8'},
  {name: 'Europe/Amsterdam', offset: '+1'},
  {name: 'Europe/Belgrade', offset: '+1'},
  {name: 'Europe/Belfast', offset: '0'},
  {name: 'Europe/Brussels', offset: '+1'},
  {name: 'Europe/Dublin', offset: '0'},
  {name: 'Europe/Lisbon', offset: '0'},
  {name: 'Europe/London', offset: '0'},
  {name: 'Europe/Minsk', offset: '+3'},
  {name: 'Europe/Moscow', offset: '+3'},
  {name: 'Pacific/Auckland', offset: '+13'},
  {name: 'Pacific/Chatham', offset: '+13.75'},
  {name: 'Pacific/Gambier', offset: '-9'},
  {name: 'Pacific/Kiritimati', offset: '+14'},
  {name: 'Pacific/Marquesas', offset: '-9.5'},
  {name: 'Pacific/Midway', offset: '-11'},
  {name: 'Pacific/Norfolk', offset: '+11'},
  {name: 'Pacific/Tongatapu', offset: '+13'},
  {name: 'Asia/Anadyr', offset: '+12'},
  {name: 'Asia/Beirut', offset: '+2'},
  {name: 'Asia/Bangkok', offset: '+7'},
  {name: 'Asia/Damascus', offset: '+2'},
  {name: 'Asia/Dhaka', offset: '+6'},
  {name: 'Asia/Dubai', offset: '+4'},
  {name: 'Asia/Gaza', offset: '+2'},
  {name: 'Asia/Hong_Kong', offset: '+8'},
  {name: 'Asia/Irkutsk', offset: '+8'},
  {name: 'Asia/Jerusalem', offset: '+2'},
  {name: 'Asia/Kabul', offset: '+4.5'},
  {name: 'Asia/Katmandu', offset: '+5.75'},
  {name: 'Asia/Kolkata', offset: '+5.5'},
  {name: 'Asia/Krasnoyarsk', offset: '+7'},
  {name: 'Asia/Rangoon', offset: '+6.5'},
  {name: 'Asia/Riyadh', offset: '+3'},
  {name: 'Asia/Seoul', offset: '+9'},
  {name: 'Asia/Tashkent', offset: '+5'},
  {name: 'Asia/Tehran', offset: '+3.5'},
  {name: 'Asia/Tokyo', offset: '+9'},
  {name: 'Asia/Vladivostok', offset: '+10'},
  {name: 'Asia/Yakutsk', offset: '+9'},
  {name: 'Asia/Yekaterinburg', offset: '+5'},
  {name: 'Asia/Yerevan', offset: '+4'},
  {name: 'Chile/EasterIsland', offset: '-6'},
  {name: 'Etc/GMT+10', offset: '-10'},
  {name: 'Etc/GMT+12', offset: '-12'},
  {name: 'Etc/GMT+8', offset: '-8'},
  {name: 'Etc/GMT-11', offset: '+11'},
  {name: 'Etc/GMT-12', offset: '+12'},
];

