import React, {useState, useRef, useEffect} from 'react';
import styles from '../../components/ColorSectionItem/ColorSectionItem.module.scss';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import CustomColorPicker from '../ColorPicker/CustomColorPicker';
import info from '../../assets/images/info.svg';
import {useSelector} from 'react-redux';

interface IProp {
  title: string;
  theme: any;
  showInfo?: boolean;
  infoContent?: any;
  fieldName: string;
  defaultColor?: string;
  index?: number;
}
const ColorSectionItem = ({fieldName, title, theme, showInfo, infoContent, defaultColor, index}: IProp) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showInfoState, setShowInfoState] = useState(false);
  const [color, setColor] = useState(defaultColor || '');

  const node = useRef(null);
  useOutsideAlerter(node, setIsOpen);

  const infoWindow = useRef(null);
  useOutsideAlerter(infoWindow, setShowInfoState, true);

  // useEffect(()=>{
  // }, [color]);

  useEffect(()=>{
    if (fieldName === 'borderColor' ) {
    }
    setColor(defaultColor!);
  }, [theme]);
  return (
    <div className={styles.container} >
      <div className={styles.section_header}>
        <span className={styles.section_name}>{title}</span>
        {showInfo &&
        <div ref={infoWindow}>
          <img
            className={styles.section_icon}
            src={info}
            onClick={() => setShowInfoState(true)}
            onMouseEnter={() => setShowInfoState(!showInfoState)}
          />
          {showInfoState && <object className={styles.info_window} data={infoContent} type="image/svg+xml" />}
        </div>}

      </div>
      <div className={styles.color_picker_wrapper}>
        <div
          className={styles.color}
          style={{backgroundColor: color}}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {isOpen && (
            <div className={styles.wrapperPicker}>
              <div className={`${styles.closePicker} ${index && (index % 2 == 1) ? styles.even_picker_close : ''}`} onClick={(event)=>{
                setIsOpen(false);
              }}>X</div>
              <div className={`${styles.colorPicker} ${index && (index % 2 == 1) ? styles.even_picker : ''}`} ref={node}>
                <CustomColorPicker
                  theme={theme}
                  fieldName={fieldName}
                  color={color}
                  setColor={setColor}
                />
              </div>
            </div>
          )}
        </div>
        <input
          className={styles.input_pick}
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default ColorSectionItem;
