import React from 'react';
import ReactApexChart from 'react-apexcharts';
import '../Charts/charts.modules.scss';

interface IProps {
  color: String;
  value: number;
  percentage: number;
  name: String;
}
const RadialBarChart = (props: IProps) => {
  const series = [props.percentage];
  const options: ApexCharts.ApexOptions | undefined = {
    chart: {
      height: 160,
      type: 'radialBar',
      fontFamily: 'EudoxusSans',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '55%',
        },
        dataLabels: {
          name: {
            show: false,
            color: '#4E5D78',
          },
          value: {
            show: true,
            color: '#333',
            offsetY: 9,
            fontSize: '20px',
            fontWeight: 'bold',
          },
        },
      },
    },
    fill: {
      colors: [props.color],
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Volatility'],
  };

  return (
    <>
      <div
        className="radial_chart_wrapper"
        id="radialChart"
      >
        <ReactApexChart
          options={options}
          series={series}
          type="radialBar"
          height={160}
        />
        <span className="media_number">{props.value}</span>
        <span className="media_name">{props.name}</span>
      </div>
    </>
  );
};

export default RadialBarChart;
