import React, {useEffect} from 'react';
import classNames from 'classnames';
import styles from '../../style/global.module.scss';
// import logo from '../../assets/images/logoSvg.svg'; // replaced
import logo from '../../assets/images/alfan-logo.svg';
import HeaderImageV2 from '../../assets/images/mobiteli.svg'; // replaced
import spotifySvg from '../../assets/images/spotify.svg'; // replaced
import tiktok2 from '../../assets/images/tiktok2.svg'; // replaced
import instagramSvg2 from '../../assets/images/instagram2.svg'; // replaced
import facebookSvg from '../../assets/images/facebook.svg';// replaced
import snapchatSvg from '../../assets/images/snapchat.svg';// replaced
import deezerJpg from '../../assets/images/deezer.svg';// replaced
import youtubeSvg from '../../assets/images/youtube.svg';// replaced
import shopifySvg from '../../assets/images/shopify.svg';// replaced
import twitchSvg from '../../assets/images/twitch.svg';// replaced
import discordSvg from '../../assets/images/discord.svg';// replaced
import anghamiSvg from '../../assets/images/Anghami.svg';// replaced
import activitySvg from '../../assets/images/analytics-icon.svg'; // replaced
import macbookPro from '../../assets/images/MacBookPro.svg'; // replaced
import analytics from '../../assets/images/analytics.svg';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import ReactGA from 'react-ga';
import {useTranslation} from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import createTheme from '@material-ui/core/styles/createTheme';
import MetaTagWrapper from '../../components/MetaTagWrapper/MetaTagWrapper';

const cn = classNames.bind(styles);

const Landing = () => {
  const {t} = useTranslation();
  return (
    <div>
      <header>
        <div className={styles['container']}>
          <div className={styles['header-inner']}>
            <div className={styles['logo']}>
              <object data={logo} type="image/svg+xml"></object>
            </div>
            <div style={{"display": "flex"}}>
              <div className={styles.localizer}>
                <LanguagePicker onChange={() => {}}></LanguagePicker>
              </div>
              <div className={styles['login']}>
                <ul>
                  <li><a href="/public/login" className={styles['translate']}>{t('signin')}</a></li>
                  <li><a href="/public/signup" className={cn(styles['main-btn'], styles['translate'])}
                    id="get-started-navigation" style={{marginTop: '2px'}}
                  >{t('get_started')}</a></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className={styles['banner-section']}>
        <div className={styles['container']}>
          <div className={styles['banner-inner']}>
            <div className={styles['banner-content']}>
              <h1 className={cn(styles['translate'], styles['banner-heading'])} id="banner-heading">{t('alfan_link_description')} <span className={styles['text-highlight']}>{t('Alfan Link')}</span></h1>
              <p className={styles['translate']} id="banner-content">{t('build_your_page')}</p>
              <a href="/public/signup" className={cn(styles['main-btn'], styles['translate'])} id="banner-get-started-btn">{t('get_started_free')}</a>
              <a href="/public/login" className={cn(styles['main-btn'], styles['gray-btn'], styles['translate'])} id="banner-signIn-btn">{t('signin')}</a>

            </div>
            <div className={styles['banner-img']}>
              <object data={HeaderImageV2} type="image/svg+xml"></object>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(styles['everywhere-section'])}>
        <div className={styles['container']}>
          <object data={logo} type="image/svg+xml" className={styles['everywhere-logo']}></object>
          <h2 className={styles['translate']} id="everywhere-link-heading">{t('all_pages_one_place')}</h2>
          <p className={styles['translate']} id="everywhere-link-content">{t('share_everywhere')}</p>

          <div className={styles['circle']}></div>
          <div className={styles['circle2']}></div>

          {/* <img src={tiktok2} className={styles['everywhere-tiktok']} alt=""/>
          <img src={instagramSvg2} className={styles['everywhere-instagram']} alt=""/>
          <img src={facebookSvg} className={styles['everywhere-facebook']} alt=""/>
          <img src={spotifySvg} className={styles['everywhere-spotify']} alt=""/>
          <img src={snapchatSvg} className={styles['everywhere-snapchat']} alt=""/>
          <img src={deezerJpg} className={styles['everywhere-deezer']} alt=""/> */}

          {/* <img src={youtubeSvg} className={styles['everywhere-youtube']} alt=""/>
          <img src={shopifySvg} className={styles['everywhere-nonolive']} alt=""/>
          <img src={twitchSvg} className={styles['everywhere-music']} alt=""/>
          <img src={discordSvg} className={styles['everywhere-bitmap']} alt=""/>
          <img src={anghamiSvg} className={styles['everywhere-bitmap2']} alt=""/>*/}

          <object data={tiktok2} className={styles['everywhere-tiktok']} type="image/svg+xml"></object>
          <object data={instagramSvg2} className={styles['everywhere-instagram']} type="image/svg+xml"></object>
          <object data={facebookSvg} className={styles['everywhere-facebook']} type="image/svg+xml"></object>
          <object data={spotifySvg} className={styles['everywhere-spotify']} type="image/svg+xml"></object>
          <object data={snapchatSvg} className={styles['everywhere-snapchat']} type="image/svg+xml"></object>
          <object data={deezerJpg} className={styles['everywhere-deezer']} type="image/svg+xml"></object>

          <object data={youtubeSvg} className={styles['everywhere-youtube']} type="image/svg+xml"></object>
          <object data={shopifySvg} className={styles['everywhere-nonolive']} type="image/svg+xml"></object>
          <object data={twitchSvg} className={styles['everywhere-music']} type="image/svg+xml"></object>
          <object data={discordSvg} className={styles['everywhere-bitmap']} type="image/svg+xml"></object>
          <object data={anghamiSvg} className={styles['everywhere-bitmap2']} type="image/svg+xml"></object>
        </div>
      </section>

      <section className={styles['get-featured-section']}>
        <div className={styles['container']}>
          <div className={styles['get-featured-inner']}>
            <div className={cn(styles['promote-content-inner'], styles['promote-content'])}>
              <h2 className={styles['translate']} id="get-featured-heading">{t('customize_pages')}
              </h2>
              <p className={styles['translate']} id="get-featured-content">{t('premade_themes')}</p>
            </div>
            <div className={styles['get-featured-video']}>
              <object data={macbookPro} type="image/svg+xml"></object>
            </div>
          </div>
        </div>

      </section>

      <section className={styles['media-stats-section']}>
        <div className={styles['container']}>
          <div className={styles['media-stats-inner']}>
            <div className={styles['media-stats-image']}>
              <object data={analytics} type="image/svg+xml"></object>
              <div className={styles['media-stats-icon']}>
                <object data={activitySvg} type="image/svg+xml"></object>
              </div>
            </div>
            <div className={styles['promote-content']}>
              <h2 className={styles['translate']} id="social-media-stats-heading">{t('full_insights')}</h2>
              <p className={styles['translate']} id="social-media-stats-content">{t('live_reports')}</p>

              <a href="/public/signup" className={cn(styles['main-btn'], styles['translate'])}>{t('get_started_free')}</a>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className={styles['container']}>
          <div className={styles['footer-inner']}>
            <div className={styles['footer-center']}>
              <div className={styles['footer-logo']}>
                <object data={logo} type="image/svg+xml"></object>
              </div>
              <div className={styles['footer-links']}>
                <a className={styles['translate']} href='https://alfangroup.com/' id="company-heading">{t('Alfan')}</a>
                <a className={styles['translate']} href='https://alfanblog.com/' id="support-heading">{t('blog')}</a>
                <a className={styles['translate']} href='https://app.al.fan/privacy-policy' id="company-privacy">{t('Privacy')}</a>
                <a className={styles['translate']} href='https://app.al.fan/terms-and-condition' id="support-terms">{t('TnC')}</a>
              </div>
            </div>
            <div className={styles['footer-border-horizontal']}></div>
            <div className={styles['footer-right']}>
              <p className={styles['translate']} id="design-by">{t('claim_cr')}</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};


Landing.propTypes = {
};

export default Landing;
