import React from 'react';
import {Helmet} from 'react-helmet';

type OGProps = {
  ogImage?: string;
}

type MetaProps = {
    title: string;
    description:string;
    metaProperties: OGProps;
}

const MetaTagWrapper = ({title, description, metaProperties: metaProperties}:MetaProps)=>{
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name='description' content={description} />
      <meta property='og:title' content={title}></meta>
      <meta property='og:description' content={description}></meta>
      {metaProperties.ogImage && <meta property='og:image' content={metaProperties.ogImage}></meta>}
    </Helmet>
  );
};

export default MetaTagWrapper;
