/* eslint-disable react/prop-types */
import React, {useState, useRef} from 'react';
import {MediaItem} from '../LandingCarousel/LandingCarousel';
import './CreatorsSlideshow.scss';

const getWidthNumber = (widthString: string) => {
  const widthNum = Number(widthString.substring(0, widthString.length - 3));
  return widthNum;
};

const SlideshowSlide: React.FC<{ creator: MediaItem, expandedWidth?:number, minimizedWidth?:number }> = ({creator, expandedWidth, minimizedWidth}) => {
  const [isActive, setIsActive] = useState(false);
  const [currentStyle, setCurrentStyle] = useState({
    width: minimizedWidth? minimizedWidth + 'rem' : '10rem',
    height: expandedWidth? expandedWidth + 'rem': '39rem',
    minWidth: minimizedWidth? minimizedWidth + 'rem' : '10rem',
  });
  const intervalId = useRef<any>(null);

  const expandWidth = () => {
    const max = expandedWidth? expandedWidth : 36;
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    setIsActive(true);
    intervalId.current = setInterval(() => {
      setCurrentStyle((prevStyle) => {
        const newWidth = getWidthNumber(prevStyle.width) + 0.5;
        if (newWidth >= max) {
          clearInterval(intervalId.current);
          return {...prevStyle, width: max + 'rem', willChange: undefined};
        }
        return {...prevStyle, width: newWidth + 'rem', willChange: 'width', minWidth: newWidth + 'rem'};
      });
    }, 4);
  };

  const minimizeWidth = () => {
    const min = minimizedWidth? minimizedWidth : 10;
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    setIsActive(false);
    intervalId.current = setInterval(() => {
      setCurrentStyle((prevStyle) => {
        const newWidth = getWidthNumber(prevStyle.width) - 0.5;
        if (newWidth <= min) {
          clearInterval(intervalId.current);
          return {...prevStyle, width: min + 'rem', willChange: undefined, minWidth: '9rem'};
        }
        return {...prevStyle, width: newWidth + 'rem', willChange: 'width', minWidth: newWidth + 'rem'};
      });
    }, 4);
  };

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={creator.creatorProfileURL}
      onMouseOver={expandWidth}
      onMouseOut={minimizeWidth}
      style={currentStyle}
      className="creatorRectangle"
    >
      <div className="img-container">
        <img
          src={creator.imageURL}
          alt={creator.creatorName}
          className={`${isActive ? 'active' : ''}`}
        />
      </div>
      <div className={`creatorName ${isActive ? 'active' : ''}`}>
        {creator.creatorName}
      </div>
    </a>
  );
};

export default SlideshowSlide;
