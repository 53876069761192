import React, {
    useEffect,
    useState,
  } from 'react';
  import moment, {Moment} from 'moment';
  import useRequest from '../../../../features/API/request';
  import styles from './Deals.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";
  import CountChart from '../../../Charts/CountChart';
  
  const DealsClickStats = () => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [total, setTotal] = useState(0);
    const [stackedData, setStackedData]= useState<{stackValues: {name: string, data: number[]}[], stackLabels: string[]}>({stackValues: [], stackLabels: []});
    const stackRequest = useRequest({method: 'GET', url: '/user-deals-analytics'});
  
    const getDealsAnalyticsData = async () => {
      try {
        const params = {
          startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
        };
        const stackResult = await stackRequest.doRequest(params);
        if (stackResult?.userDealAnalytics) {
            const keys: string[] = Object.keys(stackResult?.userDealAnalytics); // Seperate keys for x-axis 
            const dealsIds: any = [];
            Object.values(stackResult?.userDealAnalytics).map((ele: any) => {
                ele.map((e: {userDealId: string, name: string|null, type: string}) => {
                    if (dealsIds.findIndex((ex: any) => ex.userDealId == e.userDealId) == -1) {
                    dealsIds.push({userDealId: e?.userDealId, name: e?.name, type: e?.type}); // Creating unique deals avaiable is given set of data
                    }
                });
            });
            const dealsIdsNew: {name: string, data: number[]}[] = [];
            dealsIds.map((e: any) => dealsIdsNew.push({name: e.name || e.type, data: Array.from({length: keys.length}, (v, i) => 0)})); // Creating initial data for graph points
            Object.values(stackResult?.userDealAnalytics).map((ele: any, i) => {
                dealsIds.map((e: any, ind: number) => {
                    const index = ele.findIndex((ee: any) => ee.userDealId == e.userDealId);
                    if (index != -1) {
                        dealsIdsNew[ind].data[i] = parseInt(ele[index]?.count as string); // populate data if dealsIds matches
                    }
                });
            });
            const stackValues: {name: string, data: number[]}[] = dealsIdsNew || [];
            const stackLabels: string[] = keys || [];
            setStackedData({stackValues, stackLabels});
            setTotal(stackResult?.total);
            setData(true);
          } else {
          setData(true);
        }
      } catch (error) {
        setData(true);
      }
    };
  
    useEffect(() => {
        if (value.length) {
          getDealsAnalyticsData();    
        }
    }, [value]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
              <div className={styles.heading}><p>{t('clicks')}</p><p className={styles.total}>{total?.toLocaleString()}</p></div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
  
            <div className={styles.chart_wrapper}>
            {data ? <CountChart values={stackedData.stackValues} labels={stackedData.stackLabels} /> :
            <div style={{position: 'relative'}}>
              <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
              </div>
              <CountChart values={[]} labels={[]} />
            </div>
            }
          </div>
          </div>
        </div>
    );
  };
export default DealsClickStats;
