export const userTemplate = {
  'id': null,
  'fullname': 'Scarlet Tyler',
  'nickname': 'Scarlet Tyler',
  'location': 'Saudi Arabia',
  'publicEmail': 'scarlettyler@gmail.com',
  'bio': 'Official account for 25 E-Sports Game organization',
  'selectedTheme': '01',
  'themeBackgroundColor': null,
  'themeCardColor': null,
  'themeTextColor': null,
  'themeCardShape': null,
  'themeEnglishFont': null,
  'themeArabicFont': null,
  'createdAt': null,
  'updatedAt': null,
  'linksSections': [
    {
      'id': null,
      'type': 'social',
      'title': 'Add Social Media',
      'titlePlaceholder': 'Add Social Media',
      'subtitle': 'All your social media Links will be here',
      'subtitlePlaceholder': 'All your social media Links will be here',
      'orderingNumber': '0',
      'linksInSection': 1,
      'isVisible': true,
      'createdAt': '2021-09-06T14:46:49.271Z',
      'updatedAt': '2021-09-06T14:56:50.893Z',
      'linksSectionItems': [
        {
          'id': null,
          'type': 'twitter',
          'title': null,
          'url': 'twitter.com/scarlettaylor',
          'orderNumber': 0,
          'createdAt': '2021-09-06T14:56:50.711Z',
          'updatedAt': '2021-09-06T14:56:50.893Z',
        },
        {
          'id': null,
          'type': 'instagram',
          'title': null,
          'url': 'instagram.com/scarlettaylor',
          'orderNumber': 1,
          'createdAt': '2021-09-06T14:56:50.711Z',
          'updatedAt': '2021-09-06T14:56:50.893Z',
        },
        {
          'id': null,
          'type': 'facebook',
          'title': null,
          'url': 'facebook.com/scarlettaylor',
          'orderNumber': 2,
          'createdAt': '2021-09-06T14:56:50.711Z',
          'updatedAt': '2021-09-06T14:56:50.893Z',
        },
        {
          'id': null,
          'type': 'dribble',
          'title': null,
          'url': 'dribble.com/scarlettaylor',
          'orderNumber': 3,
          'createdAt': '2021-09-06T14:56:50.711Z',
          'updatedAt': '2021-09-06T14:56:50.893Z',
        },

      ],
    },
    {
      'id': null,
      'type': 'important_links',
      'title': 'Add Important Links',
      'titlePlaceholder': 'Add Important Links',
      'subtitle': 'All your Important Links will be here',
      'subtitlePlaceholder': 'All your Important Links will be here',
      'orderingNumber': '4',
      'linksInSection': 1,
      'isVisible': true,
      'createdAt': '2021-09-06T14:46:49.273Z',
      'updatedAt': '2021-09-07T12:09:13.833Z',
      'linksSectionItems': [
        {
          'id': null,
          'type': 'instagram',
          'title': 'Folow me on Instagram',
          'url': 'instagram.com/scarlet',
          'orderNumber': 0,
          'createdAt': '2021-09-07T12:09:13.096Z',
          'updatedAt': '2021-09-07T12:09:13.833Z',
        },
        {
          'id': null,
          'type': 'twitter',
          'title': 'Like my tweets',
          'url': 'twitter.com/scarlet',
          'orderNumber': 1,
          'createdAt': '2021-09-07T12:09:13.096Z',
          'updatedAt': '2021-09-07T12:09:13.833Z',
        },
        {
          'id': null,
          'type': 'facebook',
          'title': 'Latest Facebook Video',
          'url': 'facebook.com/scarlet',
          'orderNumber': 2,
          'createdAt': '2021-09-07T12:09:13.096Z',
          'updatedAt': '2021-09-07T12:09:13.833Z',
        },
      ],
    },
    {
      'id': null,
      'type': 'video',
      'title': 'Featured Videos',
      'titlePlaceholder': 'Featured Videos',
      'subtitle': '',
      'subtitlePlaceholder': '',
      'orderingNumber': '3',
      'linksInSection': 0,
      'isVisible': true,
      'createdAt': '2021-09-06T14:46:49.269Z',
      'updatedAt': '2021-09-06T14:46:49.278Z',
      'linksSectionItems': [],
    },
    {
      'id': null,
      'type': 'products',
      'title': 'Products I love',
      'titlePlaceholder': 'Products I love',
      'subtitle': 'All your social media Links will be here',
      'subtitlePlaceholder': 'All your social media Links will be here',
      'orderingNumber': '1',
      'linksInSection': 0,
      'isVisible': true,
      'createdAt': '2021-09-06T14:46:49.211Z',
      'updatedAt': '2021-09-06T14:46:49.278Z',
      'linksSectionItems': [],
    },
    {
      'id': null,
      'type': 'music',
      'title': 'My Music is found on',
      'titlePlaceholder': 'My Music is found on',
      'subtitle': 'All your social media Links will be here',
      'subtitlePlaceholder': 'All your social media Links will be here',
      'orderingNumber': '2',
      'linksInSection': 0,
      'isVisible': true,
      'createdAt': '2021-09-06T14:46:49.265Z',
      'updatedAt': '2021-09-06T14:46:49.278Z',
      'linksSectionItems': [],
    },
    {
      'id': null,
      'type': 'podcast',
      'title': 'Add Podcast Links',
      'titlePlaceholder': 'Add Podcast Links',
      'subtitle': 'All your Podcasts will be here',
      'subtitlePlaceholder': 'All your Podcasts will be here',
      'orderingNumber': '5',
      'linksInSection': 0,
      'isVisible': true,
      'createdAt': '2021-09-06T14:46:49.275Z',
      'updatedAt': '2021-09-06T14:46:49.278Z',
      'linksSectionItems': [],
    },
  ],
  'avatar': null,
  'themeBackgroundImage': null,
};
