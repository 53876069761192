import React, {useRef} from 'react';
import styles from './AutoSizeInput.module.scss';
import pencil from '../../assets/images/pencil.svg';
import ContentEditable from 'react-contenteditable';
import classNames from 'classnames';

interface IAutoSizeInput {
    value: string;
    onChange: (e: string)=>void;
    onClick?: (e: any)=>void;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
}

const AutoSizeInput = ({value, onChange, onClick, disabled, className, placeholder,
}:IAutoSizeInput)=>{
  const inputRef = useRef() as any;
  return (
    <ContentEditable
      ref={inputRef}
      html={value}
      disabled={disabled}
      placeholder= {placeholder}
      className={styles.autoSizeInput + ' ' + className}
      style={(!value? {display: 'block'} : {})}
      onClick={onClick}
      onChange={(e) => {
        onChange(e.currentTarget.innerText);
      }}
    ></ContentEditable>);
};

export default AutoSizeInput;
