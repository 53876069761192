import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';
import BaseModal from '../components/BaseModal';
import DeleteButton from '../components/DeleteButton';
import EditableImageInput from '../components/EditableImageInput';
import EditableTextInput from '../components/EditableTextInput';
import SaveButton from '../components/SaveButton';
import ToggleButton from '../components/ToggleButton';
import LinkModal, {LinkModalProps} from '../LinkModal';
import currencies from '../../../constants/currencies';
import Select from '../components/Select';
import {MonetizeType, initialLink} from './types';
import PriceBreakdown from '../components/PriceBreakdown';
import PriceInput, {useMonetizePrice} from '../components/PriceInput';
import Alert from '@material-ui/lab/Alert';

const DEFAULT_CURRENCY = 'usd';

const VideoMsgLinkModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();

  const {validate: validatePrice} = useMonetizePrice(link.currency, link.type as MonetizeType);

  const handleLinkBreakdown = (index: number, data: any) => {
    setLink((prevState) => {
      const linkCopy = JSON.parse(JSON.stringify(prevState));
      linkCopy.price[index] = {...linkCopy.price[index], ...data};
      return linkCopy;
    });
  };

  useEffect(() => {
    setLink((prevState) => ({
      ...prevState,
      ...defaultLink,
      currency: defaultLink.currency || DEFAULT_CURRENCY,
      isMonitize: true,
    }));
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length <= 50) {
      setLink((prevState) => ({
        ...prevState,
        title,
      }));
    }
  };

  const isValid = useMemo(() => {
    return (
      link.title?.length &&
      !validatePrice(link.price) &&
      link.currency &&
      link?.price?.every((el: any) => el?.amount)
    );
  }, [link]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t('add_title')}
        disabled={!editable}
      />

      {!editable && (
        <Alert style={{fontWeight: 500, fontSize: '1rem'}} severity="warning">
          {t('monetization_is_disabled_for_your_account')}
        </Alert>
      )}

      <EditableImageInput
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(customUploadedImage) =>
          setLink((prevState) => ({
            ...prevState,
            customUploadedImage,
          }))
        }
        editable={editable}
      />

      <Select
        value={link.currency}
        onChange={(e) =>
          setLink((prevState) => ({
            ...prevState,
            currency: e.target.value as string,
          }))
        }
        disabled={!editable}
        label={t('currency')}
      >
        {Object.keys(currencies).map((key) => (
          <Select.Item key={key} value={key}>
            {key.toUpperCase()}
          </Select.Item>
        ))}
      </Select>

      <PriceInput
        link={link}
        onChange={(amount) => handleLinkBreakdown(0, {amount})}
        editable={editable}
      />

      <LinkModal.EditControlsContainer>
        <ToggleButton
          checked={link.isVisible}
          onChange={(checked) =>
            setLink((prevState) => ({
              ...prevState,
              isVisible: checked,
            }))
          }
          label={t('visibility')}
        />

        {mode === 'edit' && <DeleteButton onClick={() => onDelete(link.id)}>{t('delete')}</DeleteButton>}
      </LinkModal.EditControlsContainer>

      <PriceBreakdown link={link} />

      <SaveButton disabled={!isValid} onClick={() => onSave(link)}>
        {t('save')}
      </SaveButton>
    </BaseModal>
  );
};

export default VideoMsgLinkModal;
