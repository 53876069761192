import React from 'react';
import {Tab} from '@material-ui/core';
import useWindowWidth from '../../../hooks/useWindowWidth';
import style from '../Public.module.scss';

interface IPublicTabProps {
  styles: any;
  theme: any;
  label: string;
  value: string;
  selectedTab: string;
  onClick: (value: string) => void;
  tabStyles?: React.CSSProperties;
  iconStyles?: React.CSSProperties;
  isMobile?: boolean;
  selectedStyle: string;
  profileBoxTextColor: string;
  tabsColor: string;
}

const PublicTab = ({
  styles,
  theme,
  label,
  value,
  selectedTab,
  onClick,
  tabStyles = {},
  selectedStyle,
  profileBoxTextColor,
  tabsColor,
}: IPublicTabProps) => {
  const isActive = selectedTab === value;
  const widthScreen = useWindowWidth();
  return (
    <Tab
      value={value}
      label={
        isActive ? (
          <span className={style.tab_style}
            style={{
              color: theme.backgroundColor,
              backgroundColor: tabsColor,
              fontFamily: theme.englishFont,
            }}
          >
            {label}
          </span>
        ) : (
          widthScreen > 350 && (
            <span
              className={style.tab_style}
              style={{
                fontFamily: theme.englishFont,
              }}
            >
              {label}
            </span>
          )
        )
      }
      onClick={() => onClick(value)}
      style={{
        ...tabStyles,
        color: tabsColor,
      }}
      className={`${styles.tab_name} ${isActive ? styles.activeTab : ''}`}
    />
  );
};

export default PublicTab;
