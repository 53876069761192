export const SECTION_NAMES = {
  PROFILE: 'Profile',
  ICONS: 'Icons',
  MYLINKS: 'MyLinks',
  VIDEOS: 'Videos',
};
export const DEFAULT_OPTIONS = [
  {
    section: SECTION_NAMES.PROFILE,
    valid: true,
    saveStatus: true,
  },
  {
    section: SECTION_NAMES.ICONS,
    valid: true,
    saveStatus: true,
  },
  {
    section: SECTION_NAMES.MYLINKS,
    valid: true,
    saveStatus: true,
  },
  {
    section: SECTION_NAMES.VIDEOS,
    valid: true,
    saveStatus: true,
  },
];
