import React, {
    useEffect,
    useState,
  } from 'react';
  import moment, {Moment} from 'moment';
  import useRequest from '../../../../features/API/request';
  import styles from './Followers.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";
  import FollowersChart from '../../../Charts/FollowersChart';
import {numberFollowerFormat} from '../../../../Helper/generalHelper';
  
  const Followers = ({updateStats}: {updateStats?: (value: any) => void}) => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [growthPercentage, setGrowthPercentage] = useState<number|undefined>(undefined);
    const [growthCount, setGrowthCount] = useState<number>(0);
    const [stackedData, setStackedData]= useState<{stackValues: {name: string, data: number[]}[], stackLabels: string[]}>({stackValues: [], stackLabels: []});
    const stackRequest = useRequest({method: 'GET', url: '/LinksFollowersAnalytics/followers'});
  
    const getPercentageChange = (a: number, b: number) => {
      return Number((a-b)/b)*100;
    };

    const getFollowersAnalyticsData = async () => {
      try {
        const params = {
          startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
        };
        const stackResult: {[key: string]: {count: string, date: string}[]} = await stackRequest.doRequest(params);
        if (stackResult) {
            const keys: string[] = Object.keys(stackResult); // Seperate keys for x-axis 
            const followerArray: number[] = [];
            Object.values(stackResult).map((ele: {count: string, date: string}[]) => {
                followerArray.push(parseInt(ele[0].count));
            });
            const stackValues: {name: string, data: number[]}[] = [{name: 'Followers', data: followerArray}] || [];
            setGrowthPercentage((followerArray.length > 0 && followerArray[0] > 0) ? 
              getPercentageChange(followerArray[followerArray.length-1], followerArray[0]) : undefined);
            setGrowthCount((followerArray.length > 0) ? Number(followerArray[followerArray.length-1] - followerArray[0]) : 0);
            const stackLabels: string[] = keys || [];
            setStackedData({stackValues, stackLabels});
            if (moment(params?.endDate)?.diff(moment(params?.startDate), 'days') == 30 && updateStats) {
              if (followerArray.length > 1) {
                const count = followerArray[followerArray.length - 1] - followerArray[0];
                updateStats({followers: numberFollowerFormat(count) || '0'});
              } else if (followerArray.length) {
                updateStats({followers: numberFollowerFormat(followerArray[0]) || '0'});
              }
            }
            setData(true);
        } else {
          setData(true);
        }
      } catch (error) {
        setData(true);
        console.log('Error occured', error);
      }
    };
  
    useEffect(() => {
        if (value.length) {
          getFollowersAnalyticsData();    
        }
    }, [value]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
              <div className={styles.heading}>
                <p>{t('followers_over_time')}</p>
                <p className={styles.total}>{numberFollowerFormat(growthCount)} {growthPercentage != undefined ? `(${growthPercentage.toFixed(2)}%)` : ''}</p>
              </div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
  
            <div className={styles.chart_wrapper}>
              {data ? <FollowersChart values={stackedData.stackValues} labels={stackedData.stackLabels} /> :
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                  <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                </div>
                <FollowersChart values={[]} labels={[]} />
              </div>
              }
            </div>
          </div>
        </div>
    );
  };
export default Followers;
