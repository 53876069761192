import axios from 'axios';
import React, {useEffect, useState} from 'react';
import styles from './FeaturedVideoItem.module.scss';
import moveIcon from '../../assets/images/al_move_icon.svg';
import trashIcon from '../../assets/images/al_delete_purple.svg';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {LinksSectionItem} from '../../features/stores/linksUserSlicer';

interface IProps{
  link: LinksSectionItem;
  onDeleteClick: (arg: string) => void;
}

const FeaturedVideoItem = ({link, onDeleteClick}: IProps) => {
  const [videoThumnbail, setVideoThumbnail] = useState(link.videoThumbnail);

  useEffect(() => {
    // this is legacy support for links that are already in DB but dont have a thumbnail saved
    if (!videoThumnbail) {
      const videoUrl = `https://www.youtube.com/oembed?url=${link.url}&maxwidth=0&maxheight=0&nowrap=on`;
      axios.get(videoUrl).then((res) => {
        setVideoThumbnail(res.data.thumbnail_url);
      }).catch((e) => {
        console.log(e);
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.trashContainer}>
          <img src={trashIcon} className={styles.trash} onClick={() => onDeleteClick(link.id.toString())}/>
        </div>
        <img src={videoThumnbail!}/>
      </div>

      <div className={styles.title_container}>
        {link.channelProfileIcon && <img src={link.channelProfileIcon} className={styles.channel_icon}/>}
        <div className={styles.title}>
          {link.title}
        </div>
      </div>

      <div className={styles.move_wrapper}>
        <img src={moveIcon} />
      </div>
    </div>
  );
};

export default FeaturedVideoItem;
