import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./CreatorProfile.module.scss";
import {contentOptions, creatorTypeOptions, contentOptionsProd, creatorTypeOptionsProd, contentOptionsNewOnBoardingProd, contentOptionsNewOnBoarding, MAX_CONTENT_CATEGORY_SELECTION} from "../../constants/helper";
import {useSelector} from "react-redux";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {store} from "../../app/store";
import {Checkbox} from "@material-ui/core";
import TextFieldInput from "../TextFieldInput/TextFieldInput";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import PhoneNumber from "../PhoneNumber/PhoneNumber";
import SelectGender from "../SelectGender/SelectGender";
import {isProdSite} from "../../Helper/generalHelper";
import {MAX_BIO_CHARACTERS} from '../../constants/helper';
import {toast} from "react-toastify";

const CreatorProfile: React.FC = ()=> {
  const {t, i18n} = useTranslation();
  const isProd = isProdSite();
  const linksUser = useSelector(selectLinksUser);
  const showBioAndName = window.location.pathname !== '/profile/setting/personal-information' && window.location.pathname !== '/profile/settings' && window.location.pathname !== '/profile/settings/*';
  const creatorTypeOptionsLocal = isProd ? creatorTypeOptionsProd : creatorTypeOptions;
  const contentOptionsLocal = isProd ? contentOptionsNewOnBoardingProd : contentOptionsNewOnBoarding;
  const inactiveColor = '#AA9C9F';
  const activeColor = '#000';

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newBio = e.target.value;
    const truncatedBio = newBio.slice(0, MAX_BIO_CHARACTERS); // Truncate the text if it exceeds 200 characters
    store.dispatch(setLinksUser({...linksUser, bio: truncatedBio}));
    store.dispatch(setDataChange(true));
  };

  const handleSelect = (value: string, param: string) => {
    const dataCopy = JSON.parse(JSON.stringify(param == 'categoryIds' ? linksUser?.categoryIds : linksUser?.contentCategoryIds));
    if (dataCopy?.length >= MAX_CONTENT_CATEGORY_SELECTION && !dataCopy.includes(value)) {
      toast(<span style={{color: 'black'}}>{t('you_can_select_max_three_superpower')}</span>, {
        autoClose: 5000,
      });
      return;
    }
    store.dispatch(setLinksUser({...linksUser, [param]: dataCopy.includes(value) ? dataCopy.filter((e: string) => e != value) : [...dataCopy, value]}));
    store.dispatch(setDataChange(true));
  };

  return (
    <div className={showBioAndName ? '' : styles.profile_body}>
      <div className={styles.margin_28_top}>
        <div className={styles.heading}>{t('i_make_content_related_to')}</div>
        <div className={styles.content_realted}>
          {contentOptionsLocal.map((e) => <div onClick={() => handleSelect(e?.id, 'contentCategoryIds')} key={e?.id} className={`${styles.content_item} ${linksUser?.contentCategoryIds?.includes(e?.id) ? styles.active : null}`}>
            <div className={styles.content_text}>
              <e.icon fill={linksUser?.contentCategoryIds?.includes(e?.id) ? activeColor : inactiveColor} />
              {e?.name}
            </div>
            <div>
              <Checkbox style ={{color: linksUser?.contentCategoryIds?.includes(e?.id) ? '#6F65FF' : '#CED0D3', padding: '0'}} checked={linksUser?.contentCategoryIds?.includes(e?.id)} name="withdrawCheck" />
            </div>
          </div>)}
        </div>
      </div>
      {showBioAndName && <>
        <div className={styles.margin_28_top}>
          <div className={styles.heading}>{t('display_name_label')}</div>
          <TextFieldInput
            placeholder={t('display_name_placeholder')}
            value={linksUser.fullname as string}
            customStyle={{height: '56px', padding: '0px 12px'}}
            onChange={(e: any) => {
              store.dispatch(setLinksUser({...linksUser, fullname: e.target.value}));
              store.dispatch(setDataChange(true));
            }}
          />
        </div>
        <div className={styles.margin_20_top}>
          <div className={styles.heading}>{t('bio')}</div>
          <div>
            <textarea
              className={styles.biography}
              rows={6}
              onChange={handleBioChange}
              value={linksUser.bio as string}
              maxLength={MAX_BIO_CHARACTERS} // Set the maxLength attribute for the textarea
              placeholder={t('bio_placeholder')}
            ></textarea>
            <p className={styles.characterCounter}>{`${(linksUser.bio || '').length}/${MAX_BIO_CHARACTERS}`}</p>
          </div>
        </div>
      </>}
      {!showBioAndName && <>
        <div className={styles.margin_28_top}>
          <div className={`${styles.heading} ${styles.margin_12_bottom}`}>{t('your_phone_number')}</div>
          <div>
            <PhoneNumber value={linksUser?.phone} country={'ae'} onChange={(v, c: {dialCode: string}, e) => {
              const phone = v[0] == '+' ? v.replace(/ /g, '') : '+' + v.replace(/ /g, '');
              const dialCode = '+' + c?.dialCode;
              let value = '';
              if (phone.substring(0, dialCode?.length) == dialCode) {
                value = phone;
              } else {
                value = `${dialCode}${v}`;
              }
              store.dispatch(setLinksUser({...linksUser, phone: value}));
              store.dispatch(setDataChange(true));
            }} />
          </div></div>
        <div className={styles.margin_28_top}>
          <div className={`${styles.heading} ${styles.margin_12_bottom}`}>{t('gender')}</div>
          <div>
            <SelectGender value={linksUser?.gender || ''} handleChange={(e: string)=>{
              store.dispatch(setLinksUser({...linksUser, gender: e}));
              store.dispatch(setDataChange(true));
            }} />
          </div></div><br /></>}
    </div>
  );
};

export default CreatorProfile;
