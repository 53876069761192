import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './TrackingLinksPage.module.scss';
import DealsContainer from '../../components/Deals/DealsContainer';
import TrackingLinksTable from '../../components/TrackingLinks/TrackingLinksTable';
import AddTrackingLinkModal from '../../components/AddTrackingLinkModal/AddTrackingLinkModal';
import nonHookRequest from "../../features/API/nonHookRequest";
import {PlusCircleFilled} from '@ant-design/icons';
import {ButtonCustom} from '../../components/ImportantLinks/ImportantLinks';
import useWindowWidth from '../../hooks/useWindowWidth';
import {themeColor} from '../../constants/newThemes';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import {deal} from '../../features/stores/dealsSlicer';
import {useDebouncedCallback} from "use-debounce";
import Loader from 'react-loader-spinner';

export interface TrackingLinks {
  id: string;
  userDealId: string;
  productTrackingLink: string;
  description: string;
  visible: boolean;
  logoUrl: string;
  createdAt: string;
  updatedAt: string;
  userDeal: {
    id: string;
    deal: deal;
  }
}

const TrackingLinksPage: React.FC = () => {
  const [openTrackingLinkModal, setOpenTrackingLinkModal] = useState(false);
  const [trackingLinks, setTrackingLinks] = useState<TrackingLinks[]>([]);
  const [trackingLinksTotalCount, setTrackingLinksTotalCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const keywordRef = useRef("");
  const mobile = useWindowWidth();

  const perPage = 10;
  const isInitialEmptyState = !trackingLinks?.length && !keywordRef;

  useEffect(() => {
    getTrackingLinks();
  }, [currPage]);

  const getTrackingLinks = async () => {
    const start = (currPage - 1) * perPage;
    const trackingLinks = await nonHookRequest({
        method: 'GET',
        url: `/brandshub/api/user-deal/trackinglink?start=${start}&limit=${perPage}&sortOrder=${sortOrder}&sortField=createdAt&searchKey=${keyword}`,
        isShortUrl: true,
    });
    setTrackingLinks(trackingLinks?.data);
    setTrackingLinksTotalCount(parseInt(trackingLinks?.total));
    setLoading(false);
  };

  const AddTrackingLinkButton = () => {
    return (
      <ButtonCustom
        onClick={() => setOpenTrackingLinkModal(true)}
        className={styles.custom_btn}
        variant="contained"
        size={mobile > 1024 ? 'large' : 'default'}
        colortext={themeColor.btnColor}
        background={themeColor.btnBackground}
        startIcon={<PlusCircleFilled />}
      >
        <div>{t('add_tracking_link')}</div>
      </ButtonCustom>
    );
  };

  const handleSearch = useDebouncedCallback((e: any) => {
    keywordRef.current = keyword;
    getTrackingLinks();
  }, 1000);

  return (
    <DealsContainer screen="trackingLinks">
      <div className={styles.container}>
        <h1 className={styles.heading}>{t('tracking_links')}</h1>
        <p className={styles.subHeading}>{t('create_manage_share')}</p>

        {!isInitialEmptyState ?
        <>
          <AddTrackingLinkButton />
          <div>
            <OutlinedInput
              className={styles.search}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                handleSearch(e);
              }}
              startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
              placeholder={t('search')}
            />
          </div> 
        </> : null}

        {loading ? 
          <div className={styles.loader}>
            <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
          </div> :
          <TrackingLinksTable 
            trackingLinks={trackingLinks} 
            trackingLinksTotalCount={trackingLinksTotalCount}
            getTrackingLinks={getTrackingLinks}
            currPage={currPage}
            setCurrPage={setCurrPage}
            perPage={perPage}
            isSearching={!!keywordRef}
            AddTrackingLinkButton={AddTrackingLinkButton}
          />
        }
      </div>
      <AddTrackingLinkModal
        getTrackingLinks={getTrackingLinks}
        setCurrPage={setCurrPage}
        open={openTrackingLinkModal}
        onClose={() => {
          setOpenTrackingLinkModal(false);
        }}
        PaperProps={{
          style: {
            width: '80%',
            maxWidth: '900px',
            height: '700px',
          },
        }}
      />
    </DealsContainer>
  );
};

export default TrackingLinksPage;
