import React, {useEffect} from 'react';
import Loader from 'react-loader-spinner';
import jwtDecode from "jwt-decode";
import styles from './NativeLogin.module.scss';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';

const NativeLogin = () => {
  const handleCognitoSession = (postData: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryRedirectValue = searchParams?.get('url')?.toLowerCase();
    const decodeValues: any = jwtDecode(postData['JWTToken']);
    const socialLoginParams = decodeValues?.identities?.filter((e: any) => ['Google', 'Facebook'].includes(e?.providerName)) || [];
    const socialTokenAvailable = socialLoginParams?.length;
    let authUserName = '';
    if (socialTokenAvailable) {
      authUserName = decodeValues['cognito:username'];
    } else {
      authUserName = decodeValues['email'];
    }
    if (postData['refreshToken'] && postData['JWTToken'] && authUserName) {
      localStorage.setItem(`CognitoIdentityServiceProvider.${envConfig().userPoolWebClientId}.${authUserName}.accessToken`, 'accesstoken');
      localStorage.setItem(`CognitoIdentityServiceProvider.${envConfig().userPoolWebClientId}.${authUserName}.refreshToken`, postData['refreshToken']);
      localStorage.setItem(`CognitoIdentityServiceProvider.${envConfig().userPoolWebClientId}.${authUserName}.idToken`, postData['JWTToken']);
      localStorage.setItem(`CognitoIdentityServiceProvider.${envConfig().userPoolWebClientId}.LastAuthUser`, authUserName);
      localStorage.setItem(`token`, postData['JWTToken']);
      if (queryRedirectValue) {
        window.location.href = queryRedirectValue;
      } else {
        window.location.href = window.location.origin;
      }
    }
  };
  useEffect(() => {
    const messageListener = window.addEventListener('message', (nativeEvent) => {
      const jsonObj = JSON.parse(nativeEvent?.data);
      handleCognitoSession(jsonObj);
    }, true);
    return messageListener;
  }, []);
  return (
    <>
      <div className={styles.loader}><Loader type="TailSpin" color="#FFFFFF" height={80} width={80} /></div>
    </>
  );
};

export default NativeLogin;
