import React from 'react';
import styles from './AdvanceSettings.module.scss';
import {Route, Switch} from 'react-router';
import {useTranslation} from 'react-i18next';
import {isProdSite} from '../../Helper/generalHelper';
import Contracts from './Contracts/Contracts';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {selectMe} from '../../features/stores/userSlicer';
import UpdatePassword from './UpdatePassword/UpdatePassword';
import PersonalInformation from './PersonalInformation/PersonalInformation';


const AdvanceSettings = () => {
  const me = selectMe(useAppSelector(store.getState)).me;
  const {t} = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
      </div>
      <div className={styles.content_container}>
        <Switch>
          <Route exact path="/profile/setting" component={UpdatePassword} />
          {me?.inNetwork && !!!isProdSite() && <Route exact path="/profile/setting/contract" component={Contracts} />}
          <Route exact path="/profile/setting/update-password" component={UpdatePassword} />
          <Route exact path="/profile/setting/personal-information" component={PersonalInformation} />
          <Route exact path="/profile/setting/*" component={UpdatePassword} />
        </Switch>
      </div>
    </div>
  );
};

export default AdvanceSettings;
