import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './PlatformSwitcher.module.scss';
import Alfan from './../../assets/images/Alfan.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {haveSegmentPermission} from '../../features/util';
import {useSelector} from 'react-redux';
import {getConfig} from '../../features/stores/configSlicer';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {useTranslation} from 'react-i18next';

const switchOptions = [{
  key: 'mediakit',
  url: `${window?.location?.origin}/mediakit/profile/my-profile`,
  text: 'media kit',
}, {
  key: 'earnings',
  url: `${window?.location?.origin}/earnings/dashboard`,
  text: 'earnings',
}];

const PlatformSwitcher = () => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const config = useSelector(getConfig).data || [];
  const user = selectMe(useAppSelector(store.getState));
  const medikitProfileExist = !!user?.me?.mediakitUser?.id;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const havePermision = haveSegmentPermission(config, 'monetize') && !haveSegmentPermission(config, 'monetizeBlocklist');
  let finalSwitchOptions = switchOptions;
  finalSwitchOptions = havePermision ? finalSwitchOptions : finalSwitchOptions.filter((e) => e.key != 'earnings');

  const handleToggle = () => {
    if (finalSwitchOptions?.length) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleOpenUrl = (url: string) => {
    window.location.href = url;
  };

  const handleClose = (event: Event | React.SyntheticEvent | any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
      <div>
        <span className={styles.logo_container} ref={anchorRef} id="composition-button" aria-controls={open ? 'composition-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleToggle}>
              <div className={styles.logo}>
                <div className={styles.logo_row_one}>
                  <img src={Alfan} height={'30px'} style={{width: (finalSwitchOptions?.length ? '' : 'auto')}} />
                </div>
                <div className={styles.logo_row_two}>
                    <div className={styles.title}>alfan</div>
                    <div className={styles.sub_title}>link</div>
                </div>
              </div>
        {finalSwitchOptions?.length ? <ExpandMoreIcon /> : null}
        </span>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{zIndex: 5, width: '200px'}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {finalSwitchOptions.map((e) => (
                        <MenuItem key={e.key} style={{background: 'white'}} onClick={() => handleOpenUrl(e?.url)}><div>
                        {/* <a href={e.url}><img src={e.imgSrc} style={{height: '30px'}} /></a> */}
                        <div className={`${styles.logo} ${styles.list}`}>
                            <div className={styles.logo_row_one}>
                                <img src={Alfan} />
                            </div>
                            <div className={styles.logo_row_two}>
                                <div className={styles.title}>alfan</div>
                                <div className={styles.sub_title}>{e.text}</div>
                            </div>
                            {(!medikitProfileExist && e?.key == 'mediakit') ? <div className={styles.create}>{t('create')}</div> : null}
                        </div>
                    </div></MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
  );
};

export default PlatformSwitcher;
