import React, {useRef, useState, useEffect} from 'react';
import {CircularProgress} from '@material-ui/core';
import {addLinkIcons} from '../../../constants/socialMedia';
import {ReactComponent as PencilIcon} from '../../../assets/images/edit_pencil.svg';
import Compressor from 'compressorjs';
import styles from './EditableImageInput.module.scss';
import {useUploadRequest} from '../../../features/API/request';
import {toast} from 'react-toastify';

const MAX_SIZE_MB = 5; // 5 MB max size
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert to bytes

type Attachment = {
  id: string;
  url: string;
  name: string;
};

export type EditableImageInputGuideProps = {
  value: string;
  type: string;
  onChange: (value: string) => void;
  onUploadComplete?: (file: Attachment) => void; // Callback for when the upload is complete
  onLinkIconUpload?: (url: string) => void; // Callback for when the link icon upload is complete
  editable: boolean;
  id: string;
};

const EditableImageInputGuide = ({
  value: customImage,
  onChange,
  type,
  onUploadComplete,
  onLinkIconUpload,
  editable = true,
}: EditableImageInputGuideProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadFileRequest = useUploadRequest({method: 'POST', url: '/linksFile/upload'});

  const getImage = () => {
    if (customImage) {
      return <img className={styles.linkIcon} src={customImage} alt="Link Icon" />;
    }

    const LinkIcon = addLinkIcons[type] || addLinkIcons['regularLink'];
    return <LinkIcon className={styles.linkIcon} />;
  };

  const fileSelectedHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_SIZE_BYTES) {
        toast.error(`File size should be less than ${MAX_SIZE_MB} MB`);
        return;
      }

      // Upload the original image for cover
      await uploadOriginalImage(file);

      // Resize the image for the link icon
      resizeAndSetLinkIcon(file);
    }
  };

  const handleUploadThumbnail = () => {
    inputRef.current?.click();
  };

  const uploadOriginalImage = async (file: File) => {
    setIsUploading(true);
    try {
      const [uploadedFile] = await uploadFileRequest.doRequest({
        file: {
          key: 'file',
          value: file,
          fileName: file.name,
        },
      });
      if (onUploadComplete) {
        onUploadComplete(uploadedFile);
      }
    } catch (error) {
      toast('Cover image upload failed', {type: 'error'});
    } finally {
      setIsUploading(false);
    }
  };

  const resizeAndSetLinkIcon = (file: File) => {
    new Compressor(file, {
      quality: 0.8,
      maxWidth: 100,
      maxHeight: 100,
      success: async (compressedBlob) => {
        // Convert Blob to File
        const compressedFile = new File([compressedBlob], file.name, {
          type: compressedBlob.type,
          lastModified: Date.now(),
        });

        // Convert to a data URL and set it as the link icon
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          onChange(imageUrl); // Update link icon with the resized image

          // Optionally upload the resized image for storage
          uploadResizedImage(compressedFile);
        };
        reader.readAsDataURL(compressedFile);
      },
      error: (err) => {
        console.error('Compression error:', err);
      },
    });
  };

  const uploadResizedImage = async (file: File) => {
    try {
      const [uploadedFile] = await uploadFileRequest.doRequest({
        file: {
          key: 'file',
          value: file,
          fileName: file.name,
        },
      });
      if (onLinkIconUpload) {
        onLinkIconUpload(uploadedFile.url);
      }
    } catch (error) {
      toast('Link icon upload failed', {type: 'error'});
    }
  };

  return (
    <div>
      <div>
        <input
          ref={inputRef}
          style={{display: 'none'}}
          type="file"
          onChange={fileSelectedHandler}
          disabled={!editable || isUploading}
        />
        <div className={styles.imageContainer}>
          {isUploading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {getImage()}
              {editable && (
                <div className={styles.imageEditIcon}>
                  <PencilIcon fill="white" className={styles.pencilIcon} width={18} onClick={handleUploadThumbnail} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableImageInputGuide;
