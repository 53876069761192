import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Divider, Switch, Tooltip} from 'antd';
import styles from './DealItem.module.scss';
import trashIcon from '../../assets/images/al_delete_purple.svg';
import {deal, dealItem} from '../../features/stores/dealsSlicer';
import Linkify from 'react-linkify';
import {dealsConst, commissionType} from '../../constants/helper';

interface IProps {
  deal: deal;
  dealItem?: dealItem;
  handleDealApply: (arg: string) => void;
  activeId: string;
  status: string;
  handleVisibilityToggle?: (id: string, currentVisbility: boolean) => void;
  handleEditDealCollection?: (id: string) => void;
  handleShowCoupons?: (id: string) => void;
}

const DealItem = ({
  deal,
  dealItem,
  handleDealApply,
  activeId,
  status,
  handleVisibilityToggle,
  handleEditDealCollection,
  handleShowCoupons,
}: IProps) => {
  const {t} = useTranslation();
  const handleShowMoreLess = (idMore: any, idLess: any, param: any) => {
    const more: any = document.getElementById(idMore);
    const less: any = document.getElementById(idLess);
    if (more && less) {
      if (param === 'more') {
        more.style.display = 'none';
        less.style.display = 'block';
      } else {
        more.style.display = 'block';
        less.style.display = 'none';
      }
    }
  };

  const getCollapseText = (
      title: string,
      id: string,
      param: string,
      trimLength: number = 60,
  ) => {
    return title?.length > trimLength ? (
      <>
        <div
          id={`show-more-${param}-${id}`}
          onClick={(e) => {
            e.stopPropagation();
            handleShowMoreLess(
                `show-more-${param}-${id}`,
                `show-less-${param}-${id}`,
                'more',
            );
          }}
        >
          {title?.substring(0, trimLength)}...
        </div>
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
          <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
            {decoratedText}
          </a>
        )}><div
          style={{display: 'none'}}
          id={`show-less-${param}-${id}`}
          onClick={(e) => {
            e.stopPropagation();
            handleShowMoreLess(
                `show-more-${param}-${id}`,
                `show-less-${param}-${id}`,
                'less',
            );
          }}
        >
          {title}
        </div>
      </Linkify>
      </>
    ) : (
      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
        <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
          {decoratedText}
        </a>
      )}>{title || '--'}</Linkify>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div>
          <img src={deal?.logoUrl} />
        </div>
        <Tooltip title={deal?.name}>
          <div className={styles.title}>
            {getCollapseText(
                deal?.name || '',
                deal?.id,
                'name-box',
                20,
            )}</div>
        </Tooltip>
      </div>
      {deal?.description?.length > 0 && (
        <div className={styles.middle}>
          <Tooltip title={deal?.description} className={styles.description}>
            <div className={styles.row_value}>
              {getCollapseText(
                  deal?.description || '',
                  deal?.id,
                  'title-box',
                  60,
              )}
            </div>
          </Tooltip>
        </div>
      )}
      <Divider className={styles.deal_divider}/>
      <div className={styles.middle}>
        <div className={styles.row}>
          <div className={styles.row_title}>{t('commission')}</div>
          <div className={styles.row_value}>
            <p className={styles.small_hint}>{t('up_to')}</p>
            {parseInt(deal?.commission)}
            {deal?.commissionType === commissionType.ABSOLUTE ? ` ${deal?.currency.toUpperCase()}` : '%'}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.row_title}>{t('payment_term')}</div>
          <div className={styles.row_value}>
            {deal?.paymentTerm} {t('days')}
          </div>
        </div>
        {status == dealsConst.AVAIALBLE ? (
        <div
          className={`${styles.bottom} ${
            activeId == dealItem?.id ?
              styles.load :
              activeId?.length ?
              styles.disabled :
              ''
          }`}
          onClick={() => handleDealApply(dealItem?.id || '')}
        >
          <div>{t(activeId == dealItem?.id ? 'applying' : 'apply')}</div>
        </div>
      ) : (
        <div className={styles.title_pencil_wrapper}>
          {handleVisibilityToggle && <><div className={styles.row_title}>{t('visibility')}</div>
          {dealItem && (
            <Switch
              title={dealItem?.userDealContents?.length! > 0 ? '' : t('add_min_one_content')}
              disabled={dealItem?.userDealContents?.length! < 1}
              className={styles.switch}
              checked={dealItem?.visible}
              onChange={() => {
                handleVisibilityToggle &&
                  handleVisibilityToggle(dealItem?.id || '', dealItem?.visible);
              }}
            />
          )}</>}
        </div>
      )}
      <br></br>
            
      {status === dealsConst.APPROVED && dealItem?.deal?.affiliateMarketingPlatformType === "Everflow" && handleShowCoupons && <div
        className={`${styles.bottom}`}
        onClick={() => handleShowCoupons(dealItem?.id || '')}
      >
        <div>{t('preview_details')}</div>
      </div>}

      {status === dealsConst.APPROVED && dealItem?.deal?.affiliateMarketingPlatformType !== 'Everflow' && handleEditDealCollection && <div
        className={`${styles.bottom}`}
        onClick={() => handleEditDealCollection(dealItem?.id || '')}
      >
        <div>{t('edit_collections')}</div>
      </div>}
    </div>
    </div>
  );
};

export default DealItem;

