import React from 'react';
import LandingHeader from '../../components/LandingHeader/LandingHeader';
import './landingPageNew.scss';
import LandingSection from './LandingSection/LandingSection';
import CreatorsSection from './CreatorsSection/CreatorsSection';
import FeaturesSection from './FeaturesSection/FeaturesSection';
import LandingFooter from '../../components/LandingFooter/LandingFooter';
import {isArabic} from '../../features/util';
import {useTranslation} from 'react-i18next';

const LandingPageNew = () => {
  const {i18n} = useTranslation();
  const arabicFormat: boolean = isArabic(i18n.language);
  return (
    <div id="landingPage" className={arabicFormat ? 'siwa-font-ar' : 'lufga-font'}>
      <LandingHeader />
      <LandingSection />
      <CreatorsSection />
      <FeaturesSection />
      <LandingFooter />
      <div className="glows"></div>
    </div>
  );
};

export default LandingPageNew;
