import React, {ComponentProps, PropsWithChildren} from 'react';
import styles from './SaveButton.module.scss';

type Props = ComponentProps<'div'> & {
  disabled?: boolean;
};

const SaveButton = ({
  children,
  disabled = false,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={`${styles.saveBtn} ${disabled ? styles.disabled : ''}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default SaveButton;
