import 'react-hot-loader/patch';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {hydrate, render} from 'react-dom';
import './i18n';

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  hydrate( <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>, rootElement);
} else {
  render( <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>, rootElement);
}


