/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import {Button} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getPreferredLocale, setPreferredLocale} from '../../features/util';
import {useHistory} from 'react-router-dom';
import styles from './LanguagePicker.module.scss';
import globe from './../../assets/images/versionV2/HelperIcons/globe.svg';

interface ILanguagePicker {
  onChange: () => void;
  newUi?: boolean;
  color?: string;
  onBoardUi?: boolean;
}

const LanguagePicker = ({onChange, newUi, color, onBoardUi}: ILanguagePicker) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    const updatedLocale = getPreferredLocale() === 'ar' ? 'en' : 'ar';
    setPreferredLocale(updatedLocale);
    i18n.changeLanguage(updatedLocale);
    window.gtag('event', 'language_change', {
      event_category: 'language_change',
      event_label: updatedLocale,
    });
    const searchParams = new URLSearchParams(history.location.search);
    if (searchParams.has('lang')) {
      searchParams.set('lang', updatedLocale);
      history.replace({search: searchParams.toString()});
    }

    onChange();
    location.reload(); // TODO check if we can avoid this.
  };

  if (newUi) {
    return (
      <span className="nav-link" onClick={handleClick} aria-current="page" style={{display: 'flex', color: color ? color : ''}}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.33268 11L6.66602 13.0667H7.99935L7.33268 11Z" fill="#121212" />
          <path
            d="M20.1999 2H9.5332V5.33333H12.1999V6.66667H3.5332C2.39987 6.66667 1.5332 7.53333 1.5332 8.66667V16C1.5332 17.1333 2.39987 18 3.5332 18H4.19987V21.4L8.39987 18H12.8665V13.3333H20.1999C21.3332 13.3333 22.1999 12.4667 22.1999 11.3333V4C22.1999 2.86667 21.3332 2 20.1999 2ZM8.7332 15.2667L8.39987 14.2H6.3332L5.9332 15.2667H4.3332L6.5332 9.33333H8.1332L10.3332 15.2667H8.7332ZM18.8665 10V11.3333C17.9999 11.3333 17.0665 11.0667 16.2665 10.6667C15.4665 11.0667 14.5332 11.2667 13.5999 11.3333L13.5332 10C13.9999 10 14.4665 9.93333 14.9332 9.8C14.3332 9.2 13.9332 8.46667 13.7332 7.66667H15.1332C15.3332 8.26667 15.7332 8.73333 16.1999 9.13333C16.9332 8.53333 17.3999 7.66667 17.4665 6.66667H13.4665V5.33333H15.4665V4H16.7999V5.33333H18.9999L19.0665 6C19.1332 7.4 18.5999 8.8 17.5999 9.8C18.0665 9.93333 18.4665 10 18.8665 10Z"
            fill={color ? color : '#121212'}
          />
        </svg>
        {getPreferredLocale() === 'ar' ? t('english') : t('arabic')}
      </span>
    );
  } else if (onBoardUi) {
    return (<div className={styles.onbaord_picker} onClick={handleClick}>
      <div>{getPreferredLocale() === 'ar' ? t('english') : t('arabic')}</div>
      <img src={globe} />
    </div>);
  }

  return (
    <Button
      variant="outlined"
      style={{color: color ? 'white' : ''}}
      onClick={handleClick}
    >
      {getPreferredLocale() === 'ar' ? t('english') : t('arabic')}
    </Button>
  );
};

export default LanguagePicker;
