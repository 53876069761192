/* eslint-disable react/prop-types */
import React, {FC, useCallback} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import './RichTextEditor.scss';

type RichTextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  label?: string;
  error?: string;
  maxCharacters?: number;
};

const RichTextEditor: FC<RichTextEditorProps> = ({
  value,
  onChange,
  disabled = false,
  label,
  error,
  maxCharacters = 500,
}) => {
  const handleChange = useCallback(
      (content: string) => {
        const cleanedContent = DOMPurify.sanitize(content);
        const contentLength = cleanedContent.replace(/<[^>]*>/g, '').length;

        if (contentLength <= maxCharacters) {
          onChange(cleanedContent);
        }
      },
      [onChange, maxCharacters],
  );

  return (
    <div className="richTextEditorContainer">
      <ReactQuill
        value={value}
        onChange={handleChange}
        readOnly={disabled}
        modules={richTextEditorModules}
        formats={richTextEditorFormats}
        placeholder={label}
        theme="snow"
      />
      {error && <div className="error">{error}</div>}
      <div className="footer">
        <span className="characterCount">
          {value.replace(/<[^>]*>/g, '').length}/{maxCharacters} characters
        </span>
      </div>
    </div>
  );
};

// Define the modules and formats outside the component
const richTextEditorModules = {
  toolbar: [
    [{list: 'ordered'}, {list: 'bullet'}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{align: []}],
    ['clean'], // remove formatting button
  ],
};

const richTextEditorFormats = [
  'header', 'font',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'color', 'background',
  'align',
  'link',
];

export default RichTextEditor;
