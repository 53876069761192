import React, {useState} from 'react';
import './landingHeader.scss';
// Assets
import Logo from '../../assets/images/landing/LogoWhiteFont.svg';
import {Link} from 'react-router-dom';
import ShowNavIcon from '../../assets/images/landing/headerShowNav.svg';
import HideNavIcon from '../../assets/images/landing/headerHideNav.svg';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import {useTranslation} from 'react-i18next';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  const {t} = useTranslation();
  const redirectLinks = envConfig();

  const toggleNav = () => {
    setShowNav(!showNav);
  };
  return (
    <header id='landingHeader' className={showNav ? 'responsive' : ''}>
      <Link to="/" className="logo-container">
        <img src={Logo} alt="Alfan Link Logo" />
      </Link>

      <img
        className="menu-icon"
        src={showNav ? HideNavIcon : ShowNavIcon}
        alt={showNav ? 'Hide Navigation' : 'Show Navigation'}
        onClick={toggleNav}
      />

      <nav className={showNav ? 'responsive-nav' : 'hide'}>
        <ul>
          <li> <a href={`${redirectLinks.alfanGroupLink}/public/home`} rel="noreferrer">{t('home')}</a> </li>
          <li> <a href={`${redirectLinks.alfanGroupLink}/public/network`} rel="noreferrer">{t('network')} </a> </li>
          <li> <a href={`${redirectLinks.alfanGroupLink}/public/audio`} rel="noreferrer">{t('audio')} </a> </li>
          <li> <a href={`${redirectLinks.alfanGroupLink}/public/brands`} rel="noreferrer">{t('brands')} </a> </li>
          <li> <a href={`${redirectLinks.alfanGroupLink}/public/careers`} rel="noreferrer">{t('careers')} </a> </li>
        </ul>

        <div className="login-container hide">
          <span className="nav-item languagePicker">
            <LanguagePicker onChange={() => {}} newUi={true} color='#9E9E9E'></LanguagePicker>
          </span>
          <Link to="/public/login">
            <span className="login-btn"> {t('login')} </span>
          </Link>
          <Link to="/public/signup">
            <button className="signup-btn"> {t('get_start')} </button>
          </Link>
        </div>

        <p className="copyrights">
          Copyright © 2023 Alfan Group. All rights reserved
        </p>
        <div className="glows copyrights"></div>
      </nav>
    </header>
  );
};

export default Header;
