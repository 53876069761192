import React, {
    useEffect,
    useState,
  } from 'react';
  import moment from 'moment';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Pagination, Select, DatePicker} from "antd";
  import styles from './requestDashboard.module.scss';
  import RequestTable from '../../components/RequestTable/RequestTable';
  import OutlinedInput from "@material-ui/core/OutlinedInput";
  import InputAdornment from "@material-ui/core/InputAdornment";
  import SearchIcon from '@material-ui/icons/Search';
  import useRequest from '../../features/API/request';
  import {useDebouncedCallback} from "use-debounce";
  import Loader from 'react-loader-spinner';
  import {dateRangeOption, statusMap, statusOption} from '../../constants/helper';
  import {getDateFromRange} from '../../Helper/generalHelper';
  import NoTransaction from '../../assets/images/noTransaction.svg';
  import {useSelector} from 'react-redux';
  import {getConfig} from '../../features/stores/configSlicer';
  import {haveSegmentPermission} from '../../features/util';
  import TaskCompleteModal from '../../components/TaskCompleteModal/TaskCompleteModal';
  import InputLabel from "@material-ui/core/InputLabel";
  import MenuItem from "@material-ui/core/MenuItem";
  import FormControl from "@material-ui/core/FormControl";
  import ListSubheader from "@material-ui/core/ListSubheader";
  import {Select as MUSelect} from "@material-ui/core";
  import ProofModal from '../../components/ProofModal/ProofModal';
  import {getCurrencyLabel} from '../../constants/currencies';
  import FilterIcon from '../../assets/images/versionV2/RequestDashboard/filter_icon.svg';
  import ClickAwayListener from '@material-ui/core/ClickAwayListener';
  import CheckIcon from '@material-ui/icons/Check';
  import Chip from '@material-ui/core/Chip';
  import ClearIcon from '@material-ui/icons/Clear';
  import {makeStyles} from "@material-ui/core/styles";
  
  const {Option} = Select;
  const {RangePicker} = DatePicker;

  const useStyles = makeStyles((theme) => ({
    root: {
      'display': "flex",
      'flexWrap': "wrap",
      "& .MuiChip-deleteIcon": {
        'marginRight': "10px", // Change those values to yours
        'marginLeft': "10px", // Change those values to yours
      },
    },
  }));
  
  const RequestDashboard = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(12);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('createdAt-DESC');
    const [dates, setDates] = useState<any>([moment(getDateFromRange(dateRangeOption[0])[0]), moment(getDateFromRange(dateRangeOption[0])[1])]);
    const [requestList, setRequestList] = useState<any>([]);
    const [orderStatusCount, setOrderStatusCount] = useState<any>([]);
    const [metaRecord, setMetaRecord] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [customRange, setCustomRange] = useState(dateRangeOption[0]);
    const [openTaskCompleteModal, setOpenTaskCompleteModal] = useState(false);
    const [taskIDObj, setTaskIDObj] = useState({taskID: '', type: ''});
    const [filter, setFilter] = useState<any>([]);
    const [openProofModal, setOpenProofModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [modalObj, setModalObj] = useState({attachmentUrl: '', comments: ''});
    const getDashboardRequest = useRequest({method: 'GET', url: '/finance/api/orders', isShortUrl: true});
    const getMetaRequest = useRequest({method: 'GET', url: '/finance/api/orders/dashboard/summary', isShortUrl: true});
    const config = useSelector(getConfig).data || [];
    const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];

    const updatePagination = (page: any, sizePage: any) => {
      setCurrent(page);
      setSize(sizePage);
    };

    const handleSearch = useDebouncedCallback((e: any) => {
        fetchTransactions();
      }, 1000);

    const disabledDate = (current: any) => {
      return current && moment(current, 'YYYY-MM-DD').isAfter(moment().add(1, 'd').format('YYYY-MM-DD'));
    };

    const onCalendarChange = (dates: any, dateStrings: [string, string], info: any) => {
      if (dateStrings[0].length && dateStrings[1].length) {
        setDates(dateStrings);
        setCustomRange('');
      };
    };

    const handleCustomRange = (range: any) => {
      if (range == "Custom") {
        setCustomRange('');
        setDates([moment(dates[0]), moment(dates[1])]);
      } else {
        const [start, end] = getDateFromRange(range);
        setCustomRange(range);
        setDates([start, end]);
      }
    };

    const fetchMeta = async () => {
        try {
          setLoading(true);
          const params = {
            startDate: moment(`${moment(dates[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(`${moment(dates[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
            productType: 'Link',
          };
          const result = await getMetaRequest.doRequest(params);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log('Something went wrong!');
        }
      };

    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const params = {
          start: (current - 1) * size,
          limit: size,
          sortField: sort.split('-')[0],
          sortOrder: sort.split('-')[1],
          startDate: moment(`${moment(dates[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(`${moment(dates[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
          search: search ? search : undefined,
          filters: {
            status: filter?.length ? filter : undefined,
          },
          productType: 'Link',
        };
        const result = await getDashboardRequest.doRequest(params);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Something went wrong!');
      }
    };

    const updateFilter = (value: string) => {
      // if (e.target.value && e.target.value.every((evt: any) => evt != undefined)) {
      //   setFilter(e.target.value);
      // }
      if (filter.includes(value)) {
        setFilter(filter.filter((e: string) => e != value));
      } else {
        setFilter([...filter, value]);
      }
    };

    const getEarnedAmount = (currency = ['usd', 'aed']) => {
      const earnedArr = [];
      for (let i = 0; i < currency.length; i++) {
        const amountEarned = (Array.isArray(metaRecord?.amountEarned) ? metaRecord?.amountEarned?.filter((ele: any) => ele?.currency?.toLowerCase() == currency[i])[0]?.total : metaRecord?.amountEarned) || 0;
        earnedArr.push(<div style={{minWidth: '50px'}}>{getCurrencyLabel(currency[i]) + ' ' + amountEarned/(currencyMap.filter((e: any) => e.currency == currency[i])[0]?.conversionFactor || 100) + (metaRecord?.amountEarned?.filter((cur: any) => cur.currency != 'sar')?.length > (i + 1) ? ' +' : '')}</div>);
      }
      return earnedArr.length ? earnedArr : '0';
    };

    useEffect(() => {
        if (getMetaRequest?.data) {
            setMetaRecord(getMetaRequest?.data);
        } else {
            setMetaRecord({});
        }
    }, [getMetaRequest.data]);

    useEffect(() => {
      if (getDashboardRequest?.data?.orders?.length) {
        setRequestList(getDashboardRequest?.data?.orders || []);
        setTotal(getDashboardRequest?.data?.totalCount);
        setOrderStatusCount(getDashboardRequest?.data?.ordersStatusCount || []);
      } else {
        setRequestList([]);
        setTotal(0);
        setOrderStatusCount(getDashboardRequest?.data?.ordersStatusCount || []);
      }
    }, [getDashboardRequest.data]);

    useEffect(() => {
        fetchTransactions();
    }, [dates, sort, size, current, filter]);

    useEffect(() => {
      fetchMeta();
    }, [dates]);
  
    return (
      <div className={styles.request_container}>
        {openProofModal && <ProofModal handleClose={() => {
        setOpenProofModal(false);
        setModalObj({attachmentUrl: '', comments: ''});
        }} modalObj={modalObj} />}
        {openTaskCompleteModal && <TaskCompleteModal handleClose={(status?: any, attachmentUrl?: any, comments?: any) => {
          setOpenTaskCompleteModal(false);
          if (typeof status == 'string' && ['Completed', 'Accepted', 'Rejected'].includes(status)) {
            fetchTransactions();
          }
          setTaskIDObj({taskID: '', type: ''});
          }} taskIDObj={taskIDObj} />}
        <div className={styles.dashboard_body}>
        <div className={styles.heading_main}>
          <div className={styles.heading_title}>{t('requests_dashboard')}</div>
          <div className={styles.dateRange} id="date-range">
              {customRange ? <Select
              defaultValue={customRange}
              onChange={(e) => handleCustomRange(e)}
            >
              {dateRangeOption.map((e: any) => {
                return <Option key={e} value={e}>{t(e)}</Option>;
              })}
            </Select> : 
              <RangePicker bordered={true}
                defaultValue={dates}
                disabledDate={disabledDate}
                onCalendarChange={onCalendarChange}
                placeholder={[t('start_date'), t('end_date')]}
                renderExtraFooter={() => {
                  return <div className={styles.cal_fotter}>{dateRangeOption.filter((e: any) => e != 'Custom').map((e: any) => {
                    return <div onClick={() => handleCustomRange(e)} className={styles.single_date} key={e}>{t(e)}</div>;
                  })}</div>;
                }}
              />}
            </div>
        </div>
        <>
        <div className={styles.meta_wrapper}>
          <div className={styles.meta_container}>
              <div className={styles.single_meta}>
                  <div>
                    <div className={styles.heading}>{t('new_requests')}</div>
                    <div className={styles.amount}><div className={styles.light}>{t('request_in_24hrs')}:&nbsp;</div> <div>{metaRecord.lastDayCount || '0'}</div></div>
                  </div>
                  <div className={styles.count}>{orderStatusCount.filter((e: any) => e.status == 'In Review')?.[0]?.count || '0'}</div>
              </div>
              <div className={`${styles.single_meta} ${styles.middle}`}>
                  <div>
                    <div className={styles.heading}>{t('open_requests')}</div>
                    {metaRecord?.latestAcceptedOrderDate && <div className={styles.amount}><div className={styles.light}>{t('last_request_on')} &nbsp;</div> <div>{moment(metaRecord.latestAcceptedOrderDate).format('DD MMM')}</div></div>}
                    {!metaRecord?.latestAcceptedOrderDate && <div className={styles.amount}><div className={styles.light}>{t('no_pending_request')}</div></div>}
                  </div>
                  <div className={styles.count}>{orderStatusCount.filter((e: any) => e.status == 'Accepted')?.[0]?.count || '0'}</div>
              </div>
              <div className={styles.single_meta}>
                  <div>
                    <div className={styles.heading}>{t('closed_requests')}</div>
                    <div className={styles.amount}><div className={styles.light}>{t('amount_earned')}:&nbsp;&nbsp;</div><div className={styles.amount_earned}>{getEarnedAmount() || '0'}</div></div>
                  </div>
                  <div className={styles.count}>{orderStatusCount.filter((e: any) => e.status == 'Completed')?.[0]?.count || '0'}</div>
              </div>
          </div> 
          </div> 
          <div className={styles.filter_container}>
            <div className={styles.filter_text}>{t('requests_filter')}</div>
            <div className={styles.search_container}>
              <div style={{width: '100%', position: 'relative'}}>
                <OutlinedInput
                  className={styles.search}
                  value={search}
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                    handleSearch(e);
                  }}
                  startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
                  placeholder={t('search_for_request')}
                />
                <div className={styles.multifilter} onClick={() => setOpenFilter(!openFilter)}>
                  <img className={styles.icon} src={FilterIcon} onClick={() => {}} />
                </div>
                {openFilter && <ClickAwayListener onClickAway={() => setOpenFilter(false)}><div className={styles.multi_filter_container}>
                  {statusOption.map((ele: any) => {
                      // return (
                      //   // <>
                      //   [<ListSubheader key={ele.header}>{t(ele.header)}</ListSubheader>,
                      //   ele.subHeader.map((el: any) => {
                          return <MenuItem onClick={() => updateFilter(ele.value)} key={ele.value} value={`${ele.value}`}>
                             <div className={styles.option}><div>{ele.key}</div>{filter.includes(ele.value) && <div><CheckIcon /></div>}</div>
                            </MenuItem>;
                        // })]
                        // </>
                      // );
                    })}
                </div></ClickAwayListener>}
              </div>
              {/* <div>
                <FormControl size="small" variant="outlined" className={styles.filter}>
                  <InputLabel htmlFor="grouped-select-dashboard">{filter.length ? '' : t('Filter')}</InputLabel>
                  <MUSelect onChange={updateFilter} multiple value={filter} id="grouped-select-dashboard">
                    {statusOption.map((ele: any) => {
                      return (
                        // <>
                        [<ListSubheader key={ele.header}>{t(ele.header)}</ListSubheader>,
                        ele.subHeader.map((el: any) => {
                          return <MenuItem key={el.value} value={`${el.value}-${el.category}`}>{el.key}</MenuItem>;
                        })]
                        // </>
                      );
                    })}
                  </MUSelect>
                </FormControl>
              </div> */}
            </div>
          </div>
          {filter?.length ? <div className={`${styles.selected_filter} ${classes.root}`}>
            {filter.map((ele: string) => (
              <Chip key={ele} style={{color: 'black', backgroundColor: statusOption.filter((e: any) => e?.value == ele)[0].color}} label={ele} onDelete={() => updateFilter(ele)} color="primary" deleteIcon={<ClearIcon fontSize='small' style={{color: 'black'}} />} />
            ))}
          </div> : null}
          </>
        <div className={loading ? styles.loader : ''}>
          {loading ? <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /> : requestList.length ? <RequestTable requestList={requestList} sort={sort} setSort={setSort} setTaskIDObj={setTaskIDObj} setOpenTaskCompleteModal={setOpenTaskCompleteModal} setOpenProofModal={setOpenProofModal} setModalObj={setModalObj} /> :
          <div className={styles.noTransaction}>
            <img src={NoTransaction} />
            <div>{t('no_request_to_display')}</div>
          </div>}
        </div>
        {!!requestList.length && <div className={styles.pagination_container} id="pageSelector">
          <Select
            defaultValue={size}
            style={{
              width: '70px',
              height: '35px',
            }}
            onChange={(e) => updatePagination(1, e)}
          >
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </Select>
          <Pagination
              onChange={updatePagination}
              size="small"
              pageSize={size}
              total={total}
              current={current}
              showSizeChanger={false}
              showLessItems={true}
              itemRender={(page, type, originalElement) => {
                return type == "page" ? (
                  <div className={current == page ? styles.pagiActive : ''}>{page}</div>
                ) : (
                  originalElement
                );
              }}
            />
        </div>}
        </div>
      </div>
    );
  };
  
  export default RequestDashboard;
