import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';

export interface ScheduleState {
  data: any;
}

const initialState: ScheduleState = {
  data: null,
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    removeSchedule: (state) => {
      state.data = null;
    },
    setSchedule: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const {removeSchedule, setSchedule} = scheduleSlice.actions;

export const getSchedule = (state: RootState) => state.schedule;

export default scheduleSlice.reducer;
