/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import React from 'react';
import styles from './Public.module.scss';
import {useEffect} from 'react';
import useRequest from '../../features/API/request';
import {useState} from 'react';
import ReactDOM from 'react-dom';
import {useParams} from 'react-router-dom';
import UserPublicProfile from './UserPublicProfileV2/UserPublicProfile';
import notFound from '../../assets/images/user_not_found.svg';
import {CircularProgress} from '@material-ui/core';
import {useSelector} from 'react-redux';
import MetaTagWrapper from '../../components/MetaTagWrapper/MetaTagWrapper';
import Loader from 'react-loader-spinner';
import {NULL_THEME} from '../../constants/newThemes';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import ReactGA from 'react-ga';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWindowWidth from '../../hooks/useWindowWidth';
import {MusicAlbumItem} from '../../features/stores/musicAlbumsSlicer';
import {dealItem} from '../../features/stores/dealsSlicer';


const PublicPage = (): JSX.Element => {
  const [publicUser, setPublicUser] = useState(undefined) as any;
  const [musicLinks, setMusicLinks] = useState<MusicAlbumItem[]>([]);
  const [deals, setDeals] = useState<dealItem[]>([]);
  const [message, setMessage] = useState<string>('Loading...');
  const theme = useSelector(selectUserTheme);
  const useOnlyLinksUser = true;
  const mobile = useWindowWidth();
  const params: {nickname: string} = useParams();
  const request = useRequest({method: 'GET', url: '/linksUser/nickname'});

  const getLinksUser = async () => {
    const credentials = {
      nickname: params?.nickname,
    };
    const result = await request.doRequest({...credentials});

    if (result) {
      if (result.status === 200) {
        const linksUser = {...result.linksUser};
        setPublicUser(linksUser);
        setMusicLinks((result.albums || []).slice().sort((a: any, b: any) => {
          if (a.order > b.order) return 1;
          else return -1;
        }));
        setDeals(result.userDeals || []);
      } else if (result.status === 404) {
        setMessage(result.message);
      }
    } else {
      setMessage('Something went wrong.');
    }
  };

  useEffect(() => {
    getLinksUser();
    window.Trengo = window.Trengo || {};
    window.Trengo.render = false;
  }, []);

  // useEffect(() => {
  //   getLinksUser();
  //   window.FreshworksWidget('hide', 'launcher');
  // }, []);
  

  const meta = {
    title: publicUser?.fullname,
    description: publicUser?.bio,
    metaProperties: {
      ogImage: publicUser?.avatar?.url || '/social_image.png',
    },
  };

  const CustomLoader = (
  //  <CircularProgress style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
    <div style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
    </div>
  );

  return (
    <>
      <MetaTagWrapper {...meta} />
      {publicUser === undefined ? (
        <div>
          {message !== 'Loading...' ? <img src={notFound} style={{height: '900px'}}/>:CustomLoader }
        </div>
      ) : (
        <>
          <UserPublicProfile
            deals={deals}
            linksUser={publicUser}
            musicLinks={musicLinks}
            themeProp={useOnlyLinksUser?
            NULL_THEME : theme}
          />
          {mobile < 668 ? (
              <ToastContainer
                position="top-center"
                autoClose={1600}
                style={{padding: `20px`, marginBottom: `220px`}}
              />
            ) : (
              <ToastContainer position="bottom-right" autoClose={6600} />
            )}
        </>
      )}
    </>
  );
};

export default PublicPage;
