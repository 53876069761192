import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./SelectGender.module.scss";
import SelectWithSearch from "../SelectWithSearch/SelectWithSearch";
type GenderProps = {
    value: string;
    handleChange: Function;
};

const SelectGender: React.FC<GenderProps> = ({value, handleChange}: GenderProps)=> {
  const {t} = useTranslation();
  const genderMap: {[key: string]: string} = {'Other': t('prefer_not_to_say')};
  return (
      <SelectWithSearch value={value}
          onChange={(e) => handleChange(e?.target?.value || '')}
          label={t('gender')} options={[t('male'), t('female'), t('other')].map((e) => {
              return {display: genderMap[e] || e, value: e};
          })} />
  );
};

export default SelectGender;
                
