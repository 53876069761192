import profileActive from '../assets/images/versionV2/Header/al_profile_active.svg';
import profileUnactive from '../assets/images/versionV2/Header/al_profile_inactive.svg';
import linksActive from '../assets/images/versionV2/Header/linksActive.svg';
import linksInactive from '../assets/images/versionV2/Header/linksInactive.svg';
import dashboardUnactive from '../assets/images/versionV2/Header/al_dashboard_inactive.svg';
import dashboardActive from '../assets/images/versionV2/Header/al_dashboard_active.svg';
import homeActive from '../assets/images/versionV2/Header/al_home_active.svg';
import homeInactive from '../assets/images/versionV2/Header/al_home_inactive.svg';
import referralInactive from '../assets/images/versionV2/Header/al_referral_inactive.svg';
import referralActive from '../assets/images/versionV2/Header/al_referral_active.svg';
import dealsInactive from '../assets/images/versionV2/Header/dealsInactive.svg';
import dealsActive from '../assets/images/versionV2/Header/dealsActive.svg';


export const headerRoutes = [
  {
    key: 'home',
    route: '/profile/home',
    name: 'Home',
    label: '',
    icon: homeInactive,
    activeIcon: homeActive,
    activatedRoute: ['/profile/home', '/profile/profile-icons'],
  },
  {
    key: 'myprofile',
    route: '/profile/myprofile',
    name: 'Links',
    label: '',
    icon: linksInactive,
    activeIcon: linksActive,
    activatedRoute: [],
  },
  {
    key: 'deals',
    route: '/profile/deals',
    name: 'Deals',
    label: '',
    icon: dealsInactive,
    activeIcon: dealsActive,
  },
  {
    key: 'requestdashboard',
    route: '/profile/request-dashboard',
    name: 'Dashboard',
    label: '',
    icon: dashboardUnactive,
    activeIcon: dashboardActive,
    activatedRoute: [],
  },
  {
    key: 'referrals',
    route: '/profile/settings/referrals',
    name: 'Referrals',
    label: '',
    icon: referralInactive,
    activeIcon: referralActive,
    activatedRoute: [],
  },
];


