import React, {MouseEvent} from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import styles from './MusicLinkIcon.module.scss';

export type MusicLinkIconProps = {
  image?: string;
  audio?: string;
};

export const MusicLinkIcon = ({image, audio}: MusicLinkIconProps) => {
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const toggleAudio = async (e: MouseEvent<HTMLDivElement>) => {
    try {
      e.stopPropagation();
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          await audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    } catch (error) {
      setIsPlaying(false);
    }
  };
  if (!audio) {
    return <img src={image} alt='link image' />;
  }
  return (
    <div className={styles.iconContainer} onClick={toggleAudio}>
      <img src={image} alt='link image' />
      <audio onEnded={() => setIsPlaying(false)} ref={audioRef} src={audio}></audio>
      {isPlaying ? <PauseIcon className={styles.actionIcon} /> : <PlayArrowIcon className={styles.actionIcon} />}
    </div>
  );
};
