import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./TotalRevenue.module.scss";
import {useSelector} from "react-redux";
import {getWalletStore} from "../../features/stores/walletSlicer";
import {getConfig} from "../../features/stores/configSlicer";
import {haveSegmentPermission} from "../../features/util";
import {MenuItem, Select} from "@material-ui/core";
import {getCurrencyFullLabel, getCurrencyLabel} from "../../constants/currencies";
import {Space} from "antd";
import DateTabs from "../../pages/Analytics/Components/DateTabs/DateTabs";
import moment from "moment";
import useRequest from "../../features/API/request";
import Loader from "react-loader-spinner";
import EarningDashboardChart from "./EarningDashboardChart";

const TotalRevenue = ()=> {
  const {t} = useTranslation();
  const walletList = useSelector(getWalletStore).data || [];
  const config = useSelector(getConfig).data || [];
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];
  const [currencySelected, setCurrencySelected] = useState('');
  const [value, setValue] = useState([]);
  const [earningData, setEarningData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [earningDataStats, setEarningDataStats] = useState({earningsTotal: 0, fanEarningsTotal: 0, platformEarningsTotal: 0, dealEarningsTotal: 0});
  const [graphData, setGraphData] = useState<{value: {name: string, data: number[]}[], label: string[]}>({value: [], label: []});
  const revenueRequest = useRequest({method: 'GET', url: `/finance/api/orders/earnings`, isShortUrl: true});

  const handleCurrencyChange = (e: any) => {
    setCurrencySelected(e?.target?.value);
  };

  const getRevenueData = async () => {
    try {
        setLoading(true);
        const params = {
            offset: (new Date()).getTimezoneOffset(),
            startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
            endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
            currency: currencySelected,
        };
        const result = await revenueRequest.doRequest(params);
        setEarningData(result?.earnings || {});
        setEarningDataStats({earningsTotal: result?.earningsTotal, fanEarningsTotal: result?.fanEarningsTotal, platformEarningsTotal: result?.platformEarningsTotal, dealEarningsTotal: result?.dealEarningsTotal});
        setLoading(false);
      } catch (error) {
        console.log('Error occured', error);
      }
  };

  const handleTooltipValue = (value: number) => {
    return `${getCurrencyLabel(currencySelected)} ${(Number(value)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  useEffect(() => {
    const graphKeys = Object.keys(earningData);
    const grapValues = Object.values(earningData);
    if (graphKeys?.length) {
      const graphArray: number[] = [];
      const graphLabel: string[] = graphKeys || [];
      for (const key in earningData) {
        if (earningData[key][0]) {
           graphArray.push(earningData[key][0].total);
        }
      }
      setGraphData({value: [{name: 'Earning', data: graphArray}], label: graphLabel});
    }
  }, [earningData]);

  useEffect(() => {
    if (value.length) {
      getRevenueData();    
    }
  }, [value, currencySelected]);

  useEffect(() => {
    if (walletList?.length) {
        setCurrencySelected(walletList[0]?.currency);
    }
  }, [walletList]);

  return walletList?.length ? (
    <div className={styles.total_revenue_box}>
        <div className={styles.total_revenue_row}>
            <div className={styles.title}>{t('total_revenue')}</div>
            <div className={styles.currency_wrapper}>
                <Select variant="outlined" fullWidth value={currencySelected} className={styles.select_main} native={false} onChange={handleCurrencyChange}>
                    {walletList?.map((wallet: any) => <MenuItem key={wallet?.currency} value={wallet?.currency}>
                        {getCurrencyFullLabel(wallet?.currency)}
                    </MenuItem>)}
                </Select>
                <div className={styles.revenue}>{currencySelected ? handleTooltipValue(earningDataStats?.earningsTotal) : null}</div>
            </div>
        </div>  
        <div className={styles.total_revenue_graph}>
            <div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
            <div>
            {loading ? <div className={styles.loader}><Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /></div> : <EarningDashboardChart formatterTooltip={handleTooltipValue} values={graphData?.value || []} labels={graphData?.label || []} />}
            </div>
        </div>    
    </div>
  ) : null;
};

export default TotalRevenue;
