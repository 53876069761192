import React, {
    useEffect,
    useState,
  } from 'react';
  import moment, {Moment} from 'moment';
  import useRequest from '../../../../features/API/request';
  import CountChart from '../../../Charts/CountChart';
  import styles from './LinkVisits.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";

  interface valueProps {count: number|string, linkId: string, title: string|null, type: string};
  interface linkprops {linkId: string, title: string|null, type: string};
  
  const LinkVisits = () => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [total, setTotal] = useState(0);
    const [stackedData, setStackedData]= useState<{stackValues: {name: string, data: number[]}[], stackLabels: string[]}>({stackValues: [], stackLabels: []});
    const stackRequest = useRequest({method: 'GET', url: '/linkAnalytics/count'});
  
    const getLinkAnalyticsData = async () => {
      try {
        const params = {
          startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
        };
        const stackResult: {linkClicks: {[key: string]: valueProps[]}, total: number} = await stackRequest.doRequest(params);
        if (stackResult?.linkClicks) {
          const keys: string[] = Object.keys(stackResult?.linkClicks); // Seperate keys for x-axis 
          const linkIds: linkprops[] = [];
          Object.values(stackResult?.linkClicks).map((ele: valueProps[]) => {
              ele.map((e: {linkId: string, title: string|null, type: string, count: number|string}) => {
                  if (linkIds.findIndex((ex: linkprops) => ex.linkId == e.linkId) == -1) {
                  linkIds.push({linkId: e?.linkId, title: e?.title, type: e?.type}); // Creating unique links avaiable is given set of data
                  }
              });
          });
          const linkIdsNew: {name: string, data: number[]}[] = [];
          linkIds.map((e: linkprops) => linkIdsNew.push({name: e.title || e.type, data: Array.from({length: keys.length}, (v, i) => 0)})); // Creating initial data for graph points
          Object.values(stackResult?.linkClicks).map((ele: valueProps[], i) => {
              linkIds.map((e: linkprops, ind: number) => {
                  const index = ele.findIndex((ee: valueProps) => ee.linkId == e.linkId);
                  if (index != -1) {
                      linkIdsNew[ind].data[i] = parseInt(ele[index]?.count as string); // populate data if linkId matches
                  }
              });
          });
          const stackValues: {name: string, data: number[]}[] = linkIdsNew || [];
          const stackLabels: string[] = keys || [];
          setStackedData({stackValues, stackLabels});
          setTotal(stackResult?.total);
          setData(true);
        } else {
          setData(true);
        }
      } catch (error) {
        setData(true);
        console.log('Error occured', error);
      }
    };
  
    useEffect(() => {
        if (value.length) {
          getLinkAnalyticsData();    
        }
    }, [value]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
              <div className={styles.heading}><p>{t('total_clicks')}</p><p className={styles.total}>{total?.toLocaleString()}</p></div>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
  
            <div className={styles.chart_wrapper}>
              {data ? <CountChart values={stackedData.stackValues} labels={stackedData.stackLabels} /> :
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                  <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                </div>
                <CountChart values={[]} labels={[]} />
              </div>
              }
            </div>
          </div>
        </div>
    );
  };
export default LinkVisits;
