import React, {useEffect, useRef} from 'react';
import styles from '../ImportantLinksItem/ImportantLinksItem.module.scss';
import AutoSizeInput from '../AutosizeInput/AutosizeInput';
import addPlus from '../../assets/images/al_add_plus.svg';
import defaultIcon from '../../assets/images/al_defaultLinkIconBlack.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useState} from 'react';
import {store} from '../../app/store';
import {socialMediaLinks} from '../../constants/socialMedia';
import arrowDown from '../../assets/images/al_arrow.svg';
import arrowUp from '../../assets/images/al_arrow_up.svg';
import trash from '../../assets/images/al_delete_red.svg';
import trashGrey from '../../assets/images/al_delete_grey.svg';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {useSelector} from 'react-redux';
import ModalCrop from '../ModalCrop/ModalCrop';
import pencil from '../../assets/images/pencil.svg';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import {LinksSectionItem, selectLinksUser, updateSectionItem} from '../../features/stores/linksUserSlicer';
import {selectMe} from '../../features/stores/userSlicer';
import Compressor from 'compressorjs';
import {blobToBase64, convertBase64ToBlob} from '../../Helper/imageCropper';
import ContentEditable from 'react-contenteditable';
import {Switch, Modal} from 'antd';
import 'antd/dist/antd.css';
import socialMediaFollow from '../../assets/images/socialMediaFollow.svg';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {useTranslation} from "react-i18next";
import useWindowWidth from '../../hooks/useWindowWidth';
import {getConfig} from '../../features/stores/configSlicer';
import {haveSegmentPermission} from '../../features/util';
import {getCurrencyLabel} from '../../constants/currencies';
import {numberFollowerFormat} from '../../Helper/generalHelper';

export interface IOptions{
  type: string,
  url: string,
  icon: string,
  showIcon: string,
  title: string,
  linksSectionId?: string,
  id?: string,
  orderNumber: number,
  customImageToUpload?: any;
  customUploadedImage?: {url:string} | any;
  isNewLink?: boolean;
}
interface IProps{
  title: string;
  customImage: string;
  url: string;
  type: string;
  handleDelete:(id:string) => void;
  id:string;
  isVisible?: boolean;
  layout?: string | undefined;
  isMonitize?: boolean;
  price?: any;
  attachment?: any;
  note?: any;
  currency?: string;
  isEditable?: boolean;
}

const ImportantLinksItem = ({
  title,
  customImage,
  url,
  type,
  handleDelete,
  id,
  isVisible,
  layout,
  isMonitize,
  price,
  attachment,
  note,
  currency,
  isEditable = true,
}: IProps) =>{
  const [isTitleDisabled, setIsTitleDisabled] = useState(false);
  const [isTeaserPreviewOpen, setIsTeaserPreviewOpen] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const linksUser = useSelector(selectLinksUser);
  const {t} = useTranslation();
  const mobile = useWindowWidth();
  const socialSectionData = linksUser.linksSections.find(
      (section: any) => section.type === 'social',
  );
  const loggedUser = useSelector(selectMe).me.linksUser;
  const config = useSelector(getConfig).data || [];
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];
  const newLink = id.length < 30; // this is used to determine whether the link is new or not
  const determineIcon = () => {
    /*
    customImage = null, type - instagram => icon = instagram
    customImage = null, type - other => icon = generic_link
    customImage != null, type - nebitno => icon = customImage
    */
    if (!customImage) {
      if (type !== 'other') {
        return socialMediaLinks.find((e) => e.key === type)?.defaultIcon;
      } else {
        if (newLink) {
          return addPlus;
        } else {
          return defaultIcon;
        }
      }
    } else {
      return customImage;
    }
  };

  useEffect(() => {
    // checks wheather the fields are empty and hides the link item if true
    checkIfEmpty();
  }, [title, url, customImage]);

  const checkIfEmpty = () => {
    if (!title && !url && !customImage) {
      store.dispatch(updateSectionItem({id: id, data: {hidden: true}}));
    }
  };

  const handleChangeTitle = (e: string) => {
    store.dispatch(updateSectionItem({id: id, data: {title: e.length <= 50 ? e : title, hidden: false}}));
    store.dispatch(setDataChange(true));
  };

  const handleChangeNote = (e: string) => {
    store.dispatch(updateSectionItem({id: id, data: {note: e, hidden: false}}));
    store.dispatch(setDataChange(true));
  };

  const getFromUrl = (url: string): string => {
    const skipSections = new Set(['seasonal']);
    const value = socialMediaLinks.find(({key, section}) => url.toLowerCase().includes(key) && !skipSections.has(section!))?.key;
    return value || 'other';
  };

  const handleChangeUrl = (value: any) => {
    const type = getFromUrl(value);
    store.dispatch(updateSectionItem({id: id, data: {url: value, type: type, hidden: false}}));
    setIsOpen(false);
    store.dispatch(setDataChange(true));
  };

  const handleChangeInputValue = (e: any, nv: any) => {
    handleChangeUrl(nv.url);
  };

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl: any = await readFile(e.target.files[0]);
      store.dispatch(updateSectionItem({id: id, data: {customUploadedImage: imageDataUrl, hidden: false}}));
      e.target.value=null;
    }
    setIsCropperOpen(true);
  };

  const handleUploadThumbnail = () => {
    document.getElementById(`linkIcon${id}`)?.click();
  };

  const handleToggle = () => {
    store.dispatch(updateSectionItem({id: id, data: {isVisible: !isVisible}}));
    store.dispatch(setDataChange(true));
  };

  const getAmountValue = () => {
    const currencyFactor = currencyMap.filter((e: any) => e.currency == (currency || 'usd'))[0]?.conversionFactor || 100;
    // less than 1000 check is for if amount is below 1000 then we show exact value otherwise show formatted value
    if (Number(price)/Number(currencyFactor) < 1000) {
      return (Number(price)/Number(currencyFactor)).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    } else {
      return numberFollowerFormat(Number(price)/Number(currencyFactor));
    } 
  };

  const node = useRef(null);
  useOutsideAlerter(node, setIsOpen);

  return (
    <div className={styles.container}>
      {isTeaserPreviewOpen && (
          <Modal
          title={null}
          footer={null}
          centered
          visible={isTeaserPreviewOpen}
          onCancel={() => {
            setIsTeaserPreviewOpen(false);
          }}
        >
          <img src={attachment?.url || attachment}></img>
        </Modal>
      )}
      {isCropperOpen && (
        <ModalCrop
          setIsOpen={setIsCropperOpen}
          imgSrc={customImage}
          setImageUrl={(image) => {
            if (image.includes('base64')) {
              new Compressor(convertBase64ToBlob(image), {
                quality: 1,
                maxHeight: 100,
                maxWidth: 100,
                async success(result) {
                  const baseImage = await blobToBase64(result) as string;
                  store.dispatch(updateSectionItem({id: id, data: {customUploadedImage: baseImage}}));

                  store.dispatch(setDataChange(true));
                },
                error(err) {
                  console.log(err.message);
                },
              });
            } else {
              store.dispatch(updateSectionItem({id: id, data: {customUploadedImage: image}}));
            }
          }}
          handleCancelUpload={() => {
            const oldImage = loggedUser.linksSections.reduce((acc: any, curr: any) => {
              acc = [...acc, ...curr.linksSectionItems];
              return acc;
            }, []).find((e: any) => e.id === id)?.customUploadedImage;
            store.dispatch(updateSectionItem({id: id, data: {customUploadedImage: oldImage?.url}}));
          }}
          type={'important_links'}
        />
      )}
      { <>
          <div>
          <div className={styles.new_input_wrapper}>
            <div className={`${styles.right}`}>
              <input
                id={`linkIcon${id}`}
                style={{display: 'none'}}
                type={'file'}
                onChange={fileSelectedHandler}
                disabled={!isEditable}
              />
              <div className={styles.thumbnail}>
                <div className={styles.icon_sibling}>
                  <img
                    className={styles.img_sibling}
                    src={determineIcon()}
                    style={{
                      backgroundColor: 'transparent', cursor: isEditable ? 'pointer' : 'default', borderRadius: '8px',
                    }
                    }
                    onClick={handleUploadThumbnail}
                  />
                  {determineIcon() !== addPlus && isEditable && <div className={styles.pencil_icon_container} onClick={handleUploadThumbnail}>
                    <img src={pencil} className={styles.pencil_icon} />
                  </div>}
                </div>
                {isEditable ? <div onClick={handleUploadThumbnail} className={styles.new_card_upload}>{t('change_thumbnail')}</div>: null}
                </div>
                {isMonitize && <div className={styles.monitize}>
                  {/* <MonetizationOnIcon /> */}
                  <div className={styles.monetizationLabel}>{getCurrencyLabel(currency)}</div>
                  {type != 'tipJar' && <div>{getAmountValue()}</div>}
                  {/* {mobile > 720 && t('monetize')} */}
                </div>}
            </div>
          </div>
          <div className={styles.box_wrapper}>
            {attachment && mobile > 720 && <div className={styles.box_left}>
              <img src={attachment?.url || attachment} />
              </div>}
            <div className={styles.box_right}>
            <div className={styles.new_input_wrapper}>
            <div className={styles.left} ref={node}>
                <div className={styles.inputs_wrapper}>
                  <label className={styles.label}>{t('title')}</label>
                  <div className={styles.input_wrapper}>
                    <input
                      className={styles.input_field}
                      placeholder={t('add_title')}
                      value={title}
                      onChange={(e) => {
                        handleChangeTitle(e.target.value);
                      }}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
            </div>
          </div>
          {type == 'sellProduct' && <div className={styles.new_input_wrapper}>
            <div className={styles.left} ref={node}>
                <div className={styles.inputs_wrapper}>
                  <label className={styles.label}>{t('notes')}</label>
                  <div className={styles.input_wrapper}>
                    <input
                      className={styles.input_field}
                      value={note}
                      placeholder={t('enter_note')}
                      onChange={(e) => {
                        handleChangeNote(e.target.value);
                      }}
                    />
                  </div>
                </div>
            </div>
          </div>}
          {type == 'shortCall' && <div className={styles.new_input_wrapper}>
            <div className={styles.left} ref={node}>
                <div className={styles.inputs_wrapper}>
                  <label className={styles.label}>{t('url')}</label>
                  <div className={styles.input_wrapper}>
                    <input
                      className={styles.input_field}
                      value={url}
                      disabled
                      onChange={(e) => {
                        handleChangeNote(e.target.value);
                      }}
                    />
                  </div>
                </div>
            </div>
          </div>}
          {/* {type != 'tipJar' && type != 'sellProduct' && <div className={styles.new_input_wrapper}> */}
          {!isMonitize && <div className={styles.new_input_wrapper}> 
            <div className={styles.left} ref={node}>
                <div className={styles.inputs_wrapper}>
                  <div className={styles.label}>{isMonitize ? t('amount') : t('url')}</div>
                  <div className={styles.input_wrapper}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={socialSectionData?.linksSectionItems as LinksSectionItem[]}
                      getOptionLabel={(option)=>option.url || ''}
                      classes={{
                        input: styles.autocomplete_input,
                        listbox: styles.listbox,
                        option: styles.option,
                      }}
                      disabled={isMonitize || !isEditable}
                      // value={isMonitize ? (Number(price)/(currencyMap.filter((e: any) => e.currency == 'usd')[0]?.conversionFactor || 100)).toFixed(2) : url}
                      value={url}
                      role="combobox"
                      onChange={(e, nv) => handleChangeInputValue(e, nv)}
                      className={styles.autocomplete}
                      open={open}
                      renderInput={(params)=>{
                        return (
                          <div >
                            <div ref={params.InputProps.ref}>
                              <input
                                {...params.inputProps}
                                // value={isMonitize ? (Number(price)/(currencyMap.filter((e: any) => e.currency == 'usd')[0]?.conversionFactor || 100)).toFixed(2) : url}
                                value={url}
                                type="text"
                                onClick={()=>{
                                  if (url === '') setIsOpen(true);
                                  else setIsOpen(false);
                                }}
                                onChange={(e) => {
                                  handleChangeUrl(e.target.value);
                                  if (e.target.value === '') setIsOpen(true);
                                  else setIsOpen(false);
                                }}
                                placeholder={'http://'}
                                style={{
                                  ...(open && (socialSectionData?.linksSectionItems?.length) && {
                                    borderRadius: 0,
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                    backgroundImage: `url(${arrowUp})`,
                                  }),
                                  zIndex: 3,
                                  ...(!open && (socialSectionData?.linksSectionItems?.length) && {backgroundImage: `url(${arrowDown})`}),
                                }}
                              />
                            </div>
                          </div>

                        );
                      }}
                      freeSolo={true}
                      renderOption={(option) => {
                        return (
                          <div
                            onClick={()=>{
                              handleChangeUrl(option.url);
                              setIsOpen(false);
                            }}
                            style={{display: 'flex', gap: '12px', clear: 'both', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                            <img src={socialMediaLinks.find((e) => e.key === option.type)?.defaultIcon} />
                            <p>{option.url}</p>
                          </div>
                        );
                      }}
                      ListboxProps={{
                        style: {
                          padding: '0px',
                        },
                      }}
                   />
                  </div>
                </div>
            </div>
          </div>}
            </div>
          </div>
          
          <br />
          <div className={`${styles.cardFooter}`}>
          {(attachment && mobile <= 720) && (
            <div className={styles.teaserPreview} >
                <img src={attachment?.url || attachment} onClick={() => {
                  setIsTeaserPreviewOpen(true);
                }}/>
            </div>) }
            <div className={`${styles.title_input_wrapper} ${(!attachment || mobile > 720) && styles.fullWidth}`}>
                <div className={styles.title_pencil_wrapper}>
                  <Switch className={styles.switch} checked={isVisible} onChange={handleToggle} />
                  &nbsp;&nbsp;{t('visibility')}
                </div>
                <div className={`${styles.title_pencil_wrapper} ${styles.footDelete}`} onClick={() => handleDelete(id)} >
                  <img src={trash} className={styles.trash} />
                  &nbsp;&nbsp;{mobile > 720 ? t('delete') : ''}
                </div>
            </div>
          </div>
          </div>
        </>}

    </div>
  );
};
export default ImportantLinksItem;


// const saveImportantLink = () => {
//   // check if it had a custom image
//   const tempImages = uploadedNotSavedImages.filter((el:any)=>el.linkId ===linkProp?.id) as any;
//   if (tempImages.length && tempImages[0].customImageToUpload) {
//     const newArray = uploadedNotSavedImages.map((el:any)=>{
//       if (el.linkId === linkProp?.id) return {...el, customImageToUpload: null};
//       return {...el};
//     });
//     store.dispatch(setLinksData({
//       linksIconData: newArray,
//     }));
//   }
//   const customImageToUpload = linkIcon;


//   // if (linkIconProp && linkIconProp.includes('data')) {
//   //   customImageToUpload = linkIconProp;
//   // } else {
//   //   customImageToUpload = linkIcon;
//   // }

//   // const isKnownSocial = socialMediaLinks.find((el)=>el.defaultIcon === linkIcon);
//   // console.log('aaa is known social', isKnownSocial);
//   // if ((linkProp?.type === 'other' && linkIcon != addPlus &&
//   // (isNewLink && !isKnownSocial || isUpdate)) ||
//   // (linkProp?.type !== 'other' && !isKnownSocial))
//   // customImageToUpload=linkIcon;


//   if (isUpdate) {
//     const tempArray = importantLinksProp?.map((e: IOptions) => {
//       if (e.id === linkProp?.id) {
//         return {
//           ...e,
//           url: linkString,
//           title: title,
//           type: type,
//           customImageToUpload,
//           isNewLink: linkProp?.isNewLink,
//         };
//       } else return e;
//     });

//     setImportantLinks(tempArray!!);
//     return;
//   }
//   const obj: IOptions = {
//     type: type,
//     url: linkString,
//     icon: linkIcon,
//     showIcon: '',
//     // customImageToUpload: linkProp?.type === 'other' && linkIcon != addPlus ? linkIcon: '',
//     customImageToUpload,
//     title: title,
//     id: Date.now().toString(),
//     linksSectionId: socialSectionDataImportant.id,
//     orderNumber: importantLinksProp[importantLinksProp.length-1]?.orderNumber + 1 || 0,
//     isNewLink: true,
//   };


//   const allLinks: IOptions[] = importantLinksProp|| [];
//   setTitle('');
//   setIsTitleDisabled(true);
//   setLinkIcon(linkIconProp);
//   setLinksString('');
//   setType('');
//   setImportantLinks([...allLinks, obj]);
//   autocompleteData();
// };
