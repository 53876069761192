import React, {
  useEffect,
  useState,
  useMemo,
  lazy,
} from 'react';
import {useDispatch} from 'react-redux';
import {setShouldSave} from '../../features/stores/changeDataSlicer';
import {fetchUsersDeals} from '../../features/Login/login';
import useWindowWidth from '../../hooks/useWindowWidth';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {removeStepCookie} from '../../Helper/generalHelper';
import {setAppliedDeals, setDeals} from '../../features/stores/dealsSlicer';
import {
  setPreviewMode,
} from '../../features/stores/previewModeSlicer';
import {isRtl} from './../../features/util';
import styles from './DealsPage.module.scss';
import DealsContainer from '../../components/Deals/DealsContainer';
import {Select} from "antd";

const MyProfileItem = lazy(
    () => import('../../components/MyProfileItems/MyProfileItem'),
);
const AvailableDeals = lazy(
    () => import('../../components/Deals/AvailableDeals'),
);
const AppliedDeals = lazy(
  () => import('../../components/Deals/AppliedDeals'),
);
const Deals = lazy(() => import('../../components/Deals/Deals'));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  width: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, width, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={width < 1024 ? 1 : 3} pr={width < 1024 ? 1 : 3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const DealsPage: React.FC = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });
  const width = useWindowWidth();
  const {Option} = Select;

  const SectionList = useMemo(
      () => [
        {
          id: '0',
          key: 'deals',
          name: t('available_deals'),
          subtitle: t('quick_link_to_deals'),
          isAccordion: false,
          content: <AvailableDeals />,
          isVisible: true,
        },
        {
          id: '1',
          key: 'appliedDeals',
          name: t('applied_deals'),
          subtitle: '',
          isAccordion: false,
          content: <AppliedDeals />,
          isVisible: true,
        },
        {
          id: '2',
          key: 'activeDeals',
          name: t('active_deals'),
          subtitle: '',
          isAccordion: false,
          content: <Deals />,
          isVisible: true,
        },
      ],
      [t],
  );

  useEffect(() => {
    dispatch(setPreviewMode(false));
    dispatch(setShouldSave(false));
    removeStepCookie();
  }, [dispatch]);

  const handleChange = (event: string) => {
    setValue(parseInt(event));
    localStorage.setItem('activeTab', event);
  };

  useEffect(() => {
    fetchUsersDeals(setDeals, {status: 'Available'});
    fetchUsersDeals(setAppliedDeals, {status: 'Applied'});
  }, []);

  return (
    <DealsContainer screen="deals">
      <div className={styles.customSelect}>
        <Select
          defaultValue={value.toString()}
          onChange={handleChange}
          className={styles.customSelector}
        >
          {SectionList.map((section, index) => {
            return <Option key={index} value={section?.id}>{section.name}</Option>;
          })}
        </Select>
      </div>
      {SectionList.map((section, index) => {
        return (
          <div key={section.key} className={styles.section_container}>
            {section.isVisible &&
          <TabPanel width={width} value={value} index={index} key={`${section.key}-item`} dir={isRtl(i18n.language) ? 'rtl' : 'ltr'}>
            <MyProfileItem
              name={section.name as string}
              id={section.id}
              subtitle={section.subtitle}
              content={section.content}
              isAccordion={section.isAccordion}
              isVisible={section.isVisible}
              editableTitle={section.key === 'featuredVideos'}
            />
          </TabPanel>
            }
          </div>
        );
      })}
    </DealsContainer>
  );
};

export default DealsPage;
