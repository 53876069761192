import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./RegionSelect.module.scss";
import {useSelector} from "react-redux";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {store} from "../../app/store";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import {CountryRegionData} from 'react-country-region-selector';
import Arrow from "../../assets/images/al_arrow.svg";
import SelectWithSearch from "../SelectWithSearch/SelectWithSearch";

type Props = {};

const RegionSelect: React.FC<Props> = ()=> {
  const {t, i18n} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const [countryRegionDataOptions, setCountryRegionDataOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (linksUser?.location) {
      setCountryRegionDataOptions((prevData) => (CountryRegionData.filter((e) => e[0] === linksUser?.location)[0][2].split('|').map((e) => e = e.split('~')[0])));
    }
    setTimeout(() => setLoading(false), 500);
  }, [linksUser?.location]);
  return (
    <div className={styles.content}>
      {loading ? null : <SelectWithSearch value={linksUser?.city || ''}
          onChange={(val: any) => {
            if (val?.target?.value) {
              store.dispatch(setLinksUser({...linksUser, city: val?.target?.value ?? ''}));
              store.dispatch(setDataChange(true));
            }
          }}
          label={t('city')} options={countryRegionDataOptions?.map((e) => {
              return {display: e, value: e};
          })} />}
    </div>
  );
};

export default RegionSelect;
