import {Button} from 'antd';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {dealItem} from '../../features/stores/dealsSlicer';
import trash from '../../assets/images/al_delete_red.svg';
import CloseIcon from '@material-ui/icons/Close';
import TextInput from '../LinkModal/components/TextInput';
import styles from './EditCollectionsModal.module.scss';
import CreateIcon from '@material-ui/icons/Create';
import {themeColor} from '../../constants/newThemes';
import {toast} from 'react-toastify';
import {PlusCircleFilled} from '@ant-design/icons';
import {useUploadRequest} from '../../features/API/request';
import {ButtonCustom} from '../ImportantLinks/ImportantLinks';
import Loader from 'react-loader-spinner';
import {dealType} from '../../constants/helper';
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';
import nonHookRequest from '../../features/API/nonHookRequest';
import {isValidHttpUrl} from '../../features/util';
interface IModalProps{
    dealItem?: dealItem;
    setIsOpen: (arg: boolean) => void;
    open: boolean;
    onSubmit: () => void;
}

interface ICollectionItem {
  content: string;
  description: string;
  type: string;
  id?: string;
  logoUrl: string;
  previewUrl?: string;
  logoFile?: File;
}

const EditCollectionsModal = ({dealItem, setIsOpen, open, onSubmit}: IModalProps) =>{
    const {t} = useTranslation();
    const fileInputs = useRef<HTMLInputElement[]>([]);
    const uploadFileRequest = useUploadRequest({method: 'POST', url: `/user-deals/upload/${dealItem?.id}`});
    const [publishDisable, setPublishDisable] = useState(false);
    const [isLoading, setLoader] = useState(false);
    const [formData, setFormData] = useState<ICollectionItem[]>(dealItem?.userDealContents?.filter((item) => item.type == dealType.COLLECTIONS) || []);

    useEffect(() => {
        validateForm();
      }, [formData]);

    const validateForm = () => {
        const isFormValid = formData.every((item) => item.content.trim() !== '' && isValidHttpUrl(item?.content) && item.description.trim() !== '');
        setPublishDisable(!isFormValid);
    };

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        if (name == 'description' && value?.length > 200) {
            return;
        }
        const newFormData = [...formData];
        newFormData[index] = {
            ...newFormData[index],
            [name]: value,
        };
        setFormData(newFormData);
    };

    const handleFileInputClick = (index: number) => {
        fileInputs.current[index].click();
    };

    const handleAddItem = (logoUrl?: string) => {
        setFormData([...formData, {content: '',
            description: '',
            type: dealType.COLLECTIONS,
            logoUrl: logoUrl || '',
        }]);
    };

    const handleRemoveItem = (index: number) => {
        const newFormData = formData.filter((_, i) => i !== index);
        setFormData(newFormData);
    };

    const handleChangeIcon = (index: number) => {
        handleFileInputClick(index);
    };

    const uploadFile = async (file: File): Promise<string> => {
        const maxSize = 25;
        try {
            if (file && ((file.size/ 1024 / 1024) > maxSize)) {
                toast(<span style={{color: 'black'}}><Trans i18nKey="size_has_exceeded_the_allowed_size" maxSize={maxSize}>{file.name} size has exceeded the allowed size {{maxSize}} Mb </Trans></span>, {autoClose: 3000});
            }
            const body = {
                file: {
                    key: 'file',
                    value: file,
                    fileName: file.name,
                },
            };
            const response = await uploadFileRequest.doRequest(body);
            return response;
        } catch (e) {
            toast('Upload failed', {type: 'error'});
            console.error(e);
            return '';
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        setLoader(true);
        event.preventDefault();
        const updatedFormData = await Promise.all(formData.map(async (item) => {
            if (item.logoFile) {
                const logoUrl = await uploadFile(item.logoFile);
                return {...item, logoUrl, previewUrl: undefined, logoFile: undefined, description: item?.description || ''};
            }
            return item;
        }));
        setFormData(updatedFormData);
        const otherContents: any = dealItem?.userDealContents?.filter((content) => content.type !== dealType.COLLECTIONS)?.map((e: any) => {
            return {...e, description: e?.description == null ? '' : e?.description};
        }) || [];
        await nonHookRequest({url: `/user-deals/${dealItem?.id}`, method: 'PUT', body: {visible: dealItem?.visible, userDealContents: [...updatedFormData, ...otherContents]}});
        setLoader(false);
        setIsOpen(false);
        onSubmit();
    };

    const handleFileChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            const newFormData = [...formData];
            newFormData[index] = {
                ...newFormData[index],
                previewUrl, // Store preview URL temporarily
                logoFile: file, // Store the file to upload later
            };
            setFormData(newFormData);
        }
    };

    return (
    open? <div className={styles.modal}>
      <div className={styles.modal_content}>
        <div className={styles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
        <div className={styles.content_wrapper}>
            <div className={styles.content_headline}>
                {dealItem?.deal.name}
            </div>
            {formData.length < 1 && 
                <div className={styles.no_links}>
                    <img src={noVideo} alt={t('no_collections_found')} />
                    <div className={styles.no_links_text}>{t('no_collections_found')}</div>
                    <br></br>
                </div>
            }
            <form onSubmit={handleSubmit}>
                {formData.map((data, index) => (
                    <div key={index} className={styles.collection_item}>
                        <div
                                    className={styles.upload_button}
                                    onClick={() => handleChangeIcon(index)}
                                >
                                    {(data?.previewUrl || data?.logoUrl) ? (
                                        <img src={data?.previewUrl || data?.logoUrl} className={styles.collection_logo} alt={`Logo ${data?.previewUrl ? 'Preview' : ''}`} />
                                    ) : (
                                        "Upload Image"
                                    )}
                                    <input
                                        type="file"
                                        ref={(el) => el && (fileInputs.current[index] = el)}
                                        id={`file-input-${index}`}
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(index, e)}
                                        style={{display: 'none'}}
                                    />
                                    {(data.previewUrl || data.logoUrl) && (
                                        <div className={styles.hover_preview}>
                                            <CreateIcon />
                                        </div>
                                    )}
                                </div>

                        <div className={styles.collection}>
                            <TextInput
                                type="text"
                                name="description"
                                value={data.description}
                                className={styles.collection_title} 
                                onChange={(e) => handleInputChange(index, e)} label={'Title'} />
                             <div className={styles.typed}>{data?.description?.length}/200</div>
                            <TextInput
                                type="text"
                                name="content"
                                label={'URL'}
                                onChange={(e) => handleInputChange(index, e)}
                                containerClassName={styles.collection_url}
                                value={data.content}
                            />
                        </div>
                        <div onClick={() => handleRemoveItem(index)}>
                            <img src={trash} className={styles.trash} />
                        </div>
                    </div>
                ))}
                <div className={styles.submit}>
                    <ButtonCustom onClick={() => { 
                        handleAddItem(dealItem?.logoUrl || dealItem?.deal.logoUrl);
                    }} className={styles.button} variant="contained" size={"medium"} colortext={themeColor.btnColor} background={themeColor.btnBackground} startIcon={<PlusCircleFilled />}>
                        <div>{t('add_collection')}</div>
                    </ButtonCustom>
                    {isLoading ? <Loader type={'TailSpin'} color="#EB3B5A" height={40} width={40}></Loader> :
                    <ButtonCustom disabled={publishDisable} className={styles.button} onClick={handleSubmit} color={themeColor.color} colortext={themeColor.color} background={themeColor.background} variant="contained" size={"large"}>{t('publish')}</ButtonCustom>}
                </div>
            </form>
        </div>
      </div>
    </div> : <></>
  );
};

export default EditCollectionsModal;
