import clevertap from 'clevertap-web-sdk';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import nonHookRequest from '../../features/API/nonHookRequest';
import {EventTracking} from './event-tracking';

const backendEvents = ['calendar_view_loaded', 'tip_jar_intent', 'payment_intent', 'monetize_link_click'] as const;

const backendTrack = async (eventName: string, data: Record<string, any> = {}) => {
  await nonHookRequest({
    url: '/finance/api/clevertap/monetization_links',
    method: 'POST',
    body: {productType: 'Link', clevertapEvent: eventName, ...data},
    isShortUrl: true,
  });
};

const initialize = () => {
  clevertap.init(envConfig().cleverTapId!);
  clevertap.spa = true;
};


const track = (eventName: string, data: Record<string, any> = {}) => {
  if (backendEvents.includes(eventName as any)) {
    backendTrack(eventName, data);
    return;
  }
  clevertap.event.push(eventName, data);
};

const identify = (id: string, data: Record<string, any> = {}) => {
  const cleverTapData = {
    Site: {
      Identity: id,
      ...data,
    },
  };
  clevertap.onUserLogin.push(cleverTapData);
};

const updateProfile = (data: Record<string, any>) => {
  const cleverTapData = {
    Site: data,
  };
  clevertap.profile.push(cleverTapData);
};

const reset = () => clevertap.logout();

const clevertapEventTracking: EventTracking = {
  initialize,
  track,
  identify,
  updateProfile,
  reset,
};

export default clevertapEventTracking;
